import React, {createContext} from 'react';

const UserProviderContext = createContext({name: 'UserProvider'});
const {Consumer, Provider} = UserProviderContext;

const withUser = (WrappedComponent) => (props) => {
    return (
        <Consumer>
            {user => <WrappedComponent user={user} userId={user?.user_id} {...props}/>}
        </Consumer>
    )
}

export {withUser};
export default Provider;