import React, {useCallback, useEffect, useState, isValidElement} from "react";
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    TextField
} from "@material-ui/core";
import {List} from 'react-virtualized';
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core";
import {isEmpty} from "../helpers/utils";

const CustomAutocomplete = ({
                                error,
                                disabled,
                                label,
                                value,
                                defaultOption,
                                helperText,
                                options,
                                onChange,
                                defaultValue,
                                sort,
                                placeholder,
                                noOptionsText,
                                onInputChange
                            }) => {
    const [internalValue, setInternalValue] = useState(defaultOption?.value ?? defaultValue);
    const [internalOptions, setInternalOptions] = useState([]);

    const getOptionsToDisplay = useCallback(() => {
        const mergeOptions = isEmpty(defaultOption) ? [] : [defaultOption];
        if (sort) {
            return [
                ...mergeOptions,
                ...options
            ];
        } else {
            return [...mergeOptions, ...options];
        }
    }, [defaultOption, options, sort]);

    useEffect(() => {
        if (isEmpty(value) && defaultOption) {
            setInternalValue(defaultOption);
        } else {
            setInternalValue(value ?? defaultValue);
        }
    }, [value, defaultOption, defaultValue]);

    useEffect(() => {
        setInternalOptions(getOptionsToDisplay());
    }, [getOptionsToDisplay]);

    const onInternalChange = (event, value) => {
        const isSearchInput = event.target.nodeName === "INPUT";
        if (!isSearchInput && isEmpty(value) && value?.value !== defaultValue?.value) {
            onChange(defaultOption);
        } else if (!isSearchInput) {
            onChange(value);
        }
    }

    const classes = useStyles();

    return (
        <FormControl variant={"outlined"} size={"small"} fullWidth error={error} margin={"normal"}
                     className={classes.autocomplete}>
            <InputLabel shrink error={error}>
                {label}
            </InputLabel>
            <Autocomplete value={internalValue} error={error}
                          size="small"
                          clearOnBlur
                          disabled={disabled}
                          noOptionsText={noOptionsText ?? "No options"}
                          options={internalOptions ?? []}
                          getOptionLabel={(option) => option?.label ?? ""}
                          getOptionSelected={(option, value) => (option?.value === value?.value)}
                          renderInput={(params) => (
                              <TextField variant="outlined" disabled={disabled} size="small" label={label}
                                         error={error}
                                         placeholder={placeholder || "Search..."} fullWidth {...params}
                                         InputLabelProps={{shrink: true, ...params.InputLabelProps}}/>
                          )}
                          disableListWrap
                          ListboxComponent={ListBoxComponent}
                          onChange={onInternalChange}
                          onInputChange={onInputChange}/>
            <Box>
                {!isEmpty(helperText) && <FormHelperText error={error}>{helperText}</FormHelperText>}
            </Box>
        </FormControl>
    )
}

const ListBoxComponent = React.forwardRef(({children, role, ...otherProps}, ref) => {
    const items = React.Children.toArray(children);
    const itemCount = items.length;
    const itemSize = 40;
    const listHeight = itemSize * itemCount;

    return (
        <Box ref={ref}>
            <Box {...otherProps}>
                <List height={Math.min(listHeight, 250)}
                      width={800}
                      style={{width: "100%"}}
                      rowHeight={itemSize}
                      overscanCount={5}
                      rowCount={itemCount}
                      rowRenderer={(listRowProps) => {
                          if (isValidElement(items[listRowProps.index])) {
                              return React.cloneElement(items[listRowProps.index], {
                                  style: listRowProps.style,
                              });
                          }
                          return null;
                      }}
                      role={role}
                />
            </Box>
        </Box>
    );
});

const useStyles = makeStyles({
    autocomplete: {
        backgroundColor: 'transparent',
        "& input::placeholder": {
            color: '#000000',
            opacity: 1
        },
        "& .Mui-focused input::placeholder": {
            color: '#000000',
            opacity: 0.5
        },
        position: "relative"
    }
});

export default CustomAutocomplete;
