import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Box from "@material-ui/core/Box";
import TablePagination from '@material-ui/core/TablePagination';
import {Checkbox, LinearProgress, TableSortLabel} from "@material-ui/core";
import {useDebouncedCallback} from "use-debounce";
import { setNotification } from "../../../slices/notification";
import {useDispatch, useSelector} from "react-redux";
import TableHead from "@material-ui/core/TableHead";
import StatusToolbarActions from "./StatusToolbarActions";
import PaymentStatusCategoryTabs from "./PaymentStatusCategoryTabs";
import {
    getPageParametersAsObject, objectToRequestParameters,
    pageHasParameters, pagePathnameContains,
    updateBrowserWithUrlParameters

} from "../../../helpers/pageParameters";
import PaymentOrderItems from "./PaymentOrderItems";
import PaymentTableRow from "./PaymentTableRow";
import PaymentApproveFilters from "../PaymentApprove/PaymentApproveFilters";
import {
    resetState,
    getPaymentOrdersList,
    paymentOrdersSelector,
    updateOrdersRequestParameters, getTotalApproved, getTotalManual, getTotalPaid, getTotalNotPayable, getTotalReject
} from "../../../slices/paymentOrders";
import PaymentStatusManualPayDialog from "./PaymentStatusManualPayDialog";
import PaymentStatusRevokeDialog from "./PaymentStatusRevokeDialog";
import PaymentStatusRejectDialog from "./PaymentStatusRejectDialog";
import PaymentOrderSearchExport from "./PaymentOrderSearchExport";
import PaymentStatusManualNotPayableDialog from "./PaymentStatusMarkNotPayableDialog";
import PaymentStatusMarkPaidDialog from "./PaymentStatusMarkPaidDialog";
import api from "../../../helpers/api";
import queryString from "query-string";
import moment from "moment";
import PaymentStatusEditPaymentRefDialog from "./PaymentStatusEditPaymentRefDialog";
import PaymentStatusEditPaymentDateDialog from "./PaymentStatusEditPaymentDateDialog";
import AddEditNotesDialog from "./AddEditNotesDialog";


const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    noWrap:{
        whiteSpace: 'nowrap'
    },
    emptyTable: {
        height: '424px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
        '& td': {
            borderBottom: 0,
        }
    },
}));

const status = [
    'APPROVED',
    'PROCESSING_MANUALLY',
    'PAID,PAID_MANUALLY',
    'CANCELLED,LOST,FAILED',
    'REJECTED'
]

const getEmptyText = active =>{
    switch (active){
        case 0:
            return "No payments to approve";
        case 1:
            return "No manual payments"
        case 2:
            return "No payments";
        case 3:
            return "No payments, not payable"
        default:
            return "No payments rejected"
    }
}

const PaymentStatusTable = () => {
    const { paymentsOrdersList, paymentsOrdersTotal, isLoading, paymentsOrdersFilters, error, errorMessage } =useSelector(paymentOrdersSelector);
    const classes = useStyles();
    const dispatch = useDispatch();

    const [itemsPerPage, setItemsPerPage] = useState(paymentsOrdersFilters.per_page);

    const [page, setPage] = useState(paymentsOrdersFilters.page);

    const [activeCategory, setActiveCategory] = useState(0);

    const [selected, setSelected] = useState([]);

    const [singleItem, setSingleItem] = useState(null);

    const [filterOpen, setFilterOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState(paymentsOrdersFilters.search || "");

    const debouncedSearchTerm = useDebouncedCallback((value)=>{
        dispatch(updateOrdersRequestParameters({...paymentsOrdersFilters, page: 1, search: searchTerm, }));
    }, 500);

    const searchHandler = value =>{
        setSearchTerm(value);
        debouncedSearchTerm()
    }
    const [pageInitialized, setPageInitialized] = useState(false);

    const [openHistory, setOpenHistory] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openRevoke, setOpenRevoke] = useState(false);
    const [openManual, setOpenManual] = useState(false);
    const [openPaid, setOpenPaid] = useState(false);
    const [openNotPayable, setOpenNotPayable] = useState(false);
    const [openEditPaymentRefCode,setOpenEditPaymentRefCode] = useState(false);
    const [openEditPaymentDate,setOpenEditPaymentDate] = useState(false);
    const [openAddEditNote,setOpenAddEditNote] = useState(false);

    useEffect(() => {
        if (error) {
            dispatch(setNotification(errorMessage, "error"));
        }
    }, [error, errorMessage, dispatch]);

    useEffect(() => {
        if(!pageInitialized){
            if(pageHasParameters() && pagePathnameContains("status")){
                const pageParams = getPageParametersAsObject();
                dispatch(updateOrdersRequestParameters(pageParams));
                const tab = status.indexOf(pageParams.status);
                if(tab !== -1){
                    setActiveCategory(tab);
                }
            }else{
                updateBrowserWithUrlParameters(paymentsOrdersFilters);
                dispatch(resetState('APPROVED'));
            }
            setPageInitialized(true);
        }

    }, [dispatch, pageInitialized, paymentsOrdersFilters]);

    useEffect(() => {
        if(pageInitialized) {
            dispatch(getPaymentOrdersList(objectToRequestParameters(paymentsOrdersFilters)));
            updateBrowserWithUrlParameters(paymentsOrdersFilters);
        }
    }, [dispatch, pageInitialized, paymentsOrdersFilters]);

    const handleChangePage = (event, newPage) => {
        if (newPage>=0) {
            setPage(newPage+1);
        }
        dispatch(updateOrdersRequestParameters({...paymentsOrdersFilters, page: newPage+1 }));
    }
    const onChangeTab= (tabId) => {
        setActiveCategory(tabId);
        setSelected([]);
        setPage(1);
        setSearchTerm("");
        dispatch(updateOrdersRequestParameters({...paymentsOrdersFilters, page: 1, search: null, status: status[tabId]}));
    }
    const handleChangeRowsPerPage = (event) => {
        setItemsPerPage(+event.target.value);
        setPage(1);
        dispatch(updateOrdersRequestParameters({...paymentsOrdersFilters, page: 1, per_page: +event.target.value}));
    }

    const onSelectAll = (event) => {
        if (event.target.checked) {
            setSelected(paymentsOrdersList);
        } else {
            setSelected([]);
        }
    }

    const onSelect = (orderSelected) => {
        const exist = selected.find(f=>f.order_id === orderSelected.order_id)
        if (exist) {
            setSelected(selected.filter(s => s.order_id !== orderSelected.order_id));
        } else {
            setSelected([...selected, orderSelected]);
        }
    }

    const handleExport = () => {
        if(paymentsOrdersTotal === 0){
            return
        }
        const exportFilters = {...paymentsOrdersFilters};
        delete exportFilters.per_page;
        delete exportFilters.page;
        delete exportFilters.search;
        const fileName = `payment_${status[activeCategory]}_${moment().format('DD-MM-YYYY')}.csv`;
        api.payment.statusExport(queryString.stringify(exportFilters), fileName);
    }

    const handleSingleAction = (cbk, item) => {
        setSingleItem(item);
        if(typeof cbk === 'function'){
            cbk(true);
        }
    }
    const clearAll = () =>{
        setOpenHistory(false);
        setOpenRevoke(false);
        setOpenReject(false);
        setOpenPaid(false);
        setOpenNotPayable(false);
        setOpenManual(false);
        setOpenEditPaymentRefCode(false);
        setOpenEditPaymentDate(false);
        setOpenAddEditNote(false);
        setSingleItem(null);
    }

    const getSingleItem = () => {
        return singleItem ? [singleItem] : null
    }

    const onSaveSuccess = () =>{
        setSelected([]);
        clearAll();
        const params = "page=1&per_page=1&status=";
        dispatch(getTotalApproved(params+status[0]));
        dispatch(getTotalManual(params+status[1]));
        dispatch(getTotalPaid(params+status[2]));
        dispatch(getTotalNotPayable(params+status[3]));
        dispatch(getTotalReject(params+status[4]));
        dispatch(updateOrdersRequestParameters({...paymentsOrdersFilters, page: 1}));
    }
    const direction = paymentsOrdersFilters?.sort ? paymentsOrdersFilters.sort.split(":")[1] : "desc";
    const activeSortedColumn = paymentsOrdersFilters?.sort ? paymentsOrdersFilters.sort.split(":")[0] : "title";
    const handleSortEvent = column => {
        const sortParams =  paymentsOrdersFilters?.sort ? paymentsOrdersFilters.sort.split(":") : [];
        const sortField = sortParams.length > 0 ? sortParams[0] : "";
        const sortDirection = sortParams.length > 0 ? sortParams[1] : "";
        const isAsc = column === sortField && sortDirection === "asc";
        dispatch(updateOrdersRequestParameters({...paymentsOrdersFilters, page: 1, sort: `${column}:${(isAsc ? "desc" : "asc")}`}));
    }

    const enableCheckbox = activeCategory === 0;
    const enableMenu = [0,1,2,3,4].includes(activeCategory);
    const notesColumn = [1,2,3,4].includes(activeCategory);
    return (
        <Box>
            <PaymentStatusCategoryTabs value={activeCategory} onChange={onChangeTab} />
            {
                selected.length > 0 ? <StatusToolbarActions actionsFor={activeCategory} selected={selected} openManual={setOpenManual} openRevoke={setOpenRevoke} openReject={setOpenReject} /> :
                    <PaymentOrderSearchExport searchValue={searchTerm}  onChangeSearchTerm={searchHandler} onClickExport={handleExport} />
            }

            <TableContainer>
                <Table size={'medium'} className={classes.table}>
                    <TableHead>
                        <TableRow>
                            { enableCheckbox && <TableCell padding={"checkbox"}>
                                <Checkbox
                                    onChange={(e) => onSelectAll(e)}/>
                            </TableCell>}
                            <TableCell className={classes.noWrap}>
                                <TableSortLabel onClick={()=>handleSortEvent("order_id")} active={activeSortedColumn === "order_id"} direction={direction}>
                                    Payment ID
                                </TableSortLabel>

                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={()=>handleSortEvent("first_name,last_name")} active={activeSortedColumn === "first_name,last_name"} direction={direction}>
                                Creator
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.noWrap}>
                                Creator ID
                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={()=>handleSortEvent("statement_code")} active={activeSortedColumn === "statement_code"} direction={direction}>
                                    Statement
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.noWrap}>
                                Payment date
                            </TableCell>
                            <TableCell className={classes.noWrap}>
                                Payment ref code
                            </TableCell>
                            <TableCell className={classes.noWrap}>
                                Gross amount
                            </TableCell>
                            <TableCell>
                                Fees
                            </TableCell>
                            <TableCell className={classes.noWrap}>
                                Payment amount
                            </TableCell>
                            {notesColumn && <TableCell align={'left'}>
                                Note
                            </TableCell>}
                            {enableMenu && <TableCell>

                            </TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            paymentsOrdersList.map((payment) => {
                                const isSelected = Boolean(selected.find(f=>f.order_id === payment.order_id));

                                return <PaymentTableRow
                                    activeCategory={activeCategory}
                                    enableCheckbox={enableCheckbox}
                                    payment={payment}
                                    onSelect={onSelect}
                                    key={payment.order_id}
                                    isSelected={isSelected}
                                    reasonColumn={false}
                                    paymentDate={true}
                                    handleSingleAction={handleSingleAction}
                                    setOpenReject={setOpenReject}
                                    setOpenPaid={setOpenPaid}
                                    setOpenRevoke={setOpenRevoke}
                                    setOpenManual={setOpenManual}
                                    enableMenu={enableMenu}
                                    setOpenNotPayable={setOpenNotPayable}
                                    setOpenEditPaymentRefCode={setOpenEditPaymentRefCode}
                                    setOpenEditPaymentDate={setOpenEditPaymentDate}
                                    setOpenAddEditNote={setOpenAddEditNote}
                                    setOpenHistory={setOpenHistory} />
                            })
                        }
                        {
                            (!isLoading && searchTerm.trim() !== "" && paymentsOrdersTotal === 0) &&
                            <TableRow>
                                <TableCell colSpan={12}>
                                    <Box justifyContent={'center'} display={'flex'} py={6}>
                                        No results found
                                    </Box>
                                </TableCell>
                            </TableRow>
                        }
                        {
                            (!isLoading && searchTerm.trim() === "" && paymentsOrdersTotal === 0) &&
                            <TableRow>
                                <TableCell colSpan={12}>
                                    <Box justifyContent={'center'} display={'flex'} py={6}>
                                        {
                                            getEmptyText(activeCategory)
                                        }
                                    </Box>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={paymentsOrdersTotal}
                rowsPerPage={itemsPerPage}
                page={page -1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                backIconButtonProps={{disabled: isLoading || paymentsOrdersList.length === 0 || page===1}}
                nextIconButtonProps={{disabled: isLoading || paymentsOrdersList.length === 0 || page>=Math.ceil(paymentsOrdersTotal/itemsPerPage)}}
            />
            {isLoading && <LinearProgress/>}
            { filterOpen && <PaymentApproveFilters isOpen={filterOpen} onClose={() => setFilterOpen(false)}/>}
            <PaymentOrderItems isOpen={openHistory} payment={singleItem}  onClose={clearAll} />
            <PaymentStatusManualPayDialog onClose={clearAll} selected={selected} singleItem={getSingleItem(singleItem)} isOpen={openManual} onSaveSuccess={onSaveSuccess} />
            <PaymentStatusRevokeDialog onClose={clearAll} selected={selected} singleItem={getSingleItem(singleItem)} isOpen={openRevoke} onSaveSuccess={onSaveSuccess}/>
            {openReject && <PaymentStatusRejectDialog onClose={clearAll} selected={selected} singleItem={getSingleItem(singleItem)} isOpen={openReject} onSaveSuccess={onSaveSuccess}/>}
            {openPaid && <PaymentStatusMarkPaidDialog onClose={clearAll} singleItem={singleItem} isOpen={openPaid} onSaveSuccess={onSaveSuccess}/>}
            {openNotPayable && <PaymentStatusManualNotPayableDialog onClose={clearAll} singleItem={singleItem} isOpen={openNotPayable} onSaveSuccess={onSaveSuccess}/>}
            {openEditPaymentRefCode &&
                <PaymentStatusEditPaymentRefDialog isOpen={openEditPaymentRefCode} singleItem={singleItem}
                                                   onClose={clearAll} onSaveSuccess={onSaveSuccess}/>}
            {openEditPaymentDate &&
                <PaymentStatusEditPaymentDateDialog isOpen={openEditPaymentDate} singleItem={singleItem}
                                                    onClose={clearAll} onSaveSuccess={onSaveSuccess}/>}
            {openAddEditNote && <AddEditNotesDialog isOpen={openAddEditNote} singleItem={singleItem}
                                                    onClose={clearAll} onSaveSuccess={onSaveSuccess}/>}
        </Box>
    );
}

export default PaymentStatusTable;