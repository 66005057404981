import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import React from "react";

const PaymentApproveTableHead = () =>{
    return <TableHead>
        <TableRow>
            <TableCell>
                Item/Description
            </TableCell>
            <TableCell>
                Client
            </TableCell>
            <TableCell>
                Source
            </TableCell>
            <TableCell>
                Statement
            </TableCell>
            <TableCell>
                Date created
            </TableCell>
            <TableCell>
                Amount
            </TableCell>
        </TableRow>
    </TableHead>
}

export default PaymentApproveTableHead;