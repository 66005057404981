import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LinearProgress, makeStyles, Typography} from '@material-ui/core';
import {campaignAdminSelector, reloadSection, updateOpenDialog} from "../../../slices/campaignAdmin";
import {useDispatch, useSelector} from "react-redux";
import Client from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";



const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(3)
    }

}))

const RemoveProductSeedingBlock = ({open}) => {
    const {row, draft} = useSelector(campaignAdminSelector);
    const [isSaving, setIsSaving] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const onConfirmEvent = () => {
        setIsSaving(true);
        const campaign = {};
        campaign.id = row.id;
        campaign.productSeedingOverrideFlag = true;
        Client.CampaignApi.edit()(
            response =>{
                setIsSaving(false);
                dispatch(updateOpenDialog({
                    dialog: "RemoveProductSeedingBlock",
                    value: false,
                    row: null
                }))
                dispatch(reloadSection(draft))
            },
            ()=>{
                setIsSaving(false);
                dispatch(setNotification("Something went wrong", "error"));
            })(campaign)

    }
    const handleClose = () => {
        dispatch(updateOpenDialog({
            dialog: "RemoveProductSeedingBlock",
            value: false,
            row: null
        }))
    }
    return <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle >Remove product seeding block?</DialogTitle>
            <DialogContent style={{minWidth: 400}}>
                <Typography>
                    This will allow the campaign to be confirmed by the client.
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button autoFocus onClick={handleClose} variant="contained">

                    Cancel

                </Button>
                <Button onClick={onConfirmEvent} style={{width: "98px"}} color="primary"
                        autoFocus variant="contained">
                    Yes
                </Button>
            </DialogActions>
            {isSaving && <LinearProgress/>}
        </Dialog>
    </div>;
}

export default RemoveProductSeedingBlock;