import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 0,
        '& li': {
            padding: 0,
            '& div:first-child': {
                minWidth: 30,
                width: 30,
            }
        }
    },
    grayIconColor: {
        fill: '#e0e0e0',
    },
    greenIconColor: {
        fill: '#00e676',
    }
}));

const PasswordValidator = (props) => {
    const {password, onValidate} = props;
    const classes = useStyles();

    const isEmpty = password.trim() === '';
    const isCaseValid = /(?=.*[a-z])(?=.*[A-Z])/.test(password);
    const isSpecialsValid = /(?=.*[$@$!%*?&])/.test(password);
    const isLengthValid = password.trim().length >= 8;
    
    const caseIconColor = isCaseValid ? classes.greenIconColor : classes.grayIconColor;
    const specialsIconColor = isSpecialsValid ? classes.greenIconColor : classes.grayIconColor;
    const lengthIconColor = isLengthValid ? classes.greenIconColor : classes.grayIconColor;

    const intl = useIntl();
    const caseText = intl.formatMessage({
        id: 'PasswordValidator.OneUppercaseOneLowercase',
        defaultMessage: 'Uppercase and lowercase letters'
    });
    const specialsText = intl.formatMessage({
        id: 'PasswordValidator.OneSpecialChar',
        defaultMessage: 'At least one special character'
    });
    const lengthText = intl.formatMessage({
        id: 'PasswordValidator.MinimumEightCharacters',
        defaultMessage: 'Minimum 8 characters or more'
    });

    useEffect(() => {
        const isValid = !isEmpty && isCaseValid && isSpecialsValid && isLengthValid;
        onValidate(isValid);
    }, [isEmpty, isCaseValid, isSpecialsValid, isLengthValid, onValidate]);

    return (
        <List dense={true} className={classes.root}>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon className={caseIconColor} fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary={caseText} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon className={specialsIconColor} fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary={specialsText} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon className={lengthIconColor} fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary={lengthText} />
            </ListItem>
        </List>
    );
}

export default PasswordValidator;