import {createSlice} from '@reduxjs/toolkit';
import api from '../helpers/api';

export const initialState = {
    resourcesList: {
        total: 0,
        items: [],
        page: 1
    },
    resource: null,
    isEmpty: false,
    deleted: false,
    failed: false,
    saved: false,
    updated: false,
    firstLoadList: true,
    fetching: false,
    sort: '',
    client_id: 1758930
};

const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        getResourceListSuccess: (state, {payload}) => {
            state.resourcesList = payload[0]
            state.firstLoadList = false;
        },
        getResourceListFailure: (state, {payload}) => {
            state.isEmpty = true;
            state.firstLoadList = false;
        },
        saveResourceSuccess: (state, {payload}) => {
            state.saved = true;
            state.firstLoadList = true;
        },
        saveResourceFailure: (state, {payload}) => {
            state.failed = true;
        },
        resetResource: (state)=>{
            state.saved = false;
            state.failed = false;
            state.deleted = false;
            state.firstLoadList = true;
            state.firstLoadConfiguration = true;
            state.compiledData = null;
            state.updated = false;
        },
        updateResourceSuccess: (state, {payload}) =>{
            state.updated = true;
        },
        updateResourceFailure: (state, {payload}) =>{
            state.updated = false;
        },
        deleteResourceSuccess: (state, {payload}) =>{
            state.deleted = true;
        },
        deleteResourceFailure: (state, {payload}) =>{
            state.delete = false;
        }
    }
});

export default resourcesSlice.reducer;
export const resourcesSelector = state => state.resources;
const {getResourceListSuccess, getResourceListFailure, saveResourceSuccess, saveResourceFailure,
    resetResource, updateResourceFailure, updateResourceSuccess, deleteResourceFailure, deleteResourceSuccess}
    = resourcesSlice.actions;

export const getResources = (parameters) => dispatch  => api.getResourcesList(parameters)(
    resp => dispatch(getResourceListSuccess(resp.data)),
    err => dispatch(getResourceListFailure(err))
)
export const updateResourceImage = (data) => dispatch => {
    const formData = new FormData();
    formData.append('upfile', data.file);
    const params = {client_id: data.client_id};
    return api.updateResourceImage(data.resource_id, params)(
        resp => dispatch(saveResourceSuccess(resp.data)),
        err => dispatch(saveResourceFailure(err))
    )(formData)
}
export const uploadResource = (data) => dispatch => {
    const formData = new FormData();
    formData.append('upfile', data.file);
    const params = {client_id: data.client_id};
    return api.uploadResource(params)(
        resp => dispatch(saveResourceSuccess(resp.data)),
        err => dispatch(saveResourceFailure(err))
    )(formData)
}
export const deleteResource = (resource_id, params) => dispatch => api.deleteResource(resource_id, params)(
    resp => dispatch(deleteResourceSuccess(resp.data)),
    err=>dispatch(deleteResourceFailure(err))
    )
export const updateMetadataResource = (resource_id, params, newTitle) => dispatch => {
    return api.updateMetadataResource(resource_id, params)(
        resp => dispatch(updateResourceSuccess(resp.data)),
        err => dispatch(updateResourceFailure(err))
    )(newTitle)
}

export const resetSave = () => dispatch =>{
    dispatch(resetResource());
}