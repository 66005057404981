import HelpIcon from "@material-ui/icons/HelpOutline";
import React from "react";
import {Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
    tooltip: {
        maxWidth: 300,
        fontSize: theme.typography.body2.fontSize,
    },
    helpIcon: {
        cursor: "pointer",
        fill: theme.palette.grey[400],
        '&:hover': {
            fill: theme.palette.grey[800],
        }
    },
    input: {
        paddingRight: "7px",
    }
}));

const HelpAdornment = ({helpTooltipText, onClick}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const placement = matches ? "right" : "left";
    const enableClick = typeof onClick === "function";

    if (enableClick) {
        return (
            <InputAdornment position="end" onClick={onClick} onMouseUp={e => e.stopPropagation()}>
                <HelpIcon classes={{root: classes.helpIcon}}/>
            </InputAdornment>
        )
    } else {
        return (
            <InputAdornment position="end">
                <Tooltip title={helpTooltipText} placement={placement} classes={{tooltip: classes.tooltip}} arrow>
                    <HelpIcon classes={{root: classes.helpIcon}}/>
                </Tooltip>
            </InputAdornment>
        )
    }
}

const withHelpIconForInput = (WrappedComponent) => {
    const InputWrappedWithHelpIcon = ({helpTooltipText, startAdornment, onClickHelpIcon, ...props}) => {
        const classes = useStyles();
        const enableToolTip = (helpTooltipText && helpTooltipText.trim() !== "") || typeof onClickHelpIcon === "function";

        if (enableToolTip) {
            return (
                <WrappedComponent {...props}
                                  InputProps={{
                                      startAdornment,
                                      endAdornment: <HelpAdornment helpTooltipText={helpTooltipText} onClick={onClickHelpIcon}/>,
                                      classes: {adornedEnd: classes.input}
                                  }}/>
            )
        } else {
            return (
                <WrappedComponent {...props}
                                  InputProps={{
                                      startAdornment,
                                  }}/>
            )
        }

    }
    return InputWrappedWithHelpIcon;
}

export default withHelpIconForInput;