import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux'
import {Box} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import UsersTable from "../components/AccountAdmin/UsersTable";
import {setPageTitle} from "../slices/page";
import {Paper} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
        "& .MuiPaper-root": {
            marginBottom: "70px",
        }
    },
}));

const AdminUsers = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle("Account admin"));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);

    return (
        <Box className={classes.root}>
            <Paper>
                <UsersTable/>
            </Paper>
        </Box>
    );
}

export default AdminUsers;