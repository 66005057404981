import Link from "@material-ui/core/Link";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, {useState} from "react";
import {isEmpty, strings} from "../../helpers/utils";
import UnreadIndicator from "./UnreadIndicator";
import {Box} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import moment from "moment";
import ChatAvatar from "../Chat/ChatAvatar";

const formatDate = (dateString) => {
    if (!isEmpty(dateString)) {
        return moment.utc(dateString).format("M/D/yyyy h:mm:ss a")
    } else {
        return "--";
    }
}
const getManagerValue = (manager) =>{
    if(manager){
        return manager.full_name;
    }
    return "--"
}

const ChatsTableRow = ({chat, onClick}) => {
    const theme = useTheme();
    const [isHover, setIsHover] = useState(false);

    const getChatTitle = () => {
        if (!isEmpty(chat.support_chat_details.company_name)) {
            return `${chat.support_chat_details.full_name}, ${chat.support_chat_details.company_name}`;
        } else {
            return chat.support_chat_details.full_name;
        }
    }

    const activePeers = chat?.peers?.filter(f=>f.is_active);
    const totalPeers = activePeers?.length > 0 ? activePeers?.length : '--';
    const isUnread = chat?.support_chat_details?.unread;
    
    return (
        <TableRow
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            hover
            onClick={() => onClick(chat)}
            style={{
              backgroundColor: isUnread ? theme.palette.action.hover : "inherit",
              fontWeight: isUnread ? "bold" : "normal",
              cursor: "pointer"
            }}>
            <TableCell align="left">
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        width: "100%",
                    }}>
                    <UnreadIndicator mr={1} unread={isUnread} />
                    {strings.stringOrDash(formatDate(chat?.last_message?.time))}
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                    }}>
                        <ChatAvatar chatDetails={chat.support_chat_details} />
                        <Box sx={{ marginLeft: theme.spacing(1) }}>
                            {getChatTitle()}
                        </Box>
                </Box>
            </TableCell>
            <TableCell>
                {getManagerValue(chat.support_chat_details?.sales_manager)}
            </TableCell>
            <TableCell>
                {getManagerValue(chat.support_chat_details?.account_manager)}
            </TableCell>
            <TableCell>{totalPeers}</TableCell>
            <TableCell
                align="left"
                style={{ whiteSpace: "nowrap", width: "200px" }}>
                {strings.stringOrDash(formatDate(chat.creation_time))}
            </TableCell>
            <TableCell align="center" style={{ width: "110px" }}>
                {isHover && (
                    <Link
                        onClick={() => onClick(chat)}
                        component={"button"}
                        style={{ whiteSpace: "nowrap" }}>
                        View chat →
                    </Link>
                )}
            </TableCell>
        </TableRow>
    );
}

export default ChatsTableRow;