import {FormControl, LinearProgress, Paper, TextField} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import {isValidEmail} from "../../../helpers/validators";
import {isEmpty} from "../../../helpers/utils";
import Api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";
import RequestError from "../../../helpers/RequestError";
import {useDispatch} from "react-redux";

const UserBasicInfo = ({user, loading, onCancel, onUpdateSuccess}) => {
    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [showErrors, setShowErrors] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        setInProgress(loading);
    }, [loading]);

    useEffect(() => {
        if (user) {
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setEmail(user.email);
        } else {
            setFirstName("");
            setLastName("");
            setEmail("");
        }
    }, [user]);

    const onSave = () => {
        if (isEmpty(firstName) || isEmpty(lastName) || !isValidEmail(email)) {
            setShowErrors(true);
        } else {
            setShowErrors(false);
            setInProgress(true);
            Api.updateUser(user.user_id)(
                () => onUpdateSuccess(),
                (error) => dispatch(setNotification((new RequestError(error).getMessage()), "error"))
            )({first_name: firstName, last_name: lastName, email}).then(() => setInProgress(false));
        }
    }

    return (
        <Box>
            <Paper>
                <Box p={2} pt={1}>
                    <FormControl fullWidth margin={"normal"}>
                        <TextField label={"First name"}
                                   error={showErrors && isEmpty(firstName)}
                                   helperText={showErrors && isEmpty(firstName) ? "This field is required." : ""}
                                   variant="outlined"
                                   size="small"
                                   fullWidth
                                   disabled={inProgress}
                                   value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth margin={"normal"}>
                        <TextField label={"Last name"}
                                   error={showErrors && isEmpty(lastName)}
                                   helperText={showErrors && isEmpty(lastName) ? "This field is required." : ""}
                                   variant="outlined"
                                   size="small"
                                   fullWidth
                                   disabled={inProgress}
                                   value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth margin={"normal"}>
                        <TextField label={"Email address"}
                                   error={showErrors && !isValidEmail(email)}
                                   helperText={showErrors && !isValidEmail(email) ? "This field is required." : ""}
                                   variant="outlined"
                                   size="small"
                                   fullWidth
                                   disabled={inProgress}
                                   value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </FormControl>
                </Box>
                {inProgress && <LinearProgress/>}
            </Paper>
            <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
                <Box mr={2} width={"110px"}>
                    <Button variant={"outlined"} onClick={onCancel} disabled={inProgress} width={"100px"}
                            fullWidth>Cancel</Button>
                </Box>
                <Box width={"110px"}>
                    <Button variant={"contained"} color={"primary"} onClick={onSave} disabled={inProgress}
                            fullWidth>Save</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default UserBasicInfo;