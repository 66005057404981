import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CheckIcon from '@material-ui/icons/Check';
import {Link} from "@material-ui/core";
import CurrencyDisplay from "../CurrencyDisplay";

const getMontlyFeeValue = price =>{
    if(!price){
        return "$0.00";
    }
    return <CurrencyDisplay value={price} penniesToDollars={true} />
}

const PlansTableRow = ({plan, onClick}) =>{
    return <TableRow data-testid={'plan-item'}>
        <TableCell>
            <Link underline={'none'} onClick={()=>onClick(plan)}>{plan.name}</Link>
        </TableCell>
        <TableCell>
            {getMontlyFeeValue(plan.base_price_pennies)}
        </TableCell>
        <TableCell>
            {plan.social_enabled ? <CheckIcon /> : ""}
        </TableCell>
        <TableCell>
            {plan.site_enabled ? <CheckIcon /> : ""}
        </TableCell>
        <TableCell>
            {plan.team_enabled ? <CheckIcon /> : ""}
        </TableCell>
        <TableCell>
            {plan.enabled ? <CheckIcon /> : ""}
        </TableCell>
        <TableCell>
            {plan.default ? <CheckIcon /> : ""}
        </TableCell>
    </TableRow>
}
export default PlansTableRow;