import React from "react";
import HelpIcon from "@material-ui/icons/HelpOutline";
import {Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Box} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    tooltip: {
        maxWidth: 300,
        fontSize: theme.typography.body2.fontSize,
    },
    icon: {
        paddingLeft: "7px",
        fill: theme.palette.grey[400],
        '&:hover': {
            fill: theme.palette.grey[800],
        }
    },
}));

const HelpAdornment = ({helpTooltipText, label}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const placement = matches ? "right" : "left";
    return (
        <Box display={"flex"} alignItems={"center"}>
            {label}
            <Tooltip title={helpTooltipText} placement={placement} classes={{tooltip: classes.tooltip}} arrow>
                <HelpIcon classes={{root: classes.icon}}/>
            </Tooltip>
        </Box>
    )
}

const withHelpIconForSwitch = (WrappedComponent) => {
    const SwitchWrappedWithHelpIcon = ({label, helpTooltipText, ...props}) => {
        const enableToolTip = (helpTooltipText && helpTooltipText.trim() !== "");
        return (
            <WrappedComponent {...props}
                              label={enableToolTip ? <HelpAdornment helpTooltipText={helpTooltipText} label={label}/> : label}/>
        )
    }
    return SwitchWrappedWithHelpIcon;
}

export default withHelpIconForSwitch;