import {Divider, Drawer} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import ClientAutocomplete from "../ClientAutocompleteSelect";

const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        minWidth: 440,
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "calc(100vw)",
        },
        [theme.breakpoints.up('sm')]: {
            width: 400,
        },
    },
}));

const CampaignAdminFilters = ({isOpen, onClose, onClientChange, value}) => {
    const classes = useStyles();

    return (
        <Drawer open={isOpen} onClose={onClose} anchor={"right"}>
            <Box display={"flex"} p={2}>
                <Typography variant="h6" component={"div"} className={classes.title}>
                    Filters
                </Typography>
                <IconButton edge="end" color="inherit" onClick={onClose} className={classes.closeButton}>
                    <CloseIcon/>
                </IconButton>
            </Box>
            <Divider/>
            <Box className={classes.content} p={2} mt={1} height={"100%"}>
                <ClientAutocomplete onChange={onClientChange} value={value} />
            </Box>
        </Drawer>
    )
}

CampaignAdminFilters.defaultProps = {
    filtersFor: "approve"
}

export default CampaignAdminFilters;