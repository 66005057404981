import {createSlice} from '@reduxjs/toolkit';
import api from '../helpers/api';
import RequestError from '../helpers/RequestError';

export const initialState = {
    user: null,
    isAuthenticated: null,
    authError: false,
    authErrorMessage: '',
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authSuccess: (state, {payload}) => {
            state.user = payload;
            state.isAuthenticated = true;
        },
        authFailure: (state, {payload}) => {
            if (payload.status === 401) {
                state.isAuthenticated = false;
            } else {
                state.authError = true;
                state.authErrorMessage = payload.message;
            }
        },
    },
});

export const authSelector = state => state.auth;
export default authSlice.reducer;

const {authSuccess, authFailure} = authSlice.actions;
export const authenticate = () => dispatch  => api.getMe()(
    response => dispatch(authSuccess(response.data)),
    error => {
        const respError = new RequestError(error)
        dispatch(authFailure({
            status: respError.getHttpStatusCode(),
            message: respError.getMessage()
        }));
    }
);