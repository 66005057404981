import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Box from "@material-ui/core/Box";
import {withRouter} from "react-router";
import {useDebounce} from "use-debounce";
import routes from "../../helpers/routes";
import {isEmpty} from "../../helpers/utils";
import ClientsTableHead from "./ClientsTableHead";
import TablePagination from '@material-ui/core/TablePagination';
import {LinearProgress, Paper} from "@material-ui/core";
import ClientsTableRow from "./ClientsTableRow";
import ClientsTableToolbar from "./ClientsTableToolbar";
import {clientsSelector, getClients} from "../../slices/clients";
import {useDispatch, useSelector} from "react-redux";
import {setNotification} from "../../slices/notification";
import {useParams} from "react-router-dom";
import ClientDetailsWrapper from "../ClientDetails/ClientDetailsWrapper";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    emptyTable: {
        height: '424px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
        '& td': {
            borderBottom: 0,
        }
    },
}));

const ClientsTable = ({history}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {clients, totalClients, isClientsInProgress, clientsError, isClientListInvalid} = useSelector(clientsSelector);

    const [error, setError] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const [inProgress, setInProgress] = useState(true);
    const [client_id, setClientId] = useState(null);
    const {clientId} = useParams();


    useEffect(() => {
        if (isClientListInvalid) {
            dispatch(getClients(page, itemsPerPage, debouncedSearchTerm));
        }
    }, [page, itemsPerPage, debouncedSearchTerm, isClientListInvalid, dispatch]);

    useEffect(() => {
        if (isClientsInProgress) {
            setInProgress(true);
        }else{
            setInProgress(false);
        }
    }, [isClientsInProgress]);

    useEffect(() => {
        if (isClientsInProgress === false && totalClients === 0) {
            setInProgress(false);

        }
    }, [isClientsInProgress, totalClients]);

    useEffect(() => {
        if (clientsError) {
            setInProgress(false);
            dispatch(setNotification(clientsError, "error"));
        }
    }, [clientsError, dispatch]);

    useEffect(() => {
        dispatch(getClients(1, itemsPerPage, debouncedSearchTerm));
    }, [itemsPerPage, debouncedSearchTerm, dispatch]);



    useEffect(() => {
        if (error !== null) {
            setError(null);
            dispatch(setNotification(error, "error"));
        }
    }, [error, dispatch]);

    useEffect(() => {
        if(clientId){
            setClientId(clientId);
        }
    }, [clientId]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
        dispatch(getClients(newPage + 1, itemsPerPage, debouncedSearchTerm));
    };

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setPage(1);
    };

    const onClickDetails = (client) => {
        history.push(routes.getAdminClientsUrl()+`/${client.client_id}/general`);
    }

    const handleClose = () =>{
        setClientId(null);
        history.push(routes.getAdminClientsUrl());
    }

    return (
        <Box>
            <Box pb={2}>
                <Paper>
                    <ClientsTableToolbar onChangeSearchTerm={setSearchTerm} searchTerm={searchTerm}/>
                </Paper>
            </Box>
            <Paper>
            <TableContainer>
                <Table size={'medium'} className={classes.table}>
                    <ClientsTableHead/>
                    <TableBody>
                        {
                            clients.map((client) => <ClientsTableRow
                                key={client.client_id}
                                client={client}
                                onClickDetails={onClickDetails}/>)
                        }
                        {
                            clients.length >= 1 && (itemsPerPage - clients.length) > 0 && (
                                <TableRow style={{height: (53) * (itemsPerPage - clients.length)}}>
                                    <TableCell colSpan={5} style={{border: "none"}}/>
                                </TableRow>
                            )
                        }
                        {
                            (clients.length === 0) && (
                                <TableRow className={classes.emptyTable}>
                                    {
                                        isEmpty(error) &&
                                        <TableCell colSpan={4}>No clients found.</TableCell>
                                    }
                                    {
                                        !isEmpty(error) &&
                                        <TableCell colSpan={4}>Error loading client list.</TableCell>
                                    }
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={totalClients}
                rowsPerPage={itemsPerPage}
                page={page ? page - 1 : 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeItemsPerPage}
                backIconButtonProps={{disabled: inProgress || page===1}}
                nextIconButtonProps={{disabled: inProgress || page===Math.ceil(totalClients/itemsPerPage)}}
            />
            {inProgress && <LinearProgress/>}
            </Paper>
            {Boolean(client_id) && <ClientDetailsWrapper open={Boolean(client_id)} client_id={client_id} onClose={()=>handleClose()}/>}
        </Box>
    );
};

export default withRouter(ClientsTable);