import {createSlice} from "@reduxjs/toolkit";
import api from "../helpers/api";
import RequestError from "../helpers/RequestError";

const initialState = {
    paymentsApproveList: [],
    paymentsApproveTotal: 0,
    isLoading: true,
    paymentApproveFilters: {
        page: 1,
        per_page: 10,
        search: null,
        client_id: null,
        user_id: null,
        status: 'pending',
        sort: "item_timestamp:desc",

    },
    paymentStatusTotal: {
        approved: 0,
    },
    error: false,
    errorMessage: "",
}

const paymentApproveSlice = createSlice({
    name: "paymentApprove",
    initialState,
    reducers: {
        getPaymentApproveSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.paymentsApproveList = payload.items;
            state.paymentsApproveTotal = payload.total;
        },
        getPaymentApproveFailed: (state, {payload}) => {
            state.isLoading = false;
            state.error = true;
            state.errorMessage = payload;
        },
        resetPaymentApprove: (state, {payload}) => {
            state.paymentApproveFilters = initialState.paymentApproveFilters;
            state.paymentsApproveList = [];
            state.paymentsApproveTotal = 0;
            if (payload) {
                state.paymentApproveFilters.status = payload;
            }
        },
        updateParameters: (state, {payload}) => {
            state.paymentApproveFilters = payload;
        },
        startLoading: (state) => {
            state.isLoading = true;
        },
        updateTotal: (state, {payload}) => {
            state.paymentStatusTotal[payload.status] = payload.total;
        }
    }
});

export default paymentApproveSlice.reducer;

export const paymentApproveSelector = state => state.paymentApprove;

const {
    getPaymentApproveSuccess,
    getPaymentApproveFailed,
    startLoading,
    updateParameters,
    resetPaymentApprove,
    updateTotal
} = paymentApproveSlice.actions;

export const getPaymentApproveList = (params) => dispatch => {
    dispatch(startLoading());
    return api.payment.approve(params)(
        response => dispatch(getPaymentApproveSuccess(response.data)),
        error => dispatch(getPaymentApproveFailed(new RequestError(error).getMessage()))
    )
}

export const updateApproveParameters = (params) => dispatch => {
    return dispatch(updateParameters(params));
}

export const resetState = (status) => dispatch => dispatch(resetPaymentApprove(status));

export const getTotals = (statusList) => dispatch => {
    statusList.forEach(status => {
        api.payment.approve(`?status=${status}&per_page=10&page=1`)
        (response => {
            dispatch(updateTotal({status, total: response.data.total}))
        }, error => {
            dispatch(updateTotal({status, total: 0}))
        })
    })
}