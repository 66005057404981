import {createSlice} from '@reduxjs/toolkit';
import RequestError from '../helpers/RequestError';
import Api from "../helpers/api";

export const initialState = {
    clients: [],
    totalClients: 0,
    clientsError: null,
    isClientsInProgress: null,
    isClientListInvalid: null,
};

const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        getClientsStart: (state) => {
            state.isClientsInProgress = true;
            state.clientsError = null;
        },
        getClientsDone: (state, {payload}) => {
            if (payload.status === 200) {
                state.clients = payload.items;
                state.totalClients = payload.total;
                state.isClientListInvalid = false;
            } else {
                state.clientsError = payload.message;
                state.isClientListInvalid = null;
            }
            state.isClientsInProgress = false;
        },
        setClientListInvalid: (state) => {
            state.isClientListInvalid = true;
        }
    },
});

export const clientsSelector = state => state.clients;
export default clientsSlice.reducer;

const {getClientsStart, getClientsDone, setClientListInvalid} = clientsSlice.actions;
export const getClients = (page, itemsPerPage, searchTerm) => dispatch  => {
    dispatch(getClientsStart());
    return Api.getClients(page, itemsPerPage, searchTerm)(
        ({data}) => dispatch(getClientsDone({...data, status: 200})),
        error => {
            const respError = new RequestError(error)
            dispatch(getClientsDone({
                status: respError.getHttpStatusCode(),
                message: respError.getMessage()
            }));
        }
    );
}
export const invalidateClientList = () => dispatch => {
    dispatch(setClientListInvalid());
}