import React from 'react';
import {Divider} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Toolbar from '@material-ui/core/Toolbar';
import SearchInput from "../SearchInput";

const ClientsTableToolbar = ({onChangeSearchTerm, searchTerm}) => {
    return (
        <Box>
            <Toolbar disableGutters>
                <Box pr={2} pl={2} data-testId={'client-table-search'}>
                    <SearchInput onChange={(e) => onChangeSearchTerm(e.target.value)} value={searchTerm}/>
                </Box>
            </Toolbar>
            <Divider/>
        </Box>
    );
}

export default ClientsTableToolbar;
