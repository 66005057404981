import React, {useEffect, useState, Fragment} from "react";
import {
    Avatar,
    Box,
    Dialog,
    DialogTitle,
    FormControl,
    LinearProgress, Paper, Radio,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {isEmpty} from "../../helpers/utils";
import Api from "../../helpers/api";
import useNotification from "../../helpers/useNotification";
import Divider from "@material-ui/core/Divider";
import UserAutocomplete from "../UserAutocomplete";
import {Skeleton} from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import UserHelper from "../../helpers/UserHelper";
import {useTheme} from "@material-ui/styles";
import {useDispatch, useSelector} from "react-redux";
import {messagesSelector, startSupportChat} from "../../slices/messages";
import {ROLES} from "../../helpers/roleHelper";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const CreateChatModal = ({isOpen, onClose, onSaveSuccess}) => {
    const notification = useNotification();
    const theme = useTheme();
    const dispatch = useDispatch();
    const {isChatMessagesInProgress} = useSelector(messagesSelector);

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserRole, setSelectedUserRole] = useState(null);

    const [inProgress, setInProgress] = useState(false);

    const [rolesLabels, setRolesLabels] = useState([]);

    useEffect(() => {
        if (isOpen === false) {
            setSelectedUser(null);
            setInProgress(false);
        }
    }, [isOpen]);

    const onSelectUser = (selected) => {
        if (selected && selected?.value) {
            setInProgress(true);
            Api.getUser(selected.value)(
                ({data}) => {
                    setSelectedUser(data);
                    UserHelper.getUserRolesLabels(data, [ROLES.ROLE_ADMIN])
                        .then((result) => {
                            setRolesLabels(result);
                            if (result.length === 1) {
                                setSelectedUserRole(result[0].role);
                            } else {
                                setSelectedUserRole(null);
                            }
                        })
                        .catch(notification.captureError)
                        .finally(() => setInProgress(false));
                },
                (error) => {
                    notification.captureError(error);
                    setInProgress(false);
                }
            );
        } else {
            setSelectedUser(null);
            setRolesLabels([]);
        }
    }

    const onCreateChat = () => {
        dispatch(startSupportChat(selectedUser.user_id, selectedUserRole.role.role_id, selectedUserRole.client?.client_id)).then(({chat_id}) => {
            onSaveSuccess(chat_id);
        }).catch(notification.captureError);
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>
                    New message
                </DialogTitle>
                <Divider/>
                <Box px={3} pb={3} pt={2}>
                    <FormControl fullWidth margin={"none"}>
                        <UserAutocomplete disabled={inProgress || isChatMessagesInProgress}
                                          fetchAll
                                          placeholder={"Search for a recipient"}
                                          roleId={ROLES.ROLE_ADMIN}
                                          onChange={onSelectUser}/>
                    </FormControl>
                    <Paper elevation={0} variant={"outlined"} style={{marginTop: theme.spacing(1)}}>
                        <Box display={"flex"} flexDirection={"row"} p={1.5}>
                            <Box>
                                {selectedUser === null &&
                                    <Skeleton variant={"circle"} width={60} height={60}
                                              animation={inProgress ? "wave" : false}/>}
                                {
                                    selectedUser !== null &&
                                    <Avatar src={UserHelper.getAnyProfileImageAvailable(selectedUser)}
                                            style={{width: 60, height: 60}}/>
                                }
                            </Box>
                            <Box flex={1} pl={2}>
                                <Typography variant="body1">
                                    {
                                        selectedUser === null &&
                                        <Skeleton variant="text" animation={inProgress ? "wave" : false}/>
                                    }
                                    {selectedUser !== null && `${selectedUser.first_name} ${selectedUser.last_name}`}
                                </Typography>
                                <Typography variant="body2">
                                    {selectedUser === null &&
                                        <Skeleton variant="text" animation={inProgress ? "wave" : false}/>}
                                    {selectedUser !== null && selectedUser.email}
                                </Typography>
                                <Typography variant="body2">
                                    {selectedUser === null &&
                                        <Skeleton variant="text" animation={inProgress ? "wave" : false}
                                                  width={"50%"}/>}
                                    {selectedUser !== null && `User ID: ${selectedUser.user_id}`}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                    <Box mt={2}>
                        <Typography variant="body2" style={{fontWeight: "bold"}}>
                            {selectedUser === null &&
                                <Skeleton variant="text" animation={inProgress ? "wave" : false}
                                          width={"15%"}/>}
                            {selectedUser !== null && "Roles:"}
                        </Typography>
                        {
                            (selectedUser === null || (inProgress && rolesLabels.length === 0)) &&
                            <Typography variant="body2">
                                <Skeleton variant="text" animation={inProgress ? "wave" : false} width={"40%"}/>
                            </Typography>
                        }
                        {
                            selectedUser !== null &&
                            <Box px={1} width={"100%"}>
                                {rolesLabels.map((roleLabel) => (
                                    <FormControlLabel key={roleLabel.label}
                                                      style={{width: "100%"}}
                                                      value={"end"}
                                                      control={<Radio color={"primary"} onChange={() => setSelectedUserRole(roleLabel.role)} disabled={isChatMessagesInProgress} checked={selectedUserRole?.client?.client_id === roleLabel.role?.client?.client_id}/>}
                                                      label={roleLabel.label}/>
                                ))}
                            </Box>
                        }
                        {
                            selectedUser !== null && rolesLabels.length === 0 && !inProgress &&
                            <Typography variant="body2" style={{fontStyle: "italic"}}>
                                No roles found
                            </Typography>
                        }
                    </Box>
                </Box>
                <Divider/>
                <Box py={2} px={3} justifyContent={"flex-end"} display={"flex"}>
                    <Button variant="outlined" onClick={onClose} disabled={inProgress} size={"medium"}
                            style={{marginRight: theme.spacing(2)}}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" onClick={onCreateChat}
                            disabled={inProgress || isEmpty(selectedUser) || isEmpty(selectedUserRole) || isChatMessagesInProgress} size={"medium"}>
                        New message
                    </Button>
                </Box>
                {(inProgress || isChatMessagesInProgress) && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default CreateChatModal;
