import React from "react";
import {
    Box,
    Divider, LinearProgress,
    Paper,
    Typography
} from "@material-ui/core";
import CampaignListTable from "./CampaignListTable";

const CampaignReport = ({title, total, campaigns, inProgress}) => {
    return (
        <Paper>
            <Box py={1} pl={2}>
                <Typography variant="h4">
                    {title} {!inProgress && `(${total})`}
                </Typography>
            </Box>
            <Divider/>
            <CampaignListTable campaigns={campaigns} inProgress={inProgress}/>
            {inProgress && <LinearProgress/>}
        </Paper>
    )
}

export default CampaignReport;