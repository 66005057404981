import React, {useState, useEffect, Fragment} from 'react';
import TopNotification from '../components/TopNotification';

const withNetworkDetector = (WrappedComponent) => {
    const WrappedWithNetworkDetector = (props) => {
        const [isDisconnected, setDisconnected] = useState(false);

        useEffect(() => {
            const handleConnectionChange = () => {
                const condition = navigator.onLine ? 'online' : 'offline';
                if (condition === 'online') {
                    const pingInterval = setInterval(() => makePing(), 2000);
                    const makePing = () => {
                        fetch('https://www.creatable.io/', {mode: 'no-cors'})
                            .then(() => {
                                setDisconnected(false);
                                clearInterval(pingInterval);
                            }).catch(() => setDisconnected(true))
                    };
                    makePing();
                } else {
                    setDisconnected(true);
                }
            }

            window.addEventListener('online', handleConnectionChange);
            window.addEventListener('offline', handleConnectionChange);

            handleConnectionChange();

            return () => {
                window.removeEventListener('online', handleConnectionChange);
                window.removeEventListener('offline', handleConnectionChange);
            };
        }, []);

        return (
            <Fragment>
                <TopNotification severity="error" message="Internet connection lost!" open={isDisconnected}/>
                <WrappedComponent {...props} isNetworkDisconnected={isDisconnected}/>
            </Fragment>
        );
    }

    return WrappedWithNetworkDetector;
}

export default withNetworkDetector;