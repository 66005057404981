import React, {useEffect, useState} from "react";
import CardWithTitleAndAction from "../CardWithTitleAndAction";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import countries from "../../../helpers/countries.json";
import {useDispatch, useSelector} from "react-redux";
import {clientConfigSelector} from "../../../slices/clientConfig";
import Api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";


const requiredFields = {
    "name": "",
    "country": "",
    "address": "",
    "city": "",
    "zipcode": "",
    "email": "",
    "state": "",
}

const numericFields = [ 'net_terms' ]

const CompanyDetails = () =>{
    const [inProgress, setInProgress] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const {clientConfig} = useSelector(clientConfigSelector);
    const [errors, setErrors] =  useState({});
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        ...requiredFields,
        "address_continued": ""
    });
    const [initialValues, setInitialValues] = useState({
        ...requiredFields,
        "address_continued": ""
    });
    useEffect(() => {
        if(clientConfig){
            setInProgress(true);
            Api.backoffice.company.details(clientConfig.client.id)(
                response =>{
                    setInProgress(false);
                    setValues(response.data);
                    setInitialValues(response.data);
                },
                error =>{
                    setInProgress(false);
                }
            );
        }
    }, [clientConfig]);
    const save = () =>{

        const newErrors = {...errors};

        Object.keys(values).forEach(field=>{
           if(requiredFields.hasOwnProperty(field) && !numericFields.includes(field) && values[field].trim() === ""){
               newErrors[field] = true;
           }else{
               delete newErrors[field];
           }
        });
        setErrors(newErrors);
        if(Object.keys(newErrors).length > 0){
            dispatch(setNotification('Please fill the required fields',  'error'))
            return;
        }
        setInProgress(true);
        Api.backoffice.company.putMinimalDetails(clientConfig.client.id)(
            response =>{
                dispatch(setNotification('Company details updated successfully'));
                setInProgress(false);
                setValues(response.data);
                setInitialValues(response.data);
                setHasChanges(false);
            },
            error =>{
                setInProgress(false);
                dispatch(setNotification('Failed to save the details',  'error'))
            }
        )(values);
    }
    const onChange = event => {
        const {name, value} = event.target;

        let newValues = {...values};
        newValues[name] = value;

        let newErrors= {...errors};
        if(requiredFields.hasOwnProperty(name) && value.trim() === ""){
            newErrors[name] = true;
            setErrors(newErrors);
        }else{
            delete newErrors[name];
            setErrors(newErrors)
        }
        setValues(newValues);
        setHasChanges((value !== initialValues[name] && Object.keys(newErrors).length === 0));
    }
    const onChangeCountry = (event, value) =>{
        let newValues = {...values};
        newValues['country'] = value.label;
        let newErrors = {...errors};

        if(value?.label &&value.label.trim() === ""){
            newErrors['country'] = true;
            setErrors(newErrors);
        }else{
            delete newErrors['country'];
            setErrors(newErrors)
        }
        setHasChanges((value.label !== initialValues['country'] && Object.keys(newErrors).length === 0));
        setValues(newValues);
    }

    const getSelectedValue = value =>{
        const country = value ? countries.find(f=>f.label === value) : null;
        return country ? country : null;
    }


    return <Box>
        <CardWithTitleAndAction title={'Company details'} inProgress={inProgress} onClickAction={save} testId={'company-details'} disableAction={!hasChanges}>
            <TextField onChange={onChange} fullWidth value={values?.name || ""} error={errors['name'] === true} name={'name'} label={'Company name'} margin={'dense'} />
            <TextField onChange={onChange} fullWidth value={values?.address || ""} error={errors['address'] === true} name={'address'} label={'Address'} margin={'dense'} />
            <TextField onChange={onChange} fullWidth value={values?.address_continued || ""} name={'address_continued'} label={'Address (cont.)'} margin={'dense'} />
            <TextField onChange={onChange} fullWidth value={values?.city || ""} error={errors['city'] === true} name={'city'} label={'City'} margin={'dense'} />
            <Autocomplete
                value={getSelectedValue(values?.country)}
                onChange={onChangeCountry}
                getOptionLabel={option=>option.label}
                renderInput={(params) => <TextField error={errors['country'] === true} {...params} label="Country" margin={'dense'} />}
                options={countries} name={'country'} label={'Country'} margin={'dense'} />
            <TextField onChange={onChange} value={values?.state || ""} fullWidth error={errors['state'] === true} name={'state'} label={'State/province'} margin={'dense'} />
            <TextField onChange={onChange} value={values?.zipcode || ""} fullWidth error={errors['zipcode'] === true} name={'zipcode'} label={'Post/ZIP code'} margin={'dense'} />
            <TextField onChange={onChange} value={values?.email || ""} fullWidth error={errors['email'] === true} name={'email'} label={'Billing email address'} margin={'dense'} />

        </CardWithTitleAndAction>
    </Box>
}
export default CompanyDetails;