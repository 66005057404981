import React, {useEffect, useState} from 'react';
import {Box, Divider, TablePagination} from "@material-ui/core";
import TableSearchAndFilter from "./TableSearchAndFilter";
import CampaignAdminTable from "./CampaignAdminTable";
import {useDispatch, useSelector} from "react-redux";
import {campaignAdminSelector, getCampaignAdminList, updateSection} from "../../slices/campaignAdmin";
import CampaignAdminFilters from "./CampaignAdminFilters";
import {
    getPageParametersAsObject,
    pageHasParameters,
    updateBrowserWithUrlParameters
} from "../../helpers/pageParameters";
import ActiveFilters from "../ActiveFilters";
import {isEmpty} from "../../helpers/utils";

const CampaignAdminTabContent = ({status}) => {
    const dispatch = useDispatch();
    const [initialized, setInitialized] = useState(false);
    const {isCampaignAdminLoading} = useSelector(campaignAdminSelector);
    const stateData = useSelector(campaignAdminSelector)[status];
    console.log('stateData', stateData, status);
    const {total, items, page, per_page, smartFilter, clientId, clientLabel} = stateData;

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            if (pageHasParameters()) {
                const pageParams = getPageParametersAsObject();
                dispatch(updateSection(status, pageParams));

                dispatch(getCampaignAdminList({...stateData, ...pageParams}));
            } else {
                const {page, per_page, smartFilter, clientId, clientLabel} = stateData;
                updateBrowserWithUrlParameters({page, per_page, smartFilter, clientId, clientLabel});
                dispatch(getCampaignAdminList(stateData));
            }
        }
    }, [initialized, dispatch, stateData, status])


    const handleChangePage = (event, page) => {
        dispatch(updateSection(status, {page: parseInt(page)}));
        dispatch(getCampaignAdminList({...stateData, page: parseInt(page)}));
    }

    const handleChangeRowsPerPage = event => {
        const {value} = event.target;
        dispatch(updateSection(status, {per_page: parseInt(value), page: 0}));
        dispatch(getCampaignAdminList({...stateData, page: 0, per_page: parseInt(value)}));
    }

    const onSearchChange = value => {
        dispatch(updateSection(status, {smartFilter: value, page: 0}));
        dispatch(getCampaignAdminList({...stateData, page: 0, smartFilter: value}));
    }

    const [openFilter, setOpenFilter] = useState(false);

    const onClientChange = (value) => {
        if (value === null) {
            dispatch(updateSection(status, {clientId: null, clientLabel: null, page: 0}));
            dispatch(getCampaignAdminList({...stateData, page: 0, clientId: null, clientLabel: null}));
        } else {
            dispatch(updateSection(status, {clientId: value.client_id, clientLabel: value.name, page: 0}));
            dispatch(getCampaignAdminList({
                ...stateData,
                page: 0,
                clientId: value.client_id,
                clientLabel: value.name
            }));
        }
    }

    const getClientValue = () => {
        if (clientId) {
            return {
                name: clientLabel,
                client_id: clientId
            }
        }
        return null;
    }

    const closeFilter = () => {
        setOpenFilter(false);
    }
    const onClickFilterIcon = () => {
        setOpenFilter(true);
    }

    const getActiveFilters = () => {
        const filters = [];
        if (clientId) {
            filters.push({label: `${clientLabel} (${clientId})`, name: "client"});
        }
        if (!isEmpty(smartFilter)) {
            filters.push({label: "Search", value: smartFilter, name: "search"});
        }
        return filters;
    };

    const onRemoveFilter = (filterName) => {
        if (filterName === "client") {
            onClientChange(null);
        } else if (filterName === "search") {
            onSearchChange("");
        }
    };

    return (
        <Box>
            <TableSearchAndFilter searchValue={smartFilter} onChangeSearchTerm={onSearchChange}
                                  onClickFilterIcon={onClickFilterIcon}/>
            {
                getActiveFilters().length > 0 &&
                <>
                    <ActiveFilters filters={getActiveFilters()} onRemoveFilter={onRemoveFilter} hideMainLabel/>
                    <Divider/>
                </>
            }
            <CampaignAdminTable items={items} isNoResults={!isCampaignAdminLoading && items.length === 0}/>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={parseInt(per_page)}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <CampaignAdminFilters isOpen={openFilter} onClose={closeFilter} onClientChange={onClientChange}
                                  value={getClientValue()}/>
        </Box>
    );
}


export default CampaignAdminTabContent;
