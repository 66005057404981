import {object as objectUtils} from "./utils";
import Api from "./api";

const DEFAULT_PROFILE_IMAGE = 'https://sitecdn.tvpage.com/static/images/default_profile.jpg';

const UserHelper = {
    getAnyProfileImageAvailable: (user) => {
        const profileImages = (user?.roles || []).map(clientUserRole => UserHelper.getProfileImageFromRole(clientUserRole, null)).filter(i => i);
        if (profileImages.length > 0) {
            return profileImages[0];
        } else {
            return DEFAULT_PROFILE_IMAGE;
        }
    },

    getProfileImageFromRole: (clientUserRole, defaultValue = null) => {
        return objectUtils.get("user_attributes.data.json_value.profilePhoto", clientUserRole, defaultValue);
    },

    getUserRolesLabels: async (user, filterRoles = []) => {
        const getRoleLabel = (clientUserRole, clientName) => {
            if (clientUserRole.role.role_id === 3) {
                return "Super admin";
            } else if (clientUserRole.role.role_id === 1) {
                return `Brand admin (${clientName ?? "Unknown"})`;
            } else if (clientUserRole.role.role_id === 2 && clientUserRole.ambasssador_type !== 3) {
                return `Team creator (${clientName ?? "Unknown"})`;
            } else if (clientUserRole.role.role_id === 4) {
                return "Marketplace creator";
            } else {
                return "Unknown";
            }
        }

        return Promise.all((user?.roles || []).map(clientUserRole => {
            return new Promise((resolve) => {
                if (clientUserRole?.client?.client_id) {
                    Api.getClient(clientUserRole.client.client_id)(
                        ({data}) => {
                            resolve({
                                role: clientUserRole,
                                label: getRoleLabel(clientUserRole, data.name),
                            });
                        },
                        (error) => {
                            console.error(error);
                            resolve({
                                role: clientUserRole,
                                label: getRoleLabel(clientUserRole, "Unknown"),
                            });
                        }
                    );
                } else {
                    resolve({
                        role: clientUserRole,
                        label: getRoleLabel(clientUserRole, "Unknown"),
                    });
                }
            });
        })).then((results) => {
            if (filterRoles.length) {
                return results.filter((result) => {
                    return filterRoles.includes(result.role.role.role_id);
                });
            } else {
                return results;
            }
        });
    },

    getFullName: (user) => {
        return `${user?.first_name ?? ""} ${user?.last_name ?? ""}`.trim();
    },
}

export default UserHelper;
