import {Box, Button, makeStyles, Toolbar, Typography} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    button: {
        textTransform: "inherit",
        color: theme.palette.primary.main,
        margin: "5px 10px"

    },
    toolbar: {
        backgroundColor: theme.palette.primary.light,
        borderTopRightRadius: "4px",
        borderTopLeftRadius: "4px"
    }
}));

const ApproveToolbarActions = ({selected, actionsDone, clickApprove, clickReject}) => {

    const classes = useStyles();

    return <Toolbar className={classes.toolbar}>
        <Box display={"flex"} alignContent={"center"} alignItems={"center"}>
            <Typography variant={"subtitle2"}>
                {selected.length} Selected
            </Typography>
            <Button onClick={clickApprove} className={classes.button}>
                Approve
            </Button>
            <Button onClick={clickReject} className={classes.button}>
                Reject
            </Button>
        </Box>
    </Toolbar>

}

export default ApproveToolbarActions;