import {Menu, MenuItem} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, {Fragment} from "react";
import Divider from "@material-ui/core/Divider";
import Api from "../../helpers/api";
import RequestError from "../../helpers/RequestError";
import routes from "../../helpers/routes";
import {setNotification} from "../../slices/notification";
import {useDispatch} from "react-redux";
import {useCookies} from "react-cookie";
import impersonationHelper from "../../helpers/impersonationHelper";

const UsersTableRowMenu = ({onClickUpdatePassword, user}) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [cookies, setCookie] = useCookies([impersonationHelper.getStartUrlCookieName()]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleMenuItemClick = (callback) => () => {
        setAnchorEl(null);
        callback();
    }

    const onClickImpersonate = () => {
        Api.impersonateUser(user.user_id)(
            () => {
                const newCookieValue = impersonationHelper.addImpersonationStartUrl(cookies[impersonationHelper.getStartUrlCookieName()], window.location.href);
                setCookie(impersonationHelper.getStartUrlCookieName(), newCookieValue, impersonationHelper.getCookieCreationOptions());
                window.location.href = routes.getLoginUrl();
            },
            (error) => {
                dispatch(setNotification((new RequestError(error)).getMessage(), "error"));
            }
        )
    }

    return (
        <Fragment>
            <IconButton onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem key={"update_password"} onClick={handleMenuItemClick(onClickUpdatePassword)}>Update password</MenuItem>
                <Divider/>
                <MenuItem key={"impersonate"} onClick={handleMenuItemClick(onClickImpersonate)}>Login</MenuItem>
            </Menu>
        </Fragment>
    )
}

export default UsersTableRowMenu;
