import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Box, LinearProgress, Paper} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import ChatsTable from "./ChatsTable";
import SearchInput from "../../../components/SearchInput";
import Toolbar from "@material-ui/core/Toolbar";
import {useDebounce} from "use-debounce";
import {
    fetchBrandCreatorChatList,
    messagesSelector,
    prepareCurrentChatData,
} from "../../../slices/messages";
import ChatModal from "../../../components/Chat/ChatModal";
import {withUser} from "../../../providers/UserProvider";
import {useTheme} from "@material-ui/styles";

const BrandCreatorMessages = ({userId}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {
        isChatMessagesInProgress,
        isChatWebSocketConnectionOpen,
        isBrandCreatorChatListInitialized
    } = useSelector(messagesSelector);

    const [isChatModalOpen, setIsChatModalOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const [sort, setSort] = useState("desc");
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        if (
            isChatWebSocketConnectionOpen === true &&
            isBrandCreatorChatListInitialized == null
        ) {
            dispatch(fetchBrandCreatorChatList("", null, page, itemsPerPage));
        }
    }, [isChatWebSocketConnectionOpen, isBrandCreatorChatListInitialized, dispatch, page, itemsPerPage]);

    useEffect(() => {
        dispatch(fetchBrandCreatorChatList(debouncedSearchTerm, null, page, itemsPerPage));
    }, [debouncedSearchTerm, dispatch, page, itemsPerPage]);

    const onClickChat = (chat) => {
        dispatch(prepareCurrentChatData(chat, userId));
        setIsChatModalOpen(true);
    }

    const handlePageChange = (newPage) => {
        console.log("Page changed to:", newPage);
        setPage(newPage);
    };

    const handleItemsPerPageChange = (newItemsPerPage) => {
        console.log("Items per page changed to:", newItemsPerPage);
        setItemsPerPage(newItemsPerPage);
        setPage(1);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar
                            disableGutters
                            style={{
                                minHeight: "auto",
                                paddingTop: theme.spacing(1),
                                paddingBottom: theme.spacing(1),
                            }}>
                            <Box flexGrow={1} pr={2} pl={2}>
                                <SearchInput
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    value={searchTerm}
                                />
                            </Box>
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <ChatsTable
                            onClickChat={onClickChat}
                            sort={sort}
                            onSort={setSort}
                            onPageChange={handlePageChange}
                            onItemsPerPageChange={handleItemsPerPageChange}
                        />
                        {isChatMessagesInProgress && <LinearProgress />}
                    </Paper>
                </Grid>
            </Grid>

            {isChatModalOpen && (
                <ChatModal
                    isSupport={false}
                    isOpen={true}
                    onClose={() => setIsChatModalOpen(false)}
                />
            )}
        </>
    );
}

export default withUser(BrandCreatorMessages);