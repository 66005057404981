import React, {useEffect, useState} from "react";
import CardWithTitleAndAction from "../CardWithTitleAndAction";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {numbers} from "../../../helpers/utils";
import {Grid, Typography} from "@material-ui/core";
import {setNotification} from "../../../slices/notification";
import Api from "../../../helpers/api";
import {useDispatch, useSelector} from "react-redux";
import {clientConfigSelector} from "../../../slices/clientConfig";

const InfluenceServiceFee = ({plan, onSuccess}) => {
    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState(false);
    const [serviceFee, setServiceFee] = useState("");
    const [minAmountPerCreator, setMinAmountPerCreator] = useState("");
    const [isDisplayErrors, setIsDisplayErrors] = useState(false);
    const {clientConfig} = useSelector(clientConfigSelector);
    const disableAction = (!plan || plan?.cancellation === true);

    useEffect(() => {
        if (plan) {
            setServiceFee(plan.campaign_fee_ratio * 100);
            setMinAmountPerCreator(numbers.penniesToDollars(plan.campaign_fee_minimum_pennies));
            setInProgress(false);
        }
    }, [plan]);

    const partialSave = () => {
        if (serviceFee < 0 || minAmountPerCreator < 0) {
            setIsDisplayErrors(true);
            dispatch(setNotification('Please fix errors highlighted in red', 'error'));
            return;
        }
        setInProgress(true);
        setIsDisplayErrors(false);
        Api.backoffice.company.updateCurrentPlan(clientConfig.client.id)(
            response => {
                setInProgress(false);
                dispatch(setNotification('Influence campaign fees updated'));
                onSuccess();
            },
            error => {
                setInProgress(false);
                dispatch(setNotification('Unable to update influence campaign fees updated', 'error'));
            }
        )({
            ...plan,
            campaign_fee_ratio: (serviceFee ? serviceFee / 100 : 0),
            campaign_fee_minimum_pennies: (minAmountPerCreator * 100),
        })
    }

    return (
        <CardWithTitleAndAction title={'Creator service fee'} inProgress={inProgress} testId={'influence-service-fee'}
                                disableAction={disableAction} onClickAction={partialSave}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography>
                        The greater of the percentage of spend or fixed creator fee will be calculated as the fee.
                    </Typography>
                </Grid>
                <Grid item xs={6} data-testId={'campaign_fee_ratio'}>
                    <CurrencyTextField margin="normal"
                                       disabled={inProgress}
                                       error={isDisplayErrors && serviceFee < 0}
                                       helperText={(isDisplayErrors && serviceFee < 0) ? "Min value is 0" : ""}
                                       name={'campaign_fee_ratio'}
                                       textAlign="left"
                                       fullWidth
                                       label="Service fee"
                                       placeholder="Service fee"
                                       decimalPlaces={0}
                                       currencySymbol={"%"}
                                       onChange={(e, value) => setServiceFee(value)}
                                       value={`${serviceFee}`}
                                       variant="outlined" size="small"/>
                </Grid>
                <Grid item xs={6} data-testId={'campaign_fee_minimum_pennies'}>
                    <CurrencyTextField margin="normal"
                                       disabled={inProgress}
                                       error={isDisplayErrors && minAmountPerCreator < 0}
                                       helperText={(isDisplayErrors && minAmountPerCreator < 0) ? "Min value is 0" : ""}
                                       name={'campaign_fee_minimum_pennies'}
                                       textAlign="left"
                                       fullWidth
                                       label="Minimum amount per creator"
                                       placeholder="Minimum amount per creator"
                                       decimalPlaces={2}
                                       currencySymbol={"$"}
                                       onChange={(e, value) => setMinAmountPerCreator(value)}
                                       value={`${minAmountPerCreator}`}
                                       variant="outlined" size="small"/>
                </Grid>
            </Grid>


        </CardWithTitleAndAction>
    );
}
export default InfluenceServiceFee;


