import React,{Fragment} from "react";

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return <div role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
        {value === index && <Fragment>{children}</Fragment>}
        </div>
}

export default TabPanel;