import {FormControl, FormHelperText} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {useEffect, useState} from "react";
import {isEmpty} from "../../helpers/utils";
import {getAvailableUserStatus, getUserStatusNameFromId} from "../../helpers/userRoleStatus";

const RoleStatusSelect = ({onChange, disabled, error, helperText, value}) => {
    const [internalValue, setInternalValue] = useState(null);

    useEffect(() => {
        if (value) {
            setInternalValue({label: getUserStatusNameFromId(value), value});
        } else {
            setInternalValue(null);
        }
    }, [value]);

    const onInternalChange = (event, status) => {
        setInternalValue(status);
        onChange(status);
    }

    return (
        <FormControl error={error} fullWidth size={"small"} margin={"normal"}>
            <Autocomplete
                value={internalValue}
                disabled={disabled}
                size="small"
                options={getAvailableUserStatus()}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                renderInput={(params) => (
                    <TextField variant="outlined" disabled={disabled} size="small"
                               label={"Status"} error={error}
                               placeholder={"Search..."} fullWidth {...params}
                               InputLabelProps={{shrink: true, ...params.InputLabelProps}}/>
                )}
                onChange={onInternalChange}
            />
            {!isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

export default RoleStatusSelect;