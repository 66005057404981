import request from './request';
import routes from "./routes";
import {isEmpty} from "./utils";
import Shared from "./Shared";

const campaignHeader = () => ({'Authorization': 'Basic ' + btoa(Shared?.session?.id + ':' + Shared?.session?.hash)});

const api = {
    getMe: () => request.get(routes.getUserServiceUrl('/me'), null, false),

    getApplicationStatus: () => request.jsonp(routes.getAppApiUrl(`/application/status`)),
    getApplicationRequestHeaders: () => request.head('/'),
    getResourcesList: (parameters) => request.get(routes.getCommunicationsApiUrl('/v3/api/communication/static'), parameters),
    uploadResource: (parameters) => request.postFormData(routes.getCommunicationsApiUrl('/v3/api/communication/static'), parameters),
    updateResourceImage: (resource_id, parameters) => request.postFormData(routes.getCommunicationsApiUrl(`/v3/api/communication/static/${resource_id}`), parameters, 'post'),
    deleteResource: (resource_id, parameters) => request.delete(routes.getCommunicationsApiUrl(`/v3/api/communication/static/${resource_id}`), parameters),
    updateMetadataResource: (resource_id, parameters) => request.post(routes.getCommunicationsApiUrl(`/v3/api/communication/static/${resource_id}/metadata`), parameters),

    getSubscriptionsList: (parameters) => request.get(routes.getCommunicationsApiUrl('/v3/api/communication/subscriptions'), parameters),
    getUsers: (page, itemsPerPage, search = "", clientId = null, roleId = null) => request.get(routes.getUserServiceUrl(`/list?page=${page}&per_page=${itemsPerPage}${isEmpty(clientId) ? "" : `&client_id=${clientId}`}${isEmpty(roleId) ? "" : `&role_id=${roleId}`}${isEmpty(search) ? "" : `&search=${encodeURIComponent(search)}`}`), {accept: "application/json"}),
    createUser: () => request.post(routes.getUserServiceUrl('/create')),
    setUserPassword: (userId) => request.put(routes.getUserServiceUrl(`/${userId}/setPassword`)),
    addUserRole: (userId) => request.post(routes.getUserServiceUrl(`/${userId}/role`)),
    disableUserRole: (userId) => request.put(routes.getUserServiceUrl(`/${userId}/disableRole`)),
    recoverUserRole: (userId) => request.put(routes.getUserServiceUrl(`/${userId}/recoverRole`)),
    getUser: (userId) => request.get(routes.getUserServiceUrl(`/${userId}`), {accept: "application/json"}),
    updateUser: (userId) => request.put(routes.getUserServiceUrl(`/${userId}`)),
    updateUserRole: (userId, clientHash, roleId) => request.put(routes.getUserServiceUrl(`/${userId}/client/${clientHash}/role/${roleId}`)),

    getRootConfigBySection: (section) => request.get(routes.getAppApiUrl(`/application/rootConfig/${section}`), null, false),

    saveMobileAppConfig: () => request.put(routes.getAppApiUrl(`/application/mobileAppConfig`), false),
    saveMaintenanceModeConfig: () => request.put(routes.getAppApiUrl(`/application/status`), false),

    getClientAnalytics: (startDate, endDate) => request.get(routes.getAnalyticsStatsPerClientUrl(startDate, endDate)),

    getWidgetTemplates: (page, limit = 10) => request.get(routes.getAppApiUrl(`/experience/widgetTemplates/?page=${page}&limit=${limit}`)),
    createWidgetTemplate: () => request.post(routes.getAppApiUrl(`/experience/widgetTemplates`)),

    getWidgetTemplate: (widgetTemplateId) => request.get(routes.getAppApiUrl(`/experience/widgetTemplate/${widgetTemplateId}`)),
    updateWidgetTemplate: (widgetTemplateId) => request.put(routes.getAppApiUrl(`/experience/widgetTemplate/${widgetTemplateId}`)),
    deleteWidgetTemplate: (widgetTemplateId) => request.delete(routes.getAppApiUrl(`/experience/widgetTemplate/${widgetTemplateId}`)),

    createClient: () => request.post(routes.getClientServiceUrl(`/clients`)),
    initializeClient: () => request.post(routes.getAppApiUrl(`/account/initialize`)),
    getClient: (clientId) => request.get(routes.getClientServiceUrl(`/clients/${clientId}`)),
    getClients: (page, itemsPerPage, searchTerm = "", clients = [], sort = "client_id:desc") => request.get(routes.getClientServiceUrl(`/clients?page=${page}&per_page=${itemsPerPage}&sort=${sort}${clients && clients.length ? `&clients=${clients.join(",")}` : ""}${searchTerm !== "" ? `&search=${searchTerm}` : ""}`)),
    getInvoices: (clientId, page, itemsPerPage, searchTerm = "", orderBy = "period", order = "desc", queryString = "") => request.get(routes.getBackofficeServiceUrl(`/invoices?client_id=${clientId}&page=${page}&per_page=${itemsPerPage}&sort=${orderBy}:${order}${searchTerm !== "" ? `&search=${searchTerm}` : ""}${queryString !== "" ? `&${queryString}` : ""}`)),
    getInvoice: (invoiceId) => request.get(routes.getBackofficeServiceUrl(`/invoices/${invoiceId}`)),

    addInvoiceLineItem: (invoiceId) => request.post(routes.getBackofficeServiceUrl(`/invoices/${invoiceId}/items`)),
    updateInvoiceLineItem: (invoiceId, itemId) => request.put(routes.getBackofficeServiceUrl(`/invoices/${invoiceId}/items/${itemId}`)),
    removeInvoiceLineItem: (invoiceId, itemId) => request.delete(routes.getBackofficeServiceUrl(`/invoices/${invoiceId}/items/${itemId}`)),

    addInvoicePayment: (invoiceId) => request.post(routes.getBackofficeServiceUrl(`/invoices/${invoiceId}/payments`)),
    updateInvoicePayment: (invoiceId, paymentId) => request.put(routes.getBackofficeServiceUrl(`/invoices/${invoiceId}/payments/${paymentId}`)),
    removeInvoicePayment: (invoiceId, paymentId) => request.delete(routes.getBackofficeServiceUrl(`/invoices/${invoiceId}/payments/${paymentId}`)),
    issueInvoice: (invoiceId) => request.post(routes.getBackofficeServiceUrl(`/invoices/${invoiceId}/issue`)),

    getBackofficeMetadata: () => request.get(routes.getBackofficeServiceUrl(`/metadata`)),
    getFinanceOverview: (clientIds = []) => request.get(routes.getBackofficeServiceUrl(`/finance/overview${clientIds.length ? `?client_id=${clientIds.join(",")}` : ""}`)),
    createFinanceSettingsForClient: (clientId) => request.put(routes.getBackofficeServiceUrl(`/finance/settings/${clientId}`)),
    getFinanceSettingsForClient: (clientId) => request.get(routes.getBackofficeServiceUrl(`/finance/settings/${clientId}`)),
    getFinanceSettings: (clientIds = []) => request.get(routes.getBackofficeServiceUrl(`/finance/settings${clientIds.length ? `?client_id=${clientIds.join(",")}` : ""}`)),
    updateFinanceSettings: (clientId) => request.patch(routes.getBackofficeServiceUrl(`/finance/settings/${clientId}`)),
    getFinanceHistory: (clientId, mode, page = 1, itemsPerPage = 5) => request.get(routes.getBackofficeServiceUrl(`/finance/settings/${clientId}/history?mode=${mode}&page=${page}&per_page=${itemsPerPage}`)),

    getClientConfig: (clientHash, full = false) => request.get(routes.getClientConfigUrl(clientHash, full)),
    getRootClientConfig: (section) => request.get(routes.getRootClientConfigSectionUrl(section)),
    putClientConfigSection: (clientHash, section) => request.put(routes.getClientConfigSectionUrl(clientHash, section)),

    createMarketplaceCategory: () => request.post(routes.getUserServiceUrl("/marketplace/category")),
    updateMarketplaceCategory: (brandId) => request.put(routes.getUserServiceUrl(`/marketplace/category/${brandId}`)),
    getMarketplaceCategories: (page, itemsPerPage, search) => request.get(routes.getUserServiceUrl(`/marketplace/category?page=${page}&per_page=${itemsPerPage}${isEmpty(search) ? "" : `&search=${search}`}`)),
    deleteMarketplaceCategory: (categoryId) => request.delete(routes.getUserServiceUrl(`/marketplace/category/${categoryId}`)),
    setMarketplaceCategoriesOrder: () => request.put(routes.getUserServiceUrl(`/marketplace/category/order`)),

    createMarketplaceBrand: () => request.post(routes.getUserServiceUrl("/marketplace/brand")),
    updateMarketplaceBrand: (brandId) => request.put(routes.getUserServiceUrl(`/marketplace/brand/${brandId}`)),
    getMarketplaceBrands: (page, itemsPerPage, search) => request.get(routes.getUserServiceUrl(`/marketplace/brand?page=${page}&per_page=${itemsPerPage}${isEmpty(search) ? "" : `&search=${search}`}`)),
    deleteMarketplaceBrand: (brandId) => request.delete(routes.getUserServiceUrl(`/marketplace/brand/${brandId}`)),
    setMarketplaceBrandsOrder: () => request.put(routes.getUserServiceUrl(`/marketplace/brand/order`)),
    setMarketplaceCategoriesToMarketplaceBrand: (brandId) => request.put(routes.getUserServiceUrl(`/marketplace/brand/${brandId}/category`)),
    saveMarketplaceBrandDarkLogo: (brandId) => request.postFormData(routes.getUserServiceUrl(`/marketplace/brand/${brandId}/darkLogo`)),
    saveMarketplaceBrandLightLogo: (brandId) => request.postFormData(routes.getUserServiceUrl(`/marketplace/brand/${brandId}/lightLogo`)),

    setMarketplaceCategoriesToUser: (userId) => request.put(routes.getUserServiceUrl(`/${userId}/marketplace/brand`)),
    setMarketplaceBrandsToUser: (userId) => request.put(routes.getUserServiceUrl(`/${userId}/marketplace/category`)),

    getAccountAdmins: (page, itemsPerPage, search = null, clientId = null) => request.get(routes.getUserServiceUrl(`/accountAdmins?page=${page}&per_page=${itemsPerPage}${search ? `&search=${search}` : ""}${clientId ? `&client_id=${clientId}` : ""}`), {
        accept: "application/json"
    }),
    impersonateUser: (userId) => request.get(routes.getUserServiceUrl(`/${userId}/impersonate`)),
    payment: {
        approve: (params) => request.get(routes.getBackofficeServiceUrl(`/payouts/items?${params}`)),
        status: (params) => request.get(routes.getBackofficeServiceUrl(`/payouts/orders?${params}`), {Accept: 'application/json'}),
        statusExport: (params, filename) => request.downloadCSV(routes.getBackofficeServiceUrl(`/payouts/orders/export?${params}`), filename, {Accept: 'text/csv'}),
        sendApprove: () => request.post(routes.getBackofficeServiceUrl('/payouts/workflow/approve')),
        sendReject: () => request.post(routes.getBackofficeServiceUrl('/payouts/workflow/reject')),
        sendManualOrder: (order_id) => request.post(routes.getBackofficeServiceUrl('/payouts/workflow/manual/' + order_id)),
        sendRejectOrder: (order_id) => request.post(routes.getBackofficeServiceUrl('/payouts/workflow/reject/' + order_id)),
        sendRevokeOrder: (order_id) => request.post(routes.getBackofficeServiceUrl('/payouts/workflow/revoke/' + order_id)),
        sendPaidOrder: (order_id) => request.post(routes.getBackofficeServiceUrl('/payouts/workflow/paid/' + order_id)),
        sendNotPayableOrder: (order_id) => request.post(routes.getBackofficeServiceUrl('/payouts/workflow/cancel/' + order_id)),
        updatePaymentRefCode: (order_id, execution_id) => request.put(routes.getBackofficeServiceUrl(`/payouts/orders/${order_id}/executions/${execution_id}`)),
        updateOrderByOrderId: order_id => request.put(routes.getBackofficeServiceUrl(`/payouts/orders/${order_id}`))
    },
    UserApi: {
        login: () => request.post(routes.getCampaignsServiceUrl('/user/login'), null, false)
    },
    CampaignApi: {
        search: () => request.post(routes.getCampaignsServiceUrl('/campaign/search'), campaignHeader(), false),
        monthlyReport: () => request.post(routes.getCampaignsServiceUrl('/campaign/monthly_report'), campaignHeader(), false),
        inventorySearch: () => request.post(routes.getCampaignsServiceUrl('/campaign/inventory/search'), campaignHeader(), false),
        searchShippingManifest: () => request.post(routes.getCampaignsServiceUrl('/campaign/shipping/search'), campaignHeader(), false),
        edit: () => request.post(routes.getCampaignsServiceUrl('/campaign/edit'), campaignHeader(), false),
        get: (campaignId) => request.getWithNoCredentials(routes.getCampaignsServiceUrl('/campaign/get/' + campaignId), campaignHeader(), false),
        productEdit: () => request.post(routes.getCampaignsServiceUrl('/campaign/product/edit'), campaignHeader(), false),
        delete: (campaignId) => request.delete(routes.getCampaignsServiceUrl('/campaign/delete/' + campaignId), campaignHeader(), false)
    },
    GigApi: {
        searchViaCampaignPortal: () => request.post(routes.getCampaignsServiceUrl('/gig/search/brand'), campaignHeader(), false),
        brandPostResponse: (gigId, approveFlag) => request.getWithNoCredentials(routes.getCampaignsServiceUrl(`/gig/brand/response/${gigId}/${approveFlag}`), campaignHeader(), false),
        brandPostShipping: () => request.post(routes.getCampaignsServiceUrl(`/gig/brand/shipping`), campaignHeader(), false),
        get: (gigId) => request.getWithNoCredentials(routes.getCampaignsServiceUrl('/gig/get/' + gigId), campaignHeader(), false),
        updateAutomatedReporting: (gigId) => request.post(routes.getCampaignsServiceUrl('/gig/automated_reporting/' + gigId), campaignHeader(), false),
    },
    ProductApi: {
        get: (identifier, brandId) => request.getWithNoCredentials(routes.getCampaignsServiceUrl(`/product/get/${identifier}/${brandId}`), campaignHeader(), false),
    },
    UtilityApi: {
        getSystemSettings: () => request.getWithNoCredentials(routes.getCampaignsServiceUrl(`/utility/system_settings`), campaignHeader(), false),
    },
    AnalyticsMonitoring: {
        getMetricSales: () => request.get(routes.getAnalyticsApiUrl(`/admin/metrics/sales`)),
    },
    social: {
        getManualSocialMetadata: () => request.get(routes.getAnalyticsApiUrl(`/metadata/manual`)),

        getManualSocialDataList: (params) => request.get(routes.getAnalyticsApiUrl(`/social/manual?${params}`)),
        deleteManualSocialData: (post_id, client_id) => request.delete(routes.getAnalyticsApiUrl(`/social/manual/${post_id}?client_id=${client_id}`)),
        getManualSocialDataLogList: (post_id, params) => request.get(routes.getAnalyticsApiUrl(`/social/manual/${post_id}?${params}`)),

        addManualPost: (clientId) => request.post(routes.getAnalyticsApiUrl(`/social/manual?client_id=${clientId}`)),
        updateManualPost: (postId, clientId) => request.put(routes.getAnalyticsApiUrl(`/social/manual/${postId}?client_id=${clientId}`)),
        appendManualSocialMetrics: (postId, clientId) => request.post(routes.getAnalyticsApiUrl(`/social/manual/${postId}?client_id=${clientId}`)),
        deleteManualSocialMetrics: (postId, entryId, clientId) => request.delete(routes.getAnalyticsApiUrl(`/social/manual/${postId}/${entryId}?client_id=${clientId}`)),
        updateManualSocialMetrics: (postId, entryId, clientId) => request.put(routes.getAnalyticsApiUrl(`/social/manual/${postId}/${entryId}?client_id=${clientId}`)),
    },
    client: {
        refreshCampaignClientData: (clientId) => request.post(routes.getCampaignsServiceUrl('/client/refresh'), campaignHeader(), false),
    },
    kpis: {
        get: () => request.get(routes.getUserServiceUrl('/me')),
    },
    backoffice: {
        plans: {
            getPlans: (page, perPage) => request.get(routes.getBackofficeServiceUrl(`/plans?page=${page}&per_page=${perPage}`)),
            createPlan: () => request.post(routes.getBackofficeServiceUrl(`/plans`)),
            getPlan: (plan_id) => request.get(routes.getBackofficeServiceUrl(`/plans/${plan_id}`)),
            updatePlan: (plan_id) => request.put(routes.getBackofficeServiceUrl(`/plans/${plan_id}`)),
            deletePlan: (plan_id) => request.delete(routes.getBackofficeServiceUrl(`/plans/${plan_id}`)),
            availablePlans: () => request.get(routes.getBackofficeServiceUrl(`/plans/available`)),
        },
        company: {
            getPlan: (clientId) => request.get(routes.getBackofficeServiceUrl(`/company/${clientId}/plan`)),
            details: (clientId) => request.get(routes.getBackofficeServiceUrl(`/company/${clientId}/details`)),
            putMinimalDetails: (clientId) => request.put(routes.getBackofficeServiceUrl(`/company/${clientId}/details`)),
            putDetails: (clientId) => request.post(routes.getBackofficeServiceUrl(`/company/${clientId}/full_details`)),
            paymentMethods: (clientId) => request.get(routes.getBackofficeServiceUrl(`/company/${clientId}/payment_methods`)),
            setDefaultPaymentMethods: (clientId, payment_method_id) => request.get(routes.getBackofficeServiceUrl(`/company/${clientId}/payment_methods/${payment_method_id}`)),
            cancelSubscription: (clientId) => request.post(routes.getBackofficeServiceUrl(`/company/${clientId}/plan/cancel`)),
            updateCurrentPlan: (clientId, mode = "retroactive") => request.post(routes.getBackofficeServiceUrl(`/company/${clientId}/plan/custom?mode=${mode}`)),
        }
    },
    communications: {
        getConfigurationList: (page, perPage, sort = "last_update_date:desc") => request.get(routes.getCommunicationsApiUrl(`/configurations?page=${page}&per_page=${perPage}&sort=${sort}`)),
        createConfiguration: () => request.post(routes.getCommunicationsApiUrl(`/configurations`)),
        updateConfiguration: (configurationId) => request.put(routes.getCommunicationsApiUrl(`/configurations/${configurationId}`)),
        deleteConfiguration: (configurationId) => request.delete(routes.getCommunicationsApiUrl(`/configurations/${configurationId}`)),
        createSubscription: () => request.post(routes.getCommunicationsApiUrl(`/subscriptions`)),
        getSubscriptionsByConfigurationId: (configurationId, page, perPage) => request.get(routes.getCommunicationsApiUrl(`/subscriptions?configuration_id=${configurationId}&page=${page}&per_page=${perPage}`)),
        getConfiguration: (configurationId) => request.get(routes.getCommunicationsApiUrl(`/configurations/${configurationId}`)),
        compileConfigurationTest: () => request.post(routes.getCommunicationsApiUrl(`/configurations/compile`)),
    },
    messages: {
        createSupportChat: (userId, roleId = null, clientId = null) => request.post(routes.getMessagesApiUrl(`/chats/contact_to_support?user_id=${userId}${roleId ? `&role_id=${roleId}` : ""}${clientId ? `&client_id=${clientId}` : ""}`)),
        getAllChats: (isSupport, page, perPage, search, supportUnread) => request.get(routes.getMessagesApiUrl(`/management/chats?page=${page}&per_page=${perPage}&support=${isSupport ? "true" : "false"}${isEmpty(search) ? "" : `&search=${search}`}${supportUnread ? "&support_unread=true" : ""}`)),
        getChatMessages: (chatId, addOffset, limit, offsetId = null) => request.get(routes.getMessagesApiUrl(`/management/chats/${chatId}/messages?add_offset=${addOffset}&limit=${limit}${offsetId ? `&offset_id=${offsetId}` : ""}`)),
        joinChat: (chatId, userId, roleId, clientId = null) => request.get(routes.getMessagesApiUrl(`/management/chats/${chatId}/join?chat_id=${chatId}&user_id=${userId}&role_id=${roleId}${clientId ? `&client_id=${clientId}` : ""}`)),
        leaveChat: (chatId, userId, roleId, clientId = null) => request.get(routes.getMessagesApiUrl(`/management/chats/${chatId}/leave?chat_id=${chatId}&user_id=${userId}&role_id=${roleId}${clientId ? `&client_id=${clientId}` : ""}`)),
    },
    shortLink: {
        getImpactConfig: (clientId) => request.get(routes.getShortLinkServiceUrl(`/impact/config/${clientId}`)),
        updateImpactConfig: (clientId) => request.put(routes.getShortLinkServiceUrl(`/impact/config/${clientId}`)),
    },
}

export default api;
