import React, {useEffect, useRef, useState} from "react";
import {Box, Card, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import {PhotoCamera} from "@material-ui/icons";

const ACCEPTED_EXTENSIONS = ".png,.jpg,.jpeg";

const useStyles = makeStyles(() => ({
    root: {
        aspectRatio: 4 / 3,
        "& .MuiDropzoneArea-icon": {
            display: "none",
        },
        "& .MuiCard-root": {
            position: "relative",
            height: "100%",
            "& .MuiCardMedia-root": {
                height: "100%",
            },
        },
    },
    noImage: {
        height: "100%",
        backgroundColor: "#f5f5f5",
    },
    button: {
        position: "absolute",
        bottom: "8px",
        right: "8px",
        border: '2px solid #fff',
        padding: '7px',
        backgroundColor: "#efefef",
        '&:hover': {
            backgroundColor: "#fff",
        }
    },
}));

const BrandLogoUploader = ({onSelectImage, previewImageUrl, disabled}) => {
    const classes = useStyles();
    const inputFile = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    useEffect(() => {
        if (!selectedFile && previewImageUrl) {
            setPreviewUrl(previewImageUrl);
        }
    }, [previewImageUrl, selectedFile]);

    useEffect(() => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = () => {
                setPreviewUrl(URL.createObjectURL(selectedFile));
            }
        }
    }, [selectedFile]);

    const onClickSelectFile = () => {
        inputFile.current.click();
    }

    const onFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            onSelectImage(event.target.files[0]);
        }
    }

    return (
        <Box className={classes.root}>
            <Card>
                {
                    previewUrl !== null &&
                    <CardMedia image={previewUrl}>
                        <UploadButton onClick={onClickSelectFile} disabled={disabled}/>
                    </CardMedia>
                }
                {
                    previewUrl === null &&
                    <Box className={classes.noImage}>
                        <UploadButton onClick={onClickSelectFile} disabled={disabled}/>
                    </Box>
                }
            </Card>
            <input type="file" ref={inputFile} onChange={onFileChange} style={{display: "none"}}
                   accept={ACCEPTED_EXTENSIONS} id="icon-button-file"/>
        </Box>
    )
}

const UploadButton = ({onClick, disabled}) => {
    const classes = useStyles();
    return (
        <IconButton onClick={onClick} className={classes.button}
                    disabled={disabled}
                    color="primary" component="span"
                    variant="outlined">
            <PhotoCamera/>
        </IconButton>
    )
}

export default BrandLogoUploader;