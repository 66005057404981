import React, {useState, useEffect, Fragment} from 'react';
import Api from '../helpers/api';

const POOLING_INTERVAL = 30000;


const withApplicationVersionDetector = (WrappedComponent) => {
    const WrappedWithApplicationVersionDetector = (props) => {
        const [isFocused, setIsFocused] = useState(true);
        const [applicationVersion, setApplicationVersion] = useState(null);
        const {isNetworkDisconnected = false} = props;

        useEffect(() => {

            const onWindowFocus = () => setIsFocused(true);
            const onWindowBlur = () => setIsFocused(false);
            window.addEventListener('focus', onWindowFocus);
            window.addEventListener('blur', onWindowBlur);

            return () => {
                window.removeEventListener('focus', onWindowFocus);
                window.removeEventListener('blur', onWindowBlur);
            };
        }, []);

        useEffect(() => {

            let activePooling = 0;

            const startPooling = () => {
                verifyApplicationVersion();
                activePooling = setInterval(() => {
                    verifyApplicationVersion();
                }, POOLING_INTERVAL);
            }

            const verifyApplicationVersion = () => {
                Api.getApplicationRequestHeaders()(
                    (response) => {
                        const lastModified = response.headers.hasOwnProperty('last-modified') ? response.headers['last-modified'] : '';
                        if (applicationVersion !== null && lastModified !== applicationVersion) {
                            window.location.reload();
                        }
                        setApplicationVersion(lastModified);
                    },
                    (error) => console.log(error)
                );
            }

            if (isFocused && isNetworkDisconnected === false) {
                startPooling();
            } else {
                clearInterval(activePooling);
            }

            return () => {
                clearInterval(activePooling);
            }
        }, [isFocused, isNetworkDisconnected, applicationVersion]);

        return (
            <Fragment>
                <WrappedComponent {...props} />
            </Fragment>
        );
    }

    return WrappedWithApplicationVersionDetector;
}

export default withApplicationVersionDetector;