import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    LinearProgress,
    makeStyles, MenuItem,
    TextField,
} from '@material-ui/core';

import {useDispatch, useSelector} from "react-redux";
import Client from "../../../helpers/api";

import {setNotification} from "../../../slices/notification";
import GigStatusEnum from "../../../helpers/GigStatusEnum";
import {campaignAdminSelector, getCampaignAdminManagerList} from "../../../slices/campaignAdmin";



const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(3)
    }

}))


const CampaignAdminTrackingInformationDialog = ({open, gigToRender, onClose}) => {
    const {campaignManager} = useSelector(campaignAdminSelector);
    const [isSaving, setIsSaving] = useState(false);
    const [carrier, setCarrier] = useState();
    const [trackingIdentifier, setTrackingNumber] = useState("");
    const [url, setUrl] = useState("");
    const classes = useStyles();
    const dispatch = useDispatch();
    const onConfirmEvent = () => {
        if(!carrier){
            dispatch(setNotification("Select a carrier","error"));
            return;
        }
        if(carrier === "OTHER" && !url){
            dispatch(setNotification("Enter the tracking url","error"));
            return;
        }
        if(carrier !== "OTHER" && !trackingIdentifier){
            dispatch(setNotification("Enter the tracking identifier","error"));
            return;
        }
        setIsSaving(true);
        const gig = Object.assign({}, gigToRender);
        gig.shippingInfo = {...gig.shippingInfo, carrier, trackingIdentifier, url}
        Client.GigApi.brandPostShipping()(
            response => {
                dispatch(getCampaignAdminManagerList({...campaignManager, campaignId: gigToRender.campaign.id}));
                setIsSaving(false);
                onClose();
            },
            err=>{
                setIsSaving(false);
                dispatch(setNotification("Something went wrong", "error"));
            }
        )(gig)

    }
    const handleClose = () => {
        onClose();
    }
    const onChangeCarrier = value => {
        if(value === "OTHER"){
            setTrackingNumber("");
        }else{
            setUrl("");
        }
        setCarrier(value);
    }
    return <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle >Enter tracking information</DialogTitle>
            <DialogContent style={{minWidth: 400}}>
                {
                    (gigToRender.status === GigStatusEnum.AWAITING_SHIPMENT) &&
                    <>
                        <TextField
                            id="carrier"
                            label="Carrier"
                            fullWidth
                            required
                            size={"small"}
                            margin={"normal"}
                            variant="outlined"
                            value={carrier}
                            select
                            onChange={(e) => onChangeCarrier(e.target.value)} >
                            <MenuItem key="FedEx" value="FedEx">
                                FedEx
                            </MenuItem>
                            <MenuItem key="UPS" value="UPS">
                                UPS
                            </MenuItem>
                            <MenuItem key="USPS" value="USPS">
                                USPS
                            </MenuItem>
                            <MenuItem key="OTHER" value="OTHER">
                                OTHER
                            </MenuItem>
                        </TextField>
                        {
                            carrier !== "OTHER" ?
                                <TextField
                                    id="trackingNumber"
                                    label="Tracking Number"
                                    required
                                    fullWidth
                                    size={"small"}
                                    margin={"normal"}
                                    variant="outlined"
                                    value={trackingIdentifier}
                                    onChange={(e) => setTrackingNumber(e.target.value)}
                                    disabled={!carrier || carrier === ""} />
                                :
                                <TextField
                                    id="url"
                                    label="Url"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    size={"small"}
                                    margin={"normal"}
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    disabled={carrier === ""}/>
                        }
                    </>
                }
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button autoFocus onClick={handleClose} variant="contained">

                    Cancel

                </Button>
                <Button onClick={onConfirmEvent} style={{width: "98px"}} color="primary"
                        autoFocus variant="contained">
                    Confirm
                </Button>
            </DialogActions>
            {isSaving && <LinearProgress/>}
        </Dialog>
    </div>;
}

export default CampaignAdminTrackingInformationDialog;


