import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Box, LinearProgress, makeStyles, Typography} from '@material-ui/core';
import {campaignAdminSelector, getCampaignAdminManagerList} from "../../../slices/campaignAdmin";
import {useDispatch, useSelector} from "react-redux";
import Client from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";
import {isEmpty} from "../../../helpers/utils";
import {Skeleton} from "@material-ui/lab";




const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(3)
    }

}))

const Text = ({ animated, lines, text, width, maxWidth, component, linebreaks }) => {
    return (
        <Box mb={1}>
            <Typography variant="body2" component={component ?? "p"} style={linebreaks ? { whiteSpace: "pre-wrap" } : null}>
                {
                    !isEmpty(text) && <>{text}</>
                }
                {
                    isEmpty(text) &&
                    Array(lines ?? 1)
                        .fill(null)
                        .map((e, i) => (
                            <Skeleton key={i}
                                      width={i === lines - 1 ? width : maxWidth}
                                      animation={animated ? "wave" : null}/>
                        ))
                }
            </Typography>
        </Box>
    )
}

const CampaignAdminAddressConfirmationDialog = ({open, gigToRender, onClose}) => {
    const {campaignManager} = useSelector(campaignAdminSelector);
    const [isSaving, setIsSaving] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const onConfirmEvent = () => {
        setIsSaving(true);

        Client.GigApi.brandPostResponse(gigToRender.id, "1")(
            response => {
                dispatch(getCampaignAdminManagerList({...campaignManager, campaignId: gigToRender.campaign.id}));
                setIsSaving(false);
                onClose();
            },
            err=>{
                setIsSaving(false);
                dispatch(setNotification("Something went wrong", "error"));
            }

        )
    }
    const handleClose = () => {
        onClose();
    }
    return <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle >Confirm shipping address</DialogTitle>
            <DialogContent style={{minWidth: 400, paddingTop: 0}}>
                <Typography variant={"subtitle2"} style={{paddingBottom: "16px"}}>
                    This will lock the shipping address information and enable the shipping process.
                </Typography>
                <Box style={{display:"flex", flexDirection:"column"}}>
                    <Typography variant="caption">
                        <b>FULL NAME:</b>
                    </Typography>
                    <Text text={`${gigToRender.user.firstName} ${gigToRender.user.lastName}`} />
                    <Typography variant="caption">
                        <b>ATTENTION-OPTIONAL</b>
                    </Typography>
                    <Text text={gigToRender.shippingInfo?.attention} />
                    <Typography variant="caption">
                        <b>ADDRESS:</b>
                    </Typography>
                    <Text text={gigToRender.shippingInfo?.address} />
                    <Typography variant="caption">
                        <b>COUNTRY:</b>
                    </Typography>
                    <Text text={gigToRender.shippingInfo?.country} />
                    <Typography variant="caption">
                        <b>STATE/PROVINCE:</b>
                    </Typography>
                    <Text text={gigToRender.shippingInfo?.state} />
                    <Typography variant="caption">
                        <b>CITY:</b>
                    </Typography>
                    <Text text={gigToRender.shippingInfo?.city} />
                    <Typography variant="caption">
                        <b>POSTAL CODE:</b>
                    </Typography>
                    <Text text={gigToRender.shippingInfo?.zip} />
                    <Typography variant="caption">
                        <b>PHONE NUMBER:</b>
                    </Typography>
                    <Text text={gigToRender.shippingInfo?.phone} />
                    <Typography variant="caption">
                        <b>EMAIL ADDRESS:</b>
                    </Typography>
                    <Text text={gigToRender.shippingInfo?.email} />
                    <Typography variant="caption">
                        <b>SPECIAL DELIVERY INSTRUCTIONS - OPTIONAL:</b>
                    </Typography>
                    <Text text={gigToRender.shippingInfo?.instructions} />

                </Box>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button autoFocus onClick={handleClose} variant="contained">

                    Cancel

                </Button>
                <Button onClick={onConfirmEvent} style={{width: "98px"}} color="primary"
                        autoFocus variant="contained">
                    Confirm
                </Button>
            </DialogActions>
            {isSaving && <LinearProgress/>}
        </Dialog>
    </div>;
}

export default CampaignAdminAddressConfirmationDialog;


