import React, { useState, useMemo, useCallback } from "react";
import {
    Chip,
    Divider,
    Grid,
    Table,
    Badge,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Toolbar,
    Typography,
    makeStyles,
    Box,
    IconButton,
    Modal,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip 
} from "@material-ui/core";
import SearchInput from "../SearchInput";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Visibility from "@material-ui/icons/Visibility";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    grid: {
        marginBottom: "5px",
    },
    modal: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minHeight: "440px",
        minWidth: "860px",
        maxHeight: "440px",
        maxWidth: "860px",
        overflow: "auto",
    },
    codeBlock: {
        backgroundColor: "#f5f5f5",
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        fontFamily: "monospace",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
    },
    accordionSummary: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    historyItem:{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    accordionDetails: {
        flexDirection: "column",
    },
    iframe: {
        width: "100%",
        height: "315px",
        border: "none",
    },
    noMediaIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "315px",
        color: theme.palette.grey[500],
    },
}));

const RunnerReportTable = ({ suite }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectedTest, setSelectedTest] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    const handleOpen = useCallback((test) => {
        setSelectedTest(test);
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const extractTests = useCallback((suite, parentTitle = "") => {;
        let tests = [];
        const fullTitle = parentTitle
            ? `${parentTitle} | ${suite.title}`
            : suite.title;

        suite.tests.forEach((test) => {
            tests.push({
                subsection: fullTitle.split(" | ").slice(1).join(" | "),
                testName: test.title,
                fullTitle: test.fullTitle,
                state: test.state,
                duration: test.duration,
                code: test.code,
                details: test,
                flakyStats: test.flakyStats,
                mediaUrl: test.state === "failed" ? "" : null,
                uuid: suite.uuid,
            });
        });

        suite.suites.forEach((nestedSuite) => {
            tests = tests.concat(extractTests(nestedSuite, fullTitle));
        });

        return tests;
    }, []);

    const allTests = useMemo(() => extractTests(suite), [suite, extractTests]);

    const filteredTests = useMemo(() => {
        return allTests.filter(test =>
            test.subsection.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [allTests, searchQuery]);

    const sortedTests = useMemo(() => {
        return filteredTests.sort((a, b) => {
            if (a.state === "failed" && b.state !== "failed") return -1;
            if (a.state !== "failed" && b.state === "failed") return 1;
            if (a.state === "pending" && b.state !== "pending") return -1;
            if (a.state !== "pending" && b.state === "pending") return 1;
            return 0;
        });
    }, [filteredTests]);

    const ItemBadge = ({test}) => (
        <Badge color="secondary"
            badgeContent={
                (
                    test.flakyStats
                        ? Object.keys(test.flakyStats).length > 0
                        : false
                )
                    ? "flaky"
                    : null
            }>
            <Chip
                label={test.state.charAt(0).toUpperCase() + test.state.slice(1)}
                style={{
                    backgroundColor:
                        test.state === "passed"
                            ? "#4caf50"
                            : test.state === "failed"
                            ? "#f44336"
                            : "#ff9800",
                    color: "white",
                }}
            />
        </Badge>
    );

    return (
        <Box>
            <Box px={2}>
                <Toolbar disableGutters>
                    <Box flexGrow={1}>
                        <SearchInput
                            placeholder="Search by Subsection"
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Box>
                </Toolbar>
            </Box>
            <Divider />
            <TableContainer>
                <Table size={"medium"} className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">Subsection</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">Test Name</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">Status</Typography>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedTests?.map((test, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <p>{test.subsection}</p>
                                </TableCell>
                                <TableCell>
                                    <p>{test.testName}</p>
                                </TableCell>
                                <TableCell>
                                    <ItemBadge test={test} />
                                </TableCell>
                                <TableCell padding={"checkbox"}>
                                    <IconButton
                                        onClick={() => handleOpen(test)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={open} onClose={handleClose}>
                <Paper className={classes.modal}>
                    <Typography variant="h6" id="simple-modal-title">
                        Test Details
                    </Typography>
                    {selectedTest && (
                        <>
                            <Grid
                                container
                                spacing={2}
                                m={2}
                                className={classes.grid}>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <strong>Platform section:&nbsp;</strong>
                                        {suite?.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <strong>Subsection:&nbsp;</strong>
                                        {selectedTest.subsection}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <strong>Test Name:&nbsp;</strong>
                                        {selectedTest.testName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box
                                        display={"flex"}
                                        justifyContent={"flex-start"}
                                        alignItems={"center"}
                                        width="100%">
                                        <Typography variant="body1">
                                            <strong>Status:&nbsp;</strong>
                                        </Typography>
                                        <ItemBadge test={selectedTest} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <strong>Duration:&nbsp;</strong>
                                        {moment
                                            .utc(selectedTest.duration)
                                            .format("HH:mm:ss.SSS")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {selectedTest.flakyStats &&
                                Object.keys(selectedTest.flakyStats).length >
                                    0 && (
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            className={
                                                classes.accordionSummary
                                            }>
                                            <Typography>Flaky Stats</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            className={
                                                classes.accordionDetails
                                            }>
                                            <Box
                                                display={"flex"}
                                                justifyContent={
                                                    "space-between"
                                                }>
                                                <Box p={2}>
                                                    <Typography variant="body2">
                                                        <strong>
                                                            Last{" "}
                                                            {
                                                                selectedTest
                                                                    .flakyStats
                                                                    .runs
                                                            }{" "}
                                                            Runs
                                                        </strong>
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <strong>
                                                            Passed:{" "}
                                                        </strong>
                                                        {
                                                            selectedTest
                                                                .flakyStats
                                                                .passed
                                                        }
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <strong>
                                                            Failed:{" "}
                                                        </strong>
                                                        {
                                                            selectedTest
                                                                .flakyStats
                                                                .failed
                                                        }
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <strong>
                                                            Success Rate:{" "}
                                                        </strong>
                                                        {selectedTest.flakyStats
                                                            .runs > 0
                                                            ? (
                                                                  (selectedTest
                                                                      .flakyStats
                                                                      .passed /
                                                                      selectedTest
                                                                          .flakyStats
                                                                          .runs) *
                                                                  100
                                                              ).toFixed(0) + "%"
                                                            : "N/A"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <strong>
                                                            Latest Fail:{" "}
                                                        </strong>
                                                        {moment(
                                                            selectedTest
                                                                .flakyStats
                                                                .lastFailDate
                                                        ).format("LLL")}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <strong>
                                                            Latest Pass:{" "}
                                                        </strong>
                                                        {moment(
                                                            selectedTest
                                                                .flakyStats
                                                                .lastPassDate
                                                        ).format("LLL")}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2">
                                                        History
                                                    </Typography>
                                                    <Box
                                                        mt={2}
                                                        p={1}
                                                        height="200px" // Fixed height
                                                        overflow="auto" // Enable scrolling
                                                        borderRadius="4px">
                                                        {selectedTest.flakyStats.history.map(
                                                            (entry, index) => (
                                                                <Typography
                                                                    className={
                                                                        classes.historyItem
                                                                    }
                                                                    key={index}
                                                                    variant="body2">
                                                                    {moment(
                                                                        entry.date
                                                                    ).format(
                                                                        "LLL"
                                                                    )}
                                                                    &nbsp;-&nbsp;
                                                                    <Typography
                                                                        variant="inherit"
                                                                        style={{
                                                                            color:
                                                                                entry.state ===
                                                                                "passed"
                                                                                    ? "#4caf50"
                                                                                    : entry.state ===
                                                                                      "failed"
                                                                                    ? "#f44336"
                                                                                    : "#ff9800",
                                                                        }}>
                                                                        {entry.state
                                                                            .charAt(
                                                                                0
                                                                            )
                                                                            .toUpperCase() +
                                                                            entry.state.slice(
                                                                                1
                                                                            )}
                                                                    </Typography>
                                                                    &nbsp;
                                                                    <Tooltip title="See Job">
                                                                        <IconButton
                                                                            href={
                                                                                entry.jobUrl
                                                                            }
                                                                            target="__blank">
                                                                            <Visibility />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Typography>
                                                            )
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className={classes.accordionSummary}>
                                    <Typography>Media</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={classes.accordionDetails}>
                                    {selectedTest.mediaUrl ? (
                                        <iframe
                                            src={selectedTest.mediaUrl}
                                            title="Media"
                                            className={classes.iframe}></iframe>
                                    ) : (
                                        <Box className={classes.noMediaIcon}>
                                            <BrokenImageIcon fontSize="large" />
                                            <Typography>
                                                No media available
                                            </Typography>
                                        </Box>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className={classes.accordionSummary}>
                                    <Typography>Automated Test Code</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={classes.accordionDetails}>
                                    {selectedTest.code.length ? (
                                        <Typography
                                            className={classes.codeBlock}>
                                            {selectedTest.code}
                                        </Typography>
                                    ) : (
                                        "No code"
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            {Object.keys(selectedTest?.details?.err).length ? (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        className={classes.accordionSummary}>
                                        <Typography>Error log</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        className={classes.accordionDetails}>
                                        <Typography
                                            className={classes.codeBlock}>
                                            {
                                                selectedTest?.details?.err
                                                    ?.message
                                            }
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ) : null}
                        </>
                    )}
                </Paper>
            </Modal>
        </Box>
    );
};

export default RunnerReportTable;
