import {LinearProgress} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import InvoiceDetail from "../components/ClientDetails/InvoiceModal/InvoiceDetail";
import TopNotification from "../components/TopNotification";
import Api from "../helpers/api";
import {object as objectUtils} from "../helpers/utils";
import React, {Fragment} from "react";

const InvoiceDetailPrintPreview = () => {
    const {invoiceId} = useParams();
    const [notification, setNotification] = useState({open: false});
    const [inProgress, setInProgress] = useState(true);
    const [invoice, setInvoice] = useState({});
    const [isInvoiceInitialized, setIsInvoiceInitialized] = useState(false);
    const [backofficeMetadata, setBackofficeMetadata] = useState(null);

    const onMessage = useCallback((message, severity) => {
        clearTimeout(notification.timeoutHandler);
        const timeoutHandler = setTimeout(() => setNotification({open: false, message, severity}), 2500);
        setNotification({open: true, message, severity, timeoutHandler});
    }, [notification]);

    const handleErrorResponse = useCallback((error) => {
        const message = objectUtils.get("response.data.message", error, "An error occurred, please try again.");
        onMessage(message, "error");
    }, [onMessage]);

    useEffect(() => {
        if (!backofficeMetadata) {
            Api.getBackofficeMetadata()(
                ({data}) => setBackofficeMetadata(data), handleErrorResponse
            );
        }
    }, [backofficeMetadata, handleErrorResponse]);

    useEffect(() => {
        console.log({invoiceId, backofficeMetadata})
        if (!isInvoiceInitialized && invoiceId && backofficeMetadata) {
            Api.getInvoice(invoiceId)(({data}) => setInvoice(data), handleErrorResponse)
                .then(() => {
                    setIsInvoiceInitialized(true);
                    setInProgress(false);
                });
        }
    }, [isInvoiceInitialized, handleErrorResponse, invoiceId, backofficeMetadata]);

    return (
        <Fragment>
            {inProgress && <LinearProgress/>}
            {
                !inProgress &&
                <Container maxWidth={"md"} disableGutters>
                    <InvoiceDetail invoice={invoice} invoiceMetadata={backofficeMetadata.invoices} isPrintPreview/>
                </Container>
            }
            <TopNotification open={notification.open}
                             message={notification.message}
                             severity={notification.severity}/>
        </Fragment>
    )
}

export default InvoiceDetailPrintPreview;