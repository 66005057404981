import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import {LinearProgress, Paper} from "@material-ui/core";
import {useDispatch} from "react-redux";
import Api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";
import AddUserRoleModal from "../../AddUserRoleModal/AddUserRoleModal";
import AddRoleButton from "./AddRoleButton";
import UserRoleDeletePrompt from "./UserRoleDeletePrompt";
import UserRolesTableHead from "./UserRolesTableHead";
import UserRolesTableRow from "./UserRolesTableRow";
import RequestError from "../../../helpers/RequestError";
import ChangeUserRoleStatusModal from "../../ChangeUserRoleStatusModal/ChangeUserRoleStatusModal";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    emptyTable: {
        height: '424px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
        '& td': {
            borderBottom: 0,
        }
    },
    fab: {
        position: 'fixed',
        zIndex: 1,
        right: 0,
        bottom: 0,
        margin: theme.spacing(2),
    }
}));

const UserRolesTable = ({user, onUpdateSuccess, loading}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [inProgress, setInProgress] = useState(true);
    const [currentRole, setCurrentRole] = useState(null);

    const [isConfirmRemoveUserRoleModalOpen, setIsConfirmRemoveUserRoleModalOpen] = useState(false);
    const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
    const [isAddUserRoleModalOpen, setIsAddUserRoleModalOpen] = useState(false);

    useEffect(() => {
        setInProgress(loading);
    }, [loading]);

    const onClickRemoveRole = (userRole) => {
        setCurrentRole(userRole);
        setIsConfirmRemoveUserRoleModalOpen(true);
    }

    const onCloseConfirmRemoveModal = () => {
        setCurrentRole(null);
        setIsConfirmRemoveUserRoleModalOpen(false);
    }

    const removeRole = () => {
        setInProgress(true);
        Api.disableUserRole(user.user_id)(
            () => {
                dispatch(setNotification("Role removed successfully"));
                setIsConfirmRemoveUserRoleModalOpen(false);
                setCurrentRole(null);
                onUpdateSuccess();
            },
            (error) => dispatch(setNotification((new RequestError(error)).getMessage(), "error"))
        )({
            role_id: currentRole.role.role_id,
            client_id: currentRole.client?.client_id,
        }).then(() => setInProgress(false));
    }

    const onClickChangeStatus = (userRole) => {
        setCurrentRole(userRole);
        setIsChangeStatusModalOpen(true);
    }

    const onClickAddUserRole = () => {
        setIsAddUserRoleModalOpen(true);
    }

    const onAddRoleSuccess = () => {
        setIsAddUserRoleModalOpen(false);
        onUpdateSuccess();
    }

    const onCloseChangeStatusModal = () => {
        setIsChangeStatusModalOpen(false);
        setCurrentRole(null);
    }

    const onUpdateStatusSuccess = () => {
        setIsChangeStatusModalOpen(false);
        setCurrentRole(null);
        onUpdateSuccess();
    }

    return (
        <Paper>
            <TableContainer>
                <Table size={'medium'} className={classes.table}>
                    <UserRolesTableHead/>
                    <TableBody>
                        {
                            user && user.roles.map((userRole) =>
                                <UserRolesTableRow key={`${userRole.client?.client_id}-${userRole.role.role_id}`}
                                                   userRole={userRole} onClickChangeStatus={onClickChangeStatus}
                                                   onClickRemove={onClickRemoveRole}/>
                            )
                        }
                        {
                            user && user.roles.length >= 1 && (10 - user.roles.length) > 0 && (
                                <TableRow style={{height: (40) * (10 - user.roles.length)}}>
                                    <TableCell colSpan={6} style={{border: "none"}}/>
                                </TableRow>
                            )
                        }
                        {
                            (!user || (user.roles.length === 0)) && (
                                <TableRow className={classes.emptyTable}>
                                    <TableCell colSpan={6}>{inProgress ? "" : "No roles found."}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <AddRoleButton onClick={onClickAddUserRole}/>
            <UserRoleDeletePrompt isOpen={isConfirmRemoveUserRoleModalOpen}
                                  inProgress={inProgress}
                                  onClose={onCloseConfirmRemoveModal}
                                  onRemove={removeRole}/>
            <AddUserRoleModal isOpen={isAddUserRoleModalOpen} onClose={() => setIsAddUserRoleModalOpen(false)}
                              onSaveSuccess={onAddRoleSuccess} userId={user?.user_id}/>
            <ChangeUserRoleStatusModal isOpen={isChangeStatusModalOpen}
                                       onClose={onCloseChangeStatusModal} userId={user?.user_id}
                                       userRole={currentRole} onSaveSuccess={onUpdateStatusSuccess}/>
            {inProgress && <LinearProgress/>}
        </Paper>
    );
}

export default UserRolesTable;