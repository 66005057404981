import React, {useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress, TextField, Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";


const PaymentStatusManualNotPayableDialog = ({isOpen, onClose, onSaveSuccess, singleItem}) => {
    const dispatch = useDispatch();

    const [inProgress, setInProgress] = useState(false);
    const [note, setNote] = useState("");

    const sendManualPay = () => {

        setInProgress(true);
        api.payment.sendNotPayableOrder(singleItem.order_id)
        (response=>{
                setInProgress(false);
                onSaveSuccess();
            },
            error => {
                dispatch(setNotification('Request failed', 'error'))
                setInProgress(false);
            })
        ({description: note});
    }

    const handleLocalClose = () =>{
        setNote("");
        onClose();
    }

    const handleNote = event => {
        const {value} = event.target;
        setNote(value)
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Mark as not-payable</DialogTitle>
                <DialogContent>
                    <Typography>
                        Click “CONFIRM” to mark this payment as not-payable.
                    </Typography>
                    <TextField
                        margin={"normal"}
                        multiline
                        label={"Enter a note"}
                        placeholder={"Enter a note"}
                        maxRows={3}
                        rows={3}
                        fullWidth
                        size={'small'}
                        variant={'outlined'}
                        value={note}
                        onChange={handleNote}
                        name={"note"}/>
                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="contained" onClick={handleLocalClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="contained" fullWidth
                                    onClick={sendManualPay}
                                    color={'primary'}
                                    disabled={inProgress}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default PaymentStatusManualNotPayableDialog;