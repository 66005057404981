import React from 'react';
import {Button, Divider} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Toolbar from '@material-ui/core/Toolbar';
import SearchInput from "../../SearchInput";


const PaymentOrderSearchExport = ({onChangeSearchTerm, onClickExport, searchValue}) => {

    return (
        <Box>
            <Box px={2}>
                <Toolbar disableGutters>
                    <Box flexGrow={1} justifyContent={"space-between"} display={"flex"}>
                        <SearchInput value={searchValue} onChange={(e) => onChangeSearchTerm(e.target.value)}/>
                        <Button onClick={onClickExport} variant={'contained'}>
                           Export
                        </Button>
                    </Box>
                </Toolbar>
            </Box>
            <Divider/>
        </Box>
    );
}

PaymentOrderSearchExport.defaultProps = {
    onChangeSearchTerm: ()=>{},
    onClickExport: ()=>{},
    disableFilter: false,
    searchValue: ""
}

export default PaymentOrderSearchExport;
