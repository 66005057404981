import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, {useMemo} from "react";
import CurrencyFormat from "react-currency-format";
import LineItemsTableHead from "./InvoiceLineItemsTableHead";
import LineItemTableRow from "./InvoiceLineItemsTableRow";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        "& .MuiTableHead-root": {
            backgroundColor: theme.palette.grey[200],
            "& .MuiTableCell-root": {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
            },
        },
        "& .MuiTableRow-root.amount .MuiTableCell-root": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            borderBottom: "none",
        },
        "& .MuiTableRow-root .MuiTableCell-root:last-child": {
            padding: 0,
            whiteSpace: "nowrap",
        },
    },
    emptyTable: {
        height: '100px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
    },
}));

const InvoiceLineItemsTable = ({items, payments, onClickEditItem, onClickRemoveItem, itemTypes, isReadOnly}) => {
    const classes = useStyles();

    const total = useMemo(() => {
        return items.map(i => (i.unit_price_pennies * i.quantity) / 100).reduce((a, v) => a + v, 0);
    }, [items]);

    const paymentsReceived = useMemo(() => {
        return payments.map(p => p.amount_pennies / 100).reduce((a, v) => a + v, 0);
    }, [payments]);

    const balanceDue = useMemo(() => {
        return total - paymentsReceived;
    }, [total, paymentsReceived]);

    return (
        <TableContainer>
            <Table size={'medium'} className={classes.table}>
                <LineItemsTableHead isReadOnly={isReadOnly}/>
                <TableBody>
                    {
                        items.map((item) => {
                            const itemType = itemTypes.find(t => t.code === item.invoice_item_type);
                            const itemTypeLabel = itemType ? itemType.label : null;
                            return <LineItemTableRow key={item.invoice_item_id}
                                                     item={{...item, invoice_item_type_label: itemTypeLabel}}
                                                     isReadOnly={isReadOnly}
                                                     onClickEdit={() => onClickEditItem(item)}
                                                     onClickRemove={() => onClickRemoveItem(item)}/>
                        })
                    }
                    {
                        items.length === 0 && (
                            <TableRow className={classes.emptyTable}>
                                <TableCell colSpan={6}>No items.</TableCell>
                            </TableRow>
                        )
                    }
                    <TableRow className={"amount"}>
                        <TableCell colSpan={2} rowSpan={3} valign={"top"}>
                            <Typography variant={"body2"} component={"span"}>
                                <i><b>Thank you for your business!</b></i>
                            </Typography>
                        </TableCell>
                        <TableCell colSpan={2}>
                            <Typography variant={"body2"} component={"p"} align={"right"}>
                                <b>TOTAL (USD)</b>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <CurrencyFormat value={total} thousandSeparator decimalScale={2} fixedDecimalScale
                                            displayType={"text"}/>
                        </TableCell>
                        {!isReadOnly && <TableCell/>}
                    </TableRow>
                    <TableRow className={"amount"}>
                        <TableCell colSpan={2}>
                            <Typography variant={"body2"} component={"p"} align={"right"}>
                                <b>PAYMENTS RECEIVED (USD)</b>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <CurrencyFormat value={paymentsReceived} thousandSeparator decimalScale={2} fixedDecimalScale
                                            displayType={"text"}/>
                        </TableCell>
                        {!isReadOnly && <TableCell/>}
                    </TableRow>
                    <TableRow className={"amount"}>
                        <TableCell colSpan={2}>
                            <Typography variant={"body2"} component={"p"} align={"right"}>
                                <b>BALANCE DUE (USD)</b>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <CurrencyFormat value={balanceDue} thousandSeparator decimalScale={2} fixedDecimalScale
                                            displayType={"text"}/>
                        </TableCell>
                        {!isReadOnly && <TableCell/>}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default InvoiceLineItemsTable;