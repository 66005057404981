import React from "react";
import {Box, Dialog, Divider, Table, TableBody, TableContainer, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import dates from "../../../helpers/dates";
import PaymentApproveTableHead from "../PaymentApprove/PaymentApproveTableHead";
import TableCell from "@material-ui/core/TableCell";
import CurrencyDisplay from "../../CurrencyDisplay";

const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        minWidth: 440,
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "calc(100vw)",
        },
        [theme.breakpoints.up('sm')]: {
            width: 400,
        },
    },
}));


const PaymentOrderItems = ({isOpen, onClose, payment}) => {
    const classes = useStyles();
    const history = payment?.items || []

    return <Dialog
        maxWidth={'md'}
        open={isOpen} onClose={onClose} >
        <Box display={"flex"} p={2}>
            <Typography variant="h6" component={"div"} className={classes.title}>
                Payment item detail
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} className={classes.closeButton}>
                <CloseIcon/>
            </IconButton>
        </Box>
        <Divider/>
        <TableContainer>
            <Table>
                <PaymentApproveTableHead />
        <TableBody className={classes.content}>
            {
                history.map((item, index) => {

                    return <TableRow key={item.item_id}><TableCell align="left">
                        <Box>
                            <Typography variant={"body2"}>
                                {item.title}
                            </Typography>
                            <Typography variant={"caption"}>
                                {item.description}
                            </Typography>
                        </Box>
                    </TableCell>
                    <TableCell align="left">{item.client_id}</TableCell>
                    <TableCell>
                        {item.source}
                    </TableCell>
                    <TableCell align="left">{payment?.statement?.code}</TableCell>
                    <TableCell align="left">{dates.formatFromString(item.item_timestamp, 'MM/DD/YYYY')}</TableCell>
                    <TableCell align="left"><CurrencyDisplay value={item.total_amount_pennies} penniesToDollars /></TableCell>
                    </TableRow>
                })
            }
        </TableBody>
            </Table>
        </TableContainer>
    </Dialog>
}

PaymentOrderItems.defaultProps = {
    isOpen: true,
    onClose: () => {

    },
    id: null
}

export default PaymentOrderItems;