import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    title: {
        whiteSpace: "nowrap",
    }
}));

function AnalyticsClientsTableHead() {
    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                <TableCell align={"left"}>
                    <span className={classes.title}>Client ID</span>
                </TableCell>
                <TableCell align={"left"}>
                    <span className={classes.title}>Total Video Views</span>
                </TableCell>
                <TableCell align={"left"}>
                    <span className={classes.title}>Total Photo Views</span>
                </TableCell>
                <TableCell align={"left"}>
                    <span className={classes.title}>Total Sales</span>
                </TableCell>
                <TableCell align={"left"}>
                    <span className={classes.title}>Total Returns</span>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default AnalyticsClientsTableHead;