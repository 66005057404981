import { FormControl, Typography } from "@material-ui/core"
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { object } from "../../../helpers/utils";
import { clientConfigSelector, getClientConfig, putClientSection } from "../../../slices/clientConfig";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import CardWithTitleAndAction from "../CardWithTitleAndAction";

const penniesToDollars = pennies => (pennies ? Number(pennies) / 100 : 0);
const dollarsToPennies = dlls => (dlls ? Number(dlls) * 100 : 0);

const ACPISettings = () => {

    const { clientConfig, isClientConfigLoading } = useSelector(clientConfigSelector);
    const { client: { analytics } } = clientConfig;
    const apci_value = analytics?.apci_value;
    const currency_symbol = object.get("experiences.currency_symbol", clientConfig, "$")

    const [acpiAmount, setAcpiAmount] = useState((Number(penniesToDollars(apci_value))).toFixed(2));
    const [hasChanges, setHasChanges] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const dispatch = useDispatch();

    const onACPIAmountChange = (e, value) => {
        setHasChanges(value !== apci_value);
        setAcpiAmount(value);
    }

    const Save = () => {
        const updatedAnalytics = {
            apci_value: acpiAmount ? dollarsToPennies(acpiAmount) : 0
        }
        const clientSectionUpdated = { ...clientConfig.client, analytics: updatedAnalytics };
        setInProgress(true);
        Promise.resolve(dispatch(putClientSection(clientSectionUpdated.hash, clientSectionUpdated))).then(() => {
            dispatch(getClientConfig(clientSectionUpdated.hash)).then(()=>{
                setInProgress(false);
                setHasChanges(false);
            });
        });
    }

    return <CardWithTitleAndAction title={"Average cost-per-interaction"} inProgress={inProgress || isClientConfigLoading} onClickAction={Save} testId={'acpi-settings'} disableAction={!hasChanges}>
                <FormControl fullWidth>
                    <CurrencyTextField margin="normal"
                        textAlign="left"
                        fullWidth
                        label="ACPI value"
                        decimalPlaces={2}
                        currencySymbol={currency_symbol}
                        onChange={onACPIAmountChange}
                        value={acpiAmount}
                        variant="outlined" size="small" />
                </FormControl>
                <Typography>
                    This value is used in calculation of the Earned Media Value metrics reported in analytics.
                </Typography>
        </CardWithTitleAndAction>
}

export default ACPISettings;
