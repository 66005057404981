import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import {Delete, Edit} from "@material-ui/icons";
import React from "react";
import CurrencyFormat from "react-currency-format";

const InvoicePaymentsTableRow = ({payment, onClickEditPayment, onClickRemovePayment, isReadOnly}) => {
    return (
        <TableRow>
            <TableCell align="left">
                <Typography variant={"body2"} component={"p"}>
                    {payment.title}
                </Typography>
                <Typography variant={"caption"} component={"p"} color={"textSecondary"}>
                    {payment.notes}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <CurrencyFormat value={payment.amount_pennies / 100} thousandSeparator decimalScale={2}
                                fixedDecimalScale displayType={"text"}/>
            </TableCell>
            {
                !isReadOnly &&
                <TableCell>
                    <IconButton onClick={() => onClickEditPayment(payment)}>
                        <Edit/>
                    </IconButton>
                    <IconButton onClick={() => onClickRemovePayment(payment)}>
                        <Delete/>
                    </IconButton>
                </TableCell>
            }
        </TableRow>
    );
}

export default InvoicePaymentsTableRow;