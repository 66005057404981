import {Box, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {useTheme} from "@material-ui/styles";
import MessagesHelper from "../../helpers/MessagesHelper";

const SystemChatMessage = ({message}) => {
    const theme = useTheme();

    return (
        <Box mt={2} className={"UserChatMessage"} data-date={MessagesHelper.getTimeIndicatorLabel(message.time)}  data-message={message.text}>
            <Paper style={{backgroundColor: theme.palette.grey[100]}} elevation={0}>
                <Box px={2} py={1}>
                    <Typography variant="body2">
                        {message.text}
                    </Typography>
                </Box>
            </Paper>
        </Box>
    );
}

export default SystemChatMessage;