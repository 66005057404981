import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    currentTitle: '',
}

const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        setPageTitle: (state, {payload}) => {
            state.currentTitle = payload
        },
    },
})
export const {setPageTitle} = pageSlice.actions;
export const pageSelector = state => state.page;
export default pageSlice.reducer