import queryString from "query-string";
import {createBrowserHistory} from "history";

const history = createBrowserHistory();

export const getPageParametersAsObject = () => queryString.parse(window.location.search);

export const pageHasParameters = () => window.location.search && window.location.search.length > 1;

export const updateUrlParameters = (parameters) => {
    if(typeof parameters === "object"){
        parameters = queryString.stringify(parameters)
    }
    return `${parameters}`;
}

export const objectToRequestParameters = (objectParameters) =>{
    if(typeof objectParameters === "object"){
        const cleanParameters = Object.keys(objectParameters).filter(f=>objectParameters[f] && !f.includes('Label'));
        const parameterWithValues = {};
        cleanParameters.forEach (p => {
            parameterWithValues[p] = objectParameters[p];
        });
        return queryString.stringify(parameterWithValues)
    }
    return '';
}
export const updateBrowserWithUrlParameters = (parameters) => {
    if(typeof parameters === "object"){
        const cleanParameters = Object.keys(parameters).filter(f=>parameters[f]);
        const parameterWithValues = {};
        cleanParameters.forEach (p => {
            parameterWithValues[p] = parameters[p];
        });
        parameters = queryString.stringify(parameterWithValues)
    }
    return history.push(`${window.location.pathname}?${parameters}`);
}

export const pagePathnameContains = part => window.location.pathname.includes(part);

