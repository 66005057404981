import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../slices/page";
import { useTheme } from "@material-ui/core/styles";
import LobbyMetricsComponent from "../components/Lobby/LobbyMetrics";

const LobbyMetrics = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    useEffect(() => {
        dispatch(setPageTitle("Overview"));
        return function cleanup() {
            dispatch(setPageTitle(""));
        };
    }, [dispatch]);

    return (
        <Box bgcolor={theme.palette.grey[100]} height={"100%"} p={3}>
            <Paper>
                <LobbyMetricsComponent/>
            </Paper>
        </Box>
    );
};
export default LobbyMetrics;
