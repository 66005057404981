import makeStyles from "@material-ui/core/styles/makeStyles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiTableCell-root": {
            whiteSpace: "nowrap",
            fontWeight: "bold",
        },
    },
}));

const CommunicationsTableHead = () => {
    const classes = useStyles();
    return (
        <TableHead className={classes.root}>
            <TableRow key="thead">
                <TableCell style={{width: "100%"}}>
                    Name
                </TableCell>
                <TableCell>
                    Type
                </TableCell>
                <TableCell>
                    Last modified
                </TableCell>
                <TableCell padding={"checkbox"}>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default CommunicationsTableHead;