import React, {useState} from 'react';
import {Box, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import CampaignCreatorManualSocialDataTableHead from "./CampaignCreatorManualSocialDataTableHead";
import CampaignCreatorManualSocialDataTableRow from "./CampaignCreatorManualSocialDataTableRow";
import {getManualSocialData, manualSocialDataSelector} from "../../../slices/manualSocialData";
import {useDispatch, useSelector} from "react-redux";
import CampaignCreatorManualSocialDataDeleteDialog from "./CampaignCreatorManualSocialDataDeleteDialog";
import CampaignCreatorManualSocialDataLogDialog from "./CampaignCreatorManualSocialDataLogDialog";
import CampaignCreatorPostDialog from "../CampaignCreatorPost/CampaignCreatorPostDialog";
import CampaignCreatorManualSocialMetricsDialog from "./CampaignCreatorManualSocialMetricsDialog";

const CampaignCreatorManualSocialDataTable = ({items, gigToRender}) => {
    const [isSocialDataDialogOpen, setIsSocialDataDialogOpen] = useState(false);
    const [isPostModalOpen, setIsPostModalOpen] = useState(false);
    const [openSocialDataLog, setOpenSocialDataLog] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [selected, setSelected] = useState(null);
    const dispatch = useDispatch();
    const {filters, isLoading} = useSelector(manualSocialDataSelector);
    
    const refreshData = React.useCallback(() => {
        const params = `client_id=${gigToRender.campaign.brand.id}&user_id=${gigToRender.user.id}&page=${filters.page}&per_page=${filters.per_page}&campaign=${gigToRender.campaign.id}`;
        dispatch(getManualSocialData(params));
    }, [dispatch, gigToRender.campaign.brand.id, gigToRender.campaign.id, gigToRender.user.id, filters.page, filters.per_page]);

    React.useEffect(() => {
        refreshData(); // Call refreshData when the component mounts
    }, [refreshData]);

    const onOpenDelete = item => {
        setSelected(item);
        setOpenDelete(true);
    }
    const onClickEditSocialData = item =>{
        setSelected(item);
        setIsSocialDataDialogOpen(true);
    }
    const onClickEditPost = item =>{
        setSelected(item);
        setIsPostModalOpen(true);
    }
    const onClickSocialDataLog = item =>{
        setSelected(item);
        setOpenSocialDataLog(true);
    }

    const handleClose = () =>{
        setIsSocialDataDialogOpen(false);
        setOpenSocialDataLog(false);
        setOpenDelete(false);
        setIsPostModalOpen(false);
        setSelected(null);
        refreshData();
    }

    const onSuccess = () =>{
        handleClose();
        refreshData();
    }

    return <div>
        <TableContainer>
            <Table>
                <CampaignCreatorManualSocialDataTableHead />
                <TableBody>
                    {
                        items.map(item=>{
                            return <CampaignCreatorManualSocialDataTableRow
                                onOpenDelete={onOpenDelete}
                                onClickEditSocialData={onClickEditSocialData}
                                onClickEditPost={onClickEditPost}
                                onClickSocialDataLog={onClickSocialDataLog}
                                onBoostStatusChanged={refreshData}
                                gig={gigToRender}
                                key={item.post_id} item={item}/>
                        })
                    }
                    {(!isLoading && items.length === 0) && <TableRow>
                        <TableCell colSpan={9}>
                            <Box py={6} textAlign={'center'}>
                                No social posts.
                            </Box>
                        </TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
        {openDelete && <CampaignCreatorManualSocialDataDeleteDialog open={openDelete} gigToRender={gigToRender} selected={selected} onClose={handleClose} onSaveSuccess={onSuccess}/>}
        {openSocialDataLog && <CampaignCreatorManualSocialDataLogDialog isOpen={openSocialDataLog} gigToRender={gigToRender} selected={selected} onClose={handleClose} onSaveSuccess={onSuccess} onDataLogChange={() => refreshData()}/>}
        <CampaignCreatorManualSocialMetricsDialog isOpen={isSocialDataDialogOpen} gig={gigToRender} onClose={handleClose} onSaveSuccess={onSuccess} post={selected}/>
        <CampaignCreatorPostDialog isOpen={isPostModalOpen} onClose={handleClose} onSaveSuccess={onSuccess} gig={gigToRender} post={selected}/>
    </div>
}

CampaignCreatorManualSocialDataTable.defaultProps = {
    items: []
}

export default CampaignCreatorManualSocialDataTable;
