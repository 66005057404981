const GigStatusEnum = Object.freeze({
	RESERVATION_REQUESTED: 'ReservationRequested',
	RESERVATION_REJECTED: 'ReservationRejected',
	RESERVATION_WAITLISTED: 'ReservationWaitlisted',
	AWAITING_PRODUCT_SELECTION: 'AwaitingProductSelection',
	AWAITING_SHIPPING_ADDRESS: 'AwaitingShippingAddress',
	AWAITING_ADDRESS_CONFIRMATION: 'AwaitingAddressConfirmation',
	AWAITING_SHIPMENT: 'AwaitingShipment',
	AWAITING_PRODUCT_CONFIRMATION: 'AwaitingProductConfirmation',
	AWAITING_DELIVERY_CONFIRMATION: 'AwaitingDeliveryConfirmation',
	AWAITING_CONTENT: 'AwaitingContent',
	AWAITING_CONTENT_REVIEW: 'AwaitingContentReview',
	AWAITING_CONTENT_RESUBMISSION: 'AwaitingContentResubmission',
	AWAITING_SOCIAL_POST: 'AwaitingSocialPost',
	AWAITING_SOCIAL_POST_REVIEW: 'AwaitingSocialPostReview',
	AWAITING_SOCIAL_REPOST: 'AwaitingSocialRepost',
	COMPLETED: 'Completed',
	CANCELLED: 'Cancelled',
});

export default GigStatusEnum;
