import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
    Box,
    Divider, IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from "@material-ui/core";
import Client from "../../../helpers/api";
import {CloseOutlined} from "@material-ui/icons";

const CampaignAdminShipmentsProductsDialog = ({open, onClose, gigToEdit}) => {

    const [items, setItems] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const {productInventoryIdArray} = gigToEdit;
    const handleClose = () => {
        onClose();
    }
    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            const params = {};
            params.campaignId = gigToEdit.campaign.id;

            params.resultParameter = {
                resultsLimitOffset: 0,
                resultsLimitCount: 100,
                resultsOrderAscending: false
            };
            Client.CampaignApi.inventorySearch() (
                response => {
                    const {inventories} = response.data;
                    const filteredInventory = inventories.filter(f => productInventoryIdArray.includes(f.id));
                    setItems(filteredInventory);
                }
            )(params)
        }

    }, [productInventoryIdArray, gigToEdit, initialized]);

    return <div>
        <Dialog open={open} onClose={handleClose}>
            <Box>


                <Box>
                    <Box display={"flex"} p={2} justifyContent={"space-between"}>
                        <Typography>
                            Products selected
                        </Typography>
                        <IconButton size={"small"} onClick={handleClose}>
                            <CloseOutlined />
                        </IconButton>
                    </Box>
                    <Divider/>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Product
                                    </TableCell>
                                    <TableCell style={{whiteSpace: 'nowrap'}}>
                                        Product SKU
                                    </TableCell>
                                    <TableCell>
                                        Variant
                                    </TableCell>
                                    <TableCell style={{whiteSpace: 'nowrap'}}>
                                        Variant detail
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    items.map((item) => {
                                        return <TableRow key={item.id}>
                                            <TableCell>
                                                {item.product.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.product.sku}
                                            </TableCell>
                                            <TableCell>
                                                {item.sku}
                                            </TableCell>
                                            <TableCell>
                                                SKU: {item.sku} {item.variantValues.map(variant=>`${variant.identifier}: ${variant.value}`).join(", ")}
                                            </TableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>


            </Box>
        </Dialog>
    </div>;
}

export default CampaignAdminShipmentsProductsDialog;