import {Divider, Drawer} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import CreatorsDropdown from "../../CreatorsDropdown/CreatorsDropdown";
import ClientsDropdown from "../../ClientsDropdown/ClientsDropdown";
import {useDispatch, useSelector} from "react-redux";
import {paymentApproveSelector, updateApproveParameters} from "../../../slices/paymentApprove";


const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        minWidth: 440,
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "calc(100vw)",
        },
        [theme.breakpoints.up('sm')]: {
            width: 400,
        },
    },
}));

const PaymentApproveFilters = ({isOpen, onClose}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        paymentApproveFilters
    } = useSelector(paymentApproveSelector);


    const getCreatorValue = () => {
        if (paymentApproveFilters?.user_id) {
            return {
                first_name: paymentApproveFilters.creatorLabel,
                last_name: "",
                user_id: paymentApproveFilters.user_id
            }
        }
        return null;
    }

    const getClientValue = () => {
        if (paymentApproveFilters?.client_id) {
            return {
                name: paymentApproveFilters.clientLabel,
                client_id: paymentApproveFilters.client_id
            }
        }
        return null;
    }

    const onCreatorChange = (event, value) => {
        if (value === null) {
            dispatch(updateApproveParameters({...paymentApproveFilters, p: 1, user_id: null, creatorLabel: null}));
        } else {
            dispatch(updateApproveParameters({
                ...paymentApproveFilters,
                p: 1,
                user_id: value.user_id,
                creatorLabel: `${value.first_name} ${value.last_name}`
            }));
        }
    }
    const onClientChange = (event, value) => {
        if (value === null) {
            dispatch(updateApproveParameters({...paymentApproveFilters, p: 1, client_id: null, clientLabel: null}));
        } else {
            dispatch(updateApproveParameters({
                ...paymentApproveFilters,
                p: 1,
                client_id: value.client_id,
                clientLabel: value.name
            }));
        }
    }

    return (
        <Drawer open={isOpen} onClose={onClose} anchor={"right"}>
            <Box display={"flex"} p={2}>
                <Typography variant="h6" component={"div"} className={classes.title}>
                    Filters
                </Typography>
                <IconButton edge="end" color="inherit" onClick={onClose} className={classes.closeButton}>
                    <CloseIcon/>
                </IconButton>
            </Box>
            <Divider/>
            <Box className={classes.content} p={2} mt={1} height={"100%"}>
                <ClientsDropdown onChange={onClientChange} value={getClientValue()} />
                <CreatorsDropdown value={getCreatorValue()} onChange={onCreatorChange}/>
            </Box>
        </Drawer>
    )
}

PaymentApproveFilters.defaultProps = {
    filtersFor: "approve"
}

export default PaymentApproveFilters;