import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";

const UsersTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell align={'left'}>
                    Client ID
                </TableCell>
                <TableCell align={'left'}>
                    Client name
                </TableCell>
                <TableCell align={'left'}>
                    User ID
                </TableCell>
                <TableCell align={'left'}>
                    First name
                </TableCell>
                <TableCell align={'left'}>
                    Last name
                </TableCell>
                <TableCell align={'left'}>
                    Email
                </TableCell>
                <TableCell padding={"checkbox"}/>
            </TableRow>
        </TableHead>
    );
}

export default UsersTableHead;