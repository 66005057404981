import {createMuiTheme} from '@material-ui/core/styles';
import detectBrowserLanguage from 'detect-browser-language';
import * as locales from '@material-ui/core/locale';

const getMuiLocaleFromBrowser = () => {
    const browserLocale = detectBrowserLanguage().split('-')[0];
    const muiLocale = Object.keys(locales).filter(l => l.slice(0, 2) === browserLocale)[0];
    if (locales.hasOwnProperty(muiLocale)) {
        return locales[muiLocale];
    } else {
        return locales.enUS;
    }
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(2, 131, 238)',
            light: 'rgb(229 241 238)',
            contrastText: '#fff'
        },
        secondary: {
            main: 'rgb(2, 131, 238)',
        },
        error: {
            main: '#e01a2b',
        },
        background: {
            default: '#fff',
        },
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: "6px",
                overflow: 'hidden'
            },
        },
        MuiButton: {
            root: {
                paddingTop: '0.375rem',
                paddingBottom: "0.375rem",
                paddingLeft: '0.75rem',
                paddingRight: '0.75rem',
                fontSize: '14px',
                fontWeight: '800',
                height: '32px',
                lineHeight: '20px',
                borderRadius: '4px',
                textTransform: "none",
            },
            sizeLarge:{
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                fontSize: '16px',
                fontWeight: '800',
                height: '40px',
                lineHeight: '24px',
                borderRadius: '6px'

            }
        },
        MuiLink: {
            root: {
                cursor: "pointer",
            },
        },
        MuiTab: {
            root: {
                textTransform: "capitalize !important",
                fontSize: '14px'
            }
        },
        MuiDialog: {
            root: {
                "& .MuiDialogTitle-root > .MuiTypography-root": {
                    fontSize: "20px",
                }
            }
        },
    },
    props:{
        MuiTextField:{
            variant: 'outlined',
            size:'small',
            autoComplete: 'off'
        },
        MuiInput:{
            autoComplete: 'off'
        },
        MuiInputBase:{
            autoComplete: 'off'
        }
    }
}, getMuiLocaleFromBrowser());

export default theme;