import React, {useState} from 'react';
import {Table, TableBody, TableContainer} from "@material-ui/core";
import CampaignAdminShipmentsTableHead from "./CampaignAdminShipmentsTableHead";
import CampaignAdminShipmentsTableRow from "./CampaignAdminShipmentsTableRow";
import CampaignAdminShipmentsProductsDialog from "./CampaignAdminShipmentsProductsDialog";


const CampaignAdminShipmentsTable = ({items}) => {
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const onClickProducts = (item) => {
        setSelected(item);
        setOpen(true);
    }
    const onClose = () => {
        setSelected(null);
        setOpen(false);
    }
    return <div>
        <TableContainer>
            <Table>
                <CampaignAdminShipmentsTableHead />
                <TableBody>
                    {
                        items.map(item=>{
                            return <CampaignAdminShipmentsTableRow key={item.id} item={item} onClickProducts={onClickProducts} />
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {open && <CampaignAdminShipmentsProductsDialog onClose={onClose} open={open} gigToEdit={selected} />}
    </div>
}

CampaignAdminShipmentsTable.defaultProps = {
    items: []
}

export default CampaignAdminShipmentsTable;