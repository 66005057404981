import TableCell from "@material-ui/core/TableCell";
import {Box, Button, Checkbox, Divider, IconButton, Link, makeStyles, Menu, MenuItem} from "@material-ui/core";
import dates from "../../../helpers/dates";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CurrencyDisplay from "../../CurrencyDisplay";
import {useTheme} from "@material-ui/styles";
import CreateIcon from '@material-ui/icons/Create';


const useStyles = makeStyles({
    paidAction:{
        "&:hover":{
            "& .paid-action":{
                display: "block !important"
            }
        }
    },
    noteButton:{
        padding: 0,
        minWidth: 0
    }
})

const PaidRowAction = props => {
    return <Box minWidth={"26px"}><IconButton className={'paid-action'} {...props} size={'small'} style={{display: "none"}}>
        <CreateIcon fontSize={'small'}/>
    </IconButton></Box>
}

const PaymentTableRow = ({
                             payment,
                             isSelected,
                             onSelect,
                             enableCheckbox,
                             enableMenu,
                             activeCategory,
                             setOpenHistory,
                             setOpenReject,
                             setOpenManual,
                             setOpenRevoke,
                             handleSingleAction,
                             setOpenPaid,
                             setOpenNotPayable,
                             setOpenEditPaymentRefCode,
                             setOpenEditPaymentDate,
                             setOpenAddEditNote
                         }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const classes = useStyles();
    const handleClose = () => {
        setAnchorEl(null);
    };
    let menuItems = [];
    if (activeCategory === 0) {
        menuItems = [
            {
                label: 'Move to manual pay',
                action: setOpenManual,
                key: 1
            },
            {
                label: 'Reject payment',
                action: setOpenReject,
                key: 2
            },
            {
                label: 'Revoke approval',
                action: setOpenRevoke,
                key: 3
            }
        ];
    }
    if (activeCategory === 1) {
        menuItems = [
            {
                label: 'Mark as paid',
                action: setOpenPaid,
                key: 4
            },
            {
                label: 'Mark as not-payable',
                action: setOpenNotPayable,
                key: 5
            }
        ];
    }
    if (activeCategory === 3) {
        menuItems = [
            {
                label: 'Move to manual pay',
                action: setOpenManual,
                key: 1
            }
        ];
    }

    function localMenuHandler(cbk) {
        handleClose();
        handleSingleAction(cbk, payment);
    }

    const notesColumn = [1, 2, 3, 4].includes(activeCategory);
    const isPaidRow = activeCategory === 2;
    return <TableRow key={`${payment.order_id}`}
                     style={{
                         backgroundColor: isSelected ? theme.palette.primary.light : "transparent"
                     }}>
        {enableCheckbox && <TableCell padding={"checkbox"}>
            <Checkbox
                onChange={() => onSelect(payment)}
                checked={isSelected}
            /></TableCell>}
        <TableCell align="left">
            {payment.order_id}
        </TableCell>
        <TableCell align="left">{`${payment.first_name} ${payment.last_name}`}</TableCell>
        <TableCell align="left">{payment.user_id}</TableCell>
        <TableCell align="left">{payment.statement.code}</TableCell>
        <TableCell align="left">
            <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'} className={classes.paidAction}>
                {dates.formatFromString(payment.execution_time, 'MM/DD/YYYY')}
                {isPaidRow && <PaidRowAction onClick={() => localMenuHandler(setOpenEditPaymentDate)}/>}
            </Box>
        </TableCell>
        <TableCell align="left">
            <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'} className={classes.paidAction}>
                {payment.payout_reference || '-'}
                {isPaidRow && <PaidRowAction onClick={() => localMenuHandler(setOpenEditPaymentRefCode)}/>}
            </Box>
        </TableCell>
        <TableCell align="left"><CurrencyDisplay value={payment.gross_amount_pennies} penniesToDollars/></TableCell>
        <TableCell align="left"><CurrencyDisplay value={payment.fees_amount_pennies} penniesToDollars/></TableCell>
        <TableCell align="left"><CurrencyDisplay value={payment.net_amount_pennies} penniesToDollars/></TableCell>
        {notesColumn && <TableCell align="left">
            <Button disableElevation component={Link} color={'primary'} onClick={() => localMenuHandler(setOpenAddEditNote)} className={classes.noteButton}>
                {payment?.user_notes ? "View" : "Add"}
            </Button>
        </TableCell>}
        {enableMenu && <TableCell align="center">
            <IconButton
                onClick={handleMenu}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    menuItems.map((menu, index) => {
                        return <MenuItem key={menu.key}
                                         onClick={() => localMenuHandler(menu.action)}>{menu.label}</MenuItem>
                    })
                }


                {menuItems.length > 0 && <Divider/>}
                <MenuItem onClick={() => localMenuHandler(setOpenHistory)}>View payment items</MenuItem>
            </Menu>
        </TableCell>}
    </TableRow>
}

PaymentTableRow.defaultProps = {
    enableMenu: false,
    paymentDate: false,
    enableCheckbox: false
}

export default PaymentTableRow;