import React, {useEffect, useState} from "react";
import {
    Box,
    TableRow
} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment/moment";
import Button from "@material-ui/core/Button";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import CampaignCreatorManualSocialDataDeleteDialog from "./CampaignCreatorManualSocialDataLogDeleteDialog";
import Api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";
import {useDispatch} from "react-redux";

const CampaignCreatorManualSocialDataLogEntryRow = ({entry, onSuccess}) => {
    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState(false);
    const [scanTime, setScanTime] = useState(moment());
    const [views, setViews] = useState("");
    const [likes, setLikes] = useState("");
    const [impressions, setImpressions] = useState("");
    const [shares, setShares] = useState("");
    const [comments, setComments] = useState("");
    const [saves, setSaves] = useState("");
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        setScanTime(moment(entry.scan_time));
        setViews(entry.metrics.video_views);
        setLikes(entry.metrics.likes);
        setImpressions(entry.metrics.impressions);
        setShares(entry.metrics.shares);
        setComments(entry.metrics.comments);
        setSaves(entry.metrics.saves);
    }, [entry]);

    const onSave = () => {
        setInProgress(true);
        Api.social.updateManualSocialMetrics(entry.post_id, entry.manual_entry_id, entry.client_id)(
            () => onSuccess(true),
            () => dispatch(setNotification("Something went wrong", "error")),
        )({
            "scan_time": scanTime.toISOString(),
            "impressions": impressions || 0,
            "shares": shares || 0,
            "video_views": views || 0,
            "comments": comments || 0,
            "likes": likes || 0,
            "saves": saves || 0
        }).then(() => setInProgress(false));
    }

    return (
        <TableRow key={entry.manual_entry_id}>
            <TableCell>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        disabled={inProgress}
                        format={"MM/DD/yyyy HH:mm"}
                        maxDate={moment()}
                        fullWidth
                        error={!scanTime}
                        value={scanTime}
                        onChange={setScanTime}/>
                </MuiPickersUtilsProvider>
            </TableCell>
            <TableCell>
                <CurrencyTextField
                    disabled={inProgress}
                    textAlign={'left'}
                    variant={'outlined'}
                    minimumValue={"0"}
                    decimalPlaces={0}
                    currencySymbol={""}
                    value={impressions}
                    onChange={(event, value) => setImpressions(value)}
                    fullWidth/>
            </TableCell>
            <TableCell>
                <CurrencyTextField
                    disabled={inProgress}
                    textAlign={'left'}
                    variant={'outlined'}
                    minimumValue={"0"}
                    decimalPlaces={0}
                    currencySymbol={""}
                    value={views}
                    onChange={(event, value) => setViews(value)}
                    fullWidth/>
            </TableCell>
            <TableCell>
                <CurrencyTextField
                    disabled={inProgress}
                    textAlign={'left'}
                    variant={'outlined'}
                    minimumValue={"0"}
                    decimalPlaces={0}
                    currencySymbol={""}
                    value={likes}
                    onChange={(event, value) => setLikes(value)}
                    fullWidth/>
            </TableCell>
            <TableCell>
                <CurrencyTextField
                    disabled={inProgress}
                    value={shares}
                    textAlign={'left'}
                    variant={'outlined'}
                    minimumValue={"0"}
                    decimalPlaces={0}
                    currencySymbol={""}
                    onChange={(event, value) => setShares(value)}
                    fullWidth/>
            </TableCell>
            <TableCell>
                <CurrencyTextField
                    disabled={inProgress}
                    value={comments}
                    textAlign={'left'}
                    variant={'outlined'}
                    minimumValue={"0"}
                    decimalPlaces={0}
                    currencySymbol={""}
                    onChange={(event, value) => setComments(value)}
                    fullWidth/>
            </TableCell>
            <TableCell>
                <CurrencyTextField
                    disabled={inProgress}
                    textAlign={'left'}
                    variant={'outlined'}
                    minimumValue={"0"}
                    decimalPlaces={0}
                    currencySymbol={""}
                    value={saves}
                    onChange={(event, value) => setSaves(value)}
                    fullWidth/>
            </TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>
                <Button variant="contained" color={"primary"} size={"small"} onClick={onSave} disabled={inProgress}>
                    Save
                </Button>
                <Box style={{display: "inline-block"}} p={1}/>
                <Button style={{backgroundColor: "#e01a2b", color: "#fff"}} variant="contained"
                        size={"small"} onClick={() => setIsDeleteModalOpen(true)} disabled={inProgress}>
                    Delete
                </Button>
                {isDeleteModalOpen && <CampaignCreatorManualSocialDataDeleteDialog isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} onSaveSuccess={onSuccess} entry={entry}/>}
            </TableCell>
        </TableRow>
    )
}

export default CampaignCreatorManualSocialDataLogEntryRow;
