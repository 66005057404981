import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import React, {useEffect, useState} from "react";
import Api from "../../helpers/api";
import RequestError from "../../helpers/RequestError";
import ResponseStatus from "../../helpers/ResponseStatus";
import {isEmpty} from "../../helpers/utils";
import PasswordInput from "./../PasswordInput";
import PasswordValidator from "./../PasswordValidator";
import {useDispatch} from "react-redux";
import {setNotification} from "../../slices/notification";

const ChangeUserPasswordModal = ({isOpen, userId, onClose, onSaveSuccess}) => {
    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setPassword("");
            setConfirmPassword("");
            setIsPasswordValid(false);
            setInProgress(false);
        }
    }, [isOpen]);

    const onSave = () => {
        if (isEmpty(confirmPassword)) {
            setShowErrors(true);
            dispatch(setNotification("Password is required.", "error"));
        } else if (password !== confirmPassword) {
            setShowErrors(true);
            dispatch(setNotification("Passwords doesn't match.", "error"));
        } else {
            setInProgress(true);
            Api.setUserPassword(userId)(
                (response) => {
                    const respStatus = new ResponseStatus(response);
                    if (respStatus.isError()) {
                        dispatch(setNotification(respStatus.getErrorMessage(), "error"));
                    } else {
                        onSaveSuccess();
                        dispatch(setNotification("Password changed successfully"));
                    }
                },
                (error) => {
                    setInProgress(false);
                    dispatch(setNotification((new RequestError(error)).getMessage(), "error"))
                }
            )({password});
        }
    }

    return (
        <Dialog open={isOpen} onClose={onClose} scroll={"body"}>
            <DialogTitle>
                Update password
            </DialogTitle>
            <DialogContent dividers={true}>
                <FormControl fullWidth margin={"normal"}>
                    <PasswordInput label={"Password"} disabled={inProgress} required
                                   onChange={(e) => setPassword(e.target.value)}
                                   error={showErrors && !isPasswordValid}/>
                </FormControl>
                <FormControl fullWidth margin={"normal"}>
                    <PasswordInput label={"Confirm password"} disabled={inProgress} required
                                   onChange={(e) => setConfirmPassword(e.target.value)}
                                   errorText={showErrors && (password !== confirmPassword) ? "Password doesn't match.." : ""}
                                   error={showErrors && (password !== confirmPassword)}/>
                </FormControl>
                <Box mt={1}>
                    <PasswordValidator password={password} onValidate={setIsPasswordValid}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box width={"100%"} p={1.5}>
                    <Grid container justify="flex-end" spacing={3}>
                        <Grid item xs={6}>
                            <Button variant="outlined" onClick={onClose} disabled={inProgress} fullWidth>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type="submit" color="primary" variant="contained"
                                    disabled={inProgress || !isPasswordValid}
                                    onClick={onSave} fullWidth>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogActions>
            {inProgress && <LinearProgress/>}
        </Dialog>
    )
}

export default ChangeUserPasswordModal;