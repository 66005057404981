import React from 'react';
import {makeStyles, TableCell, TableHead, TableRow} from "@material-ui/core";
const useStyles =  makeStyles({
    noWrapText:{
        whiteSpace: 'nowrap'
    }
})
const CampaignAdminSeedingTableHead = () =>{
    const classes = useStyles();
    return <TableHead>
        <TableRow>
            <TableCell className={classes.noWrapText}>
                Full name
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Attention
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Address 1
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Address 2
            </TableCell>
            <TableCell className={classes.noWrapText}>
                City
            </TableCell>
            <TableCell className={classes.noWrapText}>
                State/province
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Country
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Postal code
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Phone number
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Email address
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Delivery instructions
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Shipping status
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Carrier
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Tracking number
            </TableCell>
            <TableCell />
        </TableRow>
    </TableHead>
}
export default CampaignAdminSeedingTableHead;