import {DragDropContext, Droppable} from "react-beautiful-dnd";
import TableBody from "@material-ui/core/TableBody";
import React from "react";

const DroppableTableBody = (onDragEnd) => (props) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'1'} direction="vertical">
                {(provided) => {
                    return (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps}
                                   style={{overflow: "auto"}} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </TableBody>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
}

export default DroppableTableBody;