import {Link, makeStyles, TableCell, TableRow} from "@material-ui/core";
import React from "react";
import {strings} from "../../../helpers/utils";

const useStyles =  makeStyles({
    noWrapText:{
        whiteSpace: 'nowrap'
    }
})

const CampaignAdminShipmentsTableRow = ({item, onClickProducts}) => {
    const {user, shippingInfo, status} = item;

    const classes = useStyles();

    return <TableRow>
        <TableCell className={classes.noWrapText}>
            {user.firstName} {user.lastName}
        </TableCell>
        <TableCell>
            {shippingInfo.attention}
        </TableCell>
        <TableCell className={classes.noWrapText}>
            {shippingInfo.address || "-"}
        </TableCell>
        <TableCell className={classes.noWrapText}>
            {shippingInfo.address2 || "-"}
        </TableCell>
        <TableCell>
            {shippingInfo.city || "-"}
        </TableCell>
        <TableCell>
            {shippingInfo.state || "-"}
        </TableCell>
        <TableCell>
            {shippingInfo.country || "-"}
        </TableCell>
        <TableCell>
            {shippingInfo.zip || "-"}
        </TableCell>
        <TableCell>
            {shippingInfo.phone || "-"}
        </TableCell>
        <TableCell>
            {shippingInfo.email || "-"}
        </TableCell>
        <TableCell>
            {shippingInfo.instructions || "-"}
        </TableCell>
        <TableCell className={classes.noWrapText}>
            {status ? strings.insertSpacesToCamelCase(status) : "-"}
        </TableCell>
        <TableCell>
            {shippingInfo.carrier || "-"}
        </TableCell>
        <TableCell>
            {shippingInfo.trackingIdentifier || "-"}
        </TableCell>
        <TableCell className={classes.noWrapText}>
            <Link onClick={()=>onClickProducts(item)} component={"button"}>
                VIEW PRODUCTS
            </Link>
        </TableCell>
    </TableRow>
}

export default CampaignAdminShipmentsTableRow;