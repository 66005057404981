import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import WidgetTemplatesTable from "../components/WidgetTemplatesTable/WidgetTemplatesTable";
import {
    widgetTemplatesSelector,
    getWidgetTemplates,
    createWidgetTemplate,
    getWidgetTemplate,
    updateWidgetTemplate,
    deleteWidgetTemplate
} from "../slices/widgetTemplates";
import {useDispatch, useSelector} from "react-redux";
import AddWidgetTemplateModal from "../components/AddWidgetTemplateModal/AddWidgetTemplateModal";
import TopNotification from '../components/TopNotification';
import {useIntl} from "react-intl";
import {setPageTitle} from "../slices/page";
import {SpeedDialIcon} from "@material-ui/lab";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
}));

const clearNotification = {message: "", open: false, severity: "success"};

const AddWidgetTemplateButton = ({onClick}) => {
    const classes = useStyles();
    return (
        <Fab className={classes.fab} color={'primary'} onClick={onClick}>
            <SpeedDialIcon/>
        </Fab>
    )
}

const WidgetTemplates = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();

    const {
        widgetTemplates,
        totalWidgetTemplates,
        isWidgetTemplatesLoading,
        isActionWidgetTemplateError,
        actionWidgetTemplateErrorMessage,
        widgetTemplate,
        isActionWidgetTemplateInProgress,
    } = useSelector(widgetTemplatesSelector);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [isWidgetTemplateModalOpen, setIsWidgetTemplateModalOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState(clearNotification);
    const [widgetTemplateToEdit, setWidgetTemplateToEdit] = useState(null);
    const [isSaveDone, setIsSaveDone] = useState(false);
    const [isDeleteDone, setIsDeleteDone] = useState(false);
    const [saveDoneMessage, setSaveDoneMessage] = useState("");

    const textWidgetTemplateCreated = intl.formatMessage({
        id: 'Experiences.WidgetTemplateCreated',
        defaultMessage: 'Widget template has been created successfully'
    });

    const textWidgetTemplateUpdated = intl.formatMessage({
        id: 'Experiences.WidgetTemplateUpdated',
        defaultMessage: 'Widget template has been updated successfully'
    });

    const textWidgetTemplateDeleted = intl.formatMessage({
        id: 'Experiences.WidgetTemplateDeleted',
        defaultMessage: 'Widget template has been deleted successfully'
    });

    const reloadWidgetTemplates = useCallback(() => {
        return dispatch(getWidgetTemplates(currentPage, itemsPerPage));
    }, [dispatch, currentPage, itemsPerPage]);

    const onSaveTemplateError = useCallback(() => {
        setNotificationMessage({message: actionWidgetTemplateErrorMessage, open: true, severity: "error"});
        setIsFormDisabled(false);
        setIsSaveDone(false);
    }, [setNotificationMessage, setIsFormDisabled, setIsSaveDone, actionWidgetTemplateErrorMessage]);

    const onSaveTemplateSuccess = useCallback(() => {
        setNotificationMessage({message: saveDoneMessage, open: true, severity: "success"});
        setIsWidgetTemplateModalOpen(false);
        setWidgetTemplateToEdit(null);
        setIsSaveDone(false);
        setIsFormDisabled(false);
        reloadWidgetTemplates();
    }, [setNotificationMessage, setIsWidgetTemplateModalOpen, setWidgetTemplateToEdit, setIsSaveDone, setIsFormDisabled, reloadWidgetTemplates, saveDoneMessage]);

    useEffect(() => {
        dispatch(setPageTitle("Widget templates"));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(getWidgetTemplates(currentPage, itemsPerPage));
    }, [dispatch, currentPage, itemsPerPage]);

    useEffect(() => {
        setWidgetTemplateToEdit(widgetTemplate);
    }, [setWidgetTemplateToEdit, widgetTemplate]);

    useEffect(() => {
        if (notificationMessage.open) {
            setTimeout(() => {
                setNotificationMessage(clearNotification);
            }, 2500);
        }
    }, [notificationMessage, setNotificationMessage]);

    useEffect(() => {
        if (isSaveDone && isActionWidgetTemplateInProgress === false && isActionWidgetTemplateError) {
            onSaveTemplateError();
        } else if (isSaveDone && isActionWidgetTemplateInProgress === false) {
            onSaveTemplateSuccess();
        }
    }, [isActionWidgetTemplateInProgress, isActionWidgetTemplateError, isSaveDone, onSaveTemplateError, onSaveTemplateSuccess]);

    useEffect(() => {
        if (isDeleteDone && isActionWidgetTemplateInProgress === false && isActionWidgetTemplateError) {
            setNotificationMessage({message: actionWidgetTemplateErrorMessage, open: true, severity: "error"});
            setIsDeleteDone(false);
        } else if (isDeleteDone && isActionWidgetTemplateInProgress === false) {
            setNotificationMessage({message: textWidgetTemplateDeleted, open: true, severity: "success"});
            setIsDeleteDone(false);
        }
    }, [isActionWidgetTemplateInProgress, isActionWidgetTemplateError, actionWidgetTemplateErrorMessage, isDeleteDone, reloadWidgetTemplates, textWidgetTemplateDeleted]);

    const onEditWidgetTemplate = (widgetTemplateData) => {
        setWidgetTemplateToEdit(widgetTemplateData);
        setIsFormDisabled(true);
        setIsWidgetTemplateModalOpen(true);
        dispatch(getWidgetTemplate(widgetTemplateData.experience_widget_template_id))
            .then(() => {
                setIsFormDisabled(false);
                setIsWidgetTemplateModalOpen(true);
            });
    }

    const onSaveWidgetTemplate = (widgetTemplateData) => {
        setNotificationMessage(clearNotification);
        setIsFormDisabled(true);

        if (widgetTemplateData.hasOwnProperty("experience_widget_template_id")) {
            setSaveDoneMessage(textWidgetTemplateUpdated);
            dispatch(updateWidgetTemplate(widgetTemplateData.experience_widget_template_id, widgetTemplateData))
                .then(() => setIsSaveDone(true));
        } else {
            setSaveDoneMessage(textWidgetTemplateCreated);
            dispatch(createWidgetTemplate(widgetTemplateData))
                .then(() => setIsSaveDone(true));
        }
    }

    const onDeleteWidgetDone = useCallback(() => {
        const lastPageAfterRemoval = Math.ceil((totalWidgetTemplates - 1) / itemsPerPage) - 1;
        const newPage = Math.min(currentPage, lastPageAfterRemoval);
        if (newPage !== currentPage && newPage >= 0) {
            setCurrentPage(newPage);
        } else {
            reloadWidgetTemplates();
        }
        setIsDeleteDone(true);
    }, [currentPage, totalWidgetTemplates, itemsPerPage, reloadWidgetTemplates]);

    const onDeleteWidget = (widget) => {
        dispatch(deleteWidgetTemplate(widget.experience_widget_template_id))
            .then(onDeleteWidgetDone);
    }

    const onChangeCurrentPage = (newPage) => {
        setCurrentPage(newPage);
    }

    const onChangeItemsPerPage = (itemsPerPage) => {
        setItemsPerPage(itemsPerPage);
    }

    return (
        <Box className={classes.root}>
            <Paper>
                <WidgetTemplatesTable loading={isWidgetTemplatesLoading}
                                      widgetTemplates={widgetTemplates}
                                      totalWidgetTemplates={totalWidgetTemplates}
                                      onClickAdd={() => setIsWidgetTemplateModalOpen(true)}
                                      onClickEdit={onEditWidgetTemplate}
                                      onClickDelete={onDeleteWidget}
                                      currentPage={currentPage}
                                      rowsPerPage={itemsPerPage}
                                      onChangeRowsPerPage={onChangeItemsPerPage}
                                      onChangeCurrentPage={onChangeCurrentPage}/>
                {isWidgetTemplatesLoading && <LinearProgress/>}
            </Paper>
            <Box height={80}>
                <AddWidgetTemplateButton onClick={() => setIsWidgetTemplateModalOpen(true)}/>
            </Box>
            <AddWidgetTemplateModal open={isWidgetTemplateModalOpen}
                                    onCancel={() => setIsWidgetTemplateModalOpen(false)}
                                    widgetTemplateData={widgetTemplateToEdit} onSave={onSaveWidgetTemplate}
                                    disabled={isFormDisabled}
                                    inProgress={isActionWidgetTemplateInProgress}/>
            <TopNotification open={notificationMessage.open} message={notificationMessage.message}
                             severity={notificationMessage.severity}/>
        </Box>
    );
}

export default WidgetTemplates;