import {
    FormControl,
    FormControlLabel,
    Typography
} from "@material-ui/core"
import React, {useEffect, useState} from "react";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {clientConfigSelector, getClientConfig, putClientSection} from "../../../slices/clientConfig";
import {setNotification} from "../../../slices/notification";
import Api from "../../../helpers/api";
import Client from "../../../helpers/api";
import Shared from "../../../helpers/Shared";
import {withUser} from "../../../providers/UserProvider";
import CardWithTitleAndAction from "../CardWithTitleAndAction";
import Switch from "@material-ui/core/Switch";


const CampaignSocialData = ({user}) => {

    const {
        clientConfig,
        isClientConfigLoading,
        clientConfigErrorMessage,
        isClientConfigError
    } = useSelector(clientConfigSelector);
    const {client: {campaigns}} = clientConfig;
    const {program_campaign} = campaigns;
    const [automatedReportingEnabled, setAutomatedReportingEnabled] = useState(!!program_campaign?.automated_reporting_enabled);
    const [hasChanges, setHasChanges] = useState(false);
    const [isCampaignSessionProcessed, setIsCampaignSessionProcessed] = useState(null);

    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState(false);

    const reloadCampaignsSession = useCallback(() => {
        if (isCampaignSessionProcessed === null) {
            setIsCampaignSessionProcessed(false);
            return Client.UserApi.login()(
                (session) => Shared.session = session.data, () => {}
            )({cookie: document.cookie, userId: user.user_id}).then(() => setIsCampaignSessionProcessed(true));
        } else {
            return Promise.resolve();
        }
    }, [isCampaignSessionProcessed, user]);

    const reloadCampaignsClientData = useCallback(() => {
        setInProgress(true);
        reloadCampaignsSession().then(() => {
            Api.client.refreshCampaignClientData()(
                () => {}, () => dispatch(setNotification('Error refreshing client data on campaigns service', "error"))
            )({client_id: clientConfig.client.id}).then(() => setInProgress(false));
        });
    }, [reloadCampaignsSession, dispatch, clientConfig]);

    useEffect(() => {
        setInProgress(isClientConfigLoading);
    }, [isClientConfigLoading])



    const onClickSave = () => {
        const updatedCampaigns = {
            program_campaign: {
                ...clientConfig.client.campaigns.program_campaign,
                automated_reporting_enabled: automatedReportingEnabled,
            }
        }
        const clientSectionUpdated = {...clientConfig.client, campaigns: updatedCampaigns};
        setInProgress(true);
        Promise.all([dispatch(putClientSection(clientSectionUpdated.hash, clientSectionUpdated))]).then(() => {
            dispatch(getClientConfig(clientConfig.client.hash));
        }).then(() => reloadCampaignsClientData());
    }

    useEffect(() => {
        if (isClientConfigError) {
            dispatch(setNotification(clientConfigErrorMessage, 'error'));

        }
    }, [clientConfig, isClientConfigError, clientConfigErrorMessage, dispatch])
    const onChangeSwitch = e =>{
        setAutomatedReportingEnabled(e.target.checked)
        setHasChanges(!(program_campaign?.automated_reporting_enabled === e.target.checked));
    }
    return <CardWithTitleAndAction title={'Campaign social data'} onClickAction={onClickSave} inProgress={inProgress} testId={'campaign-social-data'} disableAction={!hasChanges}>
            <FormControl fullWidth>
                <FormControlLabel margin="normal"
                                  onChange={onChangeSwitch}
                                  control={<Switch checked={automatedReportingEnabled}/>}
                                  label="Enable automated data gathering"/>
            </FormControl>
        <Typography variant={'subtitle2'}>
            When this is enabled and a creator has connected social media accounts, the system will automatically gather posts that contain the matching campaign #hashtag.
        </Typography>
    </CardWithTitleAndAction>


}

export default withUser(CampaignSocialData);
