import React from 'react';
import {Link} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import {useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import routes from "../../helpers/routes";
import Box from "@material-ui/core/Box";
import environment from "../../helpers/environment";

const useStyles = makeStyles({
    list: {
        width: 350,
    },
    fullList: {
        width: 'auto',
    },
});

const ListHeading = ({text}) => {
    return (
        <ListItem style={{paddingTop: 0, paddingBottom: 0}}>
            <Typography variant="overline" component="span" style={{fontWeight: "bold"}}>
                {text}
            </Typography>
        </ListItem>
    );
}

const AppMenu = () => {
    const classes = useStyles();
    const intl = useIntl();
    const logoUrl = 'https://sitecdn.tvpage.com/static/images/creatable_logo.svg';


    const textUsers = intl.formatMessage({
        id: 'App.Menu.Users',
        defaultMessage: 'Users'
    });


    const textClientSummary = intl.formatMessage({
        id: 'App.Menu.ClientSummary',
        defaultMessage: 'Client Summary'
    });

    // const textWidgetTemplates = intl.formatMessage({
    //     id: 'App.Menu.WidgetTemplates',
    //     defaultMessage: 'Widget Templates'
    // });
    //
    // const textSettings = intl.formatMessage({
    //     id: 'App.Menu.Settings',
    //     defaultMessage: 'Mobile app settings'
    // });

    const textLogout = intl.formatMessage({
        id: 'App.Menu.Users',
        defaultMessage: 'Logout'
    });

    const textHeadingAccountAdmin = intl.formatMessage({
        id: 'App.Menu.AccountAdmin',
        defaultMessage: 'Account admin'
    });

    const list = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={closeMenu}
            onKeyDown={closeMenu}
        >
            <Box p={2}>
                <Box display={"flex"} justifyContent={"center"}>
                    <img alt="Creatable" src={logoUrl} width={"200px"}/>
                </Box>
            </Box>
            <Divider/>
            <List style={{paddingBottom: 0}}>
                {/*<ListHeading text={"Marketplace"}/>*/}
                {/*<ListItemLink component={Link} to={routes.getAdminMarketplaceBrandsUrl()} text={"Brands & categories"}/>*/}
                <ListItemLink component="a" href={routes.getKnowledgeBaseUrl()} text={"Knowledgebase"}/>
                <Divider/>
                <ListHeading text={"Campaigns"}/>
                <ListItemLink component={Link} to={routes.getBrandCampaignAdmin('/draft')} text={"Campaign admin"}/>
                {/*<ListItemLink component={Link} to={routes.getBrandCampaignStatusReport()} text={"Campaign status report"}/>*/}
                <Divider/>
                <ListHeading text={"Finance"}/>
                <ListItemLink component={Link} to={routes.getPaymentApprovalUrl()} text={"Payment item approval"}/>
                <ListItemLink component={Link} to={routes.getPaymentStatusUrl()} text={"Payments"}/>
                <Divider />
                <ListItemLink component={Link} to={routes.getAccountAdminUsersUrl()} text={textHeadingAccountAdmin} />
                <Divider/>
                <ListItemLink component={Link} to={routes.getAdminPlansUrl()} text={'Plans'}/>
                <ListItemLink component={Link} to={routes.getAdminUsersUrl()} text={textUsers}/>
                <ListItemLink component={Link} to={routes.getAdminClientSummaryUrl()} text={textClientSummary}/>
                {/*<ListItemLink component={Link} to={routes.getAdminWidgetTemplatesUrl()} text={textWidgetTemplates}/>*/}
                {/*<ListItemLink component={Link} to={routes.getAdminSettingsUrl()} text={textSettings}/>*/}
                <ListItemLink component={Link} to={routes.getAdminClientsUrl()} text={"Client account settings"}/>
                <ListItemLink component={Link} to={routes.getAdminCommunicationsUrl()} text={"Communications"}/>
                <ListItemLink component={Link} to={routes.getAdminMessagesUrl()} text={"Messages"}/>
                <Divider/>
                <ListHeading text={"Quality"}/>
                <ListItemLink component={Link} to={routes.getCoreUiReportsPageUrl()} text={"Platform operational tests"}/>
                <ListItemLink component={Link} to={routes.getAnalyticsMonitoringPageUrl()} text={"Analytics monitoring"}/>
                <Divider/>
                <ListItemLink component={"a"} href={routes.getLoginUrl()} text={"Switch dashboard"}/>
                <Divider/>
                <ListItemLink component={"a"} href={routes.getUserServiceLogoutUrl()} text={textLogout}/>
                <Divider/>
                <ListItemLink component={"a"} href={routes.getLobbyMetricsPageUrl()} text={"Lobby"}/>
            </List>
            <Divider/>
            <List>
                <Typography gutterBottom align={"center"} component="p">
                    {`v${process.env.REACT_APP_VERSION}${environment.getName().charAt(0)}`}
                </Typography>
            </List>
        </div>
    );

    const [isMenuOpen, setMenuOpen] = React.useState(false);
    const openMenu = () => {
        setMenuOpen(true);
    }
    const closeMenu = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setMenuOpen(false);
    }
    return (
        <div>
            <IconButton onClick={openMenu} edge="start" color="inherit" aria-label="menu">
                <MenuIcon/>
            </IconButton>
            <SwipeableDrawer anchor={"left"} open={isMenuOpen} onClose={closeMenu} onOpen={openMenu}>
                {list("left")}
            </SwipeableDrawer>
        </div>
    );
}

const ListItemLink = ({text, ...props}) => {
    return (
        <ListItem button {...props}>
            <ListItemText primary={text}/>
        </ListItem>
    );
}
export default AppMenu;
