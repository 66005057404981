import {createSlice} from '@reduxjs/toolkit';
import api from "../helpers/api";

const initialState = {
    items: [],
    total: 0,
    isLoading: true,
    filters:{
        page: 1,
        per_page: 100
    }
}

const manualSocialDataSlice = createSlice({
    name: 'manualSocialData',
    initialState,
    reducers: {
        successRequest: (state, {payload}) => {
            state.items = payload.items;
            state.total = payload.total;
            state.isLoading = false;
        },
        failedRequest: (state) => {
            state.isLoading = false;
        }
    },
})
export const {successRequest, failedRequest} = manualSocialDataSlice.actions;
export const manualSocialDataSelector = state => state.manualSocialData;
export default manualSocialDataSlice.reducer

export const getManualSocialData = (params) => dispatch => {

    api.social.getManualSocialDataList(params)(
        response =>{
            dispatch(successRequest(response.data))
        },
        error => {
            dispatch(failedRequest())
        }
    )

}