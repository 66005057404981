import {IconButton, InputBase} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import SearchIcon from '@material-ui/icons/Search';
import {isDefined, isEmpty} from "../helpers/utils";

const SearchInput = ({enableClear, onClear, onChange, value, ...props}) => {
    const [internalValue, setInternalValue] = useState("");

    useEffect(() => {
        if (isDefined(value)) {
            setInternalValue(value);
        }
    }, [value]);

    const onChangeInternal = (event) => {
        setInternalValue(event.target.value);
        onChange(event);
    }

    const onClearInternal = () => {
        setInternalValue("");
        onClear("");
    }

    return (
        <Box display={"flex"} alignItems={"center"} flexGrow={1}>
            <Box display={"flex"} alignItems={"center"} pr={1}>
                <SearchIcon/>
            </Box>
            <Box display={"flex"} flexGrow={1} alignItems={"center"} py={enableClear && isEmpty(internalValue) ? 1 : 0}>
                <InputBase placeholder="Search" fullWidth value={internalValue} onChange={onChangeInternal} {...props}/>
            </Box>
            {
                enableClear && !isEmpty(internalValue) &&
                <IconButton onClick={onClearInternal}>
                    <CloseIcon/>
                </IconButton>
            }
        </Box>
    )
}

export default SearchInput;
