import React, {useCallback, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from "@material-ui/icons/Add"
import {
    AppBar, Box, Container, Divider, Fab, IconButton,
    LinearProgress,
    Paper,
    Toolbar, Typography,
} from '@material-ui/core';

import {useDispatch, useSelector} from "react-redux";
import {CloseOutlined} from "@material-ui/icons";
import CampaignCreatorManualSocialDataTable
    from "../CampaignCreatorManualSocialData/CampaignCreatorManualSocialDataTable";
import {getManualSocialData, manualSocialDataSelector} from "../../../slices/manualSocialData";
import CampaignCreatorPostDialog from "../CampaignCreatorPost/CampaignCreatorPostDialog";

const CampaignCreatorSocialDataDialog = ({open, gigToRender, onClose}) => {

    const {filters, isLoading, items} = useSelector(manualSocialDataSelector);
    const [isPostDialogOpen, setIsPostDialogOpen] = useState(false);
    const dispatch = useDispatch();

    const reloadManualSocialData = useCallback(() => {
        const params = `client_id=${gigToRender.campaign.brand.id}&user_id=${gigToRender.user.id}&page=${filters.page}&per_page=${filters.per_page}&campaign=${gigToRender.campaign.id}`;
        dispatch(getManualSocialData(params));
    }, [gigToRender, filters, dispatch]);

    useEffect(()=>{
        reloadManualSocialData();
    },[reloadManualSocialData]);

    const handleClose = () => {
        onClose();
    }

    const onSavePostSuccess = () => {
        setIsPostDialogOpen(false);
        reloadManualSocialData();
    }

    return (
        <Dialog open={open} fullScreen onClose={handleClose}>
            <AppBar position={"relative"}>
                <Toolbar>
                    <IconButton size={'small'} onClick={handleClose}><CloseOutlined htmlColor={"#fff"} /></IconButton> <Typography>Campaign: {gigToRender.campaign.brand.name} - {gigToRender.campaign.title}</Typography>
                </Toolbar>
            </AppBar>
            <Box bgcolor={"#f5f5f5"} height={"100%"} p={2}>
                <Container maxWidth={'lg'}>
                    <Paper>
                        <Toolbar>
                            <Typography>{`${gigToRender.user.firstName} ${gigToRender.user.lastName}`}</Typography>
                        </Toolbar>
                        <Divider/>
                        <CampaignCreatorManualSocialDataTable items={items} gigToRender={gigToRender} />
                        {isLoading && <LinearProgress/>}
                    </Paper>
                </Container>
            </Box>
            <Fab
                color="primary"
                aria-label="add"
                onClick={()=>{
                    setIsPostDialogOpen(true);
                }}
                style={{
                    right: '24px',
                    bottom: '24px',
                    position: 'absolute'
                }}
            >
                <AddIcon/>
            </Fab>
            <CampaignCreatorPostDialog isOpen={isPostDialogOpen} onClose={() => setIsPostDialogOpen(false)} onSaveSuccess={onSavePostSuccess} gig={gigToRender}/>
        </Dialog>
    );
}

export default CampaignCreatorSocialDataDialog;


