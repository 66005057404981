import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableContainer} from "@material-ui/core";
import CampaignAdminManagerTableHead from "./CampaignAdminManagerTableHead";
import CampaignAdminManagerTableRow from "./CampaignAdminManagerTableRow";
import CampaignAdminAddressConfirmationDialog from "../CampaignAdminDialogs/CampaignAdminAddressConfirmationDialog";
import CampaignAdminTrackingInformationDialog from "../CampaignAdminDialogs/CampaignAdminTrackingInformationDialog";
import CampaignAdminConfirmDelivery from "../CampaignAdminDialogs/CampaignAdminConfirmDelivery";
import CampaignCreatorSocialDataDialog from "../CampaignAdminDialogs/CampaignCreatorSocialDataDialog";
import {useHistory, useParams} from "react-router-dom";
import api from "../../../helpers/api";
import routes from "../../../helpers/routes";

const CampaignAdminManagerTable = ({items, onRefresh}) => {
    const [openAddress, setOpenAddress] = useState(false);
    const [openTracking, setOpenTracking] = useState(false);
    const [openDelivery, setOpenDelivery] = useState(false);
    const [openManualSocialData, setOpenManualSocialData] = useState(false);
    const [selected, setSelected] = useState(null);

    const {gigId, tab} = useParams();
    const [initialized, setInitialized] = useState(false);
    const history = useHistory();

    useEffect(()=>{
        if(!initialized){
            setInitialized(true);
            if(gigId && !selected){
                api.GigApi.get(gigId)(
                    response =>{
                        setSelected(response.data);
                        setOpenManualSocialData(true);
                    },
                    error =>{

                    }
                )
            }
        }
    },[initialized, gigId, selected])

    const closeAddress = () =>{
        setOpenAddress(false);
    }
    const closeTracking = () =>{
        setOpenTracking(false);
    }
    const onOpenAddress = item => {
        setSelected(item);
        setOpenAddress(true);
    }
    const onOpenTracking = item => {
        setSelected(item);
        setOpenTracking(true);
    }

    const onOpenDelivery = item => {
        setSelected(item);
        setOpenDelivery(true);
    }
    const onOpenManualSocialData = item => {
        history.push({
            pathname:routes.getBrandCampaignAdmin(`/${tab}/campaign/${item.campaign.id}/social-data/${item.id}`),
            search: window.location.search
        })
        setSelected(item);
        setOpenManualSocialData(true);
    }
    const closeManualSocialData = () =>{
        history.push({
            pathname: routes.getBrandCampaignAdmin(`/${tab}/campaign/${selected.campaign.id}`),
            search: window.location.search
        });
        setOpenManualSocialData(false);
    }

    return <div>
        <TableContainer>
        <Table>
            <CampaignAdminManagerTableHead />
            <TableBody>
                {
                    items.map(item=>{
                        return <CampaignAdminManagerTableRow key={item.id} onOpenManualSocialData={onOpenManualSocialData} gig={item} onOpenAddress={onOpenAddress} onOpenTracking={onOpenTracking} onOpenDelivery={onOpenDelivery} onRefresh={onRefresh}/>
                    })
                }
            </TableBody>
        </Table>
    </TableContainer>
        {openAddress && <CampaignAdminAddressConfirmationDialog gigToRender={selected} open={openAddress} onClose={closeAddress}/> }
        {openTracking && <CampaignAdminTrackingInformationDialog gigToRender={selected} open={openTracking} onClose={closeTracking} /> }
        {openDelivery && <CampaignAdminConfirmDelivery gigToRender={selected} open={openDelivery} onClose={()=>setOpenDelivery(false)} />}
        {openManualSocialData && <CampaignCreatorSocialDataDialog gigToRender={selected} open={openManualSocialData} onClose={closeManualSocialData} />}
    </div>
}

CampaignAdminManagerTable.defaultProps = {
    items: []
}

export default CampaignAdminManagerTable;
