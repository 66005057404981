import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
// import MonitoringGrid from "../components/MonitoringGrid/MonitoringGrid";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },
    paper: {
        height: '100%'
    }
}));

const Monitoring = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Paper className={classes.paper}>
                        <Box ml={2} mr={2}>
                            {/*<MonitoringGrid/>*/}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Monitoring;