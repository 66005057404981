import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import {Box, Fab, LinearProgress, Paper} from "@material-ui/core";
import CategoriesTableHead from "./CategoriesTableHead";
import CategoriesTableToolbar from "./CategoriesTableToolbar";
import {setNotification} from "../../../slices/notification";
import {useDispatch} from "react-redux";
import CategoriesTableRowMenu from "./CategoriesTableRowMenu";
import CategoryDetailsModal from "./CategoryDetailsModal";
import Api from "../../../helpers/api";
import RequestError from "../../../helpers/RequestError";
import {SpeedDialIcon} from "@material-ui/lab";
import DeleteCategoryModal from "./DeleteCategoryModal";
import DroppableTableBody from "../../DroppableTable/DroppableTableBody";
import DraggableTableRow from "../../DroppableTable/DraggableTableRow";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {useDebounce} from "use-debounce";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    emptyTable: {
        height: '424px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
        '& td': {
            borderBottom: 0,
        }
    },
    moveButton: {
        paddingTop: theme.spacing(0.5),
        "& svg": {
            color: theme.palette.grey[700],
            cursor: "move",
        }
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
}));

const INITIAL_ITEMS_PER_PAGE = 10;

const AddCategoryButton = ({onClick}) => {
    const classes = useStyles();
    return (
        <Fab className={classes.fab} color={'primary'} onClick={onClick}>
            <SpeedDialIcon/>
        </Fab>
    )
}

const CategoriesTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [inProgress, setInProgress] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(INITIAL_ITEMS_PER_PAGE);
    const [page, setPage] = useState(1);

    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

    const [categories, setCategories] = useState([]);
    const [totalCategories, setTotalCategories] = useState(0);

    const [currentCategory, setCurrentCategory] = useState(null);
    const [isCategoryDetailsModalOpen, setIsCategoryDetailsModalOpen] = useState(false);
    const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(false);

    const [isCategoryListInitialized, setIsCategoryListInitialized] = useState(null);

    const showNotificationFromErrorResponse = useCallback((errorResponse) => {
        dispatch(setNotification((new RequestError(errorResponse)).getMessage(), "error"));
    }, [dispatch]);

    const refreshCategories = useCallback((page, itemsPerPage, search) => {
        setInProgress(true);
        return Api.getMarketplaceCategories(page, itemsPerPage, search)(
            ({data}) => {
                setTotalCategories(data["hydra:totalItems"]);
                setCategories(data["hydra:member"]);
            },
            showNotificationFromErrorResponse
        ).then(() => {
            setInProgress(false);
        });
    }, [showNotificationFromErrorResponse]);

    useEffect(() => {
        if (isCategoryListInitialized === null) {
            setIsCategoryListInitialized(false);
            refreshCategories(page, itemsPerPage).then(() => {
                setIsCategoryListInitialized(true);
            });
        }
    }, [isCategoryListInitialized, refreshCategories, page, itemsPerPage]);

    useEffect(() => {
        setPage(1);
        refreshCategories(1, itemsPerPage, debouncedSearchTerm);
    }, [itemsPerPage, debouncedSearchTerm, refreshCategories]);

    const handleChangePage = (event, newPage) => {
        if (newPage >= 0) {
            setPage(newPage + 1);
            refreshCategories(newPage + 1, itemsPerPage);
        }
    }

    const handleChangeRowsPerPage = (event) => {
        setItemsPerPage(event.target.value);
    }

    const onClickEditCategory = (category) => {
        setCurrentCategory(category);
        setIsCategoryDetailsModalOpen(true);
    }

    const onSaveCategorySuccess = () => {
        setIsCategoryDetailsModalOpen(false);
        setCurrentCategory(null);
        setPage(1);
        setIsCategoryListInitialized(null);
    }

    const onShowDeleteCategoryModal = (category) => {
        setCurrentCategory(category);
        setIsDeleteCategoryModalOpen(true);
    }

    const onDeleteCategorySuccess = () => {
        setIsDeleteCategoryModalOpen(false);
        setCurrentCategory(null);
        setIsCategoryListInitialized(null);
    }

    const onClickNewCategory = () => {
        setCurrentCategory(null);
        setIsCategoryDetailsModalOpen(true);
    }

    const onCloseCategoryDetailsModal = () => {
        setCurrentCategory(null);
        setIsCategoryDetailsModalOpen(false);
    }

    const onCloseDeleteCategoryModal = () => {
        setCurrentCategory(null);
        setIsDeleteCategoryModalOpen(false);
    }

    const onDragEnd = ({source, destination}) => {
        if (source.index !== destination.index) {
            const newCategoriesOrder = moveIndex(categories, source.index, destination.index);
            setCategories(newCategoriesOrder);
            setInProgress(true);
            Api.setMarketplaceCategoriesOrder()(
                () => setIsCategoryListInitialized(null),
                showNotificationFromErrorResponse
            )({marketplace_categories_order: newCategoriesOrder.map(b => b.marketplace_category_id)}).then(() => setInProgress(false));
        }
    }

    return (
        <Box>
            <Paper>
                <CategoriesTableToolbar onChangeSearchTerm={setSearchTerm}/>
                <TableContainer>
                    <Table size={'medium'} className={classes.table}>
                        <CategoriesTableHead/>
                        <TableBody component={DroppableTableBody(onDragEnd)}>
                            {
                                categories.map((category, currentPosition) => {
                                    return (
                                        <TableRow key={category.marketplace_category_id}
                                                  component={DraggableTableRow(category.marketplace_category_id.toString(), currentPosition)}>
                                            <TableCell align="left">
                                                {category.name}
                                            </TableCell>
                                            <TableCell align="center">
                                                {category.marketplace_brands.length}
                                            </TableCell>
                                            <TableCell align="center" padding={"checkbox"}>
                                                <Box className={classes.moveButton}>
                                                    <DragIndicatorIcon/>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="center" padding={"checkbox"}>
                                                <CategoriesTableRowMenu
                                                    onClickEdit={() => onClickEditCategory(category)}
                                                    onClickDelete={() => onShowDeleteCategoryModal(category)}/>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            {
                                categories.length >= 1 && (8 - categories.length) > 0 && (
                                    <TableRow style={{height: (53) * (8 - categories.length)}}>
                                        <TableCell colSpan={5} style={{border: "none"}}/>
                                    </TableRow>
                                )
                            }
                            {
                                categories.length === 0 && (
                                    <TableRow className={classes.emptyTable}>
                                        <TableCell colSpan={5}>{inProgress ? "" : "No categories found."}</TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={totalCategories}
                    rowsPerPage={itemsPerPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    backIconButtonProps={{disabled: inProgress || totalCategories === 0 || page === 1}}
                    nextIconButtonProps={{disabled: inProgress || totalCategories === 0 || page >= Math.ceil(totalCategories / itemsPerPage)}}
                />
                {inProgress && <LinearProgress/>}
                <CategoryDetailsModal onSaveSuccess={onSaveCategorySuccess}
                                      isOpen={isCategoryDetailsModalOpen}
                                      category={currentCategory}
                                      onClose={onCloseCategoryDetailsModal}/>
                <DeleteCategoryModal onDeleteSuccess={onDeleteCategorySuccess} isOpen={isDeleteCategoryModalOpen}
                                     onClose={onCloseDeleteCategoryModal} category={currentCategory}/>
            </Paper>
            <AddCategoryButton className={classes.fab} onClick={onClickNewCategory}/>
        </Box>
    );
}

const moveIndex = (list, sourceIndex, destinationIndex) => {
    const orderedList = [...list];
    const [removed] = orderedList.splice(sourceIndex, 1);
    orderedList.splice(destinationIndex, 0, removed)
    return orderedList;
}

export default CategoriesTable;