import {Avatar, Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {useSelector} from "react-redux";
import {messagesSelector} from "../../slices/messages";
import ChatTimeAgo from "./ChatTimeAgo";
import MessagesHelper from "../../helpers/MessagesHelper";
import ChatAvatar from "./ChatAvatar";

const UserChatMessage = ({message}) => {
    const {currentChat} = useSelector(messagesSelector);
    const peer = currentChat.peers.find(p => p.peer_id === message.author);
    return (
        <Box display={"flex"} flexDirection={"row"} mt={2} className={"UserChatMessage"} data-date={MessagesHelper.getTimeIndicatorLabel(message.time)} data-message={message.text}>
            <Box>
                {peer && peer.profile_picture_url ? (
                    <Avatar
                        src={peer.profile_picture_url}
                        style={{ width: 32, height: 32 }}
                    />
                ) : (
                    <ChatAvatar chatDetails={peer} size={32} />
                )}
            </Box>
            <Box flex={1} pl={1} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                <Typography variant="body2" style={{fontWeight: "bold"}}>
                    {peer?.full_name}
                </Typography>
                <Typography variant="body2" style={{fontSize: 12}}>
                    <ChatTimeAgo date={message.time} live/>
                </Typography>
                <Typography variant="body2">
                    {message.text}
                </Typography>
            </Box>
        </Box>
    );
}

export default UserChatMessage;