import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
}));

const AddRoleButton = ({onClick}) => {
    const classes = useStyles();

    return (
        <Fab className={classes.fab} color={'primary'} onClick={onClick}>
            <SpeedDialIcon/>
        </Fab>
    )
}

export default AddRoleButton;