import {createSlice} from '@reduxjs/toolkit';
import RequestError from '../helpers/RequestError';
import ResponseStatus from '../helpers/ResponseStatus';

import Api from "../helpers/api";
import {updateBrowserWithUrlParameters} from "../helpers/pageParameters";

export const initialState = {
    draft:{
        per_page: 10,
        page: 0,
        items: [],
        total: 0,
        status: "Draft",
    },
    open:{
        per_page: 10,
        page: 0,
        items: [],
        total: 0,
        status: "Open",
    },
    ended:{
        per_page: 10,
        page: 0,
        items: [],
        total: 0,
        status: "Closed",
    },
    completed:{
        per_page: 10,
        page: 0,
        items: [],
        total: 0,
        status: "Completed",
    },
    campaignManager:{
        per_page: 10,
        page: 0,
        items: [],
        total: 0,
        smartFilter: ""
    },
    campaignSeeding:{
        per_page: 10,
        page: 0,
        items: [],
        total: 0,
        smartFilter: ""
    },
    campaignShipments:{
        per_page: 10,
        page: 0,
        items: [],
        total: 0,
        smartFilter: ""
    },
    row: null,
    ChangeCampaignDates: false,
    RemoveCreditBlock: false,
    RemoveProductSeedingBlock: false,
    CampaignAdminShipments: false,
    CampaignAdminManager: false,
    ProductSeedingAmount: false,
    CampaignAdminSeeding: false,
    CampaignAdminDetails: false,
    CampaignAdminDelete: false,
    "smartFilter": "string",
    "filterByBrandId": 0,
    "filterByCategoryId": 0,
    "filterByStatus": "Draft",
    "filterBySeeding": true,
    "filterByCreditBlock": true,
    "resultParameter": {
        "resultsOrderBy": "string",
        "resultsOrderAscending": true,
        "resultsLimitOffset": 0,
        "resultsLimitCount": 0
    },
    isCampaignAdminLoading: false,
    isCampaignAdminError: false,
    isCampaignAdminLoaded: false,
    getCampaignAdminErrorMessage: "",
};

const campaignAdminSlice = createSlice({
    name: 'campaignAdmin',
    initialState,
    reducers: {
        onGetCampaignAdminStart: (state) => {
            state.isCampaignAdminLoading = true;
        },
        onGetCampaignAdminDone: (state, {payload}) => {
            state.isCampaignAdminError = payload.isError;
            state.isCampaignAdminLoading = false;
            state.isCampaignAdminLoaded = true;
            if (state.isCampaignAdminError) {
                state.getCampaignAdminErrorMessage = payload.errorMessage;
            } else {
                state[payload.section].items = payload.data;
                state[payload.section].total = payload.total;
                state.getCampaignAdminErrorMessage = '';
            }
        },
        updateCampaignSection: (state, {payload}) => {
            const {section, ...newValues} = payload;
            const sectionParams = {...state[payload.section], ...newValues}
            state[payload.section] = sectionParams;
            const {page, per_page, smartFilter, clientId, clientLabel} = sectionParams;
            updateBrowserWithUrlParameters({page, per_page, smartFilter, clientId, clientLabel});
        },
        updateOpenDialog: (state, {payload})=>{
            state[payload.dialog] = payload.value;
            state.row = payload.row;
        }
    },
});

export const campaignAdminSelector = state => state.campaignAdmin;
export default campaignAdminSlice.reducer;

export const {onGetCampaignAdminStart, onGetCampaignAdminDone, updateCampaignSection, updateOpenDialog} = campaignAdminSlice.actions;

export const getCampaignAdminList = ({status, page, per_page, clientId, categoryId, filterBySeeding, filterByCreditBlock, smartFilter}) => dispatch => {
    const params = {};
    params.filterByStatus = status;
    params.filterByBrandId = clientId;
    params.filterBySeeding = filterBySeeding;
    params.filterByCategoryId = categoryId;
    params.filterByCreditBlock = filterByCreditBlock;
    params.smartFilter = smartFilter;
    params.resultParameter = {
        resultsLimitOffset: page * per_page,
        resultsLimitCount: per_page,
        "resultsOrderBy": "createdate",
        "resultsOrderAscending": false,
    };
    dispatch(onGetCampaignAdminStart());

    Api.CampaignApi.search(params)(
        response => {
            const respStatus = new ResponseStatus(response);
            dispatch(onGetCampaignAdminDone({
                isError: respStatus.isError(),
                errorMessage: respStatus.getErrorMessage(),
                data: response.data.campaigns,
                total: response.data.totalItemCount,
                section: status === 'Closed' ? 'ended' : status.toLowerCase()
            }));
        },
        error => {
            const reqError = new RequestError(error);
            dispatch(onGetCampaignAdminDone({
                isError: true,
                errorMessage: reqError.getMessage()
            }));
        }
)(params);


}

export const getCampaignAdminManagerList = ({campaignId, page, per_page, smartFilter}) => dispatch => {
    const params = {};
    params.campaignId = campaignId;
    params.smartFilter = smartFilter;
    params.resultParameter = {
        resultsLimitOffset: page * per_page,
        resultsLimitCount: per_page,
        resultsOrderAscending: true
    };
    dispatch(onGetCampaignAdminStart());

    Api.GigApi.searchViaCampaignPortal(params) (
        response => {
            const respStatus = new ResponseStatus(response);
            dispatch(onGetCampaignAdminDone({
                isError: respStatus.isError(),
                errorMessage: respStatus.getErrorMessage(),
                data: response.data.gigs,
                total: response.data.totalItemCount,
                section: "campaignManager"
            }));
        },
        error => {
            const reqError = new RequestError(error);
            dispatch(onGetCampaignAdminDone({
                isError: true,
                errorMessage: reqError.getMessage()
            }));
        }
    )(params);

}

export const getCampaignAdminSeedingList = ({campaignId, page, per_page, smartFilter}) => dispatch => {
    const params = {};
    params.campaignId = campaignId;
    params.smartFilter = smartFilter;
    params.resultParameter = {
        resultsLimitOffset: page * per_page,
        resultsLimitCount: per_page,
        resultsOrderAscending: true
    };
    dispatch(onGetCampaignAdminStart());

    Api.CampaignApi.inventorySearch() (
        response => {
            const respStatus = new ResponseStatus(response);
            dispatch(onGetCampaignAdminDone({
                isError: respStatus.isError(),
                errorMessage: respStatus.getErrorMessage(),
                data: response.data.inventories,
                total: response.data.totalItemCount,
                section: "campaignSeeding"
            }));
        },
        error => {
            const reqError = new RequestError(error);
            dispatch(onGetCampaignAdminDone({
                isError: true,
                errorMessage: reqError.getMessage()
            }));
        }
    )(params);


}

export const getCampaignAdminShipmentsList = ({campaignId, page, per_page, smartFilter}) => dispatch => {
    const params = {};
    params.campaignId = campaignId;
    params.smartFilter = smartFilter;
    params.resultParameter = {
        resultsLimitOffset: page * per_page,
        resultsLimitCount: per_page,
        resultsOrderAscending: true
    };
    dispatch(onGetCampaignAdminStart());

    Api.CampaignApi.searchShippingManifest()(
        response => {
            const respStatus = new ResponseStatus(response);
            dispatch(onGetCampaignAdminDone({
                isError: respStatus.isError(),
                errorMessage: respStatus.getErrorMessage(),
                data: response.data.gigs,
                total: response.data.totalItemCount,
                section: "campaignShipments"
            }));
        },
        error => {
            const reqError = new RequestError(error);
            dispatch(onGetCampaignAdminDone({
                isError: true,
                errorMessage: reqError.getMessage()
            }));
        }
    )(params);


}

export const updateSection =  (section, newValues) => dispatch =>  dispatch(updateCampaignSection({...newValues, section}))

export const reloadSection = (section) => dispatch => {
    dispatch(updateSection(section.status.toLowerCase(), {page:0}));
    dispatch(getCampaignAdminList({...section, page: 0}));
}
