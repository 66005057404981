import React from 'react';
import {makeStyles, TableCell, TableHead, TableRow} from "@material-ui/core";
const useStyles =  makeStyles({
    noWrapText:{
        whiteSpace: 'nowrap'
    }
})
const CampaignCreatorManualSocialDataTableHead = () =>{
    const classes = useStyles();
    return <TableHead>
        <TableRow>
            <TableCell className={classes.noWrapText}>
                Social platform
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Social post link
            </TableCell>
            <TableCell>
                Boosted
            </TableCell>
            <TableCell>
                Impressions
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Video views
            </TableCell>
            <TableCell>
                Likes
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Shares
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Comments
            </TableCell>
            <TableCell>
                Saves
            </TableCell>
            <TableCell padding={'checkbox'}>

            </TableCell>
        </TableRow>
    </TableHead>
}
export default CampaignCreatorManualSocialDataTableHead;