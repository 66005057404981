import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Box, LinearProgress, Paper} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import {SpeedDialIcon} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import CreateChatModal from "../../components/Messages/CreateChatModal";
import ChatsTable from "../../components/Messages/ChatsTable";
import SearchInput from "../../components/SearchInput";
import Toolbar from "@material-ui/core/Toolbar";
import {useDebounce} from "use-debounce";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import {
    fetchSupportChatList,
    messagesSelector,
    prepareCurrentChatData,
} from "../../slices/messages";
import ChatModal from "../../components/Chat/ChatModal";
import {withUser} from "../../providers/UserProvider";
import {useTheme} from "@material-ui/styles";

const Messages = ({userId}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const {
        isChatWebSocketConnectionOpen,
        isSupportChatListInitialized,
        isChatMessagesInProgress
    } = useSelector(messagesSelector);

    const [isCreateChatModalOpen, setIsCreateChatModalOpen] = useState(false);
    const [isChatModalOpen, setIsChatModalOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const [isFilterUnreadActive, setIsFilterUnreadActive] = useState(false);
    const [sort, setSort] = useState("desc");
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    
    useEffect(() => {
        if (isChatWebSocketConnectionOpen === true && isSupportChatListInitialized == null) {
            dispatch(fetchSupportChatList("", null, page, itemsPerPage));
        }
    }, [isChatWebSocketConnectionOpen, isSupportChatListInitialized, dispatch, page, itemsPerPage]);

    useEffect(() => {
        dispatch(fetchSupportChatList(debouncedSearchTerm, isFilterUnreadActive, page, itemsPerPage));
    }, [debouncedSearchTerm, dispatch, isFilterUnreadActive, page, itemsPerPage]);

    const onClickChat = (chat) => {
        dispatch(prepareCurrentChatData(chat, userId));
        setIsChatModalOpen(true);
    }

    const onClickCreateChat = () => {
        setIsCreateChatModalOpen(true);
    }

    const onCreateSupportChatSuccess = (chatId) => {
        dispatch(fetchSupportChatList()).then((chats) => {
            setIsCreateChatModalOpen(false);
            const chat = chats.find((c) => c.chat_id === chatId);
            onClickChat(chat);
        });
    }

    const handlePageChange = (newPage) => {
        console.log("Page changed to:", newPage);
        setPage(newPage);
    };

    const handleItemsPerPageChange = (newItemsPerPage) => {
        console.log("Items per page changed to:", newItemsPerPage);
        setItemsPerPage(newItemsPerPage);
        setPage(1);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar disableGutters style={{minHeight: "auto", paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                            <Box flexGrow={1} pr={2} pl={2}>
                                <SearchInput onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm}/>
                            </Box>
                            <Box pr={1}>
                                <Typography variant={"body2"} component={"div"}>
                                    <Grid component={"label"} container alignItems={"center"} spacing={1}>
                                        <Grid item>Show only unread</Grid>
                                        <Grid item>
                                            <Switch checked={isFilterUnreadActive}
                                                    onChange={(e, isChecked) => setIsFilterUnreadActive(isChecked)}/>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Box>
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <ChatsTable 
                            onClickChat={onClickChat} 
                            sort={sort} 
                            onSort={setSort} 
                            onPageChange={handlePageChange}
                            onItemsPerPageChange={handleItemsPerPageChange}/>
                        {isChatMessagesInProgress && <LinearProgress/>}
                    </Paper>
                    <Box height={80}>
                        <Fab className={classes.fab} color={'primary'}
                             onClick={onClickCreateChat}>
                            <SpeedDialIcon/>
                        </Fab>
                    </Box>
                </Grid>
            </Grid>
            {
                isCreateChatModalOpen &&
                <CreateChatModal isOpen={true}
                                 onClose={() => setIsCreateChatModalOpen(false)}
                                 onSaveSuccess={onCreateSupportChatSuccess}/>
            }
            {isChatModalOpen && <ChatModal isOpen={true} onClose={() => setIsChatModalOpen(false)}/>}
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
}));

export default withUser(Messages);