import React, {useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";
import moment from "moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const PaymentStatusEditPaymentDateDialog = ({isOpen, onClose, onSaveSuccess, singleItem}) => {
    const dispatch = useDispatch();

    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState(false)
    const [paymentDate, setPaymentDate] = useState(singleItem.execution_time);

    const updateDate = () => {
        if(!moment(paymentDate).isValid()){
            setError(true);
            return;
        }
        const lastExecution = singleItem.executions?.length > 0 ? singleItem.executions[singleItem.executions?.length - 1] : null;
        const execution_id = lastExecution ? lastExecution.execution_id : null;
        const description = lastExecution ? lastExecution.description : "";
        if(!execution_id){
            return;
        }
        if(error){
            dispatch(setNotification('Make sure to you are using the correct date format', 'error'))
            return;
        }
        setInProgress(true);
        api.payment.updatePaymentRefCode(singleItem.order_id, execution_id)
        (response=>{
                setInProgress(false);
                onSaveSuccess();
            },
            error => {
                setInProgress(false);
                dispatch(setNotification('Request failed', 'error'))
            })
        ({
            "execution_time": paymentDate.toISOString(),
            "payout_reference": singleItem.payout_reference,
            "description": description
        });
    }

    const handleLocalClose = () =>{
        onClose();
    }
    const onChangeDate = value =>{
        setPaymentDate(value);
        if(value){
            setError(false);
        }else{
            setError(true);
        }
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Edit payment date</DialogTitle>
                <DialogContent>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        margin="normal"
                        value={moment(paymentDate)}
                        size="small"
                        format={"MM/DD/yyyy"}
                        label="Payment date"
                        onChange={onChangeDate}
                        inputVariant="outlined"/>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="contained" onClick={handleLocalClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="contained" fullWidth
                                    onClick={updateDate}
                                    color={'primary'}
                                    disabled={inProgress}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default PaymentStatusEditPaymentDateDialog;