import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    spinnerContainer: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
        textAlign: 'center',
    },
    spinner: {
        color: '#ccc',
    },
}));

const LoadingApp = () => {
    const classes = useStyles();
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.root}>
            <div className={classes.spinnerContainer}>
                <CircularProgress className={classes.spinner}/>
            </div>
        </Grid>
    );
};

export default LoadingApp;