import React, {useEffect} from "react";
import {Box} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {setPageTitle} from "../slices/page";
import {useTheme} from "@material-ui/core/styles";
import PlansWrapper from "../components/Plans/PlansWrapper";

const Plans = () =>{
    const dispatch = useDispatch();
    const theme = useTheme();
    useEffect(() => {
        dispatch(setPageTitle("Plans"));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);

    return <Box bgcolor={theme.palette.grey[100]} height={'100%'} p={2}>
        <PlansWrapper />
    </Box>
}
export default Plans;