import React from "react";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const NoPlans = () =>{
    return <TableRow>
        <TableCell colSpan={7}>
            <Box data-testId={'no-plans'}>
                <Box py={8} justifyContent={'center'} display={'flex'}>
                    <Typography>There are no plans to display.</Typography>
                </Box>
            </Box>
        </TableCell>
    </TableRow>

}
export default NoPlans;