import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    LinearProgress,
    makeStyles, MenuItem,
    TextField,
} from '@material-ui/core';

import {useDispatch, useSelector} from "react-redux";
import Client from "../../../helpers/api";

import {setNotification} from "../../../slices/notification";
import CampaignInventorySeedingStatusEnum
    from "../../../helpers/CampaignInventorySeedingStatusEnum";
import {
    campaignAdminSelector,
    getCampaignAdminSeedingList
} from "../../../slices/campaignAdmin";



const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(3)
    }

}))


const UpdateStatusDialog = ({open, item, onClose}) => {
    const {row, campaignSeeding} = useSelector(campaignAdminSelector);
    const [isSaving, setIsSaving] = useState(false);
    const [newStatus, setNewStatus] = useState();
    const [comment, setComment] = useState("");
    const classes = useStyles();
    const dispatch = useDispatch();
    const onConfirmEvent = () => {
        if(!newStatus){
            dispatch(setNotification("Select a status","error"));
            return;
        }
        setIsSaving(true);

        Client.CampaignApi.get(row.id)(
            campaign=>{
                const {campaignProducts} = campaign.data;
                const campaignProductToEdit = campaignProducts.find(product => product.productEntityIdentifier === item.product.identifier);
                const inventoryToEdit = campaignProductToEdit.inventories.find(inventory => inventory.id === item.id);

                inventoryToEdit.seedingStatus = newStatus;
                inventoryToEdit.exceptionDetails = comment;
                Client.CampaignApi.productEdit()(
                        response => {
                            dispatch(getCampaignAdminSeedingList({...campaignSeeding, campaignId: row.id}));
                            setIsSaving(false);
                            onClose();
                        },
                        err=>{
                            setIsSaving(false);
                            dispatch(setNotification("Something went wrong", "error"));
                        }

                )(campaignProductToEdit)

            },
            ()=>{
                setIsSaving(false);
                dispatch(setNotification("Something went wrong", "error"));
            }
        )

    }
    const handleClose = () => {
        onClose();
    }
    const onChangeStatus = value => {
        setNewStatus(value);
    }
    return <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle >Update status</DialogTitle>
            <DialogContent style={{minWidth: 400}}>
                <TextField
                    id="seeding"
                    label="Set seeding status"
                    placeholder={"Select a status"}
                    fullWidth
                    required
                    size={"small"}
                    margin={"normal"}
                    variant="outlined"
                    value={newStatus}
                    select
                    onChange={(e) => onChangeStatus(e.target.value)} >
                    <MenuItem value={CampaignInventorySeedingStatusEnum.WAITING_TO_RECEIVE}>
                        Waiting to receive
                    </MenuItem>
                    <MenuItem value={CampaignInventorySeedingStatusEnum.RECEIVED}>
                        Received
                    </MenuItem>
                    <MenuItem value={CampaignInventorySeedingStatusEnum.READY_FOR_SHIPMENT}>
                        Ready for shipment
                    </MenuItem>
                    <MenuItem value={CampaignInventorySeedingStatusEnum.ISSUE}>
                        Issue
                    </MenuItem>
                </TextField>

                <TextField
                        id="note"
                        label="Note - optional"
                        placeholder={"Enter note about the status..."}
                        fullWidth
                        variant="outlined"
                        size={"small"}
                        margin={"normal"}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}/>

            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button autoFocus onClick={handleClose} variant="contained">

                    Cancel

                </Button>
                <Button onClick={onConfirmEvent} style={{width: "98px"}} color="primary"
                        autoFocus variant="contained">
                    Update
                </Button>
            </DialogActions>
            {isSaving && <LinearProgress/>}
        </Dialog>
    </div>;
}

export default UpdateStatusDialog;


