import React, {useState} from 'react';
import {Table, TableBody, TableContainer} from "@material-ui/core";
import CampaignAdminSeedingTableHead from "./CampaignAdminSeedingTableHead";
import CampaignAdminSeedingTableRow from "./CampaignAdminSeedingTableRow";
import UpdateStatusDialog from "./UpdateStatusDialog";
import UpdateQuantityDialog from "./UpdateQuantityDialog";

const CampaignAdminSeedingTable = ({items}) => {
    const [openStatus, setOpenStatus] = useState(false);
    const [openQuantity, setOpenQuantity] = useState(false);
    const [selected, setSelected] = useState(null);

    const closeStatus = () =>{
        setOpenStatus(false);
    }
    const closeQuantity = () =>{
        setOpenQuantity(false);
    }
    const onOpenStatus = item => {
        setSelected(item);
        setOpenStatus(true);
    }
    const onOpenQuantity = item => {
        setSelected(item);
        setOpenQuantity(true);
    }
    return <div>
        <TableContainer>
            <Table>
                <CampaignAdminSeedingTableHead />
                <TableBody>
                    {
                        items.map(item=>{
                            return <CampaignAdminSeedingTableRow key={item.id} item={item} onOpenStatus={onOpenStatus} onOpenQuantity={onOpenQuantity}/>
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {openStatus && <UpdateStatusDialog item={selected} open={openStatus} onClose={closeStatus}/> }
        {openQuantity && <UpdateQuantityDialog item={selected} open={openQuantity} onClose={closeQuantity} /> }
    </div>
}

CampaignAdminSeedingTable.defaultProps = {
    items: []
}

export default CampaignAdminSeedingTable;