import {Box} from "@material-ui/core";
import React, {useCallback, useEffect, useRef, useState} from "react";
import MessageDivider from "./MessageDivider";
import UserChatMessage from "./UserChatMessage";
import {useSelector} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import {messagesSelector} from "../../slices/messages";
import {isEmpty} from "../../helpers/utils";
import MessagesHelper from "../../helpers/MessagesHelper";
import SystemChatMessage from "./SystemChatMessage";

const scrollToElementRef = (elementRef, behavior = "smooth") => {
    if (elementRef.current) {
        elementRef.current.scrollIntoView({behavior});
    }
};

const getIsScrollAtElementRef = (elementRef) => {
    if (elementRef.current) {
        const {scrollTop, scrollHeight, clientHeight} = elementRef.current;
        return scrollTop + clientHeight >= scrollHeight - 10;
    } else {
        return false;
    }
};

const MessageList = (props) => {
    const listRef = useRef(null);
    const endOfMessagesRef = useRef(null);
    const {currentChatMessages, isChatMessagesInProgress, isLoading} = useSelector(messagesSelector);
    const [stickyLocalTimeLabel, setStickyLocalTimeLabel] = useState(MessagesHelper.getTimeIndicatorLabel(currentChatMessages[0]?.time) ?? null);
    const [isScrollLockAtBottom, setIsScrollLockAtBottom] = useState(true);

    const onScroll = useCallback(() => {
        setIsScrollLockAtBottom(getIsScrollAtElementRef(endOfMessagesRef));

        const listItems = listRef.current.querySelectorAll(".UserChatMessage");

        let foundCurrentDate = false;
        listItems.forEach((item) => {
            const {top, bottom} = item.getBoundingClientRect();
            const realTop = top - 268;
            const realBottom = bottom - 268;

            if ((realTop > 0 || realBottom > 0) && !foundCurrentDate) {
                foundCurrentDate = true;
                const timeIndicator = item.getAttribute('data-date');
                setStickyLocalTimeLabel(timeIndicator);
            }
        });
    }, []);

    useEffect(() => {
        const list = listRef.current;
        list.addEventListener('scroll', onScroll);

        return () => {
            list.removeEventListener('scroll', onScroll);
        };
    }, [onScroll]);

    useEffect(() => {
        if (isScrollLockAtBottom && currentChatMessages.length > 0 && !isChatMessagesInProgress) {
            scrollToElementRef(endOfMessagesRef, "instant");
        }
    }, [isScrollLockAtBottom, currentChatMessages.length, isChatMessagesInProgress]);

    const getMessages = () => {
        if (currentChatMessages.length === 0) {
            return (
                <Box
                    style={{
                        position: "absolute",
                        top: " 50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}>
                    {isLoading ? <CircularProgress/> : 'No messages to display.'}
                </Box>
            );
        }

        let lastTimeLabel = stickyLocalTimeLabel;
        const components = [];

        currentChatMessages.forEach((message, index) => {
            const messageTimeLabel = MessagesHelper.getTimeIndicatorLabel(message.time);
            if (lastTimeLabel !== messageTimeLabel && index > 0) {
                components.push(<MessageDivider label={messageTimeLabel} key={`${index}-time`} mt={1}/>);
                lastTimeLabel = messageTimeLabel;
            }
            if (message.author) {
                components.push(<UserChatMessage message={message} key={index}/>);
            } else {
                components.push(<SystemChatMessage message={message} key={index}/>);
            }
        });

        return components;
    }

    return (
        <>
            {
                !isEmpty(stickyLocalTimeLabel) &&
                <Box px={3} mb={"12px"} position={"relative"} width={"100%"}>
                    <Box position={"absolute"} width={"100%"} left={0} px={3} zIndex={2}>
                        <MessageDivider label={stickyLocalTimeLabel}/>
                    </Box>
                </Box>
            }
            <Box {...props} style={{overflowY: "auto"}} ref={listRef} minHeight={"240px"} position={"relative"} pt={1}>
                {getMessages()}
                <div ref={endOfMessagesRef}></div>
            </Box>
        </>
    )
}

export default MessageList;