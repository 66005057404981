import CampaignDetails from "../../Campaign/CampaignDetails";
import React, {useEffect, useState} from "react";
import {Box, Dialog, Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {campaignAdminSelector, updateOpenDialog} from "../../../slices/campaignAdmin";
import Client from "../../../helpers/api";
import {clientConfigSelector, getClientConfig} from "../../../slices/clientConfig";

const CampaignAdminDetailsDialog = ({open}) => {

    const [products, setProducts] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [contentStyles, setContentStyles] = useState([]);

    const {row} = useSelector(campaignAdminSelector);
    const {clientConfig} = useSelector(clientConfigSelector);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(updateOpenDialog({
            dialog: "CampaignAdminDetails",
            value: false,
            row: null
        }))
    }

    useEffect(()=>{
        if(!initialized){
            setInitialized(true);
            const {productIdentifiers, brand} = row;
            productIdentifiers.forEach((productIdentifier)=>{
                Client.ProductApi.get(productIdentifier, brand.id) (
                    (response)=>{
                        setProducts((prevProducts=>{
                            return [...prevProducts, response.data];
                        }))
                    },
                    error=>{

                    }
                )
            })
            dispatch(getClientConfig(brand.hash));
            Client.UtilityApi.getSystemSettings()(
                systemResponse => {
                    setContentStyles(systemResponse.data.contentStyles);
                },
                error =>{

                }
            );


        }
    },[initialized, row, dispatch])

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs' disableScrollLock
                style={{padding: '0px', margin: '0px'}}>
            <Box style={{
                overflowX: 'hidden',
                height: '100%',
                width: '100%',
            }}>

                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{justifyContent: "center"}}>
                            {initialized && <CampaignDetails campaign={row}
                                             clientConfig={clientConfig}
                                             brand={row?.brand}
                                             contentStyles={contentStyles}
                                             products={products}

                            />}

                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Dialog>
    );

}
export default CampaignAdminDetailsDialog;