import React from "react";
import {TableCell, TableHead, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  signage: {
    '& .MuiTableCell-head': {
      fontWeight: 600,
      fontSize: "x-large"
    }
  },
}));

const CampaignListTableHead = () => {
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow className={classes.signage}>
                <TableCell>Client</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Campaign ID</TableCell>
                <TableCell>Start date</TableCell>
                <TableCell>End date</TableCell>
                <TableCell>Gross revenue</TableCell>
            </TableRow>
        </TableHead>
    )
}

export default CampaignListTableHead;