import React, {useEffect, useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import {isEmpty} from "../../helpers/utils";
import {setNotification} from "../../slices/notification";
import RoleSelect from "./RoleSelect";
import Api from "../../helpers/api";
import RequestError from "../../helpers/RequestError";
import ClientAutocompleteSelect from "../ClientAutocompleteSelect";
import ResponseStatus from "../../helpers/ResponseStatus";
import ConfirmRecoverUserRoleModal from "./ConfirmRecoverUserRoleModal";

const ROLE_ADMIN = 1;
const ROLE_SUPER_ADMIN = 3;

const AddUserRoleModal = ({isOpen, userId, onClose, onSaveSuccess}) => {
    const [roleId, setRoleId] = useState(null);
    const [clientId, setClientId] = useState(null);

    const [showErrors, setShowErrors] = useState(false);
    const [inProgress, setInProgress] = useState(true);
    const [clientSelectHelperText, setClientSelectHelperText] = useState("");
    const [isConfirmRecoverModalOpen, setIsConfirmRecoverModalOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpen === false) {
            setRoleId(null);
            setClientId(null);
            setInProgress(false);
            setShowErrors(false);
            setIsConfirmRecoverModalOpen(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (showErrors && isEmpty(clientId) && roleId === ROLE_ADMIN) {
            setClientSelectHelperText("This field is required.");
        } else if (roleId === ROLE_SUPER_ADMIN) {
            setClientSelectHelperText("Super admin role doesn't require to associate a client.");
        } else {
            setClientSelectHelperText("");
        }
    }, [showErrors, clientId, roleId]);

    const onSave = () => {
        if (isEmpty(roleId) || (roleId === ROLE_ADMIN && isEmpty(clientId))) {
            setShowErrors(true);
        } else {
            setShowErrors(false);
            setInProgress(true);
            Api.addUserRole(userId)(
                (response) => {
                    const respStatus = new ResponseStatus(response);
                    if (respStatus.isError() && respStatus.getCode() === "role_disabled") {
                        setIsConfirmRecoverModalOpen(true);
                    } else if (respStatus.isError()) {
                        dispatch(setNotification(respStatus.getErrorMessage(), "error"));
                    } else {
                        dispatch(setNotification("Role was created successfully"));
                        onSaveSuccess();
                    }
                },
                (error) => dispatch(setNotification((new RequestError(error).getMessage()), "error"))
            )({role_id: roleId, client_id: (roleId === ROLE_ADMIN ? clientId : null)}).then(() => setInProgress(false));
        }
    }

    const onChangeRole = (role) => {
        setRoleId(role ? role.role_id : null);
    }

    const onChangeClient = (client) => {
        setClientId(client ? client.client_id : null);
    }

    const onRecover = () => {
        setIsConfirmRecoverModalOpen(false);
        setInProgress(true);
        Api.recoverUserRole(userId)(
            (response) => {
                const respStatus = new ResponseStatus(response);
                if (respStatus.isError()) {
                    dispatch(setNotification(respStatus.getErrorMessage(), "error"));
                } else {
                    dispatch(setNotification("Role was restored successfully"));
                    onSaveSuccess();
                }
            },
            (error) => dispatch(setNotification((new RequestError(error).getMessage()), "error"))
        )({role_id: roleId, client_id: (roleId === ROLE_ADMIN ? clientId : null)}).then(() => setInProgress(false));
    }

    const onCancelRecover = () => {
        setIsConfirmRecoverModalOpen(false);
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Create new role for this user</DialogTitle>
                <DialogContent>
                    <RoleSelect onChange={onChangeRole}
                                disabled={inProgress}
                                error={showErrors && isEmpty(roleId)}
                                helperText={showErrors && isEmpty(roleId) ? "This field is required" : ""}/>
                    <ClientAutocompleteSelect onChange={onChangeClient}
                                              disabled={roleId === ROLE_SUPER_ADMIN || inProgress}
                                              error={showErrors && isEmpty(clientId) && roleId === ROLE_ADMIN}
                                              helperText={clientSelectHelperText}/>
                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="outlined" onClick={onClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button type="submit" color="primary" variant="contained" onClick={onSave} fullWidth
                                    disabled={inProgress}>
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
            <ConfirmRecoverUserRoleModal isOpen={isConfirmRecoverModalOpen}
                                         onClose={onCancelRecover} onRecover={onRecover}
                                         inProgress={inProgress}/>
        </Fragment>
    )
}

export default AddUserRoleModal;