import {Box, FormControl, FormHelperText, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import React, {useCallback, useEffect, useState} from "react";
import Api from "../helpers/api";
import {isEmpty} from "../helpers/utils";

const MarketplaceCategoryAutocompleteSelect = ({disabled, onChange, value, error, helperText}) => {
    const [isInitialized, setIsInitialized] = useState(null);
    const [categories, setCategories] = useState([]);
    const [isError, setIsError] = useState(false);
    const [internalValue, setInternalValue] = useState(null);

    const getCategories = useCallback(() => {
        return Api.getMarketplaceCategories(1, 100000)(
            ({data}) => setCategories(data["hydra:member"]), () => setIsError(true)
        ).then(() => setIsInitialized(true));
    }, []);

    useEffect(() => {
        if (isInitialized === null) {
            setIsInitialized(false);
            getCategories().then(() => setIsInitialized(true));
        }
    }, [getCategories, isInitialized]);

    useEffect(() => {
        setInternalValue(value ?? null);
    }, [value]);

    const onInternalChange = (event, category) => {
        onChange(category);
        setInternalValue(category);
    }

    return (
        <FormControl error={isError || error} fullWidth margin={"normal"}>
            <Autocomplete
                value={internalValue}
                disabled={disabled}
                size="small"
                noOptionsText={"Search category"}
                options={categories}
                getOptionLabel={(option) => `${option.name} (${option.marketplace_category_id})`}
                getOptionSelected={(option, value) => option.marketplace_category_id === value.marketplace_category_id}
                renderInput={(params) => (
                    <TextField variant="outlined" disabled={disabled} size="small"
                               error={error}
                               label={"Category"}
                               placeholder={"Search..."} fullWidth {...params}
                               InputLabelProps={{shrink: true, ...params.InputLabelProps}}/>
                )}
                onChange={onInternalChange}
            />
            <Box ml={2}>
                {isError && <FormHelperText>"Error loading categories"</FormHelperText>}
                {!isError && !isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
            </Box>
        </FormControl>
    )
}

export default MarketplaceCategoryAutocompleteSelect;