class WebSocketRpcClient {
    constructor(url, {onConnectionError, onConnectionOpen, onMessage, onConnectionClose}) {
        this.isOpen = false;
        this.pendingRequests = new Map();

        this.socket = new WebSocket(url);

        this.socket.onopen = () => {
            this.isOpen = true;
            onConnectionOpen();
        };

        this.socket.onerror = (error) => {
            onConnectionError(error);
        };

        this.socket.onmessage = (event) => {
            this.onMessage(event);
            onMessage(event);
        };

        this.socket.onclose = (event) => {
            this.isOpen = false;
            onConnectionClose(event);
        };
    }

    onMessage(event) {
        const response = JSON.parse(event.data);
        const {random_id, error} = response;

        const pendingRequest = this.pendingRequests.get(random_id);
        if (pendingRequest) {
            const [resolve, reject] = pendingRequest;
            if (error) {
                reject(Promise.reject(error));
            } else {
                resolve(response);
            }
            this.pendingRequests.delete(random_id);
        }
    }

    sendCommand(command) {
        if (this.isOpen) {
            return new Promise((resolve, reject) => {
                this.pendingRequests.set(command.random_id, [resolve, reject]);
                this.socket.send(JSON.stringify(command));
            });
        } else {
            return Promise.reject("Connection is closed");
        }
    }

    closeConnection() {
        this.socket.close();
    }
}

export default WebSocketRpcClient;
