import Box from "@material-ui/core/Box";
import {LinearProgress, makeStyles, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React, {useState} from "react";
import api from "../../../helpers/api";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {clientConfigSelector} from "../../../slices/clientConfig";
import {setNotification} from "../../../slices/notification";

const useStyles = makeStyles(theme=>({
    deleteDialog:{
        backgroundColor: theme.palette.error.main,
        color: '#ffffff',
        "&:hover":{
            backgroundColor: theme.palette.error.main,
        }
    }
}));



const EndSubscriptionDialog = ({open, onClose, onSuccess}) => {
    const [inProgress, setInProgress] = useState(false);
    const {clientConfig} = useSelector(clientConfigSelector);
    const dispatch = useDispatch();

    const endSubscription = () =>{
        setInProgress(true);
        api.backoffice.company.cancelSubscription(clientConfig.client.id)(
           response=>{
               dispatch(setNotification('Subscription cancelled.'));
               setInProgress(false);
               onSuccess();
           },
           error => {
               dispatch(setNotification('Unable to end subscription'));
               setInProgress(false);
           }
        )();
    }

    const classess = useStyles();

    return <Dialog open={open} onClose={onClose} maxWidth={'xs'}>
        <Box px={3}>
            <Box pt={2}>
                <Typography>End subscription</Typography>
            </Box>
            <Box pt={1}>
                <Typography>
                    By ending this subscription, it will  disable the client’s access to the account.
                </Typography>
            </Box>

            <Box pb={3} pt={2} display={'flex'} justifyContent={'flex-end'}>
                <Button data-testId={'end-plan-action-cancel'} onClick={onClose} variant={'outlined'} style={{marginRight: "16px"}}>
                    Cancel
                </Button>
                <Button data-testId={'end-plan-action-end'} className={classess.deleteDialog} variant={'contained'} onClick={endSubscription}>
                    End subscription
                </Button>
            </Box>
        </Box>
        {inProgress && <LinearProgress />}
    </Dialog>
}
export default EndSubscriptionDialog;