import React from "react";
import CurrencyFormat from "react-currency-format";
import { useSelector } from "react-redux"
import { clientConfigSelector } from "../slices/clientConfig"

const CurrencyWithClientCurrencySymbol  = (props) =>{
    const {clientConfig} = useSelector(clientConfigSelector);
    const currencySymbol =  clientConfig?.experiences?.currency_symbol || '$';
    return <>{currencySymbol}<CurrencyFormat
        decimalScale={2}
        fixedDecimalScale
        displayType={"text"}
        thousandSeparator
        value={props.value || 0} /></>
}
export default CurrencyWithClientCurrencySymbol;