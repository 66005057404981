import {Box, Button, makeStyles, Toolbar, Typography} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    button: {
        textTransform: "inherit",
        color: theme.palette.primary.main,
        margin: "5px 10px"

    },
    toolbar: {
        backgroundColor: theme.palette.primary.light,
    }
}));

const StatusToolbarActions = ({selected, actionsFor, openManual, openReject, openRevoke}) => {

    const classes = useStyles();

    const approvedActions = [
        {
            label: "Move to manual pay",
            action: openManual
        },
        {
            label: "Reject payment",
            action: openReject
        },
        {
            label: "Revoke approval",
            action: openRevoke
        }
    ]
    const actions = actionsFor === 0 ? approvedActions : [];
    return <Toolbar className={classes.toolbar}>
        <Box display={"flex"} alignContent={"center"} alignItems={"center"}>
            <Typography variant={"subtitle2"}>
                {selected.length} Selected
            </Typography>
            {
                actions.map((item,index)=>{
                    return <Button key={index} onClick={item.action} className={classes.button}>
                        {item.label}
                    </Button>
                })
            }

        </Box>
    </Toolbar>

}

export default StatusToolbarActions;