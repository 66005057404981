import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import UserRolesTableRowMenu from "./UserRolesTableRowMenu";
import dates from "../../../helpers/dates";
import {getUserStatusNameFromId} from "../../../helpers/userRoleStatus";

const getRoleTitleFromId = (roleId) => {
    if (roleId === 1) {
        return "Merchant user";
    } else if (roleId === 2) {
        return "Storefront creator";
    } else if (roleId === 3) {
        return "Super admin";
    } else if(roleId ===4){
        return "Marketplace";
    }
    else {
        return "Undefined";
    }
};

const getAmbassadorType = ambassador_type => {
    switch (ambassador_type){
        case 1:
            return 'Employee';
        case 2:
            return 'External'
        case 3:
            return 'Marketplace'
        default:
            return '-';
    }
}

const UserRolesTableRow = ({userRole, onClickRemove, onClickChangeStatus}) => {
    return (
        <TableRow>
            <TableCell align="left" style={{whiteSpace: "nowrap"}}>
                {getRoleTitleFromId(userRole.role.role_id)}
            </TableCell>
            <TableCell align="left">
                {userRole.client ? userRole.client.client_id : "-"}
            </TableCell>
            <TableCell align="left">
                {userRole.role.role_id}
            </TableCell>
            <TableCell>
                {getAmbassadorType(userRole.ambassador_type)}
            </TableCell>
            <TableCell align="left" style={{whiteSpace: "nowrap"}}>
                {userRole.role.role_id === 2 ? getUserStatusNameFromId(userRole.status) : "-"}
            </TableCell>
            <TableCell align="left">
                {dates.formatFromString(userRole.created_timestamp, "MM/DD/YYYY")}
            </TableCell>
            <TableCell align="center" padding={"checkbox"}>
                <UserRolesTableRowMenu onClickRemove={() => onClickRemove(userRole)} onClickChangeStatus={() => onClickChangeStatus(userRole)} userRole={userRole}/>
            </TableCell>
        </TableRow>
    )
}

export default UserRolesTableRow;