import {Box, Chip} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

const MessageDivider = ({label, ...props}) => {
    return (
        <Box {...props} display={"flex"} justifyContent={"center"} alignContent={"center"} position={"relative"}>
            <Divider style={{position: "absolute", width: "100%", marginTop: "12px"}}/>
            <Chip variant={"outlined"} size={"small"}  style={{backgroundColor: "#fff", zIndex: 1}}
                  label={label}/>
        </Box>
    );
}

export default MessageDivider;