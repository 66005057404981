import React from 'react';
import {Divider} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Toolbar from '@material-ui/core/Toolbar';
import SearchInput from "../SearchInput";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";

const TableSearchAndFilter = ({onChangeSearchTerm, onClickFilterIcon, disableFilter, searchValue}) => {

    return (
        <Box>
            <Box px={2}>
                <Toolbar disableGutters>
                    <Box flexGrow={1} justifyContent={"space-between"} display={"flex"}>
                        <SearchInput value={searchValue} onChange={(e) => onChangeSearchTerm(e.target.value)}/>
                        {!disableFilter && <IconButton onClick={onClickFilterIcon}>
                            <FilterListIcon/>
                        </IconButton>}
                    </Box>
                </Toolbar>
            </Box>
            <Divider/>
        </Box>
    );
}

TableSearchAndFilter.defaultProps = {
    onChangeSearchTerm: ()=>{},
    onClickFilterIcon: ()=>{},
    disableFilter: false,
    searchValue: ""
}

export default TableSearchAndFilter;
