import React, {useEffect, useState} from 'react';

import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import {useIntl} from "react-intl";
import CodeEditor from "../CodeEditor/CodeEditor";
import TextFieldWithHelp from "../TextFieldWithHelp";
import {isEmpty} from "../../helpers/utils";
import {useDispatch} from "react-redux";
import {setNotification} from "../../slices/notification";
import LabeledSwitch from "../LabeledSwitch";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    content: {
        '&:focus-visible': {
            outline: "none"
        },
    }
}));

const getSlug = (text) => text.toLowerCase().replace(/[^a-z0-9-]/g, '_').replace("__", "_");

const emptyValues = {name: "", identifier: "", autoplay: true, muted: false, notes: "", template: ""};
const requiredFields = ["name", "identifier", "template"];

const AddWidgetTemplateModal = (props) => {
    const {open, disabled, onCancel, onSave, widgetTemplateData, inProgress} = props;
    const [fieldsValues, setFieldsValues] = useState(emptyValues);
    const [fieldsErrors, setFieldsErrors] = useState({});
    const [useNameAsIdentifier, setUseNameAsIdentifier] = useState(true);
    const [showErrors, setShowErrors] = useState(true);
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();

    const textAddWidget = intl.formatMessage({
        id: 'Experiences.AddWidgetTemplate.AddWidgetTemplate',
        defaultMessage: 'Add widget template'
    });
    const [windowTitle, setWindowTitle] = useState(textAddWidget);

    const textWidgetTemplateName = intl.formatMessage({
        id: 'Experiences.AddWidgetTemplate.WidgetName',
        defaultMessage: 'Template name'
    });

    const textWidgetTemplateNotes = intl.formatMessage({
        id: 'Experiences.AddWidgetTemplate.NotesPurpose',
        defaultMessage: 'Notes / Purpose'
    });

    const textCancel = intl.formatMessage({
        id: 'Common.Cancel',
        defaultMessage: 'CANCEL'
    });

    const textSave = intl.formatMessage({
        id: 'Common.Save',
        defaultMessage: 'SAVE'
    });

    useEffect(() => {
        if (open === false) {
            setWindowTitle(textAddWidget);
            setFieldsValues(emptyValues);
            setFieldsErrors({});
            setUseNameAsIdentifier(true);
            setShowErrors(false);
        }
    }, [open, textAddWidget]);

    useEffect(() => {
        if (widgetTemplateData && widgetTemplateData.name) {
            setWindowTitle(widgetTemplateData.name);
        } else {
            setWindowTitle(textAddWidget);
        }
    }, [widgetTemplateData, textAddWidget]);

    useEffect(() => {
        if (widgetTemplateData) {
            setUseNameAsIdentifier(isEmpty(widgetTemplateData.identifier));
            setFieldsValues({
                ...widgetTemplateData,
                autoplay: widgetTemplateData.autoplay === 1,
                muted: widgetTemplateData.muted === 1,
            });
        }
    }, [widgetTemplateData, setFieldsValues]);

    useEffect(() => {
        if (!isEmpty(fieldsValues.identifier) && fieldsValues.identifier !== getSlug(fieldsValues.identifier)) {
            setFieldsValues({...fieldsValues, identifier: getSlug(fieldsValues.identifier)});
        }
    }, [fieldsValues]);

    const onChangeFields = (e) => {
        const isCheckbox = ['autoplay', 'muted'].includes(e.target.name);
        const value = isCheckbox ? (e.target.checked ? true : false) : e.target.value;
        if (e.target.name === "identifier" && isEmpty(value)) {
            setUseNameAsIdentifier(true);
        }
        if (e.target.name === "name" && useNameAsIdentifier) {
            setFieldsValues({...fieldsValues, name: value, identifier: getSlug(value)});
        } else {
            setFieldsValues({...fieldsValues, [e.target.name]: value});
        }
        if (requiredFields.includes(e.target.name)) {
            setFieldsErrors({...fieldsErrors, [e.target.name]: value.trim() === ""})
        }
    }

    const onKeyDownIdentifier = (e) => {
        setUseNameAsIdentifier(isEmpty(e.target.value));
    }

    const onChangeTemplate = (template) => {
        setFieldsValues({...fieldsValues, template: template});
        setFieldsErrors({...fieldsErrors, template: template.trim() === ""});
    }

    const onClickSave = () => {
        if (validateForm()) {
            setShowErrors(false);
            onSave(fieldsValues);
        } else {
            setShowErrors(true);
            dispatch(setNotification("Please fix the error(s) highlighted in red.", "error"));
        }
    }

    const validateForm = () => {
        const newFieldsErrors = {...fieldsErrors};
        requiredFields.forEach(f => newFieldsErrors[f] = fieldsValues[f].trim() === "");
        setFieldsErrors(newFieldsErrors);
        return requiredFields.filter(f => newFieldsErrors[f] === true).length === 0;
    }

    return (
        <Dialog open={open} scroll={"paper"} maxWidth={"md"} fullWidth disableBackdropClick>
            <DialogTitle>
                {windowTitle}
            </DialogTitle>
            <DialogContent dividers={true} className={classes.content}>
                <form noValidate autoComplete="off">
                    <FormControl variant="outlined" fullWidth margin={"normal"}>
                        <TextField
                            error={showErrors && fieldsErrors.name}
                            helperText={showErrors && fieldsErrors.name ? "This field is required" : ""}
                            label={textWidgetTemplateName}
                            disabled={disabled}
                            name="name"
                            variant="outlined"
                            value={fieldsValues.name}
                            size="small"
                            required
                            onChange={onChangeFields}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl variant="outlined" fullWidth margin={"normal"}>
                        <TextFieldWithHelp
                            error={showErrors && fieldsErrors.identifier}
                            helperText={showErrors && fieldsErrors.identifier ? "This field is required" : ""}
                            label={"Identifier"}
                            disabled={disabled}
                            name="identifier"
                            value={fieldsValues.identifier}
                            required
                            onChange={onChangeFields}
                            onKeyDown={onKeyDownIdentifier}
                            helpTooltipText={"This identified is needed for embed code generation"}
                        />
                    </FormControl>
                    <Box maxWidth={"130px"}>
                        <LabeledSwitch name="autoplay" checked={fieldsValues.autoplay} label={"Autoplay"}
                                       onChange={onChangeFields}/>
                        <LabeledSwitch name="muted" checked={fieldsValues.muted} label={"Muted"}
                                       onChange={onChangeFields}/>
                    </Box>
                    <FormControl variant="outlined" fullWidth margin={"normal"}>
                        <TextField
                            label={textWidgetTemplateNotes}
                            disabled={disabled}
                            name="notes"
                            variant="outlined"
                            value={fieldsValues.notes}
                            multiline
                            rows={5}
                            size="small"
                            onChange={onChangeFields}
                            fullWidth
                            inputProps={{"data-test-id": "AddWidgetTemplateModal-notes"}}/>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth margin={"normal"}>
                        <CodeEditor onChange={onChangeTemplate} value={`${fieldsValues.template}`}
                                    disabled={disabled}
                                    error={showErrors && fieldsErrors.template}
                                    helperText={showErrors && fieldsErrors.template ? "This field is required" : "Available variables: {{uuid}}, {{client_id}}, {{playlist_id}}, {{source_url}}, {{element}}, {{autoplay}}, {{muted}}."}/>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Box width={"100%"} p={1.5}>
                    <Grid container justify="flex-end" spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="outlined" onClick={() => onCancel()} disabled={disabled}
                                    fullWidth>{textCancel}</Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button type="submit" color="primary" variant="contained" disabled={disabled}
                                    onClick={onClickSave}
                                    fullWidth>{textSave}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogActions>
            {inProgress && <LinearProgress/>}
        </Dialog>
    );
}

export default AddWidgetTemplateModal;