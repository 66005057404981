import React from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionActions from "@material-ui/core/AccordionActions";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";

const MaintenanceModeConfig = ({maintenanceModeConfig, onChange, onSave, onCancel}) => {
    const {maintenance_mode = false, maintenance_title = "", maintenance_message = ""} = (maintenanceModeConfig || {});

    const onChangeField = (option, value) => {
        onChange({
            ...maintenanceModeConfig,
            [option]: value
        });
    }

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1c-content"
                id="panel1c-header"
            >
                <Typography variant="h6">
                    Maintenance Mode
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        value={true}
                                        checked={maintenance_mode===true}
                                        onChange={e => onChangeField('maintenance_mode', !maintenance_mode)}
                                    />
                                }
                                label="Enable"
                                labelPlacement="start"
                            />
                        </FormGroup>
                        <FormGroup aria-label="position" row>
                            <TextField
                                name="maintenance_title"
                                label="Title"
                                value={maintenance_title}
                                variant="outlined"
                                fullWidth
                                onChange={e => onChangeField('maintenance_title', e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup aria-label="position" row>
                            <TextField
                                name="maintenance_message"
                                label="Message"
                                value={maintenance_message}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                onChange={e => onChangeField('maintenance_message', e.target.value)}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </AccordionDetails>
            <Divider/>
            <AccordionActions>
                <Button size="small" onClick={onCancel}>Cancel</Button>
                <Button size="small" onClick={onSave} color="primary">
                    Save
                </Button>
            </AccordionActions>
        </Accordion>
    )
}

export default MaintenanceModeConfig;