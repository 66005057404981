import React from "react";
import { Avatar as MUIAvatar } from "@material-ui/core";

const letterColorMap = {
    A: "#8C1A11",
    B: "#BB271A",
    C: "#D16D6A",
    D: "#A96324",
    E: "#DA954B",
    F: "#ECB576",
    G: "#B89230",
    H: "#EAC451",
    I: "#F9DA78",
    J: "#48752C",
    K: "#78A65A",
    L: "#9DC384",
    M: "#264E5A",
    N: "#54808C",
    O: "#80A4AE",
    P: "#2854C5",
    Q: "#4B77D1",
    R: "#789DE5",
    S: "#25528F",
    T: "#5083C1",
    U: "#7CA6D7",
    V: "#321D70",
    W: "#634FA2",
    X: "#8B7DBE",
    Y: "#6B2246",
    Z: "#9B5277",
};

const getAvatarDetails = (full_name) => {
    // Split the full_name into words to derive initials
    const nameParts = full_name ? full_name.trim().split(" ") : [];
    const nameInitial = nameParts[0]
        ? nameParts[0].charAt(0).toUpperCase()
        : "";

    // Get the color based on the first letter (or a default if none found)
    const color = letterColorMap[nameInitial] || "#90A4AE";

    return { nameInitial, color };
};

const ChatAvatar = ({ chatDetails, size }) => {
    const { full_name } = chatDetails ?? {};
    const { nameInitial, color } = getAvatarDetails(full_name);

    return (
        <MUIAvatar
            style={{
                backgroundColor: color,
                width: size,
                height: size,
                fontSize: `${size / 2.5}px`,
                fontWeight: "bold",
                textTransform: "uppercase",
            }}>
            {nameInitial}
        </MUIAvatar>
    );
};

export default ChatAvatar;
