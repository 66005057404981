import makeStyles from "@material-ui/core/styles/makeStyles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {TableSortLabel} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiTableCell-root": {
            whiteSpace: "nowrap",
            fontWeight: "bold",
        },
    },
}));

const ChatsTableHead = ({sort, onSort}) => {
    const classes = useStyles();
    return (
        <TableHead className={classes.root}>
            <TableRow key="thead">
                <TableCell style={{width: "200px", paddingTop: "8px", paddingBottom: "8px"}}>
                    <TableSortLabel direction={sort} onClick={() => onSort(sort === "desc" ? "asc" : "desc")} active>
                        Last chat
                    </TableSortLabel>
                </TableCell>
                <TableCell style={{paddingTop: "8px", paddingBottom: "8px"}}>
                    Chat
                </TableCell>
                <TableCell>
                    Sales manager
                </TableCell>
                <TableCell>
                    Account manager
                </TableCell>
                <TableCell>
                    Participants
                </TableCell>
                <TableCell style={{paddingTop: "8px", paddingBottom: "8px"}}>
                    Created
                </TableCell>
                <TableCell style={{paddingTop: "8px", paddingBottom: "8px"}}>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default ChatsTableHead;