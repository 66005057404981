import CardWithTitleAndAction from "../CardWithTitleAndAction";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {numbers} from "../../../helpers/utils";
import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";
import {useDispatch, useSelector} from "react-redux";
import {clientConfigSelector} from "../../../slices/clientConfig";
const initialValues = {
    "views_included": "",
    "additional_view_pennies": ""
};
const SiteViewUsage = ({plan, onSuccess}) =>{
    const [values, setValues] = useState(initialValues);
    const [inProgress, setInProgress] = useState(false);
    const [errors, setErrors] = useState({});
    const {clientConfig} = useSelector(clientConfigSelector);

    const onConvertedMoneyFieldChange = (event, value, convertToPennies=true) =>{
        const {name} = event.target;
        const newValues = {...values};
        newValues[name] = convertToPennies ? numbers.dollarsToPennies(value) : value;
        setValues(newValues);
        if(!value){
            const newErrors = {...errors};
            newErrors[name] = true;
            setErrors(newErrors);
        }else{
            const newErrors = {...errors};
            delete newErrors[name];
            setErrors(newErrors);
        }
    }

    const dispatch = useDispatch();
    const disableAction = !plan || plan?.cancellation === true;

    useEffect(() => {
        if(plan){
            setValues(plan);
        }

    }, [plan]);

    const partialSave = () =>{
        if(Object.keys(errors).length > 0){
            dispatch(setNotification('Please enter the required fields', 'error'));
            return;
        }
        setInProgress(true);
        api.backoffice.company.updateCurrentPlan(clientConfig.client.id)(
            response => {
                setInProgress(false);
                dispatch(setNotification('Site view usage rates updated'));
                onSuccess();
            },
            error =>{
                setInProgress(false);
                dispatch(setNotification('Unable to update site views usage rates', 'error'));
            }
        )({...plan, views_included: values.views_included, additional_view_pennies: values.additional_view_pennies})
    }

    return <CardWithTitleAndAction title={'Site view usage rates'} inProgress={inProgress} onClickAction={partialSave} testId={'site-view-usage'} disableAction={disableAction}>
        <Grid container spacing={1}>
            <Grid item xs={6} data-testId={'views_included'}>
                <CurrencyTextField margin="normal"
                                   name={'views_included'}
                                   textAlign="left"
                                   fullWidth
                                   minimumValue={0}
                                   label="Included views"
                                   placeholder="Included views"
                                   decimalPlaces={0}
                                   currencySymbol={null}
                                   onChange={(e, value)=>onConvertedMoneyFieldChange(e, value, false)}
                                   value={values.views_included}
                                   variant="outlined" size="small" />
            </Grid>
            <Grid item xs={6} data-testId={'additional_view_pennies'}>
                <CurrencyTextField margin="normal"
                                   name={'additional_view_pennies'}
                                   textAlign="left"
                                   fullWidth
                                   label="Cost per additional view"
                                   decimalPlaces={2}
                                   minimumValue={0}
                                   currencySymbol={"$"}
                                   onChange={(e, value)=>onConvertedMoneyFieldChange(e, value)}
                                   value={values.additional_view_pennies ? numbers.penniesToDollars(values.additional_view_pennies) : ""}
                                   variant="outlined" size="small" />
            </Grid>
        </Grid>


    </CardWithTitleAndAction>
}
export default SiteViewUsage;