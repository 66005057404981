import React, {useEffect, useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress, Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useNotification from "../../helpers/useNotification";
import Api from "../../helpers/api";

const DeleteCommunicationsModal = ({isOpen, onClose, onDeleteSuccess, communication}) => {
    const notification = useNotification();
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        if (isOpen === false) {
            setInProgress(false);
        }
    }, [isOpen]);

    const onDelete = () => {
        setInProgress(true);
        Api.communications.deleteConfiguration(communication?.communication_config_id)(
            () => onDeleteSuccess(), notification.captureError
        )().then(() => setInProgress(false));
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Delete communication template?</DialogTitle>
                <DialogContent>
                    <Typography variant={"body1"}>
                        If you delete this communication template, associated messages will not been sent.
                    </Typography>
                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="outlined" onClick={onClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button type="submit" color="primary" variant="contained" onClick={onDelete} fullWidth
                                    disabled={inProgress}>
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default DeleteCommunicationsModal;