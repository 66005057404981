import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FormControl, Grid, LinearProgress, makeStyles} from '@material-ui/core';
import {campaignAdminSelector, reloadSection, updateOpenDialog} from "../../../slices/campaignAdmin";
import {useDispatch, useSelector} from "react-redux";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Client from "../../../helpers/api";
import moment from "moment";
import {setNotification} from "../../../slices/notification";



const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(3)
    }

}))

const ChangeCampaignDates = ({openDialog}) => {
    const {row, open, draft, pending, ended} = useSelector(campaignAdminSelector);
    const [isSaving, setIsSaving] = useState(false);
    const [campaignStart, setCampaignStart] = useState(row.dateStart);
    const [campaignEnd, setCampaignEnd] = useState(row.dateEnd);
    const classes = useStyles();
    const dispatch = useDispatch();

    const getSectionToRefresh = () =>{
        if(row.status === "Draft"){
            return draft
        }else if(row.status === "Pending"){
            return pending
        }else if(row.status === "Ended"){
            return ended
        }else{
            return open
        }
    }

    const onConfirmEvent = () => {
        setIsSaving(true);
        const campaign = {};
        campaign.id= row.id;
        campaign.dateStart = moment(campaignStart).format();
        campaign.dateEnd = moment(campaignEnd).format();

        Client.CampaignApi.edit()(
            response => {
                dispatch(updateOpenDialog({
                    dialog: "ChangeCampaignDates",
                    value: false,
                    row: null
                }));
                dispatch(reloadSection(getSectionToRefresh()));
                setIsSaving(false);
            },
            err=>{
                setIsSaving(false);
                dispatch(setNotification("Something went wrong, dates wasn't updated", "error"));
            }
        )(campaign)

    }
    const handleClose = () => {
        dispatch(updateOpenDialog({
            dialog: "ChangeCampaignDates",
            value: false,
            row: null
        }))

    }
    const onEndChange = date => {
        setCampaignEnd(date);
    }
    const onStarChange =  date => {
        setCampaignStart(date);
    }
    return <div>
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle >Change campaign dates</DialogTitle>
            <DialogContent style={{minWidth: 400}}>
                <FormControl component="fieldset" style={{marginTop: 15}}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <DatePicker
                                    format="MM/DD/yyyy"
                                    label="Start date"
                                    size="small"
                                    clearable
                                    inputVariant="outlined"
                                    value={campaignStart}
                                    onChange={onStarChange}/>
                            </Grid>
                            <Grid item md={6}>
                                <DatePicker
                                    minDate={campaignStart}
                                    clearable
                                    size="small"
                                    format="MM/DD/yyyy"
                                    label="End date"
                                    inputVariant="outlined"
                                    value={campaignEnd}
                                    onChange={onEndChange}/>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </FormControl>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button autoFocus onClick={handleClose} variant="contained">

                    Cancel

                </Button>
                <Button onClick={onConfirmEvent} style={{width: "98px"}} color="primary"
                        autoFocus variant="contained">
                    Confirm
                </Button>
            </DialogActions>
            {isSaving && <LinearProgress/>}
        </Dialog>
    </div>;
}

export default ChangeCampaignDates;