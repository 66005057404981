import axios from 'axios';
import jsonpAdapter from "axios-jsonp";
import sentry from './sentry';

const request = {

    jsonp: url => async (onSuccess, onError) => {
        try {
            await axios({
                url: url,
                adapter: jsonpAdapter,
            }).then((response) => {
                return onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                return onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            return Promise.reject(onError(error));
        }
    },

    head: url => async (onSuccess, onError) => {
        try {
            await axios.head(url).then((response) => {
                return onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                return onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            return Promise.reject(onError(error));
        }
    },

    put: url => (onSuccess, onError) => async data => {
        try {
            return await axios({
                url: url,
                method: 'put',
                data,
                withCredentials: true,
            }).then((response) => {
                return onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                return onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            return Promise.reject(onError(error));
        }
    },

    post: (url, headers=null, withCredentials = true) => (onSuccess, onError) => async data => {
        try {
            return await axios({
                url: url,
                method: 'post',
                data,
                withCredentials: withCredentials,
                headers,
            }).then((response) => {
                return onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                return onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            return Promise.reject(onError(error));
        }
    },

    postFormData: url => (
        onSuccess,
        onError,
        onProgress,
        setCancel
    ) => async (formData) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (typeof setCancel === "function") {
            setCancel(source.cancel);
        }
        try {
            await axios({
                method: "post",
                url: url,
                withCredentials: true,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: onProgress,
                cancelToken: source.token
            }).then((response) => {
                return onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                return onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            return Promise.reject(onError(error));
        }
    },

    patch: url => (onSuccess, onError) => async data => {
        try {
            return await axios({
                url: url,
                method: 'patch',
                data,
                withCredentials: true,
            }).then((response) => {
                return onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                return onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            return Promise.reject(onError(error));
        }
    },

    delete: (url, headers= null, withCredentials= true) => (onSuccess, onError) => async data => {
        try {
            await axios({
                url: url,
                method: 'delete',
                data,
                withCredentials: withCredentials,
                headers
            }).then((response) => {
                return onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                return onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            return Promise.reject(onError(error));
        }
    },


    get: (url, headers) => async (onSuccess, onError) => {
        try {
            return await axios({
                url: url,
                method: 'get',
                withCredentials:true,
                headers,
            }).then((response) => {
                return onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                return onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            return Promise.reject(onError(error));
        }
    },
    getWithNoCredentials: (url, headers) => async (onSuccess, onError) => {
        try {
            return await axios({
                url: url,
                method: 'get',
                withCredentials:false,
                headers,
            }).then((response) => {
                return onSuccess(response);
            }).catch((error) => {
                sentry.captureAxiosException(error);
                return onError(error);
            });
        } catch (error) {
            sentry.captureException(error);
            return Promise.reject(onError(error));
        }
    },
    downloadCSV: async (url, filename, headers) => {
        try {
            const response = await axios({
                url: url,
                method: "GET",
                responseType: "blob",
                withCredentials: true,
                headers
            });
            const uri = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = uri;
            link.setAttribute("download", `${filename ? filename : "file"}.csv`);
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            console.log("Error Downloading File", e)
        }
    }

}

export default request;