import {Divider, Menu, MenuItem} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, {Fragment} from "react";

const UserRolesTableRowMenu = ({onClickChangeStatus, onClickRemove, userRole}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleMenuItemClick = (callback) => () => {
        setAnchorEl(null);
        callback();
    }

    return (
        <Fragment>
            <IconButton onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {
                    userRole?.role?.role_id === 2 &&
                    <MenuItem key={"details"} onClick={handleMenuItemClick(onClickChangeStatus)}>Change
                        status
                    </MenuItem>
                }
                {userRole?.role?.role_id === 2 && <Divider/>}
                <MenuItem key={"delete"} onClick={handleMenuItemClick(onClickRemove)}>Delete</MenuItem>
            </Menu>
        </Fragment>
    )
}

export default UserRolesTableRowMenu;