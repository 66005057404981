import {Menu, MenuItem} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, {Fragment} from "react";

const CategoriesTableRowMenu = ({onClickEdit, onClickDelete}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleMenuItemClick = (callback) => () => {
        setAnchorEl(null);
        callback();
    }

    return (
        <Fragment>
            <IconButton onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem key={"edit"} onClick={handleMenuItemClick(onClickEdit)}>Edit</MenuItem>
                <MenuItem key={"delete"} onClick={handleMenuItemClick(onClickDelete)}>Delete</MenuItem>
            </Menu>
        </Fragment>
    )
}

export default CategoriesTableRowMenu;