import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LinearProgress, makeStyles, Typography} from '@material-ui/core';
import {useDispatch} from "react-redux";
import api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";

const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(3)
    }
}))

const CampaignCreatorManualSocialDataDeleteDialog = ({isOpen, onClose, entry, onSaveSuccess}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState(false);

    const onConfirmDelete = () => {
        setInProgress(true);
        api.social.deleteManualSocialMetrics(entry.post_id, entry.manual_entry_id, entry.client_id)(
            () => onSaveSuccess(),
            () => dispatch(setNotification("Something went wrong", "error"))
        )().then(() => {
            setInProgress(false);
        })
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Delete social post?</DialogTitle>
            <DialogContent style={{minWidth: 400}}>
                <Typography>
                    This will remove the social data entry and the data will no longer appear in reports..
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={onClose} variant="contained" disabled={inProgress}>
                    Cancel
                </Button>

                <Button onClick={onConfirmDelete} style={{width: "98px", backgroundColor: "#e01a2b", color: "#fff"}}
                        variant="contained" disabled={inProgress}>
                    Delete
                </Button>
            </DialogActions>
            {inProgress && <LinearProgress/>}
        </Dialog>
    );
}

export default CampaignCreatorManualSocialDataDeleteDialog;
