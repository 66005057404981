import React, {useCallback, useEffect, useState} from "react";
import {Checkbox, Dialog, DialogActions, DialogTitle, FormControlLabel, MenuItem, TextField} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Api from "../../../helpers/api";
import RequestError from "../../../helpers/RequestError";
import ResponseStatus from "../../../helpers/ResponseStatus";
import {isEmpty} from "../../../helpers/utils";
import {useDispatch} from "react-redux";
import {setNotification} from "../../../slices/notification";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";

const CampaignCreatorPostDialog = ({isOpen, onClose, onSaveSuccess, gig, post}) => {
    const dispatch = useDispatch();
    const [isMetadataInitialized, setIsMetadataInitialized] = useState(null);
    const [metadata, setMetadata] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [platform, setPlatform] = useState("");
    const [postType, setPostType] = useState("");
    const [postLink, setPostLink] = useState("");
    const [postDate, setPostDate] = useState(null);
    const [boosted, setBoosted] = useState(false);

    const onError = useCallback((error) => {
        dispatch(setNotification((new RequestError(error)).getMessage(), "error"))
    }, [dispatch]);

    useEffect(() => {
        if (post && isOpen) {
            setPlatform(post.platform);
            setPostType(post?.post_type?.code ?? "" );
            setPostLink(post.url);
            setPostDate(moment(post.date));
            setBoosted(post.boosted ?? false);
        } else if (!isOpen) {
            setPlatform("");
            setPostType("");
            setPostLink("");
            setPostDate(null);
            setBoosted(false);
            setInProgress(false);
            setShowErrors(false);
        }
    }, [post, isOpen]);

    useEffect(() => {
        if (isMetadataInitialized === null) {
            setInProgress(true);
            setIsMetadataInitialized(false);
            Api.social.getManualSocialMetadata()(
                ({data}) => {
                    setMetadata(data);
                    setIsMetadataInitialized(true);
                },
                onError
            ).then(() => {
                setInProgress(false);
            });
        }
    }, [isMetadataInitialized, onError]);
    
    const onClickSave = () => {
        if (isEmpty(platform) || isEmpty(postType) || isEmpty(postLink) || isEmpty(postDate)) {
            setShowErrors(true);
            dispatch(setNotification("Please fix the error(s) highlighted in red.", "error"));
            return;
        }
        setShowErrors(false);
        setInProgress(true);
        if (post) {
            onUpdate().then(() => setInProgress(false));
        } else {
            onCreate().then(() => setInProgress(false));
        }
    }

    const onCreate = () => {
        return Api.social.addManualPost(gig.campaign.brand.id)(
            onResponse, onError
        )({
            platform,
            "url": postLink,
            "creator_id": gig.user.id,
            "campaign_id": gig.campaign.id,
            "creation_date": postDate.toISOString(),
            "post_type": postType,
            boosted,
            metrics: {
                impressions: 0,
                shares: 0,
                video_views: 0,
                comments: 0,
                likes: 0,
                saves: 0,
            }
        });
    }

    const onUpdate = () => {
        return Api.social.updateManualPost(post.post_id, gig.campaign.brand.id)(
            onResponse, onError
        )({
            ...post,
            platform,
            "url": postLink,
            "creation_date": postDate.toISOString(),
            "post_type": postType,
            boosted
        });
    }

    const onResponse = (response) => {
        const respStatus = new ResponseStatus(response);
        if (respStatus.isError()) {
            dispatch(setNotification(respStatus.getErrorMessage(), "error"));
        } else {
            onSaveSuccess();
        }
    }

    const onChangePlatform = (newPlatform) => {
        setPlatform(newPlatform);
        setPostType("");
    }

    const getPostTypesForPlatform = (platform) => {
        const platforms = Array.isArray(metadata?.enums?.platforms) ? metadata.enums.platforms : [];
        return platforms.find(p => p.code === platform)?.post_types ?? [];
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                {post ? `Edit social post` : `Add a social post`}
            </DialogTitle>
            <Box px={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            disabled={inProgress}
                            value={platform}
                            error={!platform && showErrors}
                            fullWidth select label={'Social platform'}
                            placeholder={'Choose a social platform...'}
                            onChange={(event) => onChangePlatform(event.target.value)}>
                            <MenuItem value={"facebook"}>
                                Facebook
                            </MenuItem>
                            <MenuItem value={"instagram"}>
                                Instagram
                            </MenuItem>
                            <MenuItem value={"tiktok"}>
                                TikTok
                            </MenuItem>
                            <MenuItem value={"youtube"}>
                                YouTube
                            </MenuItem>
                            <MenuItem value={"twitter"}>
                                Twitter
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={postType}
                            error={!postType && showErrors}
                            fullWidth select
                            label={isEmpty(platform) ? 'Choose a social platform first...' : 'Post type'}
                            placeholder={'Choose a post type...'}
                            onChange={(event) => setPostType(event.target.value)}
                            disabled={isEmpty(platform) || inProgress}>
                            {getPostTypesForPlatform(platform).map((item) => (
                                <MenuItem value={item.code} key={item.code}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={inProgress}
                            error={!postLink && showErrors}
                            value={postLink}
                            fullWidth label={'Social post link'}
                            placeholder={'Enter the url to the social post...'}
                            onChange={(event) => setPostLink(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                disabled={inProgress}
                                format={"MM/DD/yyyy"}
                                maxDate={moment()}
                                fullWidth
                                error={!postDate && showErrors}
                                value={postDate}
                                onChange={setPostDate}
                                label={'Creation date'}
                                placeholder={'Enter the post creation date...'}/>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <FormControlLabel
                        control={
                            <Checkbox
                            checked={boosted}
                            onChange={(event) => setBoosted(event.target.checked)}
                            disabled={inProgress}
                            color="primary"
                            />
                        }
                        label="Mark as boosted"
                        />
                    </Grid>
                </Grid>
            </Box>
            <DialogActions>
                <Box width={"100%"} p={1.5}>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item xs={3}>
                            <Button variant="outlined" onClick={onClose} fullWidth>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button type="submit" color="primary" variant="contained"
                                    disabled={inProgress}
                                    onClick={onClickSave} fullWidth>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogActions>
            {inProgress && <LinearProgress/>}
        </Dialog>
    )
}

export default CampaignCreatorPostDialog;
