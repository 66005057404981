import React from 'react';
import {makeStyles, TableCell, TableHead, TableRow} from "@material-ui/core";
const useStyles =  makeStyles({
    noWrapText:{
        whiteSpace: 'nowrap'
    }
})
const CampaignAdminTableHead = ({disableMenu}) =>{
    const classes = useStyles();
    return <TableHead>
        <TableRow>
            <TableCell>
                Client
            </TableCell>
            <TableCell>
                Campaign
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Reference number
            </TableCell>
            <TableCell>
                Visibility
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Product delivery
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Campaign amount
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Seeding service
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Seeding amount
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Seeding block
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Credit block
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Start date
            </TableCell>
            <TableCell className={classes.noWrapText}>
                End date
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Created date
            </TableCell>
            <TableCell>
                Status
            </TableCell>
            { disableMenu === false && <TableCell>
                {/*menu column*/}
            </TableCell>}
        </TableRow>
    </TableHead>
}

CampaignAdminTableHead.defaultProps = {
    disableMenu: false
}

export default CampaignAdminTableHead;