import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LinearProgress, makeStyles, Typography} from '@material-ui/core';
import {useDispatch} from "react-redux";
import Client from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";


const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(3)
    }

}))

const CampaignCreatorManualSocialDataDeleteDialog = ({open, onClose, selected, gigToRender, onSaveSuccess}) => {

    const [isSaving, setIsSaving] = useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const onConfirmEvent = () => {

        Client.social.deleteManualSocialData(selected.post_id, gigToRender.campaign.brand.id)(
            response => {
                setIsSaving(false);
                onSaveSuccess();
            },
            error => {
                dispatch(setNotification("Something went wrong", "error"));
            }
        )({client_id:gigToRender.campaign.brand.id})
    }
    const handleClose = () => {
        onClose();
    }
    return <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle >Delete social post?</DialogTitle>
            <DialogContent style={{minWidth: 400}}>
                <Typography>
                    By deleting this social post, it will be removed and cannot be undone.
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button autoFocus onClick={handleClose} variant="contained">

                    Cancel

                </Button>

                <Button onClick={onConfirmEvent} style={{width: "98px", backgroundColor:"#e01a2b", color:"#fff"}} color="error.main"
                        autoFocus variant="contained">
                    Delete
                </Button>
            </DialogActions>
            {isSaving && <LinearProgress/>}
        </Dialog>
    </div>;
}

export default CampaignCreatorManualSocialDataDeleteDialog;