import moment from "moment";

const dates = {
    formatFromString: (dateString, format = "MM/DD/YYYY HH:mm:ss") => {
        return dateString ? moment.utc(dateString).local().format(format) : '-';
    },
    formatFromStringToDate: (dateString, format = "MM/DD/YYYY") => {
      return dateString ? moment.utc(dateString).local().format(format) : '-';
    },
    getMomentFromString: (dateString) => {
        return moment.utc(dateString).local();
    },
    getDaysDiffFromString: (dateStringStart, dateStringEnd) => {
        return moment.utc(dateStringEnd).diff(moment.utc(dateStringStart), "days");
    },
    formatInvoicePeriod: (period, format = "MMMM, YYYY") => {
        if (period) {
            const year = Math.floor(period/100);
            const month = Math.floor(period - year*100);
            return moment(`${year}-${month}-01`, "YYYY-MM-DD").format(format);
        } else {
            return "-";
        }
    },
}

export default dates;