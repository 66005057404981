import React, {useCallback, useEffect, useState} from "react";
import {Box, Dialog, LinearProgress, makeStyles} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {numbers} from "../../helpers/utils";
import api from "../../helpers/api";
import {setNotification} from "../../slices/notification";
import {useDispatch, useSelector} from "react-redux";
import {plansSelector} from "../../slices/plans";

const initialValues = {
    "name": "",
    "views_included": "",
    "enabled": true,
    "additional_view_pennies": "",
    "team_spot_price_pennies": "",
    "campaign_fee_ratio": "",
    "base_price_pennies": "",
    "team_spots_included": "",
    "default": false,
    "team_enabled": false,
    "site_enabled": false,
    "social_enabled": false,
    "campaign_fee_minimum_pennies": ""
};

const validateExtra = ['team_spots_included', 'team_spot_price_pennies'];
const validateExtraSite = ['views_included', 'additional_view_pennies'];
const validateExtraSocial = ['campaign_fee_ratio', 'campaign_fee_minimum_pennies'];

const useStyles = makeStyles(theme=>({
    delete:{
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
    },
    deleteDialog:{
        backgroundColor: theme.palette.error.main,
        color: '#ffffff',
        "&:hover":{
            backgroundColor: theme.palette.error.main,
        }
    }
}));

const AddPlanDialog = ({open, onClose, toEdit}) =>{
    const [isSaving, setIsSaving] = useState(false);
    const [isDefault, setIsDefault] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [values, setValues] = useState(initialValues);
    const [openDelete, setOpenDelete] = useState(false);
    const [errors, setErrors] = useState({});
    const [saveEnabled, setSaveEnabled] = useState(false);
    const dispatch = useDispatch();

    const {plans} = useSelector(plansSelector);

    const classes = useStyles();

    const checkSaveEnable = useCallback((newValues) =>{

        let toEvaluate = [];
        if(newValues.team_enabled === true){
            toEvaluate = [...toEvaluate, ...validateExtra];
        }
        if(newValues.social_enabled === true){
            toEvaluate = [...toEvaluate, ...validateExtraSocial];
        }
        if(newValues.site_enabled === true){
            toEvaluate = [...toEvaluate, ...validateExtraSite];
        }
        const empty = toEvaluate.filter(field=>(newValues[field] && newValues[field] > 0));

        if(newValues.name.trim()===""){
            setSaveEnabled(false);
            return;
        }
        const ENABLE = empty.length === toEvaluate.length && Object.keys(errors).length === 0;
        setSaveEnabled(ENABLE);
    },[errors])

    useEffect(() => {
        if(!initialized){
            setInitialized(true);
            if(toEdit){
                setValues(toEdit);
                if(toEdit?.default === true){
                    setIsDefault(toEdit.plan_id);
                }
                checkSaveEnable(toEdit);
            }else{
                setIsDefault(null);
            }
        }
    }, [initialized, toEdit, checkSaveEnable]);

    const onConvertedMoneyFieldChange = (event, value, convertToPennies=true) =>{
        const {name} = event.target;
        const newValues = {...values};
        newValues[name] = convertToPennies ? numbers.dollarsToPennies(value) : value;
        setValues(newValues);
        if(!value && name !== 'base_price_pennies'){
            const newErrors = {...errors};
            newErrors[name] = true;
            setErrors(newErrors);
        }else{
            const newErrors = {...errors};
            delete newErrors[name];
            setErrors(newErrors);
        }

        checkSaveEnable(newValues);

    }

    const onSwitchFieldChange = (event) =>{
        const {name, checked} = event.target;
        const newValues = {...values};
        newValues[name] = checked;
        setValues(newValues);
        if(name === 'team_enabled' && checked === false){
            const newErrors = {...errors};
            delete newErrors['team_spots_included'];
            delete newErrors['team_spot_price_pennies'];
            setErrors(newErrors);
        }

        checkSaveEnable(newValues);

    }

    const saveAction = () =>{
        const toSaveObject = {...toEdit, ...values};
        setIsSaving(true);
        if(toSaveObject.team_enabled === false){
            toSaveObject['team_spots_included'] = toSaveObject['team_spots_included'] ? toSaveObject['team_spots_included'] :  0;
            toSaveObject['team_spot_price_pennies'] = toSaveObject['team_spot_price_pennies'] ? toSaveObject['team_spot_price_pennies'] : 0;
        }
        if(!toSaveObject?.base_price_pennies){
            toSaveObject['base_price_pennies'] = 0;
        }
        if(toEdit){
            api.backoffice.plans.updatePlan(toEdit.plan_id)(
                response => {
                    setIsSaving(false);
                    onClose(true);
                },
                error =>{

                }
            )(toSaveObject)
        }else{
            api.backoffice.plans.createPlan()(
                response => {
                    setIsSaving(false);
                    onClose(true);
                },
                error =>{

                }
            )({...toSaveObject, plan_id: 0});
        }
    }

    const deleteAction = () =>{
        const otherPlans = plans.filter(f=> f.plan_id !== isDefault);
        const checkDefault = otherPlans.find(f=>f.default === true);
        if(!checkDefault && isDefault){
            dispatch(setNotification('Please create or set another plan as default before delete this.', 'error'))
            return;
        }
        if(toEdit){
            setIsSaving(true);
            api.backoffice.plans.deletePlan(toEdit.plan_id)(
                response => {
                    dispatch(setNotification('Plan deleted successfully'))
                    setIsSaving(false);
                    onClose(true);
                },
                error =>{

                }
            )()
        }
    }

    const onNameChange=  event =>{
        const {value} = event.target;
        const newValues = {...values};
        newValues.name =  value;
        setValues(newValues);

        if(!value){
            const newErrors = {...errors};
            newErrors.name = true;
            setErrors(newErrors);
        }else{
            const newErrors = {...errors};
            delete newErrors.name;
            setErrors(newErrors);
        }

        checkSaveEnable(newValues);

    }



    const onMonthlyFeeChange = (event, value) =>{
        const {name} = event.target;
        const newValues = {...values};
        newValues[name] =numbers.dollarsToPennies(value);
        setValues(newValues);
        const inputValue = event.target.value;

        if(inputValue.trim() === ""){
            const newErrors = {...errors};
            newErrors[name] = true;
            setErrors(newErrors);
        }else{
            const newErrors = {...errors};
            delete newErrors[name];
            setErrors(newErrors);
        }
        checkSaveEnable(newValues);
    }
    return <Dialog open={open} maxWidth={'xs'} fullWidth data-testId={'dialog-add-edit-plan'}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} px={2}>
            <Box py={1}>
                <Typography>{!toEdit ? "Add" :  "Edit"} a plan</Typography>
            </Box>
            <IconButton data-testId={'dialog-plan-close-icon'} onClick={onClose} disableRipple>
                <CloseIcon />
            </IconButton>
        </Box>
        <Divider />
        <Box px={2} pb={2}>
            <TextField
                error={errors?.name === true}
                value={values.name}
                name={'name'}
                size={'small'}
                variant={'outlined'}
                onChange={onNameChange}
                fullWidth
                margin={'normal'}
                label={'Plan name'} />
            <CurrencyTextField margin="normal"
                               name={'base_price_pennies'}
                               data-testId={'base_price_pennies'}
                               textAlign="left"
                               fullWidth
                               label="Monthly plan fee"
                               placeholder="Monthly plan fee"
                               decimalPlaces={2}
                               error={errors?.base_price_pennies === true}
                               currencySymbol={"$"}
                               onChange={(e, value)=>onMonthlyFeeChange(e, value)}
                               value={values.base_price_pennies ? numbers.penniesToDollars(values.base_price_pennies) : 0}
                               variant="outlined" size="small" />
            <Box>
                <FormControlLabel control={<Switch checked={values.default} name={'default'} onChange={onSwitchFieldChange} />} label="Set as default plan" />
            </Box>
            <Box>
                <FormControlLabel control={<Switch checked={values.enabled} name={'enabled'} onChange={onSwitchFieldChange} defaultChecked />} label="Plan enabled" />
            </Box>

            <Typography variant={'subtitle2'} gutterBottom={false}>Site</Typography>
            <FormControlLabel control={<Switch data-testId={'site-enabled-switch'} checked={values.site_enabled} name={'site_enabled'} onChange={onSwitchFieldChange} />} label="Enabled for this plan" />
            <CurrencyTextField margin="normal"
                               name={'views_included'}
                               textAlign="left"
                               fullWidth
                               disabled={!values.site_enabled}
                               label="Included views"
                               placeholder="Included views"
                               decimalPlaces={0}
                               currencySymbol={null}
                               onChange={(e, value)=>onConvertedMoneyFieldChange(e, value, false)}
                               value={values.views_included}
                               variant="outlined" size="small" />
            <CurrencyTextField margin="normal"
                               name={'additional_view_pennies'}
                               textAlign="left"
                               fullWidth
                               disabled={!values.site_enabled}
                               label="Cost per additional view"
                               decimalPlaces={2}
                               currencySymbol={"$"}
                               onChange={(e, value)=>onConvertedMoneyFieldChange(e, value)}
                               value={values.additional_view_pennies ? numbers.penniesToDollars(values.additional_view_pennies) : ""}
                               variant="outlined" size="small" />


            <Typography variant={'subtitle2'} gutterBottom={false}>Influence</Typography>
            <FormControlLabel control={<Switch data-testId={'social-enabled-switch'} checked={values.social_enabled} name={'social_enabled'} onChange={onSwitchFieldChange} />} label="Enabled for this plan" />
            <CurrencyTextField margin="normal"
                               name={'campaign_fee_ratio'}
                               textAlign="left"
                               fullWidth
                               disabled={!values.social_enabled}
                               label="Service fee"
                               placeholder="Service fee"
                               decimalPlaces={0}
                               currencySymbol={"%"}
                               onChange={(e, value)=>onConvertedMoneyFieldChange(e, value / 100, false)}
                               value={values.campaign_fee_ratio ? values.campaign_fee_ratio * 100 : ""}
                               variant="outlined" size="small" />
            <CurrencyTextField margin="normal"
                               name={'campaign_fee_minimum_pennies'}
                               textAlign="left"
                               fullWidth
                               disabled={!values.social_enabled}
                               label="Minimum amount per creator"
                               placeholder="Minimum amount per creator"
                               decimalPlaces={2}
                               currencySymbol={"$"}
                               onChange={(e, value)=>onConvertedMoneyFieldChange(e, value)}
                               value={values.campaign_fee_minimum_pennies ? numbers.penniesToDollars(values.campaign_fee_minimum_pennies) : ""}
                               variant="outlined" size="small" />

            <Typography variant={'subtitle2'} gutterBottom={false}>Team</Typography>
            <FormControlLabel control={<Switch checked={values.team_enabled} data-testId={'team-enabled-switch'} name={'team_enabled'} onChange={onSwitchFieldChange} />} label="Enabled for this plan" />
            <CurrencyTextField margin="normal"
                               name={'team_spots_included'}
                               textAlign="left"
                               fullWidth
                               disabled={!values.team_enabled}
                               label="Included seats"
                               placeholder="Included seats"
                               decimalPlaces={0}
                               currencySymbol={null}
                               onChange={(e, value)=>onConvertedMoneyFieldChange(e, value, false)}
                               value={values.team_spots_included}
                               variant="outlined" size="small" />
            <CurrencyTextField margin="normal"
                               name={'team_spot_price_pennies'}
                               textAlign="left"
                               fullWidth
                               disabled={!values.team_enabled}
                               label="Cost per additional seat"
                               placeholder="Cost per additional seat"
                               decimalPlaces={2}
                               currencySymbol={"$"}
                               onChange={(e, value)=>onConvertedMoneyFieldChange(e, value)}
                               value={values.team_spot_price_pennies ? numbers.penniesToDollars(values.team_spot_price_pennies) : ""}
                               variant="outlined" size="small" />


        </Box>
        <Divider />
        <Box p={2}>
            <Grid container spacing={2} justify={'flex-end'}>
                <Grid item xs={12} md={6}>
                    <Button className={classes.delete} data-testId={'dialog-plan-action-delete'} onClick={()=>setOpenDelete(true)} disabled={(isSaving || (values.default && toEdit) || !toEdit)} variant={'outlined'}>
                        Delete
                    </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button data-testId={'dialog-plan-action-cancel'} onClick={onClose} disabled={isSaving} variant={'contained'} fullWidth>
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button data-testId={'dialog-plan-action-save'} disabled={isSaving || !saveEnabled} color={'primary'} variant={'contained'} onClick={saveAction} fullWidth>
                        {toEdit ? "Save" : "Create"}
                    </Button>
                </Grid>
            </Grid>
            {isSaving && <LinearProgress/>}
        </Box>
        <Dialog open={openDelete} maxWidth={'xs'} fullWidth>
            <Box px={2} pt={2}>
                <Typography>Delete plan</Typography>
            </Box>
            <Box p={2} >
                <Typography>
                    If you delete this plan, it cannot be recovered.
                </Typography>
            </Box>
            <Box p={2}>
                <Grid container spacing={2} justify={'flex-end'}>
                    <Grid item xs={12} md={3}>
                        <Button data-testId={'dialog-delete-plan-action-cancel'} onClick={()=>setOpenDelete(false)} disabled={isSaving} variant={'contained'} fullWidth>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button className={classes.deleteDialog} data-testId={'dialog-delete-plan-action-delete'} disabled={isSaving} color={'primary'} variant={'contained'} onClick={deleteAction} fullWidth>
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            {isSaving && <LinearProgress/>}
        </Dialog>
    </Dialog>
}

AddPlanDialog.defaultProps = {
    onClose: () =>{

    },
    open: false,
    toEdit: null
}

export default AddPlanDialog;