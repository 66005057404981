import Link from "@material-ui/core/Link";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, {useState} from "react";
import {isEmpty, strings} from "../../../helpers/utils";
import {Avatar, Box} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import moment from "moment";
import ChatAvatar from "../../Chat/ChatAvatar";

const formatDate = (dateString) => {
    if (!isEmpty(dateString)) {
        return moment.utc(dateString).format("M/D/yyyy h:mm:ss a")
    } else {
        return "--";
    }
}

const ChatsTableRow = ({chat, onClick}) => {
    const theme = useTheme();
    const [isHover, setIsHover] = useState(false);
    
    const {
        profile_image_url = "",
        brand_name = "",
        brand_logo_url = "",
        full_name = "",
    } = chat?.brand_chat_details || {};

    const getCreatorName = () => full_name || "--";
    const getBrandName = () => brand_name || "--";
    const isUnread = chat?.support_chat_details?.unread;

    return (
        <TableRow
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={() => onClick(chat)}
            hover
            style={{
                backgroundColor: isUnread
                    ? theme.palette.action.hover
                    : "inherit",
                fontWeight: isUnread ? "bold" : "normal",
                cursor: "pointer",
            }}>
            <TableCell align="left">
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        width: "100%",
                    }}>
                    {strings.stringOrDash(formatDate(chat?.last_message?.time))}
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                    }}>
                    {brand_logo_url ? (
                        <Avatar src={brand_logo_url} />
                    ) : (
                        <ChatAvatar
                            chatDetails={{ full_name: getBrandName() }}
                        />
                    )}
                    <Box sx={{ marginLeft: theme.spacing(1) }}>{getBrandName()}</Box>
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                    }}>
                    {profile_image_url ? (
                        <Avatar src={profile_image_url} />
                    ) : (
                        <ChatAvatar
                            chatDetails={{ full_name: getCreatorName() }}
                        />
                    )}
                    <Box sx={{ marginLeft: theme.spacing(1) }}>
                        {getCreatorName()}
                    </Box>
                </Box>
            </TableCell>
            <TableCell
                align="left"
                style={{ whiteSpace: "nowrap", width: "200px" }}>
                {strings.stringOrDash(formatDate(chat.creation_time))}
            </TableCell>
            <TableCell align="center" style={{ width: "110px" }}>
                {isHover && (
                    <Link
                        onClick={() => onClick(chat)}
                        component={"button"}
                        style={{ whiteSpace: "nowrap" }}>
                        View chat →
                    </Link>
                )}
            </TableCell>
        </TableRow>
    );
}

export default ChatsTableRow;