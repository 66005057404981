import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
    AppBar,
    Box,
    Container,
    Divider,
    IconButton,
    Paper,
    TablePagination,
    Toolbar,
    Typography
} from "@material-ui/core";
import {CloseOutlined} from "@material-ui/icons";
import {
    campaignAdminSelector,
    getCampaignAdminSeedingList,
    updateOpenDialog,
    updateSection
} from "../../../slices/campaignAdmin";
import {useDispatch, useSelector} from "react-redux";
import SearchInput from "../../SearchInput";
import CampaignAdminSeedingTable from "../CampaignAdminSeeding/CampaignAdminSeedingTable";


const CampaignAdminSeedingDialog = ({open}) => {

    const [initialized, setInitialized] = useState(false);
    const dispatch = useDispatch();
    const {row, campaignSeeding} = useSelector(campaignAdminSelector);
    const {total, items, smartFilter, page, per_page} = campaignSeeding;
    useEffect(()=>{
        if(!initialized){
            setInitialized(true);
            dispatch(updateSection("campaignSeeding", {campaignId: row.id}));
            dispatch(getCampaignAdminSeedingList({...campaignSeeding, campaignId: row.id}));
        }
    },[initialized, dispatch, campaignSeeding, row])
    const handleClose = () => {
        dispatch(updateOpenDialog({
            dialog: "CampaignAdminSeeding",
            value: false,
            row: null
        }))
    }

    const handleChangePage = (event, page) => {
        dispatch(updateSection("campaignSeeding", {page: page}));
        dispatch(getCampaignAdminSeedingList({...campaignSeeding, page: page}));
    }

    const handleChangeRowsPerPage = event => {
        const {value} = event.target;
        dispatch(updateSection("campaignSeeding", {per_page: value, page:0}));
        dispatch(getCampaignAdminSeedingList({...campaignSeeding, page: 0, per_page: value}));
    }

    const onChangeSearchTerm = value=>{
        dispatch(updateSection("campaignSeeding", {smartFilter: value, page:0}));
        dispatch(getCampaignAdminSeedingList({...campaignSeeding, page: 0, smartFilter: value}));
    }
    return <div>
        <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar position={"relative"}>
                <Toolbar>
                    <IconButton size={'small'} onClick={handleClose}><CloseOutlined htmlColor={"#fff"} /></IconButton> <Typography>Seeding</Typography>
                </Toolbar>
            </AppBar>
            <Box bgcolor={"#f5f5f5"} height={"100%"} p={2}>
                <Container maxWidth={'lg'}>
                    <Paper>
                        <Box px={2}>
                            <Toolbar disableGutters>
                                <Box flexGrow={1} justifyContent={"space-between"} display={"flex"}>
                                    <SearchInput value={smartFilter} onChange={(e) => onChangeSearchTerm(e.target.value)}/>
                                </Box>
                            </Toolbar>
                        </Box>
                        <Divider/>
                        <CampaignAdminSeedingTable items={items}/>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={total}
                            rowsPerPage={per_page}
                            page={page}
                            onChangePage={handleChangePage}
                            // onPageChange={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Container>
            </Box>
        </Dialog>
    </div>;
}

export default CampaignAdminSeedingDialog;