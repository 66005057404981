import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import {Delete, Edit} from "@material-ui/icons";
import React from "react";
import CurrencyFormat from "react-currency-format";

const InvoiceLineItemTableRow = ({item, onClickEdit, onClickRemove, isReadOnly}) => {
    return (
        <TableRow>
            <TableCell align="left">
                <Typography variant={"body2"} component={"p"}>
                    {item.title}
                </Typography>
                <Typography variant={"caption"} component={"p"} color={"textSecondary"}>
                    {item.notes}
                </Typography>
            </TableCell>
            <TableCell align="left">
                {item.invoice_item_type_label ?? item.invoice_item_type}
            </TableCell>
            <TableCell align="left">
                <CurrencyFormat value={item.unit_price_pennies / 100} thousandSeparator decimalScale={2}
                                 fixedDecimalScale displayType={"text"}/>
            </TableCell>
            <TableCell align="left">
                <CurrencyFormat value={item.quantity} thousandSeparator displayType={"text"}/>
            </TableCell>
            <TableCell align="left">
                <CurrencyFormat value={item.unit_price_pennies * item.quantity / 100} thousandSeparator
                                 decimalScale={2} fixedDecimalScale displayType={"text"}/>
            </TableCell>
            {
                !isReadOnly &&
                <TableCell>
                    <IconButton onClick={onClickEdit}>
                        <Edit/>
                    </IconButton>
                    <IconButton onClick={onClickRemove}>
                        <Delete/>
                    </IconButton>
                </TableCell>
            }
        </TableRow>
    );
}

export default InvoiceLineItemTableRow;