import {FormControl, FormHelperText} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {useEffect, useState} from "react";
import {isEmpty} from "../../helpers/utils";

const getRoles = () => ([
    {label: "Admin", role_id: 1},
    {label: "Super admin", role_id: 3}
]);

const RoleSelect = ({onChange, disabled, error, helperText, value}) => {
    const [internalValue, setInternalValue] = useState(null);

    useEffect(() => {
        if (value?.role_id) {
            setInternalValue(value);
        }
    }, [value]);

    const onInternalChange = (event, newValue) => {
        setInternalValue(newValue);
        onChange(newValue);
    }

    return (
        <FormControl error={error} fullWidth size={"small"} margin={"normal"}>
            <Autocomplete
                size="small"
                value={internalValue}
                options={getRoles()}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => value && option.role_id === value.role_id}
                renderInput={(params) => (
                    <TextField {...params} error={error} required
                               variant="outlined" disabled={disabled}
                               size="small" label={"Role"} placeholder={"Search"}/>
                )}
                onChange={onInternalChange}
            />
            {!isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

export default RoleSelect;