import TableCell from "@material-ui/core/TableCell";
import {Checkbox, IconButton, Menu, MenuItem, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import dates from "../../../helpers/dates";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TableRow from "@material-ui/core/TableRow";
import React, {useEffect, useState} from "react";
import CurrencyDisplay from "../../CurrencyDisplay";
import {useTheme} from "@material-ui/styles";
import api from "../../../helpers/api";

const PaymentApproveTableRow = ({payment, isSelected, onSelect, onAprove, onReject}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [initialized, setInitialized] = useState(false);
    const [clientName, setClientName] = useState("--");
    useEffect(()=>{
        if(!initialized){
            setInitialized(true);
            api.getClient(payment.client_id)(
                response => {
                    setClientName(response?.data?.name || "--")
                },
                error => {

                }
            )
        }
    },[initialized, payment])
    const theme = useTheme();
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleApprove= () => {
        handleClose();
        onAprove(payment);
    }
    const handleReject= () => {
        handleClose();
        onReject(payment);
    }
    return <TableRow key={`${payment.item_id}`}
                     style={{
                         backgroundColor: isSelected ? theme.palette.primary.light : "transparent"
                     }}>
        <TableCell padding={"checkbox"}>
            <Checkbox
                onChange={() => onSelect(payment)}
                checked={isSelected}
            />
        </TableCell>
        <TableCell align="left">
            <Box>
                <Typography variant={"body2"}>
                    {payment.title}
                </Typography>
                <Typography variant={"caption"}>
                    {payment.description}
                </Typography>
            </Box>
        </TableCell>
        <TableCell align="left">{clientName}</TableCell>
        <TableCell align="left">{payment.client_id}</TableCell>
        <TableCell>
            {payment.item_type === "ADJUSTMENT" ? "GENERAL" : payment.item_type}
        </TableCell>
        <TableCell align="left">{`${payment.first_name} ${payment.last_name}`}</TableCell>
        <TableCell align="left">{payment.user_id}</TableCell>
        <TableCell align="left">{payment.statement.code}</TableCell>
        <TableCell align="left">{dates.formatFromString(payment.item_timestamp, 'MM/DD/YYYY')}</TableCell>
        <TableCell align="left"><CurrencyDisplay value={payment.total_amount_pennies} penniesToDollars /></TableCell>
        <TableCell align="center">
            <IconButton
                onClick={handleMenu}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleApprove}>Approve</MenuItem>
                <MenuItem onClick={handleReject}>Reject</MenuItem>
            </Menu>
        </TableCell>
    </TableRow>
}

PaymentApproveTableRow.defaultProps = {
    reasonColumn: false,
    paymentDate: false,
    enableCheckbox: false
}

export default PaymentApproveTableRow;