import React, {useEffect, useState, Fragment} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    editorDefault: {
        minHeight: "340px",
        maxHeight: "340px",
        border: "solid 1px #c4c4c4",
        borderRadius: "4px",
        "&:hover": {
            borderColor: "black"
        },
        '& .ace_print-margin': {
            background: "none",
        }
    },
    editorDisabled: {
        minHeight: "340px",
        maxHeight: "340px",
        border: "solid 1px #c4c4c4",
        backgroundColor: "#f7f7f7",
        borderRadius: "4px",
        "&:hover": {
            border: "solid 1px #c4c4c4",
        },
        '& .ace_print-margin': {
            background: "none",
        }
    },
    editorFocus: {
        minHeight: "340px",
        maxHeight: "340px",
        borderRadius: "4px",
        border: "solid 1px #E57211",
        boxShadow: "0 0 0 1px #E57211",
        '& .ace_print-margin': {
            background: "none",
        }
    },
    editorError: {
        minHeight: "340px",
        maxHeight: "340px",
        borderRadius: "4px",
        border: "solid 1px #e01a2b",
        '& .ace_print-margin': {
            background: "none",
        }
    },
    editorErrorFocus: {
        minHeight: "340px",
        maxHeight: "340px",
        borderRadius: "4px",
        border: "solid 1px #e01a2b",
        boxShadow: "0 0 0 1px #e01a2b",
        '& .ace_print-margin': {
            background: "none",
        }
    }
}));

const CodeEditor = ({required, onChange, error, disabled, helperText, ...props}) => {
    const classes = useStyles();
    const [editorClasses, setEditorClasses] = useState(classes.editorDefault);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setHasError(error);
        if (disabled) {
            setEditorClasses(classes.editorDisabled);
        } else if (error) {
            setEditorClasses(classes.editorError);
        } else {
            setEditorClasses(classes.editorDefault);
        }
    }, [error, classes, disabled]);

    const onFocus = () => {
        if (disabled) {
            setEditorClasses(classes.editorDisabled);
        } else if (hasError) {
            setEditorClasses(classes.editorErrorFocus);
        } else {
            setEditorClasses(classes.editorFocus);
        }
    }

    const onBlur = () => {
        if (disabled) {
            setEditorClasses(classes.editorDisabled);
        } else if (hasError) {
            setEditorClasses(classes.editorError);
        } else {
            setEditorClasses(classes.editorDefault);
        }
    }

    const onEditorChange = (value) => {
        onChange(value);
    }

    return (
        <Fragment>
            <AceEditor
                mode="java"
                theme="github"
                editorProps={{$blockScrolling: true}}
                maxLines={21}
                width="100%"
                height="300px"
                readOnly={disabled}

                {...props}

                className={editorClasses}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onEditorChange}
            />
            {
                helperText.trim() !== "" &&
                <Box mt={1} mb={3}>
                    <Typography variant={"caption"} component={"div"} color={error ? "error" : "inherit"}>
                        {helperText}
                    </Typography>
                </Box>
            }
        </Fragment>
    );
}

CodeEditor.defaultProps = {
    error: false,
    helperText: "",
}

export default CodeEditor;