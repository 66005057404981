import {Dialog, Divider, Paper, Slide} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, {forwardRef, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Api from "../../helpers/api";
import RequestError from "../../helpers/RequestError";
import routes from "../../helpers/routes";
import {setNotification} from "../../slices/notification";
import TabPanel from "../TabPanel/TabPanel";
import UserModalHeader from "./UserModalHeader";
import UserBasicInfo from "./UserBasicInfo/UserBasicInfo";
import UserRolesTable from "./UserRoles/UserRolesTable";
import {invalidateUserList} from "../../slices/users";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        backgroundColor: theme.palette.grey[100],
    },
    content: {
        height: "100%",
        padding: theme.spacing(2),
        paddingTop: theme.spacing(3),
        backgroundColor: theme.palette.grey[100],
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const UserDetailsModal = ({onClose}) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const {userId} = useParams();
    const [activeTab, setActiveTab] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [inProgress, setInProgress] = useState(true);
    const [isUserInitialized, setIsUserInitialized] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (activeTab !== 0 && location.pathname.includes(routes.getAdminUserDetailsUrl((userId)))) {
            setActiveTab(0);
        } else if (activeTab !== 1 && location.pathname.includes(routes.getAdminUserRolesUrl(userId))) {
            setActiveTab(1);
        }
    }, [activeTab, location, userId]);

    useEffect(() => {
        if (
            userId &&
            (
                location.pathname.includes(routes.getAdminUserDetailsUrl(userId)) ||
                location.pathname.includes(routes.getAdminUserRolesUrl(userId))
            )
        ) {
            setIsOpen(true);
        }
    }, [location, userId]);

    useEffect(() => {
        if (!isOpen) {
            setIsUserInitialized(false);
            setUser(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isUserInitialized && userId) {
            Api.getUser(userId)(
                ({data}) => setUser(data),
                (error) => dispatch(setNotification((new RequestError(error)).getMessage(), "error"))
            ).then(() => {
                setInProgress(false);
                setIsUserInitialized(true);
            })
        }
    }, [dispatch, isUserInitialized, userId]);

    const onChangeTab = (tab) => {
        setActiveTab(tab);
        if (tab === 0) {
            history.push(routes.getAdminUserDetailsUrl(userId));
        } else if (tab === 1) {
            history.push(routes.getAdminUserRolesUrl(userId));
        }
    }

    const onCloseModal = () => {
        history.push(routes.getAdminUsersUrl());
        setIsOpen(false);
        dispatch(invalidateUserList());
    }

    return (
        <Dialog fullScreen open={isOpen} TransitionComponent={Transition} classes={{paperFullScreen: classes.root}}>
            <UserModalHeader onClose={onCloseModal} user={user ?? {}}/>
            <Paper square>
                <Box textAlign={"center"}>
                    <Tabs value={activeTab} onChange={(e, tab) => onChangeTab(tab)} centered>
                        <Tab label="Details"/>
                        <Tab label="Roles"/>
                    </Tabs>
                    <Divider/>
                </Box>
            </Paper>

            <Box className={classes.content}>
                <Container maxWidth="md" disableGutters>
                    <Grid container>
                        <Grid item xs={12}>
                            <TabPanel value={activeTab} index={0}>
                                <UserBasicInfo user={user} onUpdateSuccess={() => setIsUserInitialized(false)}
                                               onCancel={onCloseModal} loading={inProgress}/>
                            </TabPanel>
                            <TabPanel value={activeTab} index={1}>
                                <UserRolesTable user={user} onUpdateSuccess={() => setIsUserInitialized(false)} loading={inProgress}/>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

        </Dialog>
    )
}

export default UserDetailsModal;