import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import {FormattedMessage} from "react-intl";

const DeleteWidgetTemplatePrompt = ({open, onCancel, onDelete, template}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>
                <FormattedMessage
                    id={'Experiences.WidgetTemplates.DeleteTitle'}
                    defaultMessage={'Delete the template?'}
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage
                        id="Experiences.WidgetTemplates.DeleteMessage"
                        defaultMessage='Are you sure you wish to delete the template "{templateName}"? Active widgets using this template will not be affected.'
                        values={{templateName: template ? template.name : ""}} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} onClick={onCancel}>
                    <FormattedMessage
                        id={'Common.Cancel'}
                        defaultMessage={'CANCEL'}
                    />
                </Button>
                <Button color={"primary"} onClick={onDelete}>
                    <FormattedMessage
                        id={'Common.Delete'}
                        defaultMessage={'DELETE'}
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteWidgetTemplatePrompt;