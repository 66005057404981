import React from "react";
import {TableCell, TableRow} from "@material-ui/core";
import dates from "../../helpers/dates";
import CurrencyDisplay from "../CurrencyDisplay";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  signage: {
    '& .MuiTableCell-body': {
      fontWeight: 600,
      fontSize: "larger"
    }
  },
}));

const CampaignListTableRow = ({campaign}) => {
    const classes = useStyles();
    return (
        <TableRow className={classes.signage}>
            <TableCell>
                {campaign.brand.name}
            </TableCell>
            <TableCell>
              {campaign.title}
            </TableCell>
            <TableCell>
                {campaign.referenceNumber}
            </TableCell>
            <TableCell>
                {dates.formatFromStringToDate(campaign.dateStart)}
            </TableCell>
            <TableCell>
                {dates.formatFromStringToDate(campaign.dateEnd)}
            </TableCell>
            <TableCell>
                <CurrencyDisplay value={campaign.campaignTotalCost}/>
            </TableCell>
        </TableRow>
    )
}

export default CampaignListTableRow;
