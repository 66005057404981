import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import AnalyticsClientsTableToolbar from "./AnalyticsClientsTableToolbar";
import AnalyticsClientsTableHead from "./AnalyticsClientsTableHead";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    actions: {
        '& .MuiTypography-root': {
            marginRight: theme.spacing(2),
        },
        '& .MuiTypography-root:first-child': {
            marginRight: theme.spacing(1),
        }
    }
}));

const AnalyticsClientsTable = (props) => {
    const {
        loading,
        clientAnalytics,
        onChangeDateRange,
        dateRange,
    } = props;
    const classes = useStyles();

    const emptyMessage = loading ? "Loading..." : "There was a problem loading data";
    return (
        <Box className={classes.root} pb={2}>
            <AnalyticsClientsTableToolbar onChangeDateRange={onChangeDateRange} dateRange={dateRange} disabled={loading}/>
            <Paper variant="outlined" square>
                <TableContainer style={{height: "calc(100vh - 190px)"}}>
                    <Table size={'medium'} stickyHeader>
                        <AnalyticsClientsTableHead classes={classes}/>
                        <TableBody>
                            {clientAnalytics.map((client) => {
                                return (
                                    <TableRow hover key={client.client_id}>
                                        <TableCell align="left">
                                            {client.client_id}
                                        </TableCell>
                                        <TableCell align="left">
                                            <CurrencyFormat value={client.video_views} displayType={'text'} thousandSeparator={true}/>
                                        </TableCell>
                                        <TableCell align="left">
                                            <CurrencyFormat value={client.photo_views} displayType={'text'} thousandSeparator={true}/>
                                        </TableCell>
                                        <TableCell align="left">
                                            <CurrencyFormat value={client.sales_pennies/100} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale/>
                                        </TableCell>
                                        <TableCell align="left">
                                            <CurrencyFormat value={client.returns_pennies/100} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale/>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {(loading || clientAnalytics.length===0 )&& (
                                <TableRow style={{height: "calc(100vh - 250px)"}}>
                                    <TableCell colSpan={5} style={{borderBottom: "none"}}>
                                        <Typography align={"center"} variant={"inherit"} component={"div"}>
                                            {emptyMessage}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

export default AnalyticsClientsTable;