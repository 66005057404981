import {Box, IconButton, Link, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import React from "react";
import GigStatusEnum from "../../../helpers/GigStatusEnum";
import GigSearchRequestFilterByApplicationStatusEnum
    from "../../../helpers/GigSearchRequestFilterByApplicationStatusEnum";
import GigSearchRequestFilterByContentStatusEnum
    from "../../../helpers/GigSearchRequestFilterByContentStatusEnum";
import GigSearchRequestFilterBySocialPostStatusEnum
    from "../../../helpers/GigSearchRequestFilterBySocialPostStatusEnum";
import GigSearchRequestFilterByProductSelectionEnum
    from "../../../helpers/GigSearchRequestFilterByProductSelectionEnum";
import {strings} from "../../../helpers/utils";
import GigSearchRequestFilterByShippingStatusEnum
    from "../../../helpers/GigSearchRequestFilterByShippingStatusEnum";
import {useDispatch, useSelector} from "react-redux";
import {campaignAdminSelector} from "../../../slices/campaignAdmin";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {setNotification} from "../../../slices/notification";
import RequestError from "../../../helpers/RequestError";
import Api from "../../../helpers/api";
import Switch from "@material-ui/core/Switch";


/**
 * Translates the application status from the Gig status
 * @param {string} status
 * @returns {string}
 */
const getApplicationStatus = (status) => {
    switch (status) {
        case GigStatusEnum.RESERVATION_WAITLISTED:
            return 'Waitlisted';
        case GigStatusEnum.RESERVATION_REJECTED:
            return 'Declined';
        case GigStatusEnum.NOT_YET_APPLIED:
        case GigStatusEnum.RESERVATION_REQUESTED:
            return GigSearchRequestFilterByApplicationStatusEnum.PENDING_REVIEW
        case GigStatusEnum.AWAITING_SHIPPING_INFORMATION:
        case GigStatusEnum.AWAITING_SHIPMENT:
        case GigStatusEnum.AWAITING_CONTENT:
        case GigStatusEnum.AWAITING_CONTENT_REVIEW:
        case GigStatusEnum.AWAITING_CONTENT_RESUBMISSION:
        case GigStatusEnum.AWAITING_SOCIAL_POST:
        case GigStatusEnum.AWAITING_SOCIAL_POST_REVIEW:
        case GigStatusEnum.AWAITING_SOCIAL_REPOST:
        case GigStatusEnum.COMPLETED:
        default:
            return GigSearchRequestFilterByApplicationStatusEnum.APPROVED
    }
}

const getProductSelectionStatus = (status) => {
    switch (status) {

        case GigStatusEnum.RESERVATION_REQUESTED:
        case GigStatusEnum.RESERVATION_REJECTED:
        case GigStatusEnum.RESERVATION_WAITLISTED:
        case GigStatusEnum.AWAITING_PRODUCT_SELECTION:
            return GigSearchRequestFilterByProductSelectionEnum.PENDING;
        case GigStatusEnum.AWAITING_SHIPPING_ADDRESS:
        case GigStatusEnum.AWAITING_ADDRESS_CONFIRMATION:
        case GigStatusEnum.AWAITING_SHIPMENT:
        case GigStatusEnum.AWAITING_PRODUCT_CONFIRMATION:
        case GigStatusEnum.AWAITING_DELIVERY_CONFIRMATION:
        case GigStatusEnum.AWAITING_CONTENT:
        case GigStatusEnum.AWAITING_CONTENT_REVIEW:
        case GigStatusEnum.AWAITING_CONTENT_RESUBMISSION:
        case GigStatusEnum.AWAITING_SOCIAL_POST:
        case GigStatusEnum.AWAITING_SOCIAL_POST_REVIEW:
        case GigStatusEnum.AWAITING_SOCIAL_REPOST:
        case GigStatusEnum.COMPLETED:
            return GigSearchRequestFilterByProductSelectionEnum.CONFIRMED;
        case GigStatusEnum.CANCELLED:
            return GigSearchRequestFilterByProductSelectionEnum.PENDING;
        default:
            return GigSearchRequestFilterByProductSelectionEnum.CONFIRMED;
    }
}


/**
 * Translates the application status from the Gig status
 * @param {string} status
 * @returns {string}
 */
const getContentApprovalStatus = (status) => {
    switch (status) {
        case GigStatusEnum.RESERVATION_REJECTED:
            return '-';
        case GigStatusEnum.NOT_YET_APPLIED:
        case GigStatusEnum.RESERVATION_REQUESTED:
        case GigStatusEnum.AWAITING_SHIPPING_INFORMATION:
        case GigStatusEnum.AWAITING_SHIPMENT:
        case GigStatusEnum.AWAITING_CONTENT:
            return GigSearchRequestFilterByContentStatusEnum.NOT_YET_SUBMITTED
        case GigStatusEnum.AWAITING_CONTENT_REVIEW:
            return GigSearchRequestFilterByContentStatusEnum.PENDING_REVIEW
        case GigStatusEnum.AWAITING_CONTENT_RESUBMISSION:
            return GigSearchRequestFilterByContentStatusEnum.NOT_YET_SUBMITTED
        case GigStatusEnum.AWAITING_SOCIAL_POST:
        case GigStatusEnum.AWAITING_SOCIAL_POST_REVIEW:
        case GigStatusEnum.AWAITING_SOCIAL_REPOST:
        case GigStatusEnum.COMPLETED:
            return GigSearchRequestFilterByContentStatusEnum.APPROVED
        default:
            return GigSearchRequestFilterByContentStatusEnum.NOT_YET_SUBMITTED
    }
}

/**
 * Translates the social post status from the Gig status
 * @param {string} status
 * @returns {string}
 */
const getSocialPostStatus = (status) => {
    switch (status) {
        case GigStatusEnum.RESERVATION_REJECTED:
            return '-';
        case GigStatusEnum.NOT_YET_APPLIED:
        case GigStatusEnum.RESERVATION_REQUESTED:
        case GigStatusEnum.AWAITING_SHIPPING_INFORMATION:
        case GigStatusEnum.AWAITING_SHIPMENT:
        case GigStatusEnum.AWAITING_CONTENT:
        case GigStatusEnum.AWAITING_CONTENT_REVIEW:
        case GigStatusEnum.AWAITING_CONTENT_RESUBMISSION:
        case GigStatusEnum.AWAITING_SOCIAL_POST:
            return GigSearchRequestFilterBySocialPostStatusEnum.NOT_YET_SUBMITTED
        case GigStatusEnum.AWAITING_SOCIAL_POST_REVIEW:
            return GigSearchRequestFilterBySocialPostStatusEnum.PENDING_REVIEW
        case GigStatusEnum.AWAITING_SOCIAL_REPOST:
            return GigSearchRequestFilterBySocialPostStatusEnum.NOT_YET_SUBMITTED
        case GigStatusEnum.COMPLETED:
            return GigSearchRequestFilterBySocialPostStatusEnum.APPROVED
        default:
            return GigSearchRequestFilterBySocialPostStatusEnum.NOT_YET_SUBMITTED
    }
}

const getShippingStatus = (status) => {
    //AwaitingAddressConfirmation
    switch (status) {
        case GigStatusEnum.RESERVATION_REJECTED:
            return '-';
        case GigStatusEnum.RESERVATION_REQUESTED:
        case GigStatusEnum.AWAITING_SHIPPING_INFORMATION:
            return "Pending"
        case GigStatusEnum.AWAITING_SHIPMENT:
            return "Pending shipment";
        case GigStatusEnum.AWAITING_ADDRESS_CONFIRMATION:
            return "Confirm shipping address";
        case GigStatusEnum.AWAITING_DELIVERY_CONFIRMATION:
            return GigSearchRequestFilterByShippingStatusEnum.SHIPPED
        case GigStatusEnum.AWAITING_CONTENT:
        case GigStatusEnum.AWAITING_CONTENT_REVIEW:
        case GigStatusEnum.AWAITING_CONTENT_RESUBMISSION:
        case GigStatusEnum.AWAITING_SOCIAL_POST:
        case GigStatusEnum.AWAITING_SOCIAL_POST_REVIEW:
        case GigStatusEnum.AWAITING_SOCIAL_REPOST:
        case GigStatusEnum.COMPLETED:
            return "Delivered"
        default:
            return GigSearchRequestFilterByShippingStatusEnum.ADDRESS_NOT_YET_SUBMITTED
    }
}

const CampaignAdminManagerTableRow = ({
                                          gig,
                                          onOpenAddress,
                                          onOpenTracking,
                                          onOpenDelivery,
                                          onOpenManualSocialData,
                                          onRefresh,
                                      }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [inProgress, setInProgress] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    }
    const {user, status, automatedReportingEnabled} = gig;
    const {row} = useSelector(campaignAdminSelector);
    const getShippingCell = () => {
        let clickAction = () => {
        };
        const label = strings.insertSpacesToCamelCase(getShippingStatus(status));
        const LinkStatus = [GigStatusEnum.AWAITING_ADDRESS_CONFIRMATION, GigStatusEnum.AWAITING_SHIPMENT, GigStatusEnum.AWAITING_DELIVERY_CONFIRMATION].includes(status);
        if (GigStatusEnum.AWAITING_ADDRESS_CONFIRMATION === status) {
            clickAction = onOpenAddress;
        } else if (GigStatusEnum.AWAITING_SHIPMENT === status) {
            clickAction = onOpenTracking;
        } else if (GigStatusEnum.AWAITING_DELIVERY_CONFIRMATION === status) {
            clickAction = onOpenDelivery;
        }
        if (row && row.productSeedingFlag && LinkStatus) {
            return <Link onClick={() => clickAction(gig)} component={"button"}>
                {label}
            </Link>
        } else {
            return label;
        }
    }
    const openManualSocialData = () => {
        handleMenuClose();
        onOpenManualSocialData(gig);
    }

    const onChangeAutomatedReporting = (event) => {
        const activeDisabledMessage = event.target.checked ? "enabled" : "disabled";
        setInProgress(true);
        Api.GigApi.updateAutomatedReporting(gig.id)(
            () => {
                onRefresh();
                dispatch(setNotification(`Automated reporting has been ${activeDisabledMessage}`));
            },
            (error) => {
                dispatch(setNotification((new RequestError(error).getMessage()), "error"));
            },
        )({automatedReportingEnabled: event.target.checked}).then(() => setInProgress(false));
    }

    return (
        <TableRow>
            <TableCell>
                <Box>{user.firstName} {user.lastName}</Box>
            </TableCell>
            <TableCell>
                {strings.insertSpacesToCamelCase(getApplicationStatus(status))}
            </TableCell>
            <TableCell>
                {strings.insertSpacesToCamelCase(getProductSelectionStatus(status))}
            </TableCell>
            <TableCell>
                {getShippingCell()}
            </TableCell>
            <TableCell>
                {strings.insertSpacesToCamelCase(getContentApprovalStatus(status))}
            </TableCell>
            <TableCell>
                {strings.insertSpacesToCamelCase(getSocialPostStatus(status))}
            </TableCell>
            <TableCell>
                <Switch color="primary" onChange={onChangeAutomatedReporting} checked={automatedReportingEnabled} disabled={inProgress}/>
            </TableCell>
            <TableCell padding={"checkbox"}>
                <IconButton onClick={handleClick}>
                    <MoreVertIcon/>
                </IconButton>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem key={"manage_campaign"} onClick={openManualSocialData}>Enter social data</MenuItem>
                    <MenuItem key={"pay_creator"} disabled={true}>Pay creator</MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
}

export default CampaignAdminManagerTableRow;
