import React, {Fragment, useState, useEffect, createRef} from "react";

import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import {FormattedMessage} from "react-intl";
import {useSelector, useDispatch} from 'react-redux';
import {getSubscriptions, subscriptionsSelector} from "../../slices/subscriptions";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import moment from 'moment';
import DeleteIcon from "@material-ui/icons/Delete";

const drawerWidth = 400;
const useStyles = makeStyles(theme=>({
    searchBar: {
        flex: "1 1 100%",
        display: "flex",
        alignContent: "center",
        alignItems: 'center'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    tableWrapper: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    filterHeader:{
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const Subscriptions = props =>{
    const {client_id, filters, subscriptionsList} = useSelector(subscriptionsSelector);
    const [filterOpen, setFilterOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [per_page, setPerPage] = useState(10);
    const [selected, setSelected] = useState([]);
    const [startDate, handleStartDateChange] = useState(new Date());
    const [endDate, handleEndDateChange] = useState(new Date());
    const closeFilter = () => setFilterOpen(false);
    const openFilter = () => setFilterOpen(true);
    const paginationRef = createRef();
    const classes = useStyles();
    const dispatch = useDispatch();
    const loadData = (page) =>{
        const parameters = {page, per_page, client:client_id, filters};
        dispatch(getSubscriptions(parameters));
    };
    const onItemsPerPageChange = (event) => {
        const noItems = event.target.value;
        setPerPage(noItems);
        loadData(1);
    };

    useEffect(()=>{
        loadData(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const onBack = () =>{
        setPage((page-1));
        loadData(page-1);
    };
    const onNext = () =>{
        setPage((page+1));
        loadData(page + 1);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const rows = subscriptionsList.items;
            const newSelected = rows.map((n) => n.resource_id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleSelectClick = (event, id) => {
        if (event.target.checked) {
            const newSelected = [...selected, id];
            setSelected(newSelected);
            return;
        }
        const removeSelected = selected.indexOf(id);
        if (removeSelected > -1) {
            const newArray = [...selected];
            newArray.splice(removeSelected, 1);
            setSelected(newArray);
        }
    };

    return <Fragment>
        <Toolbar/>
        <Toolbar>
            {selected.length > 0 ? (
                    <Typography className={classes.searchBar} color="inherit" variant="subtitle1" component="div">
                        {selected.length} selected
                    </Typography>
                ) :

                <div className={classes.searchBar}>
                    <SearchIcon/>
                    <InputBase placeholder={"Search..."}/>
                </div>
            }
            {selected.length > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ): (<Tooltip title="Filter list">
                <IconButton onClick={openFilter} aria-label="filter list">
                    <FilterListIcon />
                </IconButton>
            </Tooltip>)
            }
        </Toolbar>
        <div className={clsx(classes.content, {
            [classes.contentShift]: (filterOpen),
        })}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={'checkbox'}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < subscriptionsList.items.length}
                                    checked={subscriptionsList.items.length > 0 && selected.length === subscriptionsList.items.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                />
                            </TableCell>
                            <TableCell align={"center"}>
                                <FormattedMessage id="Subscriptions.Table.id" defaultMessage="ID"/>
                            </TableCell>
                            <TableCell align={"left"}>
                                <FormattedMessage id="Subscriptions.Table.EmailAddress" defaultMessage="Email Address"/>
                            </TableCell>
                            <TableCell align={"left"}>
                                <FormattedMessage id="Subscriptions.Table.ClientID" defaultMessage="Client ID"/>
                            </TableCell>
                            <TableCell align={"left"}>
                                <FormattedMessage id="Subscriptions.Table.UserID" defaultMessage="User ID"/>
                            </TableCell>
                            <TableCell align={"center"}>
                                <FormattedMessage id="Subscriptions.Table.CreatedDate" defaultMessage="Created Date"/>
                            </TableCell>
                            <TableCell align={"center"}>
                                <FormattedMessage id="Subscriptions.Table.Status" defaultMessage="Status"/>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            subscriptionsList.items.map((item, index)=>{
                                const isItemSelected = selected.indexOf(item.resource_id) !== -1;
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return <TableRow hover key={index}>
                                    <TableCell padding={'checkbox'}>
                                        <Checkbox
                                            onChange={(e)=>{handleSelectClick(e, item.resource_id)}}
                                            checked={isItemSelected}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        {item.resource_id}
                                    </TableCell>
                                    <TableCell align={'left'}>
                                        <Link href={'#'}>
                                            {item.title}
                                        </Link>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        {item.creation_date && moment(item.creation_date).format('MM/DD/YY hh:mm:ss')}
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        {item.is_enabled ?
                                            <FormattedMessage id={'Subscriptions.Table.Active'} defaultMessage={"Active"} /> :
                                            <FormattedMessage id={'Subscriptions.Table.Disabled'} defaultMessage={"Disabled"} />
                                        }
                                    </TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination ref={paginationRef}
                                             count={subscriptionsList.total || 0}
                                             onChangePage={()=>console.log('page changed')}
                                             backIconButtonProps={{onClick: onBack}}
                                             nextIconButtonProps={{onClick: onNext}}
                                             SelectProps={{onChange: onItemsPerPageChange}}
                                             page={page} rowsPerPage={per_page}/>
                        </TableRow>
                    </TableFooter>
                </Table>

            </TableContainer>
        </div>
        <div >
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={filterOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}>

                <Toolbar/>
                <Toolbar className={classes.filterHeader}>
                    <Typography>
                        Filters
                    </Typography>
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={closeFilter}>
                            <Close/>
                        </IconButton>
                    </div>
                </Toolbar>
                <Divider/>
                <form className={classes.root} noValidate autoComplete="off">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Grid container direction={"column"} >
                            <DatePicker
                                disableFuture
                                format="dd/MM/yyyy"
                                style={{margin: '10px'}}
                                label="Created Date Range Start"
                                inputVariant="outlined"
                                value={startDate}
                                onChange={handleStartDateChange} />
                            <DatePicker
                                disableFuture
                                format="dd/MM/yyyy"
                                style={{margin: '10px'}}
                                label="Created Date Range End"
                                inputVariant="outlined"
                                value={endDate}
                                onChange={handleEndDateChange} />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </form>
            </Drawer>
        </div>
    </Fragment>
}
export default Subscriptions;