import React, {useCallback, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import Api from "../helpers/api";
import RequestError from "../helpers/RequestError";
import {setNotification} from "../slices/notification";
import {useDispatch} from "react-redux";
import CustomAutocomplete from "./CustomAutocomplete";

const DEFAULT_ITEMS_PER_PAGE = 40;

const UserAutocomplete = ({disabled, onChange, value, label, helperText, error, placeholder, fetchAll, clientId, roleId}) => {
    const dispatch = useDispatch();
    const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE)
    const [searchTerm, setSearchTerm] = useState("");
    const [lastSearch, setLastSearch] = useState("");
    const [isInitialized, setIsInitialized] = useState(null);
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const [users, setUsers] = useState(null);

    const getCreators = useCallback((search, itemsPerPage) => {
        Api.getUsers(1, itemsPerPage, search, clientId, roleId)(
            ({data}) => setUsers(data.items.map(user => {
                user.label = `${user.first_name} ${user.last_name}`;
                user.value = user.user_id;
                return user;
            })),
            (error) => dispatch(setNotification((new RequestError(error)).getMessage(), "error"))
        ).then(() => {
            setLastSearch(search);
            setIsInitialized(true);
        });
    }, [dispatch, clientId, roleId]);

    const fetchTotalCreators = useCallback(() => {
        Api.getUsers(1, 1)(
            ({data}) => {
                setItemsPerPage(data.total);
                getCreators("", data.total);
            },
            (error) => dispatch(setNotification((new RequestError(error)).getMessage(), "error"))
        );
    }, [getCreators, dispatch]);

    useEffect(() => {
        if (isInitialized === null && fetchAll) {
            setIsInitialized(false);
            fetchTotalCreators();
        } else if (isInitialized === null) {
            setIsInitialized(false);
            getCreators("", itemsPerPage);
        }
    }, [isInitialized, fetchTotalCreators, getCreators, itemsPerPage, fetchAll]);

    useEffect(() => {
        if (lastSearch !== debouncedSearchTerm && !fetchAll && isInitialized) {
            getCreators(debouncedSearchTerm, itemsPerPage);
        }
    }, [debouncedSearchTerm, lastSearch, getCreators, fetchAll, isInitialized, itemsPerPage]);

    const onInternalChange = (value) => {
        if (typeof onChange === "function") {
            onChange(value);
        }
    }

    return (
        <CustomAutocomplete label={label || "User"}
                            options={users ?? []}
                            placeholder={placeholder || 'Select a user'}
                            sort
                            disabled={disabled}
                            value={value}
                            onChange={onInternalChange}
                            onInputChange={(e) => setSearchTerm(e.target.value)}
                            helperText={helperText}
                            noOptionsText={"User not found"}
                            error={error}/>
    );
}

export default UserAutocomplete;
