import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import CurrentPlan from "./CurrentPlan";
import SiteViewUsage from "./SiteViewUsage";
import api from "../../../helpers/api";
import {useSelector} from "react-redux";
import {clientConfigSelector} from "../../../slices/clientConfig";
import InfluenceServiceFee from "./InfluenceServiceFee";
import TeamSeatUsage from "./TeamSeatUsage";
import PaymentMethod from "./PaymentMethod";

const TabPlan = () =>{
    const {clientConfig} = useSelector(clientConfigSelector);
    const [plan, setPlan] = useState(null);
    useEffect(() => {
        api.backoffice.company.getPlan(clientConfig.client.id)(
            response => {
                setPlan(response.data);
            },
            error => {
                setPlan(null);
            }
        )
    }, [clientConfig]);

    const reloadPlan=()=>{
        api.backoffice.company.getPlan(clientConfig.client.id)(
            response => {
                setPlan(response.data);
            },
            error => {
                setPlan(null);
            }
        )
    }
    return <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CurrentPlan plan={plan} onSuccess={reloadPlan}/>
                </Grid>
                <Grid item xs={12}>
                    <PaymentMethod plan={plan} onSuccess={reloadPlan}/>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SiteViewUsage plan={plan} onSuccess={reloadPlan} />
                </Grid>
                <Grid item xs={12}>
                    <InfluenceServiceFee plan={plan} onSuccess={reloadPlan} />
                </Grid>
                <Grid item xs={12}>
                    <TeamSeatUsage plan={plan} onSuccess={reloadPlan} />
                </Grid>

            </Grid>
        </Grid>
    </Grid>
}
export default TabPlan;