import React, {useEffect, useState, Fragment, useCallback} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl, InputLabel,
    LinearProgress, ListSubheader, MenuItem, Select,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {isEmpty, object as objectUtils} from "../../helpers/utils";
import TextFieldWithHelp from "../TextFieldWithHelp";
import Api from "../../helpers/api";
import useNotification from "../../helpers/useNotification";
import Divider from "@material-ui/core/Divider";
import CodeEditor from "../CodeEditor/CodeEditor";
import Typography from "@material-ui/core/Typography";
import communicationsHelper, {EMPTY_CONFIGURATION} from "../../helpers/communicationsHelper";

const getBody = (configuration) => {
    if (configuration?.template_type === "email") {
        return objectUtils.get("template.text-html.html", configuration, "");
    } else {
        return (configuration?.template || "");
    }
}

const generateTemplatePayload = (configurationType, body, css) => {
    if (configurationType === "email") {
        return {
            "text-html": {
                "html": body,
                "css": css,
            },
            "text-plain": "",
        }
    } else {
        return body;
    }
}

const generateTransportConfiguration = (configurationType, subject) => {
    if (configurationType === "email") {
        return {
            subject,
        }
    } else {
        return {};
    }
}

const getEventTypesSelectOptions = () => {
    let selectOptions = [];
    communicationsHelper.getGroupedEventTypes().forEach(({group, options}) => {
        selectOptions.push(<ListSubheader key={group}>{group}</ListSubheader>);
        options.forEach((option) => {
            selectOptions.push(
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            );
        });
    });
    return selectOptions;
}

const AddConfigurationModal = ({isOpen, onClose, onSaveSuccess, configurationId}) => {
    const notification = useNotification();

    const [configuration, setConfiguration] = useState(null);
    const [name, setName] = useState(configuration?.name || "");
    const [templateType, setTemplateType] = useState(configuration?.template_type || "email");
    const [eventType, setEventType] = useState(configuration?.event_type_id || communicationsHelper.getGroupedEventTypes()[0].options[0].value);
    const [subject, setSubject] = useState(configuration?.transport_configuration?.subject || "");
    const [body, setBody] = useState(getBody(configuration));
    const [showErrors, setShowErrors] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [isInitialized, setIsInitialized] = useState(null);

    const fetchConfiguration = useCallback((configurationId) => {
        return new Promise((resolve, reject) => {
            Api.communications.getConfiguration(configurationId)(
                ({data}) => resolve(data),
                reject
            );
        });
    }, []);

    useEffect(() => {
        if (isOpen === false) {
            setName("");
            setTemplateType("email");
            setSubject("");
            setBody("");
            setInProgress(false);
        }
    }, [isOpen, configuration]);

    useEffect(() => {
        if (isOpen && configurationId && isInitialized === null) {
            setIsInitialized(false);
            fetchConfiguration(configurationId)
                .then((configuration) => {
                    setConfiguration(configuration);
                    setName(configuration.name);
                    setTemplateType(configuration.template_type);
                    setEventType(configuration.event_type_id);
                    setSubject(configuration?.transport_configuration?.subject || "");
                    setBody(getBody(configuration));
                })
                .catch(notification.captureError)
                .finally(() => {
                    setIsInitialized(true);
                    setInProgress(false);
                });
        } else if (isOpen && isInitialized === null) {
            setIsInitialized(true);
            setInProgress(false);
        }
    }, [isOpen, configurationId, isInitialized, fetchConfiguration, notification.captureError]);

    const createConfiguration = (configuration) => {
        return Api.communications.createConfiguration()(
            ({data}) => {
                createSubscription(data.communication_config_id);
            },
            (error) => {
                notification.captureError(error);
                setInProgress(false);
            }
        )(configuration);
    }

    const createSubscription = (configurationId) => {
        Api.communications.createSubscription()(
            () => {
                onSaveSuccess();
                setInProgress(false);
            },
            (error) => {
                notification.captureError(error);
                setInProgress(false);
            }
        )({
            "communication_config_id": configurationId,
            "is_dynamic": true,
            "destination": "event.data.creator_email",
            "client_id": null,
            "subscription_context_data": null,
            "client_context_filter": null,
            "user_context_filter": null,
            "manage_token": null,
            "status": "active"
        });
    }


    const updateConfiguration = (configurationId, configuration) => {
        Api.communications.updateConfiguration(configurationId)(
            () => {
                validateSubscription(configurationId);
            },
            (error) => {
                notification.captureError(error);
                setInProgress(false);
            }
        )(configuration);
    }

    const validateSubscription = (configurationId) => {
        Api.communications.getSubscriptionsByConfigurationId(configurationId, 1, 1)(
            ({data}) => {
                if (data.total === 0) {
                    createSubscription(configurationId);
                } else {
                    onSaveSuccess();
                    setInProgress(false);
                }
            },
            (error) => {
                notification.captureError(error);
                setInProgress(false);
            }
        );
    }

    const onSave = () => {
        if (isEmpty(name) || (templateType === "email" && isEmpty(subject)) || isEmpty(body)) {
            setShowErrors(true);
        } else {
            setInProgress(true);
            const payload = {
                ...EMPTY_CONFIGURATION,
                name,
                template: generateTemplatePayload(templateType, body, ""),
                template_type: templateType,
                transport: templateType,
                transport_configuration: generateTransportConfiguration(templateType, subject),
                event_type_id: eventType,
            };
            if (configurationId) {
                updateConfiguration(configurationId, payload);
            } else {
                createConfiguration(payload);
            }
        }
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>
                    {configurationId ? "Edit communications template" : "Create communications template"}
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <FormControl fullWidth margin={"normal"}>
                        <TextFieldWithHelp label={"Name"} required autoFocus
                                           helperText={showErrors && name === "" ? "Name is required." : ""}
                                           error={showErrors && isEmpty(name)}
                                           onChange={(e) => setName(e.target.value)}
                                           value={name}
                                           disabled={inProgress}/>
                    </FormControl>

                    <FormControl variant={"outlined"} margin={"normal"} size={"small"} fullWidth disabled={inProgress}>
                        <InputLabel>Template type</InputLabel>
                        <Select value={templateType}
                                disabled={inProgress}
                                onChange={e => setTemplateType(e.target.value)}
                                label={"Template type"}>
                            <MenuItem value={"email"}>
                                Email
                            </MenuItem>
                            <MenuItem value={"sms"}>
                                SMS
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl variant={"outlined"} margin={"normal"} size={"small"} fullWidth disabled={inProgress}>
                        <InputLabel>Event type</InputLabel>
                        <Select value={eventType}
                                disabled={inProgress}
                                onChange={e => setEventType(e.target.value)}
                                label={"Event type"}>
                            {getEventTypesSelectOptions()}
                        </Select>
                    </FormControl>

                    {
                        templateType === "email" &&
                        <FormControl fullWidth margin={"normal"}>
                            <TextFieldWithHelp label={"Subject"} required
                                               helperText={showErrors && subject === "" ? "Subject is required." : ""}
                                               error={showErrors && isEmpty(subject)}
                                               onChange={(e) => setSubject(e.target.value)}
                                               value={subject}
                                               disabled={inProgress}/>
                        </FormControl>
                    }

                    <FormControl variant="outlined" fullWidth margin={"normal"}>
                        <CodeEditor onChange={setBody} value={`${body}`}
                                    disabled={inProgress}
                                    error={showErrors && isEmpty(body)}
                                    helperText={showErrors && isEmpty(body) ? "This field is required" : ""}/>
                    </FormControl>

                    <Typography variant="caption">Keywords:</Typography>
                    <Typography variant="caption">
                        <ul style={{paddingLeft: "20px"}}>
                            <li>{"{{ event.data.creator_first_name }}"}</li>
                            <li>{"{{ event.data.creator_last_name }}"}</li>
                            <li>{"{{ event.data.campaign_name }}"}</li>
                            <li>{"{{ event.data.link_url }}"}</li>
                            <li>{"{{ event.data.comment }}"}</li>
                        </ul>
                    </Typography>
                </DialogContent>
                <Divider/>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="outlined" onClick={onClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button type="submit" color="primary" variant="contained" onClick={onSave} fullWidth
                                    disabled={inProgress}>
                                {configurationId ? "Save" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default AddConfigurationModal;
