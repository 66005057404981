import React, {useEffect, useState} from "react";
import CardWithTitleAndAction from "../CardWithTitleAndAction";
import {clientConfigSelector} from "../../../slices/clientConfig";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {setNotification} from "../../../slices/notification";
import {Box, MenuItem} from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Api from "../../../helpers/api";

const PaymentMethod = () =>{
    const {clientConfig} =  useSelector(clientConfigSelector);
    const [inProgress, setInProgress] = useState(false);
    const [values, setValues] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        Api.backoffice.company.details(clientConfig.client.id)(
            response =>{
                setInProgress(false);
                setValues(response.data);
            },
            error =>{
                setInProgress(false);
            }
        );
    }, [clientConfig]);

    const onChangePaymentMethod = (event, value) =>{
        let newValues = {...values};
        newValues['payment_method_type'] = value.props.value;
        setValues(newValues);
        if (value.props.value === 'credit_card') {
            newValues['net_terms'] = 0;
        }
    }
    const onSwitchChange = (event) => {
        const {name, checked} = event.target;
        const newValues = {...values};
        newValues[name] = checked;
        setValues(newValues);
    }

    const save = () =>{
        if(!values.payment_method_type){
            dispatch(setNotification('Select a payment method', 'error'));
            return;
        }
        setInProgress(true);
        Api.backoffice.company.putDetails(clientConfig.client.id)(
            response =>{
                dispatch(setNotification('Company details updated successfully'));
                setInProgress(false);
                setValues(response.data);
            },
            error =>{
                setInProgress(false);
                dispatch(setNotification('Failed to save the details',  'error'))
            }
        )(values);
    }
    const onChange = (event, value) => {
        const {name} = event.target;

        let newValues = {...values};
        newValues[name] = value;
        setValues(newValues);
    }

    const noDetails = inProgress === false && !values;

    return <CardWithTitleAndAction title={"Current payment method"} testId={'company-payment-method'} inProgress={inProgress} onClickAction={save} disableAction={noDetails}>
        <TextField
            select
            fullWidth
            disabled={noDetails || inProgress}
            margin={'dense'}
            name={'payment_method_type'}
            data-testId={'payment_method_type'}
            label={'Payment method'}
            onChange={onChangePaymentMethod}
            value={values?.payment_method_type || ""}>
            <MenuItem value={'credit_card'}>
                Credit card
            </MenuItem>
            <MenuItem value={'managed'}>
                Managed
            </MenuItem>
        </TextField>
        <Box data-testId={'net-terms'}>
        <CurrencyTextField
            onChange={onChange}
            value={values?.net_terms || 0 }
            fullWidth
            textAlign="left"
            name={'net_terms'}
            label={'Net terms'}
            margin={'dense'}
            variant={'outlined'}
            disabled={values?.payment_method_type === 'credit_card' || noDetails || inProgress}
            decimalPlaces={0}
            currencySymbol={null}/>
        </Box>
        <FormControlLabel control={<Switch data-testId={'invoicing-enabled-switch'} disabled={noDetails || inProgress} onChange={onSwitchChange} checked={values?.invoicing_enabled || false} name={'invoicing_enabled'}  />} label="Platform invoicing enabled" />
    </CardWithTitleAndAction>
}
export default PaymentMethod;