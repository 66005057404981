import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from "@material-ui/core/Menu";
import AppMenu from '../components/AppMenu/AppMenu';
import {useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {Switch, Route, BrowserRouter as Router} from "react-router-dom";
import Clients from "./Clients";
import InvoiceDetailPrintPreview from "./InvoiceDetailPrintPreview";
import Users from "./Users";
import Monitoring from "./Monitoring";
import Settings from "./Settings";
import Subscriptions from "../components/Subscriptions/Subscriptions";
import Box from "@material-ui/core/Box";
import routes from "../helpers/routes";
import PageTitle from "../components/PageTitle/PageTitle";
import WidgetTemplates from "./WidgetTemplates";
import Analytics from "./Analytics";
import MainNotification from "../components/MainNotification";
import Marketplace from "./Marketplace";
import AdminUsers from './AdminUsers';
import PaymentApproval from "./PaymentApproval";
import PaymentStatus from "./PaymentStatus";
import CampaignAdmin from "./CampaignAdmin";
import CampaignStatusReport from "./CampaignStatusReport";
// import Kpis from "./Kpis";
import Plans from "./Plans";
import Communications from "./Communications";
import {withUser} from "../providers/UserProvider";
import CoreUIReports from './CoreUIReports';
import LobbyMetrics from './LobbyMetrics';
import AnalyticsMonitoring from './AnalyticsMonitoring';
import MessagesContainer from "./MessagesContainer";

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        display: "flex",
        flexDirection: "column",
    },
    title: {
        flexGrow: 1,
    },
}));

const Admin = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const intl = useIntl();

    const userProfile = intl.formatMessage({
        id: 'User.Menu.Profile',
        defaultMessage: 'Profile'
    });
    const userAccount = intl.formatMessage({
        id: 'User.Menu.Account',
        defaultMessage: 'My Account'
    });

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <Router>
            <Switch>
                <Route path={routes.getAdminInvoicePrintPreview()}>
                    <InvoiceDetailPrintPreview/>
                </Route>
                <Route>
                    <div className={classes.root}>
                        <Box>
                            <AppBar position="relative">
                                <Toolbar>
                                    <AppMenu/>
                                    <Box flexGrow={1} px={1}>
                                        <PageTitle/>
                                    </Box>
                                    <div>
                                        <IconButton
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleMenu}
                                            color="inherit"
                                        >
                                            <AccountCircle/>
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={handleClose}>
                                            <MenuItem onClick={handleClose}>{userProfile}</MenuItem>
                                            <MenuItem onClick={handleClose}>{userAccount}</MenuItem>
                                        </Menu>
                                    </div>
                                </Toolbar>
                            </AppBar>
                        </Box>
                        <Box flexGrow={1}>
                            <Switch>
                                <Route path="/" exact>
                                    <AdminUsers/>
                                </Route>
                                <Route
                                    path={[routes.getAdminUsersUrl(), routes.getAdminUserDetailsUrl(), routes.getAdminUserRolesUrl()]}
                                    exact>
                                    <Users/>
                                </Route>
                                <Route path={routes.getLobbyMetricsPageUrl()}>
                                    <LobbyMetrics/>
                                </Route>
                                <Route path={routes.getCoreUiReportsPageUrl()}>
                                    <CoreUIReports/>
                                </Route>
                                <Route path={routes.getAnalyticsMonitoringPageUrl()}>
                                    <AnalyticsMonitoring/>
                                </Route>
                                <Route exact path={routes.getAdminSubscriptionsUrl()}>
                                    <Subscriptions/>
                                </Route>
                                <Route path={routes.getAdminMonitoringUrl()}>
                                    <Monitoring/>
                                </Route>
                                <Route path={routes.getAdminWidgetTemplatesUrl()}>
                                    <WidgetTemplates/>
                                </Route>
                                <Route path={routes.getAdminClientSummaryUrl()}>
                                    <Analytics/>
                                </Route>
                                <Route path={routes.getAdminSettingsUrl()}>
                                    <Settings/>
                                </Route>
                                <Route path={[routes.getAdminClientsUrl()+'/:clientId/:currentTab', routes.getAdminClientsUrl()]}>
                                    <Clients/>
                                </Route>
                                <Route
                                    path={[routes.getAdminMarketplaceCategoriesUrl(), routes.getAdminMarketplaceBrandsUrl()]}>
                                    <Marketplace/>
                                </Route>
                                <Route path={routes.getAccountAdminUsersUrl()}>
                                    <AdminUsers/>
                                </Route>
                                <Route exact path={routes.getPaymentApprovalUrl()}>
                                    <PaymentApproval/>
                                </Route>
                                <Route exact path={routes.getPaymentStatusUrl()}>
                                    <PaymentStatus/>
                                </Route>
                                <Route exact path={[routes.getBrandCampaignAdmin('/:tab'),
                                    routes.getBrandCampaignAdmin('/:tab/campaign/:campaignId'),
                                    routes.getBrandCampaignAdmin('/:tab/campaign/:campaignId/social-data/:gigId')]}>
                                    <CampaignAdmin/>
                                </Route>
                                <Route path={routes.getBrandCampaignStatusReport()}>
                                    <CampaignStatusReport/>
                                </Route>
                                <Route path={routes.getAdminPlansUrl()}>
                                    <Plans />
                                </Route>
                                <Route path={routes.getAdminCommunicationsUrl()}>
                                    <Communications/>
                                </Route>
                                <Route path={routes.getAdminMessagesUrl()}>
                                    <MessagesContainer/>
                                </Route>
                            </Switch>
                            <MainNotification/>
                        </Box>
                    </div>
                </Route>
            </Switch>
        </Router>
    )
}

export default withUser(Admin);