import React from 'react';
import {makeStyles, TableCell, TableHead, TableRow} from "@material-ui/core";
const useStyles =  makeStyles({
    noWrapText:{
        whiteSpace: 'nowrap'
    }
})
const CampaignAdminSeedingTableHead = () =>{
    const classes = useStyles();
    return <TableHead>
        <TableRow>
            <TableCell>
                Product
            </TableCell>
            <TableCell>
                Product SKU
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Options
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Quantity
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Status
            </TableCell>
            <TableCell/>
        </TableRow>
    </TableHead>
}
export default CampaignAdminSeedingTableHead;