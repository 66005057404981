import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    LinearProgress,
    makeStyles,
    TextField,
} from '@material-ui/core';

import {useDispatch, useSelector} from "react-redux";
import Client from "../../../helpers/api";

import {setNotification} from "../../../slices/notification";
import {
    campaignAdminSelector,
    getCampaignAdminSeedingList
} from "../../../slices/campaignAdmin";



const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(3)
    }

}))


const UpdateQuantityDialog = ({open, item, onClose}) => {
    const {row, campaignSeeding} = useSelector(campaignAdminSelector);
    const [isSaving, setIsSaving] = useState(false);
    const [quantity, setQuantity] = useState(item.quantity);
    const classes = useStyles();
    const dispatch = useDispatch();
    const onConfirmEvent = () => {
        if(!quantity){
            dispatch(setNotification("Enter quantity","error"));
            return;
        }
        setIsSaving(true);
        Client.CampaignApi.get(row.id)(
            campaign => {
                const {campaignProducts} = campaign.data;
                const campaignProductToEdit = campaignProducts.find(product => product.productEntityIdentifier === item.product.identifier);
                const inventoryToEdit = campaignProductToEdit.inventories.find(inventory => inventory.id === item.id);

                inventoryToEdit.quantity = quantity;

                Client.CampaignApi.productEdit()(
                        response => {
                            dispatch(getCampaignAdminSeedingList({...campaignSeeding, campaignId: row.id}));
                            setIsSaving(false);
                            onClose();
                        },
                        err => {
                            setIsSaving(false);
                            dispatch(setNotification("Something went wrong", "error"));
                        }

                )(campaignProductToEdit)
            },
            ()=>{
                setIsSaving(false);
                dispatch(setNotification("Something went wrong", "error"));
            }
        )

    }
    const handleClose = () => {
        onClose();
    }
    return <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle >Update quantity</DialogTitle>
            <DialogContent style={{minWidth: 400}}>
                <TextField
                    id="note"
                    label="Quantity"
                    placeholder={"Quantity"}
                    fullWidth
                    variant="outlined"
                    size={"small"}
                    margin={"normal"}
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}/>

            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button autoFocus onClick={handleClose} variant="contained">

                    Cancel

                </Button>
                <Button onClick={onConfirmEvent} style={{width: "98px"}} color="primary"
                        autoFocus variant="contained">
                    Update
                </Button>
            </DialogActions>
            {isSaving && <LinearProgress/>}
        </Dialog>
    </div>;
}

export default UpdateQuantityDialog;


