import {
    FormControl,
    FormControlLabel,
} from "@material-ui/core"
import React, {useEffect, useState} from "react";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {clientConfigSelector, getClientConfig, putDashboardsSection} from "../../../slices/clientConfig";
import {setNotification} from "../../../slices/notification";
import CardWithTitleAndAction from "../CardWithTitleAndAction";
import Switch from "@material-ui/core/Switch";
import Api from "../../../helpers/api";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import {isEmpty} from "../../../helpers/utils";

const CLinkSettings = () => {

    const dispatch = useDispatch();
    const {
        clientConfig,
        isClientConfigLoading,
        clientConfigErrorMessage,
        isClientConfigError,
        isClientConfigInitialized
    } = useSelector(clientConfigSelector);
    const [isShortLinkEnabled, setIsShortLinkEnabled] = useState(false);
    const [impactConfiguration, setImpactConfiguration] = useState(null);
    const [impactConfigurationChanges, setImpactConfigurationChanges] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [isImpactInitialized, setIsImpactInitialized] = useState(null);
    const [isImpactInProgress, setIsImpactInProgress] = useState(null);
    const [displayErrors, setDisplayErrors] = useState(false);

    const reloadData = useCallback(() => {
        setIsShortLinkEnabled(clientConfig.dashboards.ambassador.enable_product_short_link ?? false);
    }, [clientConfig]);

    useEffect(() => {
        if (isImpactInitialized === null) {
            setIsImpactInitialized(false);
            Api.shortLink.getImpactConfig(clientConfig.client.id)(
                ({data}) => {
                    setImpactConfiguration(data);
                    setImpactConfigurationChanges(data);
                },
                (error) => {
                    if (error.response.status !== 404) {
                        dispatch(setNotification("Error loading impact configuration", 'error'));
                        console.error(error);
                    }
                }
            ).finally(() => {
                setIsImpactInProgress(false);
                setIsImpactInitialized(true);
            })
        }
    }, [clientConfig.client.id, dispatch, isImpactInitialized]);

    useEffect(() => {
        setInProgress(isClientConfigLoading || isImpactInProgress);
    }, [isClientConfigLoading, isImpactInProgress]);

    useEffect(() => {
        if (isClientConfigInitialized) {
            reloadData();
        }
    }, [isClientConfigInitialized, reloadData]);

    const getIsImpactError = () => {
        return (
            impactConfigurationChanges.enabled &&
            (
                isEmpty(impactConfigurationChanges.program_id) ||
                isEmpty(impactConfigurationChanges.account_sid) ||
                isEmpty(impactConfigurationChanges.auth_token)
            )
        );
    }

    const Save = () => {
        if (getIsImpactError()) {
            setDisplayErrors(true);
            dispatch(setNotification("Please fix the error(s) highlighted in red.", "error"));
            return;
        }
        setInProgress(true);
        const newDashboardsSectionData = {
            ...clientConfig.dashboards,
            ambassador: {
                ...clientConfig.dashboards.ambassador,
                enable_product_short_link: isShortLinkEnabled,
            },
        };
        Promise.all([
            dispatch(putDashboardsSection(clientConfig.client.hash, newDashboardsSectionData)).then(() => {
                return dispatch(getClientConfig(clientConfig.client.hash));
            }),
            Api.shortLink.updateImpactConfig(clientConfig.client.id)(
                () => {
                    setHasChanges(false);
                },
                (error) => {
                    dispatch(setNotification("Error updating impact configuration.", "error"));
                    setHasChanges(true);
                    console.error(error);
                }
            )(impactConfigurationChanges)
        ]);
    }

    useEffect(() => {
        if (isClientConfigError) {
            dispatch(setNotification(clientConfigErrorMessage, 'error'));
        }
    }, [clientConfig, isClientConfigError, clientConfigErrorMessage, dispatch])

    const onChangeShortLinkSwitch = (e) => {
        setIsShortLinkEnabled(e.target.checked);
        setHasChanges(!(clientConfig?.dashboards?.ambassador?.enable_product_short_link === e.target.checked));
    }

    const onChangeImpactSwitch = (e) => {
        setImpactConfigurationChanges((config) => {
            return {...config, enabled: e.target.checked};
        });
        if (impactConfiguration) {
            setHasChanges(impactConfiguration?.enabled !== e.target.checked);
        } else {
            setHasChanges(true);
        }
    }

    const onChangeImpactConfig = (fieldName) => (event) => {
        const {value} = event.target;
        setImpactConfigurationChanges((config) => {
            return {...config, [fieldName]: (value ?? "")};
        });
        if (impactConfiguration) {
            setHasChanges(impactConfiguration[fieldName] !== event.target.value);
        } else {
            setHasChanges(true);
        }
    }

    return (
        <CardWithTitleAndAction title={'Short links'} onClickAction={Save} inProgress={inProgress}
                                testId={'c-link-settings'} disableAction={!hasChanges}>
            <FormControl fullWidth>
                <FormControlLabel margin="normal"
                                  onChange={onChangeShortLinkSwitch}
                                  control={<Switch checked={isShortLinkEnabled}/>}
                                  label="Enable c-links for creators"/>
            </FormControl>

            <FormControl fullWidth>
                <FormControlLabel margin="normal"
                                  onChange={onChangeImpactSwitch}
                                  control={<Switch checked={impactConfigurationChanges?.enabled === true}/>}
                                  label="Enable impact integration"/>
            </FormControl>

            {
                impactConfigurationChanges?.enabled &&
                <Box>
                    <TextField onChange={onChangeImpactConfig("program_id")} fullWidth
                               value={impactConfigurationChanges?.program_id ?? ""}
                               error={displayErrors && isEmpty(impactConfigurationChanges?.program_id)}
                               label={'Program ID'} margin={'dense'}/>
                    <TextField onChange={onChangeImpactConfig("account_sid")} fullWidth
                               value={impactConfigurationChanges?.account_sid ?? ""}
                               error={displayErrors && isEmpty(impactConfigurationChanges?.account_sid)}
                               label={'Account SID'} margin={'dense'}/>
                    <TextField onChange={onChangeImpactConfig("auth_token")} fullWidth
                               value={impactConfigurationChanges?.auth_token ?? ""}
                               error={displayErrors && isEmpty(impactConfigurationChanges?.auth_token)}
                               label={'Auth token'} margin={'dense'}/>
                </Box>
            }
        </CardWithTitleAndAction>
    );
}

export default CLinkSettings;