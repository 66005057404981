import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import withHelpIconForSwitch from "../hoc/withHelpIconForSwitch";

const StyledControlLabel = withStyles((theme) => ({
    labelPlacementStart: {
        marginLeft: 0,
        paddingLeft: 0,
        marginTop: "16px",
        marginBottom: "8px",
        display: "flex",
        '& span:nth-child(2)': {
            flexGrow: 1,
        },
    }
}))(FormControlLabel);

const LabeledSwitch = withHelpIconForSwitch(({label, marginBottom, ...props}) => {
    return (
        <StyledControlLabel
            control={<Switch color="primary" {...props}/>}
            label={label}
            labelPlacement="start"
            margin={"normal"} size={"small"}
            style={{marginBottom: marginBottom ?? "21px"}}
        />
    )
});

export default LabeledSwitch;