import React, {useEffect, useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    LinearProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import {isEmpty} from "../../../helpers/utils";
import TextFieldWithHelp from "../../TextFieldWithHelp";
import {setNotification} from "../../../slices/notification";
import Api from "../../../helpers/api";
import RequestError from "../../../helpers/RequestError";
import MarketplaceCategoryAutocompleteSelect from "../../MarketplaceCategoryAutocompleteSelect";
import BrandLogoUploader from "./BrandLogoUploader";
import Typography from "@material-ui/core/Typography";


const BrandDetailsModal = ({isOpen, onClose, onSaveSuccess, brand}) => {
    const dispatch = useDispatch();

    const [modalTitle, setModalTitle] = useState("");
    const [name, setName] = useState("");
    const [darkLogoFile, setDarkLogoFile] = useState(null);
    const [lightLogoFile, setLightLogoFile] = useState(null);
    const [lightLogoPreviewUrl, setLightLogoPreviewUrl] = useState(null);
    const [darkLogoPreviewUrl, setDarkLogoPreviewUrl] = useState(null);
    const [categories, setCategories] = useState([]);
    const [showErrors, setShowErrors] = useState(false);
    const [inProgress, setInProgress] = useState(true);

    useEffect(() => {
        if (isOpen === false) {
            setInProgress(false);
            setName("");
            setCategories([]);
        }
    }, [isOpen]);

    useEffect(() => {
        if (brand) {
            setName(brand.name);
            setCategories(brand.marketplace_categories);
            setModalTitle("Edit brand");
            setLightLogoPreviewUrl(brand.light_logo);
            setDarkLogoPreviewUrl(brand.dark_logo);
        } else {
            setName("");
            setCategories([]);
            setModalTitle("Add brand");
            setLightLogoPreviewUrl(null);
            setDarkLogoPreviewUrl(null);
        }
    }, [brand]);

    const showNotificationFromErrorResponse = (errorResponse) => {
        dispatch(setNotification((new RequestError(errorResponse)).getMessage(), "error"));
    }

    const isDarkLogoRequired = () => (brand && isEmpty(brand.dark_logo) && !darkLogoFile) || (!brand && !darkLogoFile);
    const isLightLogoRequired = () => (brand && isEmpty(brand.light_logo) && !lightLogoFile) || (!brand && !lightLogoFile);

    const onSave = () => {
        if (isEmpty(name) || categories.length === 0 || isDarkLogoRequired() || isLightLogoRequired()) {
            setShowErrors(true);
        } else {
            setInProgress(true);
            setShowErrors(false);
            (brand ? updateBrand() : createBrand()).then(() => setInProgress(false));
        }
    }

    const createBrand = () => {
        return Api.createMarketplaceBrand()(
            ({data}) => Promise.all([
                saveDarkLogo(data),
                saveLightLogo(data),
                saveMarketplaceCategories(data)
            ]),
            showNotificationFromErrorResponse
        )({name: name, sort_order: 0});
    }

    const updateBrand = () => {
        return Api.updateMarketplaceBrand(brand?.marketplace_brand_id)(
            ({data}) => {
                const promises = [];
                if (darkLogoFile) {
                    promises.push(saveDarkLogo(data));
                }
                if (lightLogoFile) {
                    promises.push(saveLightLogo(data));
                }
                promises.push(saveMarketplaceCategories(data));
                return Promise.all(promises);
            },
            showNotificationFromErrorResponse
        )({name: name});
    }

    const saveMarketplaceCategories = (marketplaceBrand) => {
        return Api.setMarketplaceCategoriesToMarketplaceBrand(marketplaceBrand.marketplace_brand_id)(
            () => onSaveSuccess(),
            showNotificationFromErrorResponse
        )({marketplace_categories: categories.map(c => c.marketplace_category_id)});
    }

    const saveDarkLogo = (brand) => {
        const formData = new FormData();
        formData.append("brand_dark_logo", darkLogoFile);
        return Api.saveMarketplaceBrandDarkLogo(brand.marketplace_brand_id)(
            () => onSaveSuccess(),
            showNotificationFromErrorResponse
        )(formData);
    }

    const saveLightLogo = (brand) => {
        const formData = new FormData();
        formData.append("brand_light_logo", lightLogoFile);
        return Api.saveMarketplaceBrandLightLogo(brand.marketplace_brand_id)(
            () => onSaveSuccess(),
            showNotificationFromErrorResponse
        )(formData);
    }

    const onChangeCategories = (marketplaceCategory) => {
        if (Array.isArray(marketplaceCategory)) {
            setCategories(marketplaceCategory);
        } else if (marketplaceCategory?.marketplace_category_id) {
            setCategories([marketplaceCategory]);
        } else {
            setCategories([]);
        }
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>{modalTitle}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin={"normal"}>
                        <TextFieldWithHelp label={"Brand name"} required autoFocus
                                           helperText={showErrors && name === "" ? "Name is required." : ""}
                                           error={showErrors && isEmpty(name)}
                                           onChange={(e) => setName(e.target.value)} value={name}
                                           disabled={inProgress}/>
                    </FormControl>
                    <MarketplaceCategoryAutocompleteSelect onChange={onChangeCategories}
                                                           disabled={inProgress}
                                                           value={brand?.marketplace_categories[0]}
                                                           error={showErrors && categories.length === 0}
                                                           helperText={showErrors && categories.length === 0 ? "Category is required." : ""}/>
                    <Box mt={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Box mb={1}>
                                    <Typography align={"center"}>White logo</Typography>
                                </Box>
                                <BrandLogoUploader onSelectImage={setLightLogoFile} disabled={inProgress} previewImageUrl={lightLogoPreviewUrl}/>
                                {showErrors && isLightLogoRequired() && <LogoFileErrorNotification/>}
                            </Grid>
                            <Grid item xs={6}>
                                <Box mb={1}>
                                    <Typography align={"center"}>Blue logo</Typography>
                                </Box>
                                <BrandLogoUploader onSelectImage={setDarkLogoFile} disabled={inProgress} previewImageUrl={darkLogoPreviewUrl}/>
                                {showErrors && isDarkLogoRequired() && <LogoFileErrorNotification/>}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="outlined" onClick={onClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button type="submit" color="primary" variant="contained" onClick={onSave} fullWidth
                                    disabled={inProgress}>
                                {brand ? "Save" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    );
}

const LogoFileErrorNotification = () => {
    return (
        <Box pl={2} pt={1}>
            <Typography variant={"caption"} color={"error"}>
                This image is required
            </Typography>
        </Box>
    )
}

export default BrandDetailsModal;