const getNestedAttribute = (sp, o, d = null) => sp.split('.').reduce((r, a) => (r && r[a]) ? r[a] : d, o);

class ResponseStatus {

    constructor(response) {
        this.response = {...response};
    }

    isError() {
        return getNestedAttribute('data.status', this.response) === 'failed';
    }

    getErrorMessage() {
        const firstViolationMessage = this.getFirstViolationMessage();
        if (this.isError() && firstViolationMessage) {
            return firstViolationMessage;
        } else if (this.isError()) {
            return getNestedAttribute('data.message', this.response);
        } else {
            return '';
        }
    }

    getCode() {
        return getNestedAttribute('data.code', this.response);
    }

    getViolations() {
        return getNestedAttribute('data.violations', this.response);
    }

    getFirstViolationMessage() {
        const violations = this.getViolations();
        if (violations) {
            return `${violations[0].propertyPath}: ${violations[0].message}`;
        } else {
            return null;
        }
    }
}

export default ResponseStatus;