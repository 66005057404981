import React from "react";
import { Chart } from "react-google-charts";
import { Paper } from "@material-ui/core";
import { Box } from "@material-ui/core";

const DAYS = 7;

const transformData = (salesArray, days) => {
    const recentSales = salesArray.slice(-days); // get last N days
    const chartData = [["Date", "Total Sales"]];

    recentSales.forEach((salesObject) => {
        const [date, sales] = Object.entries(salesObject)[0];

        const formattedDate = new Date(date).toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
        });

        const totalSales = sales.reduce((acc, sale) => acc + sale.sales, 0);
        chartData.push([formattedDate, totalSales]);
    });

    return chartData;
};

const options = {
    title: `Total sales, past ${DAYS} days`,
    legend: { position: "none" },
    isStacked: false,
    lineWidth: 0,
    areaOpacity: 1,
    colors: ["#00d5d1"],
};

export const TotalSalesChart = ({ salesArray }) => {
    const data = transformData(salesArray, DAYS);

    return (
        <Paper style={{ borderRadius: "6px" }}>
            <Box style={{ padding: "6px" }}>
                {salesArray.length ? (
                    <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="300px"
                        data={data}
                        options={options}
                    />
                ) : (
                    <Box justifyContent={"center"} display={"flex"} py={6}>
                        No data found
                    </Box>
                )}
            </Box>
        </Paper>
    );
};

export default TotalSalesChart;
