import React, {useEffect, useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    LinearProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import {isEmpty} from "../../../helpers/utils";
import TextFieldWithHelp from "../../TextFieldWithHelp";
import {setNotification} from "../../../slices/notification";
import Api from "../../../helpers/api";
import RequestError from "../../../helpers/RequestError";

const CategoryDetailsModal = ({isOpen, onClose, onSaveSuccess, category}) => {
    const dispatch = useDispatch();

    const [modalTitle, setModalTitle] = useState("");
    const [name, setName] = useState("");
    const [showErrors, setShowErrors] = useState(false);
    const [inProgress, setInProgress] = useState(true);

    useEffect(() => {
        if (isOpen === false) {
            setName("");
            setModalTitle("");
            setInProgress(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (category) {
            setName(category.name);
            setModalTitle("Edit category");
        } else {
            setName("");
            setModalTitle("Add category");
        }
    }, [category]);

    const showNotificationFromErrorResponse = (errorResponse) => {
        dispatch(setNotification((new RequestError(errorResponse)).getMessage(), "error"));
    }

    const onSave = () => {
        if (isEmpty(name)) {
            setShowErrors(true);
        } else {
            setInProgress(true);
            setShowErrors(false);
            (category ? updateCategory() : createCategory()).then(() => setInProgress(false));
        }
    }

    const createCategory = () => {
        return Api.createMarketplaceCategory()(
            () => onSaveSuccess(),
            showNotificationFromErrorResponse
        )({name: name, sort_order: 0});
    }

    const updateCategory = () => {
        return Api.updateMarketplaceCategory(category?.marketplace_category_id)(
            () => onSaveSuccess(),
            showNotificationFromErrorResponse
        )({name: name});
    }

    const onKeyDown = (e) => {
        if (e && e.keyCode === 13) {
            onSave();
        }
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>{modalTitle}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin={"normal"}>
                        <TextFieldWithHelp label={"Category name"} required autoFocus
                                           helperText={showErrors && name === "" ? "Name is required." : ""}
                                           error={showErrors && isEmpty(name)}
                                           onKeyDown={onKeyDown}
                                           onChange={(e) => setName(e.target.value)} value={name}
                                           disabled={inProgress}/>
                    </FormControl>
                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="outlined" onClick={onClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button type="submit" color="primary" variant="contained" onClick={onSave} fullWidth
                                    disabled={inProgress}>
                                {category ? "Save" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default CategoryDetailsModal;