import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Box from "@material-ui/core/Box";
import TablePagination from '@material-ui/core/TablePagination';
import {LinearProgress} from "@material-ui/core";
import {useDebounce} from "use-debounce";
import UsersTableHead from "./UsersTableHead";
import UsersTableToolbar from "./UsersTableToolbar";
import {setNotification} from "../../slices/notification";
import {useDispatch, useSelector} from "react-redux";
import {getUsers, usersSelector} from "../../slices/users";
import {isEmpty} from "../../helpers/utils";
import UsersTableRowMenu from "./UsersTableRowMenu";
import ChangeUserPasswordModal from "../ChangeUserPasswordModal/ChangeUserPasswordModal";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import routes from "../../helpers/routes";
import UserDetailsModal from "../UserDetails/UserDetailsModal";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    emptyTable: {
        height: '424px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
        '& td': {
            borderBottom: 0,
        }
    },
}));

const UsersTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {users, totalUsers, usersError, isUsersInProgress, isUserListInvalid} = useSelector(usersSelector);

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

    const [currentUser, setCurrentUser] = useState(null);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

    useEffect(() => {
        if (!isEmpty(usersError)) {
            setNotification(usersError, "error");
        }
    }, [usersError]);

    useEffect(() => {
        if (isUserListInvalid) {
            dispatch(getUsers(page, itemsPerPage, debouncedSearchTerm));
        }
    }, [isUserListInvalid, dispatch, page, itemsPerPage, debouncedSearchTerm]);

    useEffect(() => {
        setPage(1);
        dispatch(getUsers(1, itemsPerPage, debouncedSearchTerm));
    }, [dispatch, itemsPerPage, debouncedSearchTerm]);

    const handleChangePage = (event, newPage) => {
        if (newPage >= 0) {
            setPage(newPage + 1);
            dispatch(getUsers(newPage + 1, itemsPerPage, debouncedSearchTerm));
        }
    }

    const handleChangeRowsPerPage = (event) => {
        setItemsPerPage(event.target.value);
    }

    const onClickChangeUserPassword = (user) => {
        setCurrentUser(user);
        setIsChangePasswordModalOpen(true);
    }

    const onChangePasswordDone = () => {
        setCurrentUser(null);
        setIsChangePasswordModalOpen(false);
    }

    return (
        <Box>
            <UsersTableToolbar onChangeSearchTerm={setSearchTerm}/>
            <TableContainer>
                <Table size={'medium'} className={classes.table}>
                    <UsersTableHead/>
                    <TableBody>
                        {
                            users.map((user) => {
                                return (
                                    <TableRow key={`${user.client_id}-${user.user_id}`}>
                                        <TableCell align="left">{user.user_id}</TableCell>
                                        <TableCell align="left">
                                            <Link component={RouterLink} to={routes.getAdminUserDetailsUrl(user.user_id)}>
                                                {user.first_name} {user.last_name}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="left">{user.email}</TableCell>
                                        <TableCell align="center" padding={"checkbox"}>
                                            <UsersTableRowMenu
                                                onClickUpdatePassword={() => onClickChangeUserPassword(user)} user={user}/>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                        {
                            users.length >= 1 && (8 - users.length) > 0 && (
                                <TableRow style={{height: (53) * (8 - users.length)}}>
                                    <TableCell colSpan={6} style={{border: "none"}}/>
                                </TableRow>
                            )
                        }
                        {
                            users.length === 0 && (
                                <TableRow className={classes.emptyTable}>
                                    <TableCell colSpan={6}>{isUsersInProgress ? "" : "No users found."}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalUsers}
                rowsPerPage={itemsPerPage}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                backIconButtonProps={{disabled: isUsersInProgress || users.length === 0 || page === 1}}
                nextIconButtonProps={{disabled: isUsersInProgress || users.length === 0 || page >= Math.ceil(totalUsers / itemsPerPage)}}
            />
            {isUsersInProgress && <LinearProgress/>}
            <ChangeUserPasswordModal isOpen={isChangePasswordModalOpen}
                                     userId={currentUser ? currentUser.user_id : null} onClose={onChangePasswordDone}
                                     onSaveSuccess={onChangePasswordDone}/>
            <UserDetailsModal/>
        </Box>
    );
}

export default UsersTable;
