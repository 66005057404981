import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Chart } from "react-google-charts";
import {
    AppBar,
    Paper,
    Table,
    Toolbar,
    TableContainer,
    IconButton,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    Drawer,
    makeStyles,
    Button,
    Box,
    LinearProgress,
    Select,
    MenuItem,
    FormControl,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import moment from "moment";
import RunnerReportTable from "./runnerReportTable";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        justifyContent: "space-around",
        marginTop: theme.spacing(2),
    },
    dropdown: {
        marginLeft: "auto",
    },
}));

const CoreUIReportsTable = () => {
    const classes = useStyles();
    const [reportData, setReportData] = useState(null);
    const [backendAnalyticsData, setBackendAnalyticsData] = useState([]);
    const [open, setOpen] = useState(false);
    const [clickedSuite, setClickedSuite] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState("PROD");

    useEffect(() => {
        const fetchReport = async () => {
            try {
                const response = await fetch(
                    selectedBranch === "PROD"
                        ? "/master/merged-report.json"
                        : "/develop/merged-report.json"
                );
                const data = await response.json();
                setReportData(data);

                const backendAnalyticsRes = await fetch(
                    "/relayout/report.json"
                );
                const backendAnalyticsData = await backendAnalyticsRes.json();
                setBackendAnalyticsData([backendAnalyticsData]);

            } catch (error) {
                console.error("Error fetching reports:", error);
            }
        };

        fetchReport();
    }, [selectedBranch]);

    const handleOpen = useCallback((suite) => {
        setClickedSuite(suite);
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
        setClickedSuite(null);
    }, []);

    const combinedStats = useMemo(() => {
        const totalPasses =
            (reportData?.stats?.passes || 0) +
            (backendAnalyticsData?.[0]?.stats?.passes || 0);
        const totalFailures =
            (reportData?.stats?.failures || 0) +
            (backendAnalyticsData?.[0]?.stats?.failures || 0);
        const totalPending =
            (reportData?.stats?.pending || 0) +
            (backendAnalyticsData?.[0]?.stats?.pending || 0);

        return {
            passes: totalPasses,
            failures: totalFailures,
            pending: totalPending,
            testsRegistered:
                (reportData?.stats?.testsRegistered || 0) +
                (backendAnalyticsData?.[0]?.stats?.testsRegistered || 0),
        };
    }, [reportData, backendAnalyticsData]);

    const getFlakyText = (count) => {
        return count > 0 ? `${count} - Flaky` : "";
    };
    
    const flakyTestsText = useMemo(
        () => getFlakyText(reportData?.globalFlakyStats?.globalFlakyTestsCount),
        [reportData]
    );

    const summaryChartData = useMemo(
        () => [
            ["Tests", "Results Summary"],
            [
                `Passes: ${combinedStats.passes} / ` +
                    getFlakyText( reportData?.globalFlakyStats?.globalFlakyPasses),
                combinedStats.passes,
            ],
            [
                `Failures: ${combinedStats.failures} / ` +
                    getFlakyText(reportData?.globalFlakyStats?.globalFlakyFails),
                combinedStats.failures,
            ],
            [`Pending: ${combinedStats.pending}`, combinedStats.pending],
        ],
        [combinedStats, reportData]
    );

    const summaryChartOptions = useMemo(
        () => ({
            title: `${selectedBranch}: ${combinedStats.testsRegistered} Tests / ${flakyTestsText}`,
            slices: {
                0: { color: "#4caf50" },
                1: { color: "#f44336" },
                2: { color: "#ff9800" },
            },
        }),
        [selectedBranch, combinedStats, flakyTestsText]
    );

    const getPercentage = useCallback(
        (count) =>
            ((count / clickedSuite?.stats1?.testsRegistered) * 100).toFixed(0),
        [clickedSuite]
    );

    const barChartData = useMemo(
        () =>
            clickedSuite
                ? [
                      [
                          "Status",
                          "Count",
                          { role: "style" },
                          { role: "annotation" },
                      ],
                      [
                          "Pass",
                          clickedSuite?.stats1?.passes,
                          "#4caf50",
                          `${clickedSuite?.stats1?.passes} (${getPercentage(
                              clickedSuite?.stats1?.passes
                          )}%)`,
                      ],
                      [
                          "Pending",
                          clickedSuite?.stats1?.pending,
                          "#ff9800",
                          `${clickedSuite?.stats1?.pending} (${getPercentage(
                              clickedSuite?.stats1?.pending
                          )}%)`,
                      ],
                      [
                          "Failures",
                          clickedSuite?.stats1?.failures,
                          "#f44336",
                          `${clickedSuite?.stats1?.failures} (${getPercentage(
                              clickedSuite?.stats1?.failures
                          )}%)`,
                      ],
                  ]
                : [],
        [clickedSuite, getPercentage]
    );

    if (!reportData || !backendAnalyticsData) {
        return <LinearProgress />;
    }

    if(clickedSuite) console.log(clickedSuite);

    return (
        <>
            <Box display={"flex"} alignContent={"center"} alignItems={"center"}>
                <Chart
                    chartType="PieChart"
                    data={summaryChartData}
                    options={summaryChartOptions}
                    width={"100%"}
                    height={"400px"}
                />
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">
                                    Platform section:&nbsp;
                                    <FormControl className={classes.dropdown}>
                                        <Select
                                            value={selectedBranch}
                                            onChange={(e) =>
                                                setSelectedBranch(
                                                    e.target.value
                                                )
                                            }>
                                            <MenuItem value={"PROD"}>
                                                PROD
                                            </MenuItem>
                                            <MenuItem value={"STAGE"}>
                                                STAGE
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6"># of Tests</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">
                                    Tests passing
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">
                                    Tests pending
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">
                                    Tests failing
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">Last run</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData?.results?.map(({ suites }) => {
                            return suites?.map((suite) => {
                                const formattedEnd = moment(
                                    suite?.stats1?.end
                                ).format("LLL");
                                return (
                                    <TableRow
                                        key={suite.uuid}
                                        data-testid={"report-item"}
                                        onClick={() => handleOpen(suite)}
                                        style={{ cursor: "pointer" }}>
                                        <TableCell>
                                            <p>{suite.title}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>
                                                {suite?.stats1?.testsRegistered}
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{suite?.stats1?.passes} {suite?.suiteFlakeStats?.flakyPasses > 0 ? `/ ${suite.suiteFlakeStats.flakyPasses} flaking` : ""}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{suite?.stats1?.pending}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{suite?.stats1?.failures} {suite?.suiteFlakeStats?.flakyFails > 0 ? `/ ${suite.suiteFlakeStats.flakyFails} flaking` : ""}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{formattedEnd}</p>
                                        </TableCell>
                                    </TableRow>
                                );
                            });
                        })}
                        {backendAnalyticsData?.map((report, index) => {
                            const formattedEnd = moment(
                                report?.stats?.end
                            ).format("LLL");
                            return (
                                <TableRow
                                    key={index}
                                    data-testid={"report-item"}
                                    onClick={() =>
                                        handleOpen(report.results[0])
                                    }
                                    style={{ cursor: "pointer" }}>
                                    <TableCell>
                                        <p>Back end Analytics</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{report?.stats?.testsRegistered}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{report?.stats?.passes}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{report?.stats?.pending}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{report?.stats?.failures}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{formattedEnd}</p>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Drawer anchor="right" open={open} onClose={handleClose}>
                {clickedSuite && (
                    <>
                        <AppBar position={"relative"}>
                            <Toolbar>
                                <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    width="100%">
                                    <Typography>
                                        {clickedSuite?.title} ({selectedBranch})
                                    </Typography>
                                    <IconButton
                                        size={"small"}
                                        onClick={handleClose}>
                                        <CloseOutlined htmlColor={"#fff"} />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <Box
                            width="100vw"
                            bgcolor={"#f5f5f5"}
                            height={"100%"}
                            p={3}>
                            <Paper style={{ backgroundColor: "#fff" }}>
                                <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    mb={5}>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        width={"75%"}>
                                        <Chart
                                            chartType="ColumnChart"
                                            data={barChartData}
                                            options={{
                                                title: `Total: ${clickedSuite?.stats1?.testsRegistered} tests`,
                                                legend: { position: "none" },
                                                hAxis: {
                                                    title: "Status",
                                                    minValue: 0,
                                                },
                                            }}
                                            width={"100%"}
                                            height={"360px"}
                                        />
                                        <br />
                                        <Box
                                            className={classes.buttonContainer}>
                                            <Button
                                                onClick={() =>
                                                    window.open(
                                                        clickedSuite?.jobUrl,
                                                        "_blank"
                                                    )
                                                }
                                                style={{
                                                    backgroundColor: "#2196F3",
                                                    color: "#FFF",
                                                }}
                                                variant="contained">
                                                View CCI Job
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    window.open(
                                                        clickedSuite?.artifactUrl,
                                                        "_blank"
                                                    )
                                                }
                                                style={{
                                                    backgroundColor: "#4CAF50",
                                                    color: "#FFF",
                                                }}
                                                variant="contained">
                                                View Mocha Report
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        alignSelf={"flex-start"}
                                        width={"25%"}
                                        margin={"2rem 2rem 0 0"}>
                                        <div>
                                            <strong>Started:&nbsp;</strong>
                                            {moment(
                                                clickedSuite?.stats1?.start
                                            ).format("LLL")}
                                        </div>
                                        <br />
                                        <div>
                                            <strong>Ended:&nbsp;</strong>
                                            {moment(
                                                clickedSuite?.stats1?.end
                                            ).format("LLL")}
                                        </div>
                                        <br />
                                        <div>
                                            <strong>Duration:&nbsp;</strong>
                                            {moment
                                                .utc(
                                                    clickedSuite?.stats1
                                                        ?.duration
                                                )
                                                .format("HH:mm:ss.SSS")}
                                        </div>
                                        <br />
                                        {clickedSuite?.suiteFlakeStats
                                            ?.flakyTestsCount > 0 && (
                                            <>
                                                <Typography variant="body2">
                                                    <strong>
                                                        All Flaky Tests:&nbsp;
                                                    </strong>
                                                    {
                                                        clickedSuite
                                                            ?.suiteFlakeStats
                                                            ?.flakyTestsCount
                                                    }
                                                </Typography>
                                                {clickedSuite?.suiteFlakeStats
                                                    ?.flakyFails > 0 && (
                                                    <Typography variant="body2">
                                                        <strong>
                                                            Flaky fails:&nbsp;
                                                        </strong>
                                                        {
                                                            clickedSuite
                                                                ?.suiteFlakeStats
                                                                ?.flakyFails
                                                        }
                                                    </Typography>
                                                )}

                                                {clickedSuite?.suiteFlakeStats
                                                    ?.flakyPasses > 0 && (
                                                    <Typography variant="body2">
                                                        <strong>
                                                            Flaky passes:&nbsp;
                                                        </strong>
                                                        {
                                                            clickedSuite
                                                                ?.suiteFlakeStats
                                                                ?.flakyPasses
                                                        }
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </Box>
                                <RunnerReportTable suite={clickedSuite} />
                            </Paper>
                        </Box>
                    </>
                )}
            </Drawer>
        </>
    );
};
export default CoreUIReportsTable;
