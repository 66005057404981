import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector} from 'react-redux';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {FormControlLabel, Grid, LinearProgress, makeStyles, Radio, RadioGroup, Typography} from '@material-ui/core';
import Client from "../../../helpers/api";
import {campaignAdminSelector, reloadSection, updateOpenDialog} from "../../../slices/campaignAdmin";
import {setNotification} from "../../../slices/notification";


const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(3)
    }

}))

const ProductSeedingAmount = ({openDialog, campaign, onClose, onSuccess}) => {

    const [isSaving, setIsSaving] = useState(false);
    const {row, open, draft, pending, ended} = useSelector(campaignAdminSelector);

    const getSectionToRefresh = () =>{
        if(row.status === "Draft"){
            return draft
        }else if(row.status === "Pending"){
            return pending
        }else if(row.status === "Ended"){
            return ended
        }else{
            return open
        }
    }

    const [seeding, setSeeding] = useState(()=>{
        if(row.productSeedingRate){
            return "specific-amount";
        }else if(row.productSeedingPriceSheetFlag){
            return "price-sheet"
        }else{
            return null;
        }
    });
    const [specificAmount, setSpecificAmount] = useState(row.productSeedingRate);
    const currency_symbol = "$"
    const classes = useStyles();
    const dispatch = useDispatch();

    const onConfirmEvent = () => {
        if(seeding === null){
            dispatch(setNotification("Please select one option", "error"))
            return;
        }
        if(seeding === "specific-amount" && !specificAmount){
            dispatch(setNotification("Please enter the specific amount", "error"))
            return;
        }
        setIsSaving(true);
        const campaign = {};
        campaign.id = row.id;
        campaign.productSeedingPriceSheetFlag = seeding === "specific-amount" ? null : true;
        campaign.productSeedingRate = seeding === "specific-amount" ? specificAmount : null;

        Client.CampaignApi.edit()(
            response => {
                dispatch(updateOpenDialog({
                    dialog: "ProductSeedingAmount",
                    value: false,
                    row: null
                }));
                dispatch(reloadSection(getSectionToRefresh()));
                setIsSaving(false);
            },
            err=>{
                setIsSaving(false);
                dispatch(setNotification("Something went wrong, dates wasn't updated", "error"));
            }
        )(campaign)

    }
    const handleClose = () => {
        dispatch(updateOpenDialog({
            dialog: "ProductSeedingAmount",
            value: false,
            row: null
        }))
    }
    //specific amount should show the amount field
    return <div>
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle >Product seeding amount</DialogTitle>
            <DialogContent style={{minWidth: 400, maxWidth: 470}}>
                <Typography>
                    Enter the the total amount for the product seeding service.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RadioGroup row defaultValue={seeding} onChange={e=>setSeeding(e.target.value)}>
                            <FormControlLabel value="price-sheet" control={<Radio />} label="Price sheet" />
                            <FormControlLabel value="specific-amount" control={<Radio />} label="Specific amount" />
                        </RadioGroup>
                    </Grid>
                    {seeding === "specific-amount" && <Grid item xs={12}>
                        <CurrencyTextField
                            margin="none"
                            textAlign="left"
                            fullWidth
                            label="Amount"
                            variant="outlined"
                            value={specificAmount}
                            onChange={(event, value)=>setSpecificAmount(value)}
                            size="small" currencySymbol={currency_symbol} />
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button autoFocus onClick={handleClose} variant="contained">

                        Cancel

                </Button>
                <Button onClick={onConfirmEvent} style={{width: "98px"}} color="primary"
                        autoFocus variant="contained">
                    Save
                </Button>
            </DialogActions>
            {isSaving && <LinearProgress/>}
        </Dialog>
    </div>;
}

export default ProductSeedingAmount;