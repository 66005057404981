import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Box, LinearProgress, Paper} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import {SpeedDialIcon} from "@material-ui/lab";
import {setPageTitle} from "../slices/page";
import {useDispatch} from "react-redux";
import AddConfigurationModal from "../components/Communications/AddConfigurationModal";
import CommunicationsTable from "../components/Communications/CommunicationsTable";
import useNotification from "../helpers/useNotification";
import Api from "../helpers/api";
import DeleteCommunicationsModal from "../components/Communications/DeleteCommunicationsModal";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
}));

const Communications = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const notification = useNotification();

    const [isCommunicationsModalOpen, setIsCommunicationsModalOpen] = useState(false);
    const [currentConfiguration, setCurrentConfiguration] = useState(null);

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [inProgress, setInProgress] = useState(true);

    const [isInitialized, setIsInitialized] = useState(null);
    const [configurationList, setConfigurationList] = useState([]);
    const [totalConfigurations, setTotalConfigurations] = useState(0);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const fetchCommunications = useCallback((page, itemsPerPage) => {
        setInProgress(true);
        Api.communications.getConfigurationList(page, itemsPerPage)(
            ({data}) => {
                setConfigurationList(data.items);
                setTotalConfigurations(data.total);
            },
            (notification.captureError)
        ).finally(() => setInProgress(false));
    }, [notification.captureError]);

    useEffect(() => {
        dispatch(setPageTitle("Communications"));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);

    useEffect(() => {
        if (isInitialized === null) {
            setIsInitialized(false);
            fetchCommunications(1, 10);
        }
    }, [isInitialized, fetchCommunications]);

    const onChangePage = (event, newPage) => {
        setPage(newPage + 1);
        fetchCommunications(newPage + 1, itemsPerPage);
    };

    const onChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setPage(1);
        fetchCommunications(1, event.target.value);
    };

    useEffect(() => {
        dispatch(setPageTitle("Communications"));
        return () => dispatch(setPageTitle(''));
    }, [dispatch]);

    const onClickConfiguration = (configuration) => {
        setCurrentConfiguration(configuration);
        setIsCommunicationsModalOpen(true);
    }

    const onClickCreateConfiguration = () => {
        setCurrentConfiguration(null);
        setIsCommunicationsModalOpen(true);
    }

    const onCloseConfigurationModal = (reloadList) => {
        if (reloadList && currentConfiguration?.id) {
            fetchCommunications(page, itemsPerPage);
        } else if (reloadList) {
            fetchCommunications(1, itemsPerPage);
        }
        setIsCommunicationsModalOpen(false);
    }

    const onClickDeleteConfiguration = (configuration) => {
        setCurrentConfiguration(configuration);
        setIsDeleteModalOpen(true);
    }

    const onCloseDeleteModal = (reloadList) => {
        if (reloadList) {
            fetchCommunications(page, itemsPerPage);
        }
        setCurrentConfiguration(null);
        setIsDeleteModalOpen(false);
    }

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <CommunicationsTable onClickConfiguration={onClickConfiguration}
                                             configurations={configurationList} inProgress={inProgress}
                                             itemsPerPage={itemsPerPage} onChangeItemsPerPage={onChangeItemsPerPage}
                                             onChangePage={onChangePage} page={page}
                                             onClickDelete={onClickDeleteConfiguration}
                                             totalConfigurations={totalConfigurations}/>
                        {inProgress && <LinearProgress/>}
                    </Paper>
                    <Box height={80}>
                        {
                            isCommunicationsModalOpen &&
                            <AddConfigurationModal isOpen={true}
                                                   onClose={() => onCloseConfigurationModal(false)}
                                                   configurationId={currentConfiguration?.communication_config_id}
                                                   onSaveSuccess={() => onCloseConfigurationModal(true)}/>
                        }
                        {
                            isDeleteModalOpen &&
                            <DeleteCommunicationsModal isOpen={true} communication={currentConfiguration}
                                                       onDeleteSuccess={() => onCloseDeleteModal(true)}
                                                       onClose={() => onCloseDeleteModal(false)}/>
                        }
                        <Fab className={classes.fab} color={'primary'}
                             onClick={onClickCreateConfiguration}>
                            <SpeedDialIcon/>
                        </Fab>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Communications;