import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";

const CategoriesTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell align={'left'} width={"100%"}>
                    Name
                </TableCell>
                <TableCell align={'left'} style={{whiteSpace: "nowrap"}}>
                    Assigned brands
                </TableCell>
                <TableCell padding={"checkbox"} colSpan={2}/>
            </TableRow>
        </TableHead>
    );
}

export default CategoriesTableHead;