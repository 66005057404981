import {
    FormControl,
    FormControlLabel,
    TextField
} from "@material-ui/core"
import React, {useEffect, useState} from "react";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {isValidEmail} from "../../../helpers/validators";
import {clientConfigSelector, getClientConfig, putClientSection} from "../../../slices/clientConfig";
import {setNotification} from "../../../slices/notification";
import CardWithTitleAndAction from "../CardWithTitleAndAction";
import Switch from "@material-ui/core/Switch";

const EmailNotificationSettings = () => {

    const {
        clientConfig,
        isClientConfigLoading,
        clientConfigErrorMessage,
        isClientConfigError,
        isClientConfigInitialized
    } = useSelector(clientConfigSelector);
    const {client: {campaigns}} = clientConfig;
    const {management_emails} = campaigns;

    const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(management_emails.email_notifications_enabled);
    const [recipients, setRecipients] = useState(management_emails.recipients.join("\n"));
    const [hasChanges, setHasChanges] = useState(false);

    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        setInProgress(isClientConfigLoading);
    }, [isClientConfigLoading]);

    const reloadData = useCallback(() => {
        setEmailNotificationsEnabled(management_emails.email_notifications_enabled);
        setRecipients(management_emails.recipients.join("\n"));
    }, [management_emails]);

    useEffect(() => {
        if (isClientConfigInitialized) {
            reloadData();
        }
    }, [isClientConfigInitialized, reloadData])

    const Save = () => {
        const lines = recipients.split("\n").filter(f => f !== "");
        let commas = [];
        lines.forEach((item) => {
            const evaluateComma = item.split(",");
            commas = [...commas, ...evaluateComma]
        });
        const emails = commas.filter(c => c.trim() !== "" && isValidEmail(c.trim())).map(m => m.trim());
        const updatedCampaigns = {
            ...campaigns,
            management_emails: {
                email_notifications_enabled: emailNotificationsEnabled,
                recipients: emails
            }
        }
        const clientSectionUpdated = {...clientConfig.client, campaigns: updatedCampaigns};
        setInProgress(true);
        Promise.all([dispatch(putClientSection(clientSectionUpdated.hash, clientSectionUpdated))]).then(() => {
            dispatch(getClientConfig(clientConfig.client.hash)).then(()=>{
                setHasChanges(false);
            });
        });
    }

    useEffect(() => {
        if (isClientConfigError) {
            dispatch(setNotification(clientConfigErrorMessage, 'error'));
        }
    }, [clientConfig, isClientConfigError, clientConfigErrorMessage, dispatch])

    const onChangeSwitch = e =>{
        setEmailNotificationsEnabled(e.target.checked)
        setHasChanges(!(management_emails.email_notifications_enabled === e.target.checked && management_emails.recipients.trim() === recipients.trim()))
    }
    const onChangeEmails = e =>{
        setRecipients(e.target.value);
        setHasChanges(!(management_emails.email_notifications_enabled === emailNotificationsEnabled && management_emails.recipients.trim() === e.target.value.trim()))
    }

    return <CardWithTitleAndAction title={'Email notifications'} inProgress={inProgress} onClickAction={Save} testId={'email-notifications'} disableAction={!hasChanges}>

                    <FormControl fullWidth>
                        <FormControlLabel margin="normal"
                                          data-testId={'enable-campaign-management'}
                                          disabled={inProgress}
                                          onChange={onChangeSwitch}
                                          control={<Switch checked={emailNotificationsEnabled}/>}
                                          label="Enable campaign management emails"/>
                        <TextField
                            margin={'dense'}
                            label={"Recipients"}
                            rows={4}
                            maxRows={15}
                            data-testId={'email-recipients'}
                            variant={"outlined"}
                            value={recipients}
                            inputProps={{
                                readOnly: inProgress
                            }}
                            onChange={onChangeEmails}
                            FormHelperTextProps={{
                                variant: 'standard'
                            }}
                            fullWidth
                            multiline
                            disabled={inProgress}
                        />
                    </FormControl>
    </CardWithTitleAndAction>

}

export default EmailNotificationSettings;
