import {Box, IconButton, Link, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import React from "react";
import {strings} from "../../../helpers/utils";
import CampaignInventorySeedingStatusEnum
    from "../../../helpers/CampaignInventorySeedingStatusEnum";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const CampaignAdminSeedingTableRow = ({item, onOpenStatus, onOpenQuantity}) => {
    const {product, sku, quantity, variantValues, seedingStatus} = item;
    const [anchorEl, setAnchorEl] = React.useState(null);
    // seedingStatus, exceptionDetails
    const getStatus = () => {
        if(seedingStatus){
            if(seedingStatus === CampaignInventorySeedingStatusEnum.ISSUE){
                return <Box>
                    {strings.insertSpacesToCamelCase(seedingStatus)}
                    <Link component={"button"}>DETAILS</Link>
                </Box>
            }else{
                return strings.insertSpacesToCamelCase(seedingStatus)
            }
        }else{
            return "-";
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    }
    const onClickStatus = item =>{
        onOpenStatus(item);
        setAnchorEl(null);
    }
    const onClickQuantity = item => {
        onOpenQuantity(item);
        setAnchorEl(null);
    }

    return <TableRow>
        <TableCell>
            <Box >{product.name}</Box>
        </TableCell>
        <TableCell>
            {sku}
        </TableCell>
        <TableCell>
            SKU: {product.sku} {variantValues.map(variant=>`${variant.identifier}: ${variant.value}`).join(", ")}
        </TableCell>
        <TableCell>
            {quantity}
        </TableCell>
        <TableCell>
            {getStatus()}
        </TableCell>
        <TableCell>
            <IconButton onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={()=>onClickStatus(item)}>Update status</MenuItem>
                <MenuItem onClick={()=>onClickQuantity(item)}>Update quantity</MenuItem>
            </Menu>
        </TableCell>
    </TableRow>
}

export default CampaignAdminSeedingTableRow;