import {Chip, Divider, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {isEmpty} from "../helpers/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTypography-root': {
            whiteSpace: "nowrap",
        },
        '& > .MuiBox-root': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(0.7),
            paddingBottom: theme.spacing(0.7),
        },
    },
    chips: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const ActiveFilters = ({filters, onRemoveFilter, divider, hideMainLabel}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box display={"flex"}>
                {
                    !hideMainLabel &&
                    <Box pr={2} alignSelf={"center"}>
                        <Typography component={"span"} variant={"body1"}>
                            Active filters:
                        </Typography>
                    </Box>
                }
                <Box flexGrow={1} className={classes.chips}>
                    {filters.map(filter => <Chip label={isEmpty(filter.value) ? filter.label : `${filter.label}: ${filter.value}`}
                                                 key={filter.label}
                                                 onDelete={() => onRemoveFilter(filter.name)} color={"primary"}/>)}
                </Box>
            </Box>
            {divider && <Divider/>}
        </Box>
    )
}

export default ActiveFilters;
