import {createSlice} from "@reduxjs/toolkit";
import api from "../helpers/api";
import RequestError from "../helpers/RequestError";
const initialState = {
    paymentsOrdersList: [],
    isLoading: true,
    paymentsOrdersFilters: {
        page: 1,
        per_page: 10,
        search: null,
        client_id: null,
        user_id: null,
        status: 'pending',
        sort: "order_id:desc",

    },
    paymentsOrdersTotal:0,
    error: false,
    errorMessage: "",
    totalApproved: 0,
    totalManual: 0,
    totalPaid: 0,
    totalNotPayable: 0,
    totalReject: 0
}

const paymentOrdersSlice = createSlice({
    name: "paymentOrders",
    initialState,
    reducers: {
        getPaymentOrdersSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.paymentsOrdersList = payload.items;
            state.paymentsOrdersTotal = payload.total;
        },
        getPaymentOrdersFailed: (state, {payload}) => {
            state.isLoading = false;
            state.error = true;
            state.errorMessage = payload;
        },
        resetPaymentOrders: (state, {payload}) => {
            if (payload) {
                state.paymentsOrdersFilters = {...initialState.paymentsOrdersFilters, status:payload};
            }else{
                state.paymentsOrdersFilters = initialState.paymentsOrdersFilters;
            }
            state.paymentsOrdersList = [];
            state.paymentsOrdersTotal = 0;
        },
        updateOrdersParameters: (state, {payload}) => {
            state.paymentsOrdersFilters = payload;
        },
        startLoading: (state) => {
            state.isLoading = true;
        },
        updateTotal: (state, {payload}) => {
            state[payload.section] = payload.total;
        }
    }
});

export default paymentOrdersSlice.reducer;

export const paymentOrdersSelector = state => state.paymentOrders;

const {
    getPaymentOrdersSuccess,
    getPaymentOrdersFailed,
    startLoading,
    updateOrdersParameters,
    resetPaymentOrders,
    updateTotal
} = paymentOrdersSlice.actions;

export const getPaymentOrdersList = (params) => dispatch => {
    dispatch(startLoading());
    return api.payment.status(params)(
        response => dispatch(getPaymentOrdersSuccess(response.data)),
        error => dispatch(getPaymentOrdersFailed(new RequestError(error).getMessage()))
    )
}

export const updateOrdersRequestParameters = (params) => dispatch => {
    return dispatch(updateOrdersParameters(params));
}

export const resetState = (status) => dispatch => dispatch(resetPaymentOrders(status));

export const getTotalApproved = params => dispatch => {
    return api.payment.status(params)(
        response => dispatch(updateTotal({section:'totalApproved', total:response.data.total})),
        error => console.log('failed getting the totals')
    )
}

export const getTotalManual = params => dispatch => {
    return api.payment.status(params)(
        response => dispatch(updateTotal({section:'totalManual', total:response.data.total})),
        error => console.log('failed getting the totals')
    )
}

export const getTotalPaid = params => dispatch => {
    return api.payment.status(params)(
        response => dispatch(updateTotal({section:'totalPaid', total:response.data.total})),
        error => console.log('failed getting the totals')
    )
}

export const getTotalNotPayable = params => dispatch => {
    return api.payment.status(params)(
        response => dispatch(updateTotal({section:'totalNotPayable', total:response.data.total})),
        error => console.log('failed getting the totals')
    )
}

export const getTotalReject = params => dispatch => {
    return api.payment.status(params)(
        response => dispatch(updateTotal({section:'totalReject', total:response.data.total})),
        error => console.log('failed getting the totals')
    )
}
