import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux'
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {setPageTitle} from "../slices/page";
import {Paper} from "@material-ui/core";
import CampaignAdminWrapper from "../components/CampaignAdmin/CampaignAdminWrapper";
import Shared from "../helpers/Shared";
import Client from "../helpers/api";
import {withUser} from "../providers/UserProvider";
import LoadingApp from "../components/LoadingApp";
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },
}));

const CampaignAdmin = ({user}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [campaignSession, setCampaignSession] = useState(false);

    useEffect(() => {
        dispatch(setPageTitle("Campaigns"));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);


    useEffect(()=>{
        if(!campaignSession){
            const request = {};
            request.cookie = document.cookie;
            request.userId = user.user_id;
            Client.UserApi.login()(
                session => {
                    Shared.session = session.data;
                    setCampaignSession(true);
                },
                error =>{
                    setCampaignSession(false);
                })
            (request)
        }

    },[campaignSession, user])


    if(!campaignSession)
        return <LoadingApp />
    else
        return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <CampaignAdminWrapper/>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

export default withUser(CampaignAdmin);