import makeStyles from "@material-ui/core/styles/makeStyles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .rowSelected .MuiTableCell-root": {
            whiteSpace: "nowrap",
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
        },
    },
}));

const UserRolesTableHead = () => {
    const classes = useStyles();
    return (
        <TableHead className={classes.root}>
                <TableRow>
                    <TableCell align={'left'}>
                        Role
                    </TableCell>
                    <TableCell align={'left'}>
                        Client ID
                    </TableCell>
                    <TableCell align={'left'}>
                        Role ID
                    </TableCell>
                    <TableCell>
                        Type
                    </TableCell>
                    <TableCell align={'left'}>
                        Status
                    </TableCell>
                    <TableCell align={'left'}>
                        Created on
                    </TableCell>
                    <TableCell padding={"checkbox"}/>
                </TableRow>
        </TableHead>
    );
}

export default UserRolesTableHead;