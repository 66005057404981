import {isEmpty, object as objectUtils} from './utils';

class RequestError {
    
    constructor(errorResponse) {
        this.errorResponse = errorResponse;
    }

    isHydraResponse() {
        return ['hydra:Error', 'ConstraintViolationList'].includes(objectUtils.get('response.data.@type', this.errorResponse));
    }

    getMessage() {
        if (this.isHydraResponse()) {
            const title = objectUtils.get('response.data.hydra:title', this.errorResponse);
            const description = objectUtils.get('response.data.hydra:description', this.errorResponse);
            if (!isEmpty(description)) {
                return description;
            } else if (!isEmpty(title)) {
                return title;
            } else {
                return "Unknown error";
            }
        } else if (objectUtils.get('response.data.message', this.errorResponse, false)) {
            return objectUtils.get('response.data.message', this.errorResponse);
        } else {
            return objectUtils.get('message', this.errorResponse, 'Unknown error');
        }
    }

    getHttpStatusCode() {
        return objectUtils.get('response.status', this.errorResponse);
    } 
}

export default RequestError;