import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogTitle, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Api from "../../../helpers/api";
import RequestError from "../../../helpers/RequestError";
import ResponseStatus from "../../../helpers/ResponseStatus";
import {isEmpty} from "../../../helpers/utils";
import {useDispatch} from "react-redux";
import {setNotification} from "../../../slices/notification";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import moment from "moment";

const useStyles = makeStyles({
    addForm: {
        '& .MuiInputBase-root': {
            paddingLeft: "8px"
        }
    }
})

const CampaignCreatorManualSocialMetricsDialog = ({isOpen, onClose, onSaveSuccess, gig, post}) => {
    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const classes = useStyles();
    const [postDate, setPostDate] = useState(null);
    const [views, setViews] = useState("");
    const [likes, setLikes] = useState("");
    const [impressions, setImpressions] = useState("");
    const [shares, setShares] = useState("");
    const [comments, setComments] = useState("");
    const [saves, setSaves] = useState("");

    useEffect(() => {
        if (!isOpen) {
            setViews("");
            setLikes("");
            setImpressions("");
            setShares("");
            setComments("");
            setSaves("");
        }
        setPostDate(moment());
    }, [isOpen]);

    useEffect(() => {
        if (showErrors && (!isEmpty(views) || !isEmpty(likes) || !isEmpty(impressions) || !isEmpty(shares) || !isEmpty(comments) || !isEmpty(saves))) {
            setShowErrors(false);
        }
    }, [views, likes, impressions, shares, comments, saves, showErrors]);

    const onSave = () => {
        if (isEmpty(postDate)) {
            setShowErrors(true);
            dispatch(setNotification("Post date is required", "error"));
            return;
        }
        if (isEmpty(views) && isEmpty(likes) && isEmpty(impressions) && isEmpty(shares) && isEmpty(comments) && isEmpty(saves)) {
            setShowErrors(true);
            dispatch(setNotification("At least one value greater than 0 is required", "error"));
            return;
        }
        setShowErrors(false);
        setInProgress(true);
        Api.social.appendManualSocialMetrics(post.post_id, gig.campaign.brand.id)(
            (response) => {
                const respStatus = new ResponseStatus(response);
                if (respStatus.isError()) {
                    dispatch(setNotification(respStatus.getErrorMessage(), "error"));
                } else {
                    onSaveSuccess();
                }
            },
            (error) => dispatch(setNotification((new RequestError(error)).getMessage(), "error"))
        )({
            "scan_time": postDate.toISOString(),
            "impressions": impressions || 0,
            "shares": shares || 0,
            "video_views": views || 0,
            "comments": comments || 0,
            "likes": likes || 0,
            "saves": saves || 0
        }).then(() => setInProgress(false));
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                Enter social data
            </DialogTitle>
            <Box px={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                disabled={inProgress}
                                format={"MM/DD/yyyy HH:mm"}
                                maxDate={moment()}
                                fullWidth
                                error={isEmpty(postDate) && showErrors}
                                value={postDate}
                                onChange={setPostDate}
                                label={'Data entry date'}
                                placeholder={'Enter data entry date...'}/>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.addForm}>
                        <CurrencyTextField
                            disabled={inProgress}
                            textAlign={'left'}
                            variant={'outlined'}
                            minimumValue={"0"}
                            decimalPlaces={0}
                            currencySymbol={""}
                            value={impressions}
                            error={isEmpty(impressions) && showErrors}
                            onChange={(event, value) => setImpressions(value)}
                            fullWidth label={'Impressions'} placeholder={'Enter the impressions...'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.addForm}>
                        <CurrencyTextField
                            disabled={inProgress}
                            value={shares}
                            error={isEmpty(shares) && showErrors}
                            textAlign={'left'}
                            variant={'outlined'}
                            minimumValue={"0"}
                            decimalPlaces={0}
                            currencySymbol={""}
                            onChange={(event, value) => setShares(value)}
                            fullWidth label={'Shares'} placeholder={'Enter the shares...'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.addForm}>
                        <CurrencyTextField
                            disabled={inProgress}
                            textAlign={'left'}
                            variant={'outlined'}
                            minimumValue={"0"}
                            decimalPlaces={0}
                            currencySymbol={""}
                            value={views}
                            error={isEmpty(views) && showErrors}
                            onChange={(event, value) => setViews(value)}
                            fullWidth label={'Video views'} placeholder={'Enter the video views...'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.addForm}>
                        <CurrencyTextField
                            disabled={inProgress}
                            value={comments}
                            error={isEmpty(comments) && showErrors}
                            textAlign={'left'}
                            variant={'outlined'}
                            minimumValue={"0"}
                            decimalPlaces={0}
                            currencySymbol={""}
                            onChange={(event, value) => setComments(value)}
                            fullWidth label={'Comments'} placeholder={'Enter the comments...'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.addForm}>
                        <CurrencyTextField
                            disabled={inProgress}
                            textAlign={'left'}
                            variant={'outlined'}
                            minimumValue={"0"}
                            decimalPlaces={0}
                            currencySymbol={""}
                            value={likes}
                            error={isEmpty(likes) && showErrors}
                            onChange={(event, value) => setLikes(value)}
                            fullWidth label={'Likes'} placeholder={'Enter the likes...'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.addForm}>
                        <CurrencyTextField
                            disabled={inProgress}
                            textAlign={'left'}
                            variant={'outlined'}
                            minimumValue={"0"}
                            decimalPlaces={0}
                            currencySymbol={""}
                            value={saves}
                            error={isEmpty(saves) && showErrors}
                            onChange={(event, value) => setSaves(value)}
                            fullWidth label={'Saves'} placeholder={'Enter the saves...'}/>
                    </Grid>
                </Grid>

            </Box>
            <DialogActions>
                <Box width={"100%"} p={1.5}>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item xs={3}>
                            <Button variant="outlined" onClick={onClose} disabled={inProgress} fullWidth>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button type="submit" color="primary" variant="contained"
                                    disabled={inProgress}
                                    onClick={onSave} fullWidth>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogActions>
            {inProgress && <LinearProgress/>}
        </Dialog>
    )
}

export default CampaignCreatorManualSocialMetricsDialog;
