import React from "react";
import {Tabs, Tab, Box} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const tabProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const CampaignAdminTabs = ({value, onChange}) => {
    return (
        <Box flexGrow={1}>
            <Tabs onChange={(e, newValue) => onChange(newValue)}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  value={value}>
                <Tab label={"Draft"} {...tabProps(0)}/>
                <Tab label={"Active"} {...tabProps(2)} />
                <Tab label={"Ended"} {...tabProps(4)} />
                <Tab label={"Completed"} {...tabProps(5)} />
            </Tabs>
            <Divider/>
        </Box>
    );
}

export default CampaignAdminTabs;
