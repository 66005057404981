import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Dialog,
    Divider,
    LinearProgress,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TableCell from "@material-ui/core/TableCell";
import api from "../../../helpers/api";
import CampaignCreatorManualSocialDataLogEntryRow from "./CampaignCreatorManualSocialDataLogEntryRow";
import {setNotification} from "../../../slices/notification";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    table: {
        "& .MuiTableRow-root .MuiTableCell-root": {
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
        },
        "& .MuiTableRow-root .MuiTableCell-root:first-child": {
            paddingLeft: "16px",
        },
        "& .MuiTableRow-root .MuiTableCell-root:last-child": {
            paddingRight: "16px",
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: 0,
        }
    },
    title: {
        flex: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        minWidth: 440,
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "calc(100vw)",
        },
        [theme.breakpoints.up('sm')]: {
            width: 400,
        },
    },
}));


const CampaignCreatorManualSocialDataLogDialog = ({isOpen, onClose, gigToRender, selected, onDataLogChange}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [logHistory, setLogHistory] = useState([]);
    const [inProgress, setInProgress] = useState(true);

    const refreshLogList = useCallback(() => {
        setInProgress(true);
        const params = `client_id=${gigToRender.campaign.brand.id}&page=1&per_page=20`;
        api.social.getManualSocialDataLogList(selected.post_id, params)(
            (response) => setLogHistory(response.data.items),
            () => dispatch(setNotification("Something went wrong", "error"))
        ).then(() => setInProgress(false));
    }, [selected, gigToRender, dispatch]);

    useEffect(() => {
        refreshLogList();
    }, [refreshLogList]);

    const onEntryChange = () => {
        refreshLogList();
        onDataLogChange();
    }

    return <Dialog
        maxWidth={'xl'}
        open={isOpen} onClose={onClose}>
        <Box display={"flex"} p={2} justifyContent={'space-between'}>
            <Typography variant="h6" component={"div"} className={classes.title}>
                Social data log
            </Typography>
            <IconButton size={'small'} edge="end" color="inherit" onClick={onClose}>
                <CloseIcon fontSize={'small'}/>
            </IconButton>
        </Box>
        <Divider/>
        <TableContainer>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Timestamp
                        </TableCell>
                        <TableCell>
                            Impressions
                        </TableCell>
                        <TableCell className={classes.noWrapText}>
                            Video views
                        </TableCell>
                        <TableCell>
                            Likes
                        </TableCell>
                        <TableCell className={classes.noWrapText}>
                            Shares
                        </TableCell>
                        <TableCell className={classes.noWrapText}>
                            Comments
                        </TableCell>
                        <TableCell>
                            Saves
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.content}>
                    {
                        logHistory.map((entry, index) => (
                            <CampaignCreatorManualSocialDataLogEntryRow entry={entry} key={index} onSuccess={onEntryChange}/>
                        ))
                    }
                    {
                        logHistory.length === 0 &&
                        <TableCell colSpan={8}>
                            <Typography align={'center'} variant={"body2"}>
                                No logs found.
                            </Typography>
                        </TableCell>
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {inProgress && <LinearProgress/>}
    </Dialog>
}


export default CampaignCreatorManualSocialDataLogDialog;
