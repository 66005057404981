import React from "react";
import TextField from "@material-ui/core/TextField";
import withHelpIconForInput from "../hoc/withHelpIconForInput";

const TextFieldWithHelp = withHelpIconForInput(({shrinkLabel, inputStyle, min, max, ...props}) => {
    return (
        <TextField
            {...props}
            variant="outlined"
            size="small"
            fullWidth
            InputLabelProps={{
                shrink: shrinkLabel
            }}
            inputProps={{
                style: inputStyle,
                min,
                max,
            }}
        />
    )
});

export default TextFieldWithHelp;