import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Box from "@material-ui/core/Box";
import CommunicationsTableHead from "./CommunicationsTableHead";
import TablePagination from '@material-ui/core/TablePagination';
import CommunicationsTableRow from "./CommunicationsTableRow";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    emptyTable: {
        height: '424px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
        '& td': {
            borderBottom: 0,
        }
    },
}));

const CommunicationsTable = ({
                                 onClickConfiguration,
                                 configurations,
                                 totalConfigurations,
                                 inProgress,
                                 page,
                                 itemsPerPage,
                                 onChangePage,
                                 onChangeItemsPerPage,
                                 onClickDelete
                             }) => {
    const classes = useStyles();
    return (
        <Box>
            <TableContainer>
                <Table size={'medium'} className={classes.table}>
                    <CommunicationsTableHead/>
                    <TableBody>
                        {
                            configurations.length > 0 &&
                            configurations.map((configuration) => (
                                <CommunicationsTableRow
                                    key={configuration.communication_config_id}
                                    configuration={configuration}
                                    onClickDelete={() => onClickDelete(configuration)}
                                    onClick={() => onClickConfiguration(configuration)}/>
                            ))
                        }
                        {
                            configurations.length === 0 &&
                            <TableRow className={classes.emptyTable}>
                                {inProgress && <TableCell colSpan={2}/>}
                                {!inProgress && <TableCell colSpan={2}>No configurations found.</TableCell>}
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalConfigurations}
                rowsPerPage={itemsPerPage}
                page={page ? page - 1 : 0}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeItemsPerPage}
                backIconButtonProps={{disabled: inProgress || page === 1}}
                nextIconButtonProps={{disabled: inProgress || page === Math.ceil(totalConfigurations / itemsPerPage) || totalConfigurations === 0}}
            />
        </Box>
    );
};

export default CommunicationsTable;