import {LinearProgress} from "@material-ui/core";
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const UserRoleDeletePrompt = ({onRemove, inProgress, isOpen, onClose}) => {
    return (
        <Dialog open={isOpen}>
            <DialogTitle style={{cursor: 'move'}}>
                Remove role for user?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    If you remove this role, user will not be able to login into respective dashboard.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={inProgress} autoFocus>
                    Cancel
                </Button>
                <Button onClick={onRemove} disabled={inProgress} color={"primary"}>
                    Remove role
                </Button>
            </DialogActions>
            {inProgress && <LinearProgress/>}
        </Dialog>
    );
}

export default UserRoleDeletePrompt;