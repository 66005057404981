import TimeAgo from "react-timeago";
import React from "react";
import moment from "moment";

const ChatTimeAgo = ({date}) => {
    const seconds = moment.duration(moment().diff(moment(date))).as("seconds");
    const hours = moment.duration(moment().diff(moment(date))).as("hours");

    if (seconds < 60) {
        return "Just now";
    } else if (hours <= 1) {
        return <TimeAgo date={date} live/>;
    } else if (parseInt(moment(date).format("DDD")) === parseInt(moment().format("DDD"))) {
        return moment(date).format("h:mm a");
    } else if (parseInt(moment(date).format("DDD")) === parseInt(moment().format("DDD")) - 1) {
        return `Yesterday at ${moment(date).format("h:mm a")}`;
    } else {
        return moment(date).format("M/D/yyyy h:mm a");
    }
}

export default ChatTimeAgo;