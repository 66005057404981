import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
    AppBar,
    Box,
    Button,
    Container, Divider,
    IconButton,
    Paper,
    TablePagination,
    Toolbar,
    Typography
} from "@material-ui/core";
import {CloseOutlined} from "@material-ui/icons";
import {
    campaignAdminSelector,
    getCampaignAdminShipmentsList,
    updateOpenDialog,
    updateSection
} from "../../../slices/campaignAdmin";
import {useDispatch, useSelector} from "react-redux";
import CampaignAdminShipmentsTable from "../CampaignAdminShipments/CampaignAdminShipmentsTable";
import SearchInput from "../../SearchInput";
import Shared from "../../../helpers/Shared";
import routes from "../../../helpers/routes";

const CampaignAdminShipmentsDialog = ({open}) => {

    const [initialized, setInitialized] = useState(false);
    const dispatch = useDispatch();
    const {row, campaignShipments} = useSelector(campaignAdminSelector);
    const {total, items, smartFilter, page, per_page} = campaignShipments;
    useEffect(()=>{
        if(!initialized){
            setInitialized(true);
            dispatch(updateSection("campaignSeeding", {campaignId: row.id}));
            dispatch(getCampaignAdminShipmentsList({...campaignShipments, campaignId: row.id}));
        }
    },[initialized, dispatch, campaignShipments, row])
    const handleClose = () => {
        dispatch(updateOpenDialog({
            dialog: "CampaignAdminShipments",
            value: false,
            row: null
        }))
    }
    const handleChangePage = (event, page) => {
        dispatch(updateSection("campaignSeeding", {page: page}));
        dispatch(getCampaignAdminShipmentsList({...campaignShipments, page: page}));
    }

    const handleChangeRowsPerPage = event => {
        const {value} = event.target;
        dispatch(updateSection("campaignSeeding", {per_page: value, page:0}));
        dispatch(getCampaignAdminShipmentsList({...campaignShipments, page: 0, per_page: value}));
    }

    const onChangeSearchTerm = value=>{
        dispatch(updateSection("campaignSeeding", {smartFilter: value, page:0}));
        dispatch(getCampaignAdminShipmentsList({...campaignShipments, page: 0, smartFilter: value}));
    }
    const getCsvLink = () => {
        const campaignId = row.id;
        const sessionToken = `${Shared.session.id}-${Shared.session.hash}`;
        const filename = 'shipping_manifest.csv';

        let url = '/file_asset/csv/shipping_manifest/' +
            (campaignId ? encodeURI(campaignId) : '') + '/' +
            (sessionToken ? encodeURI(sessionToken) : '') + '/' +
            (filename ? encodeURI(filename) : '');

        url =  routes.getCampaignsServiceUrl(url);

        return url;
    }
    return <div>
        <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar position={"relative"}>
                <Toolbar>
                    <IconButton size={'small'} onClick={handleClose}><CloseOutlined htmlColor={"#fff"} /></IconButton> <Typography>Shipments</Typography>
                </Toolbar>
            </AppBar>
            <Box display={"flex"} justifyContent={"center"} p={2}>
                <Button componet={"a"} href={getCsvLink()} target={"_blank"} variant={"contained"}>EXPORT</Button>
            </Box>
            <Box bgcolor={"#f5f5f5"} height={"100%"} p={2}>
                <Container maxWidth={'lg'}>
                    <Paper>
                        <Box>
                            <Box px={2}>
                                <Toolbar disableGutters>
                                    <Box flexGrow={1} justifyContent={"space-between"} display={"flex"}>
                                        <SearchInput value={smartFilter} onChange={(e) => onChangeSearchTerm(e.target.value)}/>
                                    </Box>
                                </Toolbar>
                            </Box>
                            <Divider/>
                            <CampaignAdminShipmentsTable items={items}/>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={total}
                                rowsPerPage={per_page}
                                page={page}
                                onChangePage={handleChangePage}
                                // onPageChange={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </Dialog>
    </div>;
}

export default CampaignAdminShipmentsDialog;