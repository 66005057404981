import React, {useEffect, useState} from "react";
import {Tabs, Tab, Box} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import {
    getTotalApproved,
    getTotalManual, getTotalNotPayable,
    getTotalPaid,
    getTotalReject,
    paymentOrdersSelector
} from "../../../slices/paymentOrders";
import queryString from "query-string";

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const status = [
    'APPROVED',
    'PROCESSING_MANUALLY',
    'PAID,PAID_MANUALLY',
    'CANCELLED,LOST,FAILED',
    'REJECTED'
];

const PaymentStatusCategoryTabs = ({value, onChange}) => {
    const [initialized, setInitialized] = useState(false);
    const {paymentsOrdersFilters, totalApproved, totalManual, totalPaid, totalNotPayable, totalReject} = useSelector(paymentOrdersSelector);
    const dispatch =  useDispatch();
    useEffect(()=>{
        if(initialized) {
            const params = queryString.stringify(paymentsOrdersFilters);
            switch (value) {
                case 1:
                    dispatch(getTotalManual(params));
                    break
                case 2:
                    dispatch(getTotalPaid(params));
                    break
                case 3:
                    dispatch(getTotalNotPayable(params));
                    break
                case 4:
                    dispatch(getTotalReject(params));
                    break
                default:
                    dispatch(getTotalApproved(params));
            }
        }
    },[value, dispatch, paymentsOrdersFilters, initialized])

    useEffect(()=>{
        if(!initialized){
            setInitialized(true);
            const params = "page=1&per_page=1&status=";
            dispatch(getTotalApproved(params+status[0]));
            dispatch(getTotalManual(params+status[1]));
            dispatch(getTotalPaid(params+status[2]));
            dispatch(getTotalNotPayable(params+status[3]));
            dispatch(getTotalReject(params+status[4]));
        }
    },[initialized, dispatch])

    return <Box flexGrow={1}>
        <Tabs
        onChange={(e, newValue)=>onChange(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered
        value={value}
    >
            <Tab label={`Approved (${totalApproved})`} {...a11yProps(0)}/>
            <Tab label={`Manual (${totalManual})`} {...a11yProps(1)} />
            <Tab label={`Paid (${totalPaid})`} {...a11yProps(2)} />
            <Tab label={`Not-payable (${totalNotPayable})`} {...a11yProps(3)} />
            <Tab label={`Rejected (${totalReject})`} {...a11yProps(4)} />
    </Tabs>
        <Divider />
    </Box>
}

export default PaymentStatusCategoryTabs;