import React, {useEffect} from 'react';
import {Box, LinearProgress} from "@material-ui/core";
import CampaignAdminTabs from "./CampaignAdminTabs";
import {useState} from "react";
import TabPanel from "../TabPanel/TabPanel";
import ChangeCampaignDatesDialog from "./CampaignAdminDialogs/ChangeCampaignDates";
import {useDispatch, useSelector} from "react-redux";
import {campaignAdminSelector, updateOpenDialog} from "../../slices/campaignAdmin";
import RemoveCreditBlockDialog from "./CampaignAdminDialogs/RemoveCreditBlock";
import RemoveProductSeedingBlockDialog from "./CampaignAdminDialogs/RemoveProductSeedingBlock";
import CampaignAdminShipmentsDialog from "./CampaignAdminDialogs/CampaignAdminShipmentsDialog";
import CampaignManagerDialog from "./CampaignAdminDialogs/CampaignManagerDialog";
import ProductSeedingAmountDialog from "./CampaignAdminDialogs/ProductSeedingAmount";
import CampaignAdminSeedingDialog from "./CampaignAdminDialogs/CampaignAdminSeedingDialog";
import CampaignAdminDetailsDialog from "./CampaignAdminDialogs/CampaignAdminDetailsDialog";
import CampaignAdminDeleteDialog from "./CampaignAdminDialogs/CampaignAdminDeleteDialog";
import {useHistory, useParams} from "react-router-dom";
import api from "../../helpers/api";
import CampaignAdminTabContent from "./CampaignAdminTabContent";
import {setNotification} from "../../slices/notification";
import RequestError from "../../helpers/RequestError";
import {isEmpty} from "../../helpers/utils";

const tabs = ['draft', 'open', 'ended', 'completed'];
const CampaignAdminWrapper = () => {
    const {getCampaignAdminErrorMessage, isCampaignAdminLoading} = useSelector(campaignAdminSelector);
    const [initialized, setInitialized] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const {tab, campaignId} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        ChangeCampaignDates,
        RemoveCreditBlock,
        RemoveProductSeedingBlock,
        CampaignAdminShipments,
        CampaignAdminManager,
        ProductSeedingAmount,
        CampaignAdminSeeding,
        CampaignAdminDetails,
        CampaignAdminDelete
    } = useSelector(campaignAdminSelector);

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            const index = tabs.indexOf(tab);
            setActiveTab(index === -1 ? 0 : index);
            if (campaignId) {
                api.CampaignApi.get(campaignId)(
                    response => {
                        dispatch(updateOpenDialog({
                            dialog: 'CampaignAdminManager',
                            value: true,
                            row: response.data
                        }))
                    },
                    (error) => {
                        dispatch(setNotification((new RequestError(error)).getMessage(), "error"));
                    }
                )
            }

        }
    }, [initialized, tab, campaignId, dispatch]);

    useEffect(() => {
        if (!isEmpty(getCampaignAdminErrorMessage)) {
            dispatch(setNotification(getCampaignAdminErrorMessage, "error"));
        }
    }, [getCampaignAdminErrorMessage, dispatch]);

    const handleTabChange = value => {
        setActiveTab(value);
        history.push(tabs[value]);
    }

    return (
        <Box>
            {isCampaignAdminLoading && <LinearProgress/>}
            <CampaignAdminTabs value={activeTab} onChange={handleTabChange}/>
            <TabPanel value={activeTab} index={0}>
                <CampaignAdminTabContent status={"draft"}/>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
                <CampaignAdminTabContent status={"open"}/>
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
                <CampaignAdminTabContent status={"ended"}/>
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
                <CampaignAdminTabContent status={"completed"}/>
            </TabPanel>
            {ChangeCampaignDates && <ChangeCampaignDatesDialog openDialog={ChangeCampaignDates}/>}
            {RemoveCreditBlock && <RemoveCreditBlockDialog open={RemoveCreditBlock}/>}
            {RemoveProductSeedingBlock && <RemoveProductSeedingBlockDialog open={RemoveProductSeedingBlock}/>}
            {CampaignAdminShipments && <CampaignAdminShipmentsDialog open={CampaignAdminShipments}/>}
            {CampaignAdminManager && <CampaignManagerDialog open={CampaignAdminManager}/>}
            {ProductSeedingAmount && <ProductSeedingAmountDialog openDialog={ProductSeedingAmount}/>}
            {CampaignAdminSeeding && <CampaignAdminSeedingDialog open={CampaignAdminSeeding}/>}
            {CampaignAdminDetails && <CampaignAdminDetailsDialog open={CampaignAdminDetails}/>}
            {CampaignAdminDelete && <CampaignAdminDeleteDialog open={CampaignAdminDelete}/>}
        </Box>
    );
}

export default CampaignAdminWrapper;
