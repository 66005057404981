import React from 'react';
import {Table, TableBody, TableContainer, Typography} from "@material-ui/core";
import CampaignAdminTableHead from "./CampaignAdminTableHead";
import CampaignAdminTableRow from "./CampaignAdminTableRow";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const CampaignAdminTable = ({items, disableMenu, isNoResults}) => {
    return (
        <TableContainer>
            <Table>
                {
                    items.length > 0 &&
                    <CampaignAdminTableHead disableMenu={disableMenu}/>
                }
                <TableBody>
                    {
                        items.map(item => {
                            return <CampaignAdminTableRow disableMenu={disableMenu} key={item.id} campaign={item}/>
                        })
                    }
                    {
                        items.length === 0 &&
                        <TableRow>
                            <TableCell colSpan={disableMenu ? 14 : 15} style={{height: "400px"}}>
                                {
                                    isNoResults &&
                                    <Typography variant={"body2"} align={"center"}>
                                        No campaigns found.
                                    </Typography>
                                }
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

CampaignAdminTable.defaultProps = {
    items: [],
    disableMenu: false
}

export default CampaignAdminTable;
