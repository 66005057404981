import React, { useEffect, useState } from "react";
import Swipe from "react-easy-swipe";
import "./styles/index.css";
import Dotdotdot from "react-dotdotdot";
import {Box, IconButton, Link} from "@material-ui/core";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";



function Carousel({
  data,
  time,
  width,
  imageHeight,
  slideNumberStyle,
  radius,
  slideNumber,
  dots,
  automatic,
  pauseIconColor,
  pauseIconSize,
  slideImageFit,
  thumbnails,
  thumbnailWidth,
  showNavBtn = true,
  showIndexNavigation,
  onIndexChanged,
  gigId = 0
}) {

  let initialCaption = '';
  if (data && data.length > 0) {
    initialCaption = data[0].caption;
  }

  //Initialize States
  const [slide, setSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [change, setChange] = useState(0);
  const [caption, setCaption] = useState(initialCaption);

  //Function to change slide
  const addSlide = (n) => {
    if (slide + n >= data.length) setIndex(0);
    else if (slide + n < 0) setIndex(data.length - 1);
    else setIndex(slide + n);
  };

  const setIndex = (n) => {
    setSlide(n);
    setCaption(data[n]?.caption)
    onIndexChanged(n);
  }

  //Start the automatic change of slide
  useEffect(() => {
    if (automatic) {
      var index = slide;
      const interval = setInterval(
        () => {
          if (!isPaused) {
            //setIndex(index);
            setSlide(index);
            setCaption(data[index]?.caption)
            onIndexChanged(index);
            index++;
            if (index >= data.length) index = 0;
            if (index < 0) index = data.length - 1;
          }
        },
        time ? time : 2000
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [isPaused, change, automatic, data, slide, time, onIndexChanged]);

  function scrollTo(el) {
    const elLeft = el.offsetLeft + el.offsetWidth;
    const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

    // check if element not in view
    if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
      el.parentNode.scroll({ left: elLeft - elParentLeft, behavior: "smooth" });
    } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
      el.parentNode.scroll({
        left: el.offsetLeft - el.parentNode.offsetLeft,
        behavior: "smooth",
      });
    }
  }

  //Listens to slide state changes
  useEffect(() => {
    var slides = document.getElementsByClassName(`carousel-item-${gigId}`);
    var dots = document.getElementsByClassName(`dot-${gigId}`);

    var slideIndex = slide;
    var i;
    for (i = 0; i < data.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    //If thumbnails are enabled
    if (thumbnails) {
      var thumbnailsArray = document.getElementsByClassName(`thumbnail-${gigId}`);
      for (i = 0; i < thumbnailsArray.length; i++) {
        thumbnailsArray[i].className = thumbnailsArray[i].className.replace(
          " active-thumbnail",
          ""
        );
      }
      if (thumbnailsArray[slideIndex] !== undefined)
        thumbnailsArray[slideIndex].className += " active-thumbnail";
      scrollTo(document.getElementById(`thumbnail-${gigId}-${slideIndex}`));
    }

    if (slides[slideIndex] !== undefined)
      slides[slideIndex].style.display = "block";
    if (dots[slideIndex] !== undefined) dots[slideIndex].className += " active";
  }, [slide, isPaused, data, gigId, thumbnails]);

  return (
    <div className="box">
      <div>
        <Swipe
          onSwipeRight={() => {
            addSlide(-1);
            setChange(!change);
          }}
          onSwipeLeft={() => {
            addSlide(1);
            setChange(!change);
          }}
        >
          <div
            className="carousel-container"
            style={{
              height: '100%'
            }}
          >
            {data.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className={`carousel-item carousel-item-${gigId}`}
                    style={{
                      height: imageHeight || '100%'
                    }}
                    onMouseDown={(e) => {
                      automatic && setIsPaused(true);
                    }}
                    onMouseUp={(e) => {
                      automatic && setIsPaused(false);
                    }}
                    onMouseLeave={(e) => {
                      automatic && setIsPaused(false);
                    }}
                    onTouchStart={(e) => {
                      automatic && setIsPaused(true);
                    }}
                    onTouchEnd={(e) => {
                      automatic && setIsPaused(false);
                    }}
                    key={index}
                  >
                    {slideNumber && (
                      <div className="slide-number" style={slideNumberStyle}>
                        {index + 1} / {data.length}
                      </div>
                    )}

                    <img
                      src={item.image}
                      alt={item.caption}
                      className="carousel-image"
                      style={{
                        borderRadius: radius,
                        padding: '10px',
                        objectFit: slideImageFit ? slideImageFit : "cover",
                      }}
                      onError={e => e.target.src = "https://cdnjs.tvpage.com/assets/img/creatable_placeholder.png"}
                    />

                    {isPaused && (
                      <div
                        className="pause-icon pause"
                        style={{
                          color: pauseIconColor ? pauseIconColor : "white",
                          fontSize: pauseIconSize ? pauseIconSize : "40px",
                        }}
                      >
                        II
                      </div>
                    )}
                  </div>
                </div>
              );
            })}

            {showNavBtn && (
              <Link
                className="prev"
                onClick={(e) => {
                  addSlide(-1);
                  setChange(!change);
                }}
              >
                &#10094;
              </Link>
            )}
            {showNavBtn && (
              <Link
                className="next"
                onClick={(e) => {
                  addSlide(1);
                  setChange(!change);
                }}
              >
                &#10095;
              </Link>
            )}
            {dots && (
              <div className="dots">
                {data.map((item, index) => {
                  return (
                    <span
                      className={`dot dot-${gigId}`}
                      key={index}
                      onClick={(e) => {
                        setIndex(index);
                        setChange(!change);
                      }} />
                  );
                })}
              </div>
            )}
          </div>
        </Swipe>
      </div>

      {caption &&
        <div style={{ marginBottom: '5px', minHeight: '3.3em', textAlign: 'center' }}>
          <Dotdotdot clamp={2}>
            {caption}
          </Dotdotdot>
        </div>
      }

      {showIndexNavigation &&
        <div
          style={{
            paddingTop: '5px',
            marginTop: '10px',
            marginBottom: '10px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            color: '#fff'
          }}
        >
          <Box borderRadius={'40px'} style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
            <IconButton onClick={() => addSlide(-1)}>
              <ChevronLeft htmlColor="#fff" />
            </IconButton>

            <span
              style={{
                marginLeft: '5px',
                marginRight: '5px',
                verticalAlign: 'middle'
              }}
            >
              {slide + 1}/{data.length}
            </span>

            <IconButton onClick={() => addSlide(1)}>
              <ChevronRight htmlColor="#fff" />
            </IconButton>
          </Box>
        </div>
      }

      {thumbnails && (
        <div
          className="thumbnails"
          id="thumbnail-div"
          style={{ maxWidth: width }}
        >
          {data.map((item, index) => {
            return (
              <img
                width={thumbnailWidth ? thumbnailWidth : "100px"}
                src={item.image}
                alt={item.caption}
                className={`thumbnail thumbnail-${gigId}`}
                id={`thumbnail-${gigId}-${index}`}
                key={index}
                onClick={(e) => {
                  setIndex(index);
                  setChange(!change);
                }}
              />
            );
          })}
        </div>
      )}

    </div>
  );
}

export default Carousel;
