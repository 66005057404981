
const ALLOWED_BASE_DOMAINS = ["creatable.io"];
const getBaseDomain = () => ALLOWED_BASE_DOMAINS.find(d => window.location.host.includes(d)) || ALLOWED_BASE_DOMAINS[0];

const getValueFromClassByHostname = (fromClass) => {
    switch (window.location.host){
        case ENV_ADMIN_APP_HOSTS.LOCAL:
            return fromClass.LOCAL;
        case ENV_ADMIN_APP_HOSTS.TEST:
            return fromClass.TEST;
        case ENV_ADMIN_APP_HOSTS.STAGE:
            return fromClass.STAGE;
        case ENV_ADMIN_APP_HOSTS.PROD:
            return fromClass.PROD;
        default:
            throw new Error(`Couldn't determine environment`);
    }
}

const environment = {
    getName: () => getValueFromClassByHostname(ENV_NAMES),
    getUserServiceHost: () => getValueFromClassByHostname(ENV_HOSTS),
    getBrandAppHost: () => getValueFromClassByHostname(ENV_BRAND_APP_HOSTS),
    getLoginAppHost: () => getValueFromClassByHostname(ENV_LOGIN_APP_HOSTS),
    getCommunicationServiceHost: () => getValueFromClassByHostname(ENV_HOSTS),
    getMessagesServiceHost: () => getValueFromClassByHostname(ENV_HOSTS),
    getBaseDomain,
    /**
     * Legacy endpoints
     */
    getMerchantAppHost: () => getValueFromClassByHostname(ENV_HOSTS),
    getAmbassadorsAppHost: () => getValueFromClassByHostname(ENV_HOSTS),
    getAppApiHost: () => getValueFromClassByHostname(ENV_HOSTS),
    getApiHost: () => "//"+getValueFromClassByHostname(ENV_HOSTS),
    getAnalyticsApiHost: () => getValueFromClassByHostname(ENV_HOSTS),
    getClientServiceHost: () => getValueFromClassByHostname(ENV_HOSTS),
}

class ENV_NAMES {
    static get LOCAL()  { return 'local' };
    static get TEST()   { return 'test' };
    static get STAGE()  { return 'stage' };
    static get PROD()   { return 'prod' };
}

class ENV_HOSTS {
    static get LOCAL()  { return 'local.creatable.io' };
    static get TEST()   { return 'test.creatable.io' };
    static get STAGE()  { return 'stage.creatable.io' };
    static get PROD()   { return 'app.creatable.io' };
}

class ENV_LOGIN_APP_HOSTS {
    static get LOCAL()  { return 'login.local.creatable.io' };
    static get TEST()   { return 'login.test.creatable.io' };
    static get STAGE()  { return 'login.stage.creatable.io' };
    static get PROD()   { return 'login.creatable.io' };
}

class ENV_BRAND_APP_HOSTS {
    static get LOCAL()  { return 'brand.local.creatable.io' };
    static get TEST()   { return 'brand.test.creatable.io' };
    static get STAGE()  { return 'brand.stage.creatable.io' };
    static get PROD()   { return 'brand.creatable.io' };
}

class ENV_ADMIN_APP_HOSTS {
    static get LOCAL()  { return 'admin.local.creatable.io' };
    static get TEST()   { return 'admin.test.creatable.io' };
    static get STAGE()  { return 'admin.stage.creatable.io' };
    static get PROD()   { return 'admin.creatable.io' };
}

export {ENV_NAMES};
export default environment;
