import {createSlice} from '@reduxjs/toolkit';
import api from '../helpers/api';
import RequestError from '../helpers/RequestError';
import ResponseStatus from '../helpers/ResponseStatus';

export const initialState = {
    widgetTemplates: [],
    totalWidgetTemplates: 0,
    isWidgetTemplatesLoading: false,
    isGetWidgetTemplatesError: false,
    getWidgetTemplatesErrorMessage: "",

    widgetTemplate: null,
    isActionWidgetTemplateError: false,
    actionWidgetTemplateErrorMessage: "",
    isActionWidgetTemplateInProgress: false,
};

const widgetTemplatesSlice = createSlice({
    name: 'widgetTemplates',
    initialState,
    reducers: {
        onGetWidgetTemplatesStart: (state) => {
            state.isWidgetTemplatesLoading = true;
        },
        onGetWidgetTemplatesDone: (state, {payload}) => {
            state.isGetWidgetTemplatesError = payload.isError;
            state.isWidgetTemplatesLoading = false;
            if (state.isGetWidgetTemplatesError) {
                state.getWidgetTemplatesErrorMessage = payload.errorMessage;
            } else {
                state.widgetTemplates = payload.data.items;
                state.totalWidgetTemplates = payload.data.total;
                state.getWidgetTemplatesErrorMessage = '';
            }
        },
        onWidgetTemplateActionStart: (state) => {
            state.isActionWidgetTemplateInProgress = true;
            state.isActionWidgetTemplateError = false;
            state.actionWidgetTemplateErrorMessage = "";
        },
        onWidgetTemplateActionDone: (state, {payload}) => {
            state.isActionWidgetTemplateInProgress = false;
            state.isActionWidgetTemplateError = payload.isError;
            state.actionWidgetTemplateErrorMessage = payload.errorMessage;
            if (payload.hasOwnProperty("data")) {
                state.widgetTemplate = payload.data;
            }
        },
    },
});

export const widgetTemplatesSelector = state => state.widgetTemplates;
export default widgetTemplatesSlice.reducer;

const {
    onGetWidgetTemplatesStart,
    onGetWidgetTemplatesDone,
    onWidgetTemplateActionStart,
    onWidgetTemplateActionDone
} = widgetTemplatesSlice.actions;
export const getWidgetTemplates = (page, limit) => dispatch => {
    dispatch(onGetWidgetTemplatesStart());
    return api.getWidgetTemplates(page, limit)(
        response => {
            const respStatus = new ResponseStatus(response);
            dispatch(onGetWidgetTemplatesDone({
                isError: respStatus.isError(),
                errorMessage: respStatus.getErrorMessage(),
                data: response.data.data
            }));
        },
        error => {
            const reqError = new RequestError(error);
            dispatch(onGetWidgetTemplatesDone({
                isError: true,
                errorMessage: reqError.getMessage()
            }));
        }
    );
}
export const createWidgetTemplate = (widgetTemplateData) => dispatch => {
    dispatch(onWidgetTemplateActionStart());
    return api.createWidgetTemplate()(
        response => {
            const respStatus = new ResponseStatus(response);
            dispatch(onWidgetTemplateActionDone({
                isError: respStatus.isError(),
                errorMessage: respStatus.getErrorMessage()
            }));
            return respStatus.isError();
        },
        error => {
            const reqError = new RequestError(error);
            dispatch(onWidgetTemplateActionDone({
                isError: true,
                errorMessage: reqError.getMessage()
            }));
        }
    )(widgetTemplateData);
}
export const getWidgetTemplate = (widgetId) => dispatch => {
    dispatch(onWidgetTemplateActionStart());
    return api.getWidgetTemplate(widgetId)(
        response => {
            const respStatus = new ResponseStatus(response);
            dispatch(onWidgetTemplateActionDone({
                isError: respStatus.isError(),
                errorMessage: respStatus.getErrorMessage(),
                data: response.data.data
            }));
        },
        error => {
            const reqError = new RequestError(error);
            dispatch(onWidgetTemplateActionDone({
                isError: true,
                errorMessage: reqError.getMessage()
            }));
        }
    );
}
export const updateWidgetTemplate = (widgetId, widgetTemplateData) => dispatch => {
    dispatch(onWidgetTemplateActionStart(true));
    return api.updateWidgetTemplate(widgetId)(
        response => {
            const respStatus = new ResponseStatus(response);
            dispatch(onWidgetTemplateActionDone({
                isError: respStatus.isError(),
                errorMessage: respStatus.getErrorMessage()
            }));
        },
        error => {
            const reqError = new RequestError(error);
            dispatch(onWidgetTemplateActionDone({
                isError: true,
                errorMessage: reqError.getMessage()
            }));
        }
    )(widgetTemplateData);
}
export const deleteWidgetTemplate = (widgetTemplateId) => dispatch => {
    dispatch(onWidgetTemplateActionStart(true));
    return api.deleteWidgetTemplate(widgetTemplateId)(
        response => {
            const respStatus = new ResponseStatus(response);
            dispatch(onWidgetTemplateActionDone({
                isError: respStatus.isError(),
                errorMessage: respStatus.getErrorMessage()
            }));
        },
        error => {
            const reqError = new RequestError(error);
            dispatch(onWidgetTemplateActionDone({
                isError: true,
                errorMessage: reqError.getMessage()
            }));
        }
    )();
}