import Dialog from "@material-ui/core/Dialog";
import React, {forwardRef, useEffect, useState} from "react";
import {AppBar, Grid, makeStyles, Slide, Tab, Tabs} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import api from "../../helpers/api";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import TabGeneral from "./TabGeneral/TabGeneral";
import {useDispatch, useSelector} from "react-redux";
import {clientConfigSelector, getClientConfig} from "../../slices/clientConfig";
import TabPlan from "./TabPlan/TabPlan";
import {useParams} from "react-router-dom";
import routes from "../../helpers/routes";
import {withRouter} from "react-router";
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
const useStyles = makeStyles(theme=>({
    root:{
        "& .MuiPaper-root":{
            marginBottom: "0 !important"
        }
    },
    tabs:{
        borderRight: 0,
        "& .MuiTab-wrapper":{
            alignItems: 'flex-start'
        },
        "& .Mui-selected":{
            backgroundColor: "#eeeeee"
        },
        "& .MuiTabs-indicator":{
            backgroundColor: "transparent"
        }
    },
    container:{
      backgroundColor: "#f5f5f5",
        paddingTop: theme.spacing(2)
    }
}))
const ClientDetailsWrapper = ({onClose, open, client_id, history}) =>{
    const [client, setClient] = useState(null);
    const [tab, setTab] = useState(0);
    const classes =  useStyles();
    const {clientConfig} = useSelector(clientConfigSelector);
    const dispatch = useDispatch();
    const {currentTab} = useParams();
    useEffect(() => {
        api.getClient(client_id)(
            response=>{
                dispatch(getClientConfig(response.data.hash));
                setClient(response.data);
            },
            error =>{

            }
        )
        if(currentTab && currentTab === 'plan'){
            setTab(1)
        }

    }, [client_id, dispatch, currentTab]);

    const handleChange = (event, newValue) => {
        setTab(newValue);
        if(newValue === 0){
            history.push(routes.getAdminClientsUrl()+`/${client.client_id}/general`);
        }else if(newValue === 1){
            history.push(routes.getAdminClientsUrl()+`/${client.client_id}/plan`);
        }

    };

    return <Dialog open={open} fullScreen TransitionComponent={Transition} data-testId={'client-details-dialog'}>
        <AppBar position={'relative'}>
            <Toolbar>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                <Typography variant="h6">
                    {client ? `${client.name} ${client_id}` : ""}
                </Typography>
                <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
        <DialogContent className={classes.container}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={2}>
                        <Tabs
                            className={classes.tabs}
                            value={tab}
                            onChange={handleChange}
                            orientation="vertical"
                        >
                            <Tab data-testId={"details-tab-button-general"} label={"General"} {...a11yProps(0)} />
                            <Tab data-testId={"details-tab-button-plan"} label={"Plan"} {...a11yProps(1)} />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <Box>
                            <TabPanel index={0} value={tab} >
                                {clientConfig && <TabGeneral />}
                            </TabPanel>
                            <TabPanel index={1} value={tab} >
                                {clientConfig && <TabPlan />}
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

        </DialogContent>
    </Dialog>
}
export default withRouter(ClientDetailsWrapper);