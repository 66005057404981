import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Box from "@material-ui/core/Box";
import ChatsTableHead from "./ChatsTableHead";
import ChatsTableRow from "./ChatsTableRow";
import {useSelector} from "react-redux";
import {messagesSelector} from "../../slices/messages";

const ChatsTable = ({onClickChat, sort, onSort, onPageChange, onItemsPerPageChange}) => {
    const classes = useStyles();
        
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const {isChatMessagesInProgress, supportChatList, totalChats} = useSelector(messagesSelector);
    
    const handleChangePage = (_, newPage) => {
        if (newPage>=0) {
            const updatedPage = newPage + 1;
            setPage(updatedPage);
            if (onPageChange) {
                onPageChange(updatedPage);
            }
        }
    }

    const handleChangeRowsPerPage = (event) => {
        const updatedItemsPerPage = +event.target.value;
        setItemsPerPage(updatedItemsPerPage);
        setPage(1);
        if (onItemsPerPageChange) {
            onItemsPerPageChange(updatedItemsPerPage);
        }
    }

    return (
        <Box>
            <TableContainer>
                <Table size={'medium'} className={classes.table}>
                    <ChatsTableHead sort={sort} onSort={onSort}/>
                    <TableBody>
                        {
                            supportChatList.length > 0 &&
                            supportChatList.map((chat) => (
                                <ChatsTableRow
                                    key={chat.chat_id}
                                    chat={chat}
                                    onClick={() => onClickChat(chat)}/>
                            ))
                        }
                        {
                            supportChatList.length === 0 &&
                            <TableRow className={classes.emptyTable}>
                                {isChatMessagesInProgress && <TableCell colSpan={4}/>}
                                {!isChatMessagesInProgress && <TableCell colSpan={4}>There are no chats to display.</TableCell>}
                            </TableRow>
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={totalChats}
                    rowsPerPage={itemsPerPage}
                    page={page-1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    backIconButtonProps={{disabled: isChatMessagesInProgress || supportChatList.length === 0 || page===1}}
                    nextIconButtonProps={{disabled: isChatMessagesInProgress || supportChatList.length === 0 || page>=Math.ceil(totalChats/itemsPerPage)}}
                />
            </TableContainer>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    emptyTable: {
        height: '424px',
        position: 'relative',
        '& .MuiTableCell-body': {
            textAlign: 'center',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute'
        },
        '& td': {
            borderBottom: 0,
        }
    },
}));

export default ChatsTable;