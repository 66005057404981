import Link from "@material-ui/core/Link";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import dates from "../../helpers/dates";
import {strings} from "../../helpers/utils";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {Menu, MenuItem} from "@material-ui/core";

const CommunicationsTableRow = ({configuration, onClick, onClickDelete}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const onMenuClick = (callback) => () => {
        setAnchorEl(null);
        callback();
    }

    const onClickMenuIcon = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const onCloseMenu = () => {
        setAnchorEl(null);
    }

    return (
        <TableRow>
            <TableCell align="left">
                <Link onClick={() => onClick(configuration)}>
                    {configuration.name}
                </Link>
            </TableCell>
            <TableCell align="left">
                {configuration.template_type}
            </TableCell>
            <TableCell>
                {strings.stringOrDash(dates.formatFromString(configuration.last_update_date, 'M/d/yyyy'))}
            </TableCell>
            <TableCell align="center" padding={"checkbox"}>
                <IconButton onClick={onClickMenuIcon}>
                    <MoreVertIcon/>
                </IconButton>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onCloseMenu}>
                    <MenuItem key={"update_password"} onClick={onMenuClick(onClickDelete)}>
                        Delete
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
}

export default CommunicationsTableRow;