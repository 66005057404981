import React, {useEffect, useState, useCallback} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {
    rootConfigSelector,
    fetchMobileAppConfig,
    fetchDashboardsConfig,
    saveMobileAppConfig,
    saveMaintenanceModeConfig
} from "../slices/rootConfig";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Backdrop from "@material-ui/core/Backdrop";
import MobileAppConfig from "../components/MobileAppConfig";
import MaintenanceModeConfig from "../components/MaintenanceModeConfig";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '50%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const Settings = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {mobileAppConfig, dashboardsConfig, isLoading} = useSelector(rootConfigSelector);
    const [maintenanceModeEditConfig, setMaintenanceModeEditConfig] = useState(null);
    const [mobileAppEditConfig, setMobileAppEditConfig] = useState(null);
    const stableDispatch = useCallback(dispatch, []);

    useEffect(() => {
        stableDispatch(fetchMobileAppConfig());
        stableDispatch(fetchDashboardsConfig());
    }, [stableDispatch]);

    useEffect(() => {
        if (mobileAppConfig !== null) {
            setMobileAppEditConfig(mobileAppConfig);
        }
    }, [mobileAppConfig]);

    useEffect(() => {
        if (dashboardsConfig !== null) {
            const {maintenance_mode, maintenance_title, maintenance_message} = dashboardsConfig;
            setMaintenanceModeEditConfig({maintenance_mode, maintenance_title, maintenance_message});
        }
    }, [dashboardsConfig]);

    const reloadMobileAppConfig = () => {
        dispatch(fetchMobileAppConfig());
    }

    const reloadMaintenanceModeConfig = () => {
        dispatch(fetchDashboardsConfig());
    }

    const onSaveMaintenanceModeConfig = () => {
        dispatch(saveMaintenanceModeConfig(maintenanceModeEditConfig));
    }

    const onSaveMobileAppConfig = () => {
        dispatch(saveMobileAppConfig(mobileAppEditConfig));
    }

    return (
        <div className={classes.root}>
            <Toolbar/>
            <Box m={3}>
                <MobileAppConfig mobileAppConfig={mobileAppEditConfig} onChange={setMobileAppEditConfig}
                                 onSave={onSaveMobileAppConfig} onCancel={() => reloadMobileAppConfig()}/>
            </Box>
            <Box m={3}>
                <MaintenanceModeConfig maintenanceModeConfig={maintenanceModeEditConfig}
                                       onChange={setMaintenanceModeEditConfig} onSave={onSaveMaintenanceModeConfig}
                                       onCancel={() => reloadMaintenanceModeConfig()}/>
            </Box>
            <Backdrop className={classes.backdrop} open={isLoading}/>
        </div>
    );
}

export default Settings;