import React, {useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress, Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";

const getPayload = (selected) => {
    return selected.map(item=>{
        return {
            item_id: item.item_id,
            amount_pennies: item.amount_pennies,
            quantity: item.quantity
        }})
}

const PaymentStatusManualPayDialog = ({isOpen, onClose, onSaveSuccess, selected, singleItem}) => {
    const dispatch = useDispatch();

    const [inProgress, setInProgress] = useState(false);

    const sendManualPay = () => {
        setInProgress(true);
        const orders = singleItem && singleItem.length > 0 ? singleItem : selected;
        const promises = orders.map(payment=>{
            return new Promise((resolve, reject)=>{
                const payload = getPayload(payment.items);

                api.payment.sendManualOrder(payment.order_id)
                    (response=>{

                        resolve();
                    },
                    error => {
                        reject();
                    })
                ({items:payload});
            })
        });

        Promise.all(promises).then(()=>{
            onSaveSuccess();
            setInProgress(false);
        }).catch(()=>{
            dispatch(setNotification('Request failed', 'error'));
            setInProgress(false);
        })
    }

    const handleLocalClose = () =>{
        onClose();
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Move to manual-pay</DialogTitle>
                <DialogContent>
                    <Typography>
                        Click “CONFIRM” to move the selected payment(s) to manual-pay.
                    </Typography>

                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="contained" onClick={handleLocalClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="contained" fullWidth
                                    onClick={sendManualPay}
                                    color={'primary'}
                                    disabled={inProgress}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default PaymentStatusManualPayDialog;