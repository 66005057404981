import {Draggable} from "react-beautiful-dnd";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
        display: "table",
        opacity: 0.9,
        backgroundColor: "rgba(207, 226, 243, 0.4)",
        borderColor: "rgba(207, 226, 243, 1)",
        border: "1px solid rgba(224, 224, 224, 0.7)",
    })
})

const DraggableComponent = (id, index) => (props) => {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <TableRow ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          {...props}>
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    )
}

export default DraggableComponent;