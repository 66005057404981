import React from 'react';
import {useTheme} from "@material-ui/styles";
import {Box} from "@material-ui/core";

const UnreadIndicator = ({unread, ...props}) => {
    const theme = useTheme();

    return (
        <Box {...props} style={{
            backgroundColor: (unread > 0 ? theme.palette.primary.main : theme.palette.grey[200]),
            borderRadius: "50%",
            width: "12px",
            height: "12px"
        }}/>
    );
}

export default UnreadIndicator;