import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    title: {
        whiteSpace: "nowrap",
    }
}));

function WidgetTemplatesTableHead() {
    const classes = useStyles();
    const intl = useIntl();

    const textWidgetName = intl.formatMessage({
        id: 'Experience.WidgetTemplates.WidgetTemplateName',
        defaultMessage: 'Template name'
    });

    const textCreatedTimestamp = intl.formatMessage({
        id: 'Experience.WidgetTemplates.DateCreated',
        defaultMessage: 'Date created'
    });

    const textLastModified = intl.formatMessage({
        id: 'Experience.WidgetTemplates.LastModified',
        defaultMessage: 'Last modified'
    });

    return (
        <TableHead>
            <TableRow>
                <TableCell align={"left"}>
                    <span className={classes.title}>{textWidgetName}</span>
                </TableCell>
                <TableCell align={"left"}>
                    <span className={classes.title}>{textCreatedTimestamp}</span>
                </TableCell>
                <TableCell align={"left"}>
                    <span className={classes.title}>{textLastModified}</span>
                </TableCell>
                <TableCell width={"20px"} padding={"none"}/>
            </TableRow>
        </TableHead>
    );
}

export default WidgetTemplatesTableHead;