import {createSlice} from '@reduxjs/toolkit';
import RequestError from '../helpers/RequestError';
import Api from "../helpers/api";

export const initialState = {
    users: [],
    totalUsers: 0,
    usersError: null,
    isUsersInProgress: null,
    isUserListInvalid: null,
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsersStart: (state) => {
            state.isUsersInProgress = true;
            state.usersError = null;
        },
        getUsersDone: (state, {payload}) => {
            if (payload.status === 200) {
                state.users = payload.items;
                state.totalUsers = payload.total;
                state.isUserListInvalid = false;
            } else {
                state.usersError = payload.message;
                state.isUserListInvalid = null;
            }
            state.isUsersInProgress = false;
        },
        setUserListInvalid: (state) => {
            state.isUserListInvalid = true;
        }
    },
});

export const usersSelector = state => state.users;
export default usersSlice.reducer;

const {getUsersStart, getUsersDone, setUserListInvalid} = usersSlice.actions;
export const getUsers = (page, itemsPerPage, searchTerm = null, clientId = null, roleId = null) => dispatch  => {
    dispatch(getUsersStart());
    return Api.getUsers(page, itemsPerPage, (typeof searchTerm === "string" ? searchTerm.trim() : null), clientId, roleId)(
        ({data}) => dispatch(getUsersDone({...data, status: 200})),
        error => {
            const respError = new RequestError(error)
            dispatch(getUsersDone({
                status: respError.getHttpStatusCode(),
                message: respError.getMessage()
            }));
        }
    );
}
export const invalidateUserList = () => dispatch => {
    dispatch(setUserListInvalid());
}