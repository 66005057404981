import React, {useEffect, useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    LinearProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import {isEmpty} from "../../helpers/utils";
import TextFieldWithHelp from "../TextFieldWithHelp";
import {setNotification} from "../../slices/notification";
import {invalidateClientList} from "../../slices/clients";
import Api from "../../helpers/api";
import RequestError from "../../helpers/RequestError";

const AddClientModal = ({isOpen, onClose, onSaveSuccess}) => {
    const dispatch = useDispatch();

    const [clientName, setClientName] = useState("");
    const [showErrors, setShowErrors] = useState(false);
    const [inProgress, setInProgress] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!isEmpty(error)) {
            dispatch(setNotification(error, "error"));
        }
    }, [error, dispatch]);

    useEffect(() => {
        if (isOpen === false) {
            setClientName("");
            setInProgress(false);
        }
    }, [isOpen]);

    const updateClientConfig = newClient => {
        Api.getClientConfig(newClient.hash)(
            (response)=>{
                const {data: {client}} = response;
                Api.putClientConfigSection(client.hash, "client")(
                    ()=>{
                        onSaveSuccess();
                    },
                    error=>{
                        onSaveSuccess();
                        setError((new RequestError(error)).getMessage());
                    }
                )({
                    ...client,
                    brand:{
                        ...client.brand,
                        name: newClient.name
                    }
                });
            },
            (error)=>{
                onSaveSuccess();
                setError((new RequestError(error)).getMessage());
            }
        );
    }

    const initializeClient = (client) => {
        Api.initializeClient()(
            () => {
                dispatch(setNotification('Client successfully created.'));
                updateClientConfig(client);
            },
            (error) => {
                setError((new RequestError(error)).getMessage());
                setInProgress(false);
            }
        )({client_id: client.client_id});
    }

    const onSave = () => {
        if (isEmpty(clientName)) {
            setShowErrors(true);
        } else {
            setError(null);
            setInProgress(true);
            setShowErrors(false);
            Api.createClient()(
                ({data}) => {
                    dispatch(invalidateClientList());
                    initializeClient(data);
                },
                (error) => {
                    setError((new RequestError(error)).getMessage());
                    setInProgress(false);
                }
            )({name: clientName});
        }
    }

    const onKeyDown = (e) => {
        if (e && e.keyCode === 13) {
            onSave();
        }
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Create client</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin={"normal"}>
                        <TextFieldWithHelp label={"Client name"} required autoFocus
                                           helperText={showErrors && clientName === "" ? "Name is required." : ""}
                                           onKeyDown={onKeyDown}
                                           error={showErrors && isEmpty(clientName)} onChange={(e) => setClientName(e.target.value)} value={clientName}
                                           disabled={inProgress}/>
                    </FormControl>
                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="outlined" onClick={onClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button type="submit" color="primary" variant="contained" onClick={onSave} fullWidth
                                    disabled={inProgress}>
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default AddClientModal;
