import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import MomentUtils from '@date-io/moment';
import moment from "moment";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    left: {
        position: 'relative',
    },
    grow: {
        flexGrow: 1,
    },
    right: {
        display: 'flex',
    }
}));

const AnalyticsClientsTableToolbar = (props) => {
    const {onChangeDateRange, dateRange, disabled} = props;
    const classes = useToolbarStyles();

    const onChangeDate = (dateName) => (dateValue) => {
        const newTime = dateName === "startDate" ? {hour: 0, minute: 0, second: 0, millisecond: 0} : {
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 59
        };
        onChangeDateRange({
            ...dateRange,
            [dateName]: moment(dateValue).set(newTime)
        });
    }

    return (
        <Toolbar className={classes.root}>
            <div className={classes.left}>
                <Typography color="inherit" variant="h5" component="h5">
                    Client Summary
                </Typography>
            </div>
            <div className={classes.grow}/>
            <div className={classes.right}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid container justify="space-around" spacing={2}>
                        <Grid item>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Start date"
                                format={"MM/DD/YYYY"}
                                value={dateRange.startDate.toDate()}
                                onChange={onChangeDate("startDate")}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item>
                            <KeyboardDatePicker
                                margin="normal"
                                label="End date"
                                format={"MM/DD/YYYY"}
                                value={dateRange.endDate.toDate()}
                                onChange={onChangeDate("endDate")}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </div>
        </Toolbar>
    );
}

export default AnalyticsClientsTableToolbar;