import {combineReducers} from 'redux';

import authReducer from './auth';
import resourcesReducer from "./resources";
import subscriptionsReducer from "./subscriptions";
import rootConfigReducer from "./rootConfig";
import pageReducer from "./page";
import widgetTemplatesReducer from "./widgetTemplates";
import clientAnalyticsReducer from "./clientAnalytics";
import notification from "./notification";
import clients from "./clients";
import users from "./users";
import clientConfig from "./clientConfig";
import paymentApprove from "./paymentApprove";
import campaignAdmin from "./campaignAdmin";
import paymentOrders from "./paymentOrders";
import manualSocialData from "./manualSocialData";
import plans from "./plans";
import messages from "./messages";

const rootReducer = combineReducers({
    auth: authReducer,
    resources: resourcesReducer,
    subscriptions: subscriptionsReducer,
    rootConfig: rootConfigReducer,
    page: pageReducer,
    widgetTemplates: widgetTemplatesReducer,
    clientAnalytics: clientAnalyticsReducer,
    notification,
    clients,
    users,
    clientConfig,
    paymentApprove,
    paymentOrders,
    campaignAdmin,
    manualSocialData,
    plans,
    messages,
});

export default rootReducer