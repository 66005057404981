import React from 'react';
import {Divider} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Toolbar from '@material-ui/core/Toolbar';
import SearchInput from "../../SearchInput";

const CategoriesTableToolbar = ({onChangeSearchTerm}) => {
    return (
        <Box>
            <Box px={2}>
                <Toolbar disableGutters>
                    <Box flexGrow={1}>
                        <SearchInput onChange={(e) => onChangeSearchTerm(e.target.value)}/>
                    </Box>
                </Toolbar>
            </Box>
            <Divider/>
        </Box>
    );
}

export default CategoriesTableToolbar;
