import React from "react";
import {Grid} from "@material-ui/core";
import CompanyDetails from "./CompanyDetails";
import ACPISettings from "./ACPISettings";
import EmailNotificationSettings from "./EmailNotificationSettings";
import CLinkSettings from "./CLinkSettings";
import CampaignSocialData from "./CampaignSocialData";
import ExperiencesSettings from "./ExperiencesSettings";
import ContactsSettings from "./ContactsSettings";

const TabGeneral = () =>{
    return <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CompanyDetails />
                </Grid>
                <Grid item xs={12}>
                    <ContactsSettings />
                </Grid>
                <Grid item xs={12}>
                    <CampaignSocialData />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ACPISettings />
                </Grid>
                <Grid item xs={12}>
                    <EmailNotificationSettings />
                </Grid>
                <Grid item xs={12}>
                    <CLinkSettings />
                </Grid>
                <Grid item xs={12}>
                    <ExperiencesSettings />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}
export default TabGeneral;