import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {usersSelector, getUsers} from "../../slices/users";

const CreatorsDropdown = props => {

    const [userSearch, setUserSearch] = useState("");
    const dispatch = useDispatch();
    const {users} = useSelector(usersSelector);

    useEffect(() => {
        if (userSearch.trim() !== "") {
            dispatch(getUsers(1, 500, userSearch, null, 2));
        }
    }, [dispatch, userSearch])

    return (
        <Autocomplete
            variant={"outlined"}
            fullWidth
            noOptionsText={"Start typing to find creators…"}
            size="small"
            label={"Creator"}
            options={(userSearch.trim() !== "" ? users : [])}

            getOptionLabel={(option) => (option.first_name + " " + option.last_name)}
            {...props}
            renderInput={(params) => (
                <TextField margin={"normal"}
                           {...params}
                           value={userSearch}
                           onChange={(e) => setUserSearch(e.target.value)} label="Creator"
                           variant="outlined"/>
            )}
        />
    )
}
export default CreatorsDropdown;