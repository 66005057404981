import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clientsSelector, getClients} from "../../slices/clients";

const ClientsDropdown = props => {

    const [clientSearch, setClientSearch] = useState("");
    const dispatch = useDispatch();
    const {clients} = useSelector(clientsSelector);

    useEffect(() => {
        if (clientSearch.trim() !== "") {
            dispatch(getClients(1, 500, clientSearch));
        }
    }, [dispatch, clientSearch])

    return (
        <Autocomplete
            variant={"outlined"}
            fullWidth
            noOptionsText={"Start typing to find clients…"}
            size="small"
            label={"Client"}
            options={(clientSearch.trim() !== "" ? clients : [])}

            getOptionLabel={(option) => (`${option.name} - (${option.client_id})`)}
            {...props}
            renderInput={(params) => (
                <TextField margin={"normal"}
                           {...params}
                           value={clientSearch}
                           onChange={(e) => setClientSearch(e.target.value)} label="Client"
                           variant="outlined"/>
            )}
        />
    )
}
export default ClientsDropdown;