import React from "react";
import {Table, TableBody, TableCell, TableContainer} from "@material-ui/core";
import CampaignListTableHead from "./CampaignListTableHead";
import CampaignListTableRow from "./CampaignListTableRow";
import TableRow from "@material-ui/core/TableRow";

const TOTAL_DISPLAY_COLUMNS = 6;
const TOTAL_DISPLAY_ROWS = 3;

const CampaignListTable = ({campaigns, inProgress}) => {

    const getEmptyRows = () => {
        const totalRows = TOTAL_DISPLAY_ROWS - campaigns.length;
        if (totalRows > 0) {
            return (
                <TableRow key={"empty"}>
                    <TableCell colSpan={TOTAL_DISPLAY_COLUMNS} style={{height: `${50*totalRows}px`}} align={"center"}>
                        {!inProgress && campaigns.length === 0 && "No campaigns found."}
                    </TableCell>
                </TableRow>
            );
        } else {
            return null;
        }
    }

    return (
        <TableContainer>
            <Table>
                <CampaignListTableHead/>
                <TableBody>
                    {campaigns.map(c => <CampaignListTableRow campaign={c} key={c.referenceNumber}/>)}
                    {getEmptyRows()}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CampaignListTable;