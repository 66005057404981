import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const TopNotification = (props) => {
    const {severity, message, open} = props;
    return (
        <Snackbar data-testId={'top-notification'} elevation={3} anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={open}>
            <MuiAlert severity={severity} variant="filled">
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default TopNotification;