import React, {useEffect, useState} from "react";
import CardWithTitleAndAction from "../CardWithTitleAndAction";
import {Chip, Grid, Paper, Typography} from "@material-ui/core";
import dates from "../../../helpers/dates";
import Box from "@material-ui/core/Box";
import {numbers} from "../../../helpers/utils";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Button from "@material-ui/core/Button";
import ChangePlanDialog from "./ChangePlanDialog";
import EndSubscriptionDialog from "./EndSubscriptionDialog";
import api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";
import {useDispatch, useSelector} from "react-redux";
import {clientConfigSelector} from "../../../slices/clientConfig";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const defaultSwitchesValue ={
    team_enabled: false,
    site_enabled: false,
    social_enabled: false
}

const CurrentPlan = ({plan, onSuccess}) => {

    const [inProgress, setInProgress] = useState(false);
    const [changePlan, setChangePlan] = useState(false);
    const [endSubscription, setEndSubscription] = useState(false);
    const [base_price_pennies, setBasePricePennies] = useState("");
    const {clientConfig} = useSelector(clientConfigSelector);

    const [switches, setSwitched] = useState(defaultSwitchesValue);

    const onConvertedMoneyFieldChange = (event, value, convertToPennies=true) =>{
        const newValue = convertToPennies ? numbers.dollarsToPennies(value) : value;
        setBasePricePennies(newValue);
    }

    const disableAction = !plan || plan?.cancellation === true;

    const customActions = <Box display={'flex'}>
        <Button data-testId={'change-plan-action-change'} style={{marginRight: "16px"}} variant={'outlined'} onClick={()=>setChangePlan(true)}>Change plan</Button>
        <Button data-testId={'change-plan-action-end-subscription'} style={{marginRight: "16px"}} disabled={disableAction} variant={'outlined'} onClick={()=>setEndSubscription(true)}>End subscription</Button>
    </Box>

    useEffect(() => {
        if(plan){
            setBasePricePennies(plan?.base_price_pennies || 0);
            setSwitched(plan);
        }

    }, [plan]);

    const onSwitchChange = (event) => {
        const {name, checked} = event.target;
        const newValues = {...switches};
        newValues[name] = checked;
        setSwitched(newValues);
    }

    const dispatch = useDispatch();
    const updateAction = () =>{
        setInProgress(true)
        api.backoffice.company.updateCurrentPlan(clientConfig.client.id)(
            response =>{
                setInProgress(false);
                dispatch(setNotification('Plan monthly fee updated'));
                onSuccess();
            },
            error =>{
                dispatch(setNotification('Unable to update plan monthly fee', 'error'))
                setInProgress(false);
            }
        )({...plan, ...switches, base_price_pennies: (base_price_pennies ? base_price_pennies : 0)})
    }

    const onPlanChange = () =>{
        setEndSubscription(false);
        setChangePlan(false);
        onSuccess();
    }


    return <Box>
        <CardWithTitleAndAction customActions={customActions} title={'Current plan'} inProgress={inProgress} testId={'current-plan'} onClickAction={updateAction} disableAction={disableAction}>
            <Paper variant={'outlined'}>
                <Box p={3}>
                    <Grid container spacing={0} alignItems={'center'}>
                        <Grid item xs={12}>
                            <Typography>
                                Plan: {plan && plan?.cancellation !== true ? plan.name : "-"}
                            </Typography>
                            <Box py={1} data-testId={'current-plan-products'} display={'flex'} width={'100%'}>
                                Products: <Box pl={1} display={'flex'}>
                                {plan?.social_enabled && <Chip style={{marginRight: '8px'}} data-testId={'product-social'} variant={'outlined'} label={'Influence'} size={'small'} />}
                                {plan?.site_enabled && <Chip style={{marginRight: '8px'}} data-testId={'product-site'} variant={'outlined'} label={'Site'} size={'small'} />}
                                {plan?.team_enabled && <Chip style={{marginRight: '8px'}} data-testId={'product-team'} variant={'outlined'} label={'Team'} size={'small'} />}
                                <Chip data-testId={'product-track'} variant={'outlined'} label={'Track'} size={'small'} />
                                </Box>
                            </Box>
                            {(plan && plan?.cancellation !== true) && <Typography variant={'subtitle2'}>
                                Next billing cycle starts
                                on {plan ? dates.formatFromStringToDate(plan.next_billing_cycle) : "-"}
                            </Typography>}
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            <Box data-testId={'base_price_pennies'}>
            <CurrencyTextField margin="normal"
                               name={'base_price_pennies'}
                               textAlign="left"
                               fullWidth
                               label="Montlhy plan fee"
                               placeholder="Montlhy plan fee"
                               decimalPlaces={2}
                               minimumValue={0}
                               currencySymbol={"$"}
                               onChange={(e, value)=>onConvertedMoneyFieldChange(e, value)}
                               value={base_price_pennies ? numbers.penniesToDollars(base_price_pennies) : 0}
                               variant="outlined" size="small"/>
            </Box>
            <Typography variant={'subtitle2'}>
                Start date: {plan ? dates.formatFromStringToDate(plan.start_date) : "-"}
            </Typography>
            <Box data-testId={'plan-products'} flexDirection={'column'} display={'flex'}>
                <FormControlLabel control={<Switch data-testId={'current-plan-switch-social'} onChange={onSwitchChange} disabled={disableAction} checked={switches?.social_enabled || false} name={'social_enabled'}  />} label="Influence enabled" />
                <FormControlLabel control={<Switch data-testId={'current-plan-switch-site'} onChange={onSwitchChange} disabled={disableAction} checked={switches?.site_enabled || false} name={'site_enabled'}  />} label="Site enabled" />
                <FormControlLabel control={<Switch data-testId={'current-plan-switch-team'} onChange={onSwitchChange} disabled={disableAction} checked={switches?.team_enabled || false} name={'team_enabled'}  />} label="Team enabled" />
            </Box>
        </CardWithTitleAndAction>
        {endSubscription && <EndSubscriptionDialog open={endSubscription} onClose={()=>setEndSubscription(false)} onSuccess={onPlanChange} /> }
        {changePlan && <ChangePlanDialog open={changePlan} onClose={()=>setChangePlan(false)} onSuccess={onPlanChange} />}
    </Box>
}

CurrentPlan.defaultProps = {
    plan: {}
}
export default CurrentPlan;