import React from "react";
import { Box, Tooltip } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Chart } from "react-google-charts";
import HelpIcon from "@material-ui/icons/Help";

const DAYS = 7;

const transformData = (data, days) => {
    const salesByDate = {};
    const salesByClient = {};

    data.slice(-days).forEach((dayObj) => {
        const date = Object.keys(dayObj)[0];
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
        });

        const dayData = dayObj[date];
        salesByDate[formattedDate] = {};

        dayData.forEach((entry) => {
            const clientName = entry.client_name;
            const salesCount = entry.sales;
            const salesAmount = entry.sales_amount_pennies / 100;

            salesByDate[formattedDate][clientName] = salesCount;

            if (!salesByClient[clientName]) {
                salesByClient[clientName] = { sales: 0, amount: 0 };
            }
            salesByClient[clientName].sales += salesCount;
            salesByClient[clientName].amount += salesAmount;
        });
    });

    const topClients = Object.entries(salesByClient)
        .map(([client, { sales, amount }]) => [client, sales, amount])
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10); // Keep top 10 clients

    const topClientNames = topClients.map(([client]) => client).reverse();
    const chartArray = [["Date", ...topClientNames]];

    Object.entries(salesByDate).forEach(([formattedDate, clientSales]) => {
        const row = [formattedDate];
        topClientNames.forEach((client) => {
            row.push(clientSales[client] || 0);
        });
        chartArray.push(row);
    });

    return { chartData: chartArray, topClients };
};

const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    }).format(value);
};

const options = {
    title: `Sales by Client (Top 10), past ${DAYS} days`,
    legend: { position: "bottom" },
    curveType: "function",
};

export const SalesByClient = ({ salesArray }) => {
    const { chartData, topClients } = transformData(salesArray, DAYS);
    let containerStyle = {};

    if (salesArray.length) {
        containerStyle = {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        };
    }

    return (
        <Paper style={{ borderRadius: "6px" }}>
            <Box style={containerStyle}>
                {salesArray.length ? (
                    <>
                        <Box width="60%" style={{ padding: "6px", zIndex: 1 }}>
                            <Chart
                                chartType="LineChart"
                                width="110%"
                                height="600px"
                                data={chartData}
                                options={options}
                            />
                        </Box>
                        <Box p={5} mr={15} width="40%" style={{ zIndex: 2 }}>
                            <ul
                                style={{
                                    listStyleType: "none",
                                    padding: 0,
                                    margin: 0,
                                    border: "1px solid #ddd",
                                    borderRadius: "8px",
                                    backgroundColor: "#fff",
                                }}>
                                {topClients.map(
                                    ([client, totalSales, totalAmount]) => (
                                        <li
                                            key={client}
                                            style={{
                                                padding: "12px 15px",
                                                borderBottom: "1px solid #ddd",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                fontSize: "14px",
                                                color: "#333",
                                            }}>
                                            <span>{client}</span>
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                    justifyItems: "center",
                                                    alignContent: "center",
                                                }}>
                                                {formatCurrency(totalAmount)}
                                                <Tooltip
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: "5px"
                                                    }}
                                                    title={`${totalSales} Sales`}
                                                    placement="right">
                                                    <HelpIcon fontSize="small" />
                                                </Tooltip>
                                            </span>
                                        </li>
                                    )
                                )}
                            </ul>
                        </Box>
                    </>
                ) : (
                    <Box justifyContent={"center"} display={"flex"} py={6}>
                        No data found
                    </Box>
                )}
            </Box>
        </Paper>
    );
};

export default SalesByClient;
