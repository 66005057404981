

export const getAvailableUserStatus = () => ([
    {label: "New", value: 1},
    {label: "Approved", value: 2},
    {label: "Suspended", value: 3},
    {label: "Temporarily suspended", value: 4},
    {label: "Review", value: 5},
    {label: "Rejected", value: 6},
]);

export const getUserStatusNameFromId = (statusId) => {
    if (statusId === 1) {
        return "New";
    } else if (statusId === 2) {
        return "Approved";
    } else if (statusId === 3) {
        return "Suspended";
    } else if (statusId === 4) {
        return "Temporarily suspended";
    } else if (statusId === 5) {
        return "Review";
    } else if (statusId === 6) {
        return "Rejected";
    } else {
        return "Undefined";
    }
};