import React, {useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress, TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";

const PaymentStatusEditPaymentRefDialog = ({isOpen, onClose, onSaveSuccess, singleItem}) => {
    const dispatch = useDispatch();

    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState(false)
    const [refCode, setRefCode] = useState(singleItem.payout_reference || '');

    const sendPaid = () => {
        if(refCode.trim() === ''){
            setError(true);
            return;
        }

        const lastExecution = singleItem.executions?.length > 0 ? singleItem.executions[singleItem.executions?.length - 1] : null;
        const execution_id = lastExecution ? lastExecution.execution_id : null;
        const description = lastExecution ? lastExecution.description : "";
        if(!execution_id){
            return;
        }
        setInProgress(true);

        api.payment.updatePaymentRefCode(singleItem.order_id, execution_id)
        (response=>{
                setInProgress(false);
                onSaveSuccess();
            },
            error => {
                setInProgress(false);
                dispatch(setNotification('Request failed', 'error'))
            })
        ({
            "payout_reference": refCode,
            "execution_time": singleItem.execution_time,
            "description": description
        });
    }

    const handleLocalClose = () =>{
        onClose();
    }
    const onChangeRef = event =>{
        const {value} = event.target;
        setRefCode(value);
        if(value && value.trim() !== ''){
            setError(false);
        }else{
            setError(true);
        }
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Edit payment ref code</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        error={error}
                        helperText={error ? 'Payment ref code is required.' : ''}
                        margin={'normal'}
                        size={'small'}
                        label={'Payment ref code'}
                        placeholder={'Enter a payment ref code...'}
                        variant={'outlined'}
                        value={refCode}
                        onChange={onChangeRef} />
                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="contained" onClick={handleLocalClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="contained" fullWidth
                                    onClick={sendPaid}
                                    color={'primary'}
                                    disabled={inProgress}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default PaymentStatusEditPaymentRefDialog;