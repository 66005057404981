import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {LinearProgress, makeStyles, Typography} from '@material-ui/core';
import {campaignAdminSelector, reloadSection, updateOpenDialog} from "../../../slices/campaignAdmin";
import {useDispatch, useSelector} from "react-redux";
import Client from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";


const useStyles = makeStyles(theme => ({
    actions: {
        padding: theme.spacing(3)
    }

}))

const CampaignAdminDeleteDialog = ({open}) => {

    const [isSaving, setIsSaving] = useState(false);
    const {row, draft} = useSelector(campaignAdminSelector);
    const classes = useStyles();
    const dispatch = useDispatch();

    const onConfirmEvent = () => {
        setIsSaving(true);
        const campaign = {};
        campaign.id = row.id;
        campaign.creditBlockOverrideFlag = true;
        Client.CampaignApi.delete(row.id)(
            response => {
                setIsSaving(false);
                dispatch(reloadSection(draft));
                dispatch(setNotification("Campaign was removed"));
                dispatch(updateOpenDialog({
                    dialog: "CampaignAdminDelete",
                    value: false,
                    row: null
                }))

            },
            error => {
                dispatch(setNotification("Something went wrong", "error"));
            }
        )()
    }
    const handleClose = () => {
        dispatch(updateOpenDialog({
            dialog: "CampaignAdminDelete",
            value: false,
            row: null
        }))
    }
    return <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle >Delete campaign?</DialogTitle>
            <DialogContent style={{minWidth: 400}}>
                <Typography>
                    By deleting this campaign, it will be removed and cannot be undone.
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button autoFocus onClick={handleClose} variant="contained">

                    Cancel

                </Button>

                <Button onClick={onConfirmEvent} style={{width: "98px", backgroundColor:"#e01a2b", color:"#fff"}} color="error.main"
                        autoFocus variant="contained">
                    Delete
                </Button>
            </DialogActions>
            {isSaving && <LinearProgress/>}
        </Dialog>
    </div>;
}

export default CampaignAdminDeleteDialog;