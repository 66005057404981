import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import {clientAnalyticsSelector, getClientAnalytics} from "../slices/clientAnalytics";
import {useDispatch, useSelector} from "react-redux";
import TopNotification from '../components/TopNotification';
import {useIntl} from "react-intl";
import {setPageTitle} from "../slices/page";
import moment from "moment";
import AnalyticsClientsTable from "../components/AnalyticsClientsTable/AnalyticsClientsTable";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },
    paper: {
        height: '100%'
    }
}));

const clearNotification = {message: "", open: false, severity: "success"};
const initialDate = {
    startDate: moment().subtract(30, "days").set({hour: 0, minute: 0, second: 0, millisecond: 0}),
    endDate: moment().set({hour: 23, minute: 59, second: 59, millisecond: 59})
}

const Analytics = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();

    const {
        clientAnalytics,
        isClientAnalyticsLoading,
        isGetClientAnalyticsError,
        clientAnalyticsErrorMessage,
        isClientAnalyticsInitialized,
    } = useSelector(clientAnalyticsSelector);
    const [notificationMessage, setNotificationMessage] = useState(clearNotification);
    const [clientAnalyticsDateRange, setClientAnalyticsDateRange] = useState(initialDate);

    const textAnalytics = intl.formatMessage({
        id: 'ClientSummary.pageTitle',
        defaultMessage: 'Client Summary'
    });

    const reloadClientAnalytics = useCallback((dateRange) => {
        return dispatch(
            getClientAnalytics(
                dateRange.startDate.format(moment.defaultFormatUtc),
                dateRange.endDate.format(moment.defaultFormatUtc)
            )
        );
    }, [dispatch]);

    useEffect(() => {
        dispatch(setPageTitle(textAnalytics));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch, textAnalytics]);

    useEffect(() => {
        if (isClientAnalyticsInitialized === false) {
            reloadClientAnalytics(clientAnalyticsDateRange);
        }
    }, [reloadClientAnalytics, isClientAnalyticsInitialized, clientAnalyticsDateRange]);

    useEffect(() => {
        if (isGetClientAnalyticsError) {
            setNotificationMessage({message: clientAnalyticsErrorMessage, open: true, severity: "error"});
        } else {
            setNotificationMessage(clearNotification);
        }
    }, [isGetClientAnalyticsError, setNotificationMessage, clientAnalyticsErrorMessage]);

    useEffect(() => {
        if (notificationMessage.open) {
            setTimeout(() => {
                setNotificationMessage(clearNotification);
            }, 2500);
        }
    }, [notificationMessage, setNotificationMessage]);

    const onChangeClientAnalyticsDateRange = (dateRange) => {
        const isStartDateValid = dateRange.startDate && dateRange.startDate.isValid();
        const isEndDateValid = dateRange.endDate && dateRange.endDate.isValid();
        setClientAnalyticsDateRange(dateRange);
        if (isStartDateValid && isEndDateValid) {
            reloadClientAnalytics(dateRange);
        }
    }

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        {isClientAnalyticsLoading && <LinearProgress/>}
                        <Box ml={2} mr={2}>
                            <AnalyticsClientsTable loading={isClientAnalyticsLoading}
                                                   clientAnalytics={clientAnalytics}
                                                   dateRange={clientAnalyticsDateRange}
                                                   onChangeDateRange={onChangeClientAnalyticsDateRange}/>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <TopNotification open={notificationMessage.open} message={notificationMessage.message}
                             severity={notificationMessage.severity}/>
        </Box>
    );
}

export default Analytics;