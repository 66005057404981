import React from "react";
import {LinearProgress, Paper, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const CardWithTitleAndAction = ({inProgress, title, children, onClickAction, customActions, testId, disableAction}) =>{
    return <Paper data-testId={testId}>
        <Box p={2}>
            <Typography>
                {title}
            </Typography>
        </Box>
        <Divider />
        <Box px={3} py={3}>
            {children}
        </Box>
        <Divider />
        <Box p={2} justifyContent={customActions ? 'space-between' : 'flex-end'} display={'flex'}>
            {customActions}<Button data-testId={'card-with-title-action'} disabled={inProgress || disableAction} onClick={onClickAction} variant={'contained'}>Save</Button>
        </Box>
        {inProgress && <LinearProgress />}
    </Paper>

}

CardWithTitleAndAction.defaultProps = {
    inProgress: false,
    title: "",
    onClickAction: ()=>{

    },
    disableAction: false,
    testId:'card-with-title',
    customAction: null
}

export default CardWithTitleAndAction;