import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Box from "@material-ui/core/Box";
import TablePagination from '@material-ui/core/TablePagination';
import {Checkbox, LinearProgress, TableSortLabel} from "@material-ui/core";
import {useDebouncedCallback} from "use-debounce";
import {setNotification} from "../../../slices/notification";
import {useDispatch, useSelector} from "react-redux";
import {
    paymentApproveSelector,
    getPaymentApproveList,
    updateApproveParameters,
    resetState
} from "../../../slices/paymentApprove";
import TableHead from "@material-ui/core/TableHead";
import TableSearchAndFilter from "../../TableSearchAndFilter";
import PaymentApproveFilters from "./PaymentApproveFilters";
import ApproveToolbarActions from "./ApproveToolbarActions";
import {
    objectToRequestParameters,
    pageHasParameters,
    getPageParametersAsObject, updateBrowserWithUrlParameters, pagePathnameContains
} from "../../../helpers/pageParameters";
import PaymentApproveTableRow from "./PaymentApproveTableRow";
import PaymentApproveDialog from "./PaymentApproveDialog";
import PaymentRejectDialog from "./PaymentRejectDialog";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    emptyTable: {
        height: '424px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
        '& td': {
            borderBottom: 0,
        }
    },
}));

const PaymentApproveTable = () => {
    const {
        paymentsApproveList,
        paymentsApproveTotal,
        isLoading,
        paymentApproveFilters,
        error,
        errorMessage
    } = useSelector(paymentApproveSelector);
    const classes = useStyles();
    const dispatch = useDispatch();

    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [page, setPage] = useState(1);

    const [selected, setSelected] = useState([]);


    const [filterOpen, setFilterOpen] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);

    const [searchTerm, setSearchTerm] = useState(paymentApproveFilters.search || "");
    const debouncedSearchTerm = useDebouncedCallback((value)=>{
        dispatch(updateApproveParameters({...paymentApproveFilters, page: 1, search: searchTerm, }));
    }, 500);
    const searchHandler = value =>{
        setSearchTerm(value);
        debouncedSearchTerm();
    }
    const [pageInitialized, setPageInitialized] = useState(false);

    useEffect(() => {
        if (error) {
            dispatch(setNotification(errorMessage, "error"));
        }
    }, [error, errorMessage, dispatch]);

    useEffect(() => {
        if(!pageInitialized){
            if(pageHasParameters() && pagePathnameContains("approval")){
                const pageParams = getPageParametersAsObject();
                dispatch(updateApproveParameters(pageParams))
            }else{
                updateBrowserWithUrlParameters(paymentApproveFilters);
                dispatch(resetState());
            }
            setPageInitialized(true);
        }
    }, [dispatch, pageInitialized, paymentApproveFilters]);

    useEffect(() => {
        if(pageInitialized) {
            updateBrowserWithUrlParameters(paymentApproveFilters);
            dispatch(getPaymentApproveList(objectToRequestParameters(paymentApproveFilters)));
        }
    }, [dispatch, pageInitialized, paymentApproveFilters]);

    useEffect(() => {
        setPage(1);
    }, [debouncedSearchTerm]);

    const handleChangePage = (event, newPage) => {
        if (newPage >= 0) {
            setPage(newPage+1);
        }
        dispatch(updateApproveParameters({...paymentApproveFilters, page: newPage+1 }));
    }

    const handleChangeRowsPerPage = (event) => {
        setItemsPerPage(+event.target.value);
        setPage(1);
        dispatch(updateApproveParameters({...paymentApproveFilters, page: 1, per_page: +event.target.value}));
    }

    const onSelectAll = (event) => {debugger;
        if (event.target.checked) {
            setSelected(paymentsApproveList);
        } else {
            setSelected([]);
        }
    }

    const onSelect = (item) => {
        const itemExist = selected.find(f=> f.item_id === item.item_id);
        if (itemExist) {
            setSelected(selected.filter(s => s.item_id !== item.item_id));
        } else {
            setSelected([...selected, item]);
        }
    }
    const direction = paymentApproveFilters?.sort ? paymentApproveFilters.sort.split(":")[1] : "desc";
    const activeSortedColumn = paymentApproveFilters?.sort ? paymentApproveFilters.sort.split(":")[0] : "title";
    const handleSortEvent = column => {
        const sortParams =  paymentApproveFilters?.sort ? paymentApproveFilters.sort.split(":") : [];
        const sortField = sortParams.length > 0 ? sortParams[0] : "";
        const sortDirection = sortParams.length > 0 ? sortParams[1] : "";
        const isAsc = column === sortField && sortDirection === "asc";
        dispatch(updateApproveParameters({...paymentApproveFilters, page: 1, sort: `${column}:${(isAsc ? "desc" : "asc")}`}));
    }
    const actionsDone = () => {
        setSelected([]);
        setOpenReject(false);
        setOpenApprove(false);
        dispatch(updateApproveParameters({...paymentApproveFilters, page: 1}));
    }
    const openApproveHandler = () => {
        setOpenApprove(true);
    }
    const openRejectHandler = () => {
        setOpenReject(true);
    }
    const rowApproveHandler = item =>{
        setSelected([item]);
        openApproveHandler();
    }
    const rowRejectHandler = item =>{
        setSelected([item]);
        openRejectHandler();
    }
    return (
        <Box>
            {selected.length > 0 ? <ApproveToolbarActions
                    clickApprove={openApproveHandler}
                clickReject={openRejectHandler}
                    selected={selected}/> :
                <TableSearchAndFilter searchValue={searchTerm} onChangeSearchTerm={searchHandler} onClickFilterIcon={() => setFilterOpen(true)}/>
            }
            <TableContainer>
                <Table size={'medium'} className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <Checkbox
                                    onChange={(e) => onSelectAll(e)}/>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={()=>handleSortEvent("title")} active={activeSortedColumn === "title"} direction={direction}>
                                    Item/Description
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                Client
                            </TableCell>
                            <TableCell>
                                Client ID
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={()=>handleSortEvent("first_name")} active={activeSortedColumn === "first_name"} direction={direction}>
                                    Creator
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                Creator ID
                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={()=>handleSortEvent("statement")} active={activeSortedColumn === "statement"} direction={direction}>
                                    Statement
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={()=>handleSortEvent("item_timestamp")} active={activeSortedColumn === "item_timestamp"} direction={direction}>
                                    Date created
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={()=>handleSortEvent("total_amount_pennies")} active={activeSortedColumn === "total_amount_pennies"} direction={direction}>
                                    Amount
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            paymentsApproveList.map((payment) => {
                                const isSelected = Boolean(selected.find(f=>f.item_id === payment.item_id));
                                return <PaymentApproveTableRow
                                    payment={payment}
                                    onAprove={rowApproveHandler}
                                    onReject={rowRejectHandler}
                                    enableCheckbox={true}
                                    onSelect={onSelect}
                                    key={payment.item_id}
                                    isSelected={isSelected}/>
                            })
                        }
                        {
                            (!isLoading && searchTerm.trim() !== "" && paymentsApproveTotal === 0) &&
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <Box justifyContent={'center'} display={'flex'} py={6}>
                                        No results found
                                    </Box>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={paymentsApproveTotal}
                rowsPerPage={itemsPerPage}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                backIconButtonProps={{disabled: isLoading || paymentsApproveList.length === 0 || page === 1}}
                nextIconButtonProps={{disabled: isLoading || paymentsApproveList.length === 0 || page >= Math.ceil(paymentsApproveTotal / itemsPerPage)}}
            />
            {isLoading && <LinearProgress/>}
            { filterOpen && <PaymentApproveFilters isOpen={filterOpen} onClose={() => setFilterOpen(false)}/>}
            <PaymentApproveDialog isOpen={openApprove} onClose={()=>setOpenApprove(false)} selected={selected} onSaveSuccess={actionsDone} />
            <PaymentRejectDialog isOpen={openReject} onClose={()=>setOpenReject(false)} selected={selected} onSaveSuccess={actionsDone}/>
        </Box>
    );
}

export default PaymentApproveTable;