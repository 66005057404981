import React from "react";
import {useSelector} from 'react-redux'
import {pageSelector} from "../../slices/page";
import Typography from "@material-ui/core/Typography";

const PageTitle = () => {
    const {currentTitle} = useSelector(pageSelector);

    return (
        <Typography variant="h6" component={"h1"}>
            {currentTitle}
        </Typography>
    )
};

export default PageTitle;