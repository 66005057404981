import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import InvoicePaymentsTableHead from "./InvoicePaymentsTableHead";
import InvoicePaymentsTableRow from "./InvoicePaymentsTableRow";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        "& .MuiTableHead-root": {
            backgroundColor: theme.palette.grey[200],
            "& .MuiTableCell-root": {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
            },
        },
        "& .MuiTableRow-root .MuiTableCell-root:last-child": {
            padding: 0,
            whiteSpace: "nowrap",
        },
    },
    emptyTable: {
        height: '100px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
    },
}));

const InvoicePaymentsTable = ({payments, onClickEditPayment, onClickRemovePayment, isReadOnly}) => {
    const classes = useStyles();
    return (
        <TableContainer>
            <Table size={'medium'} className={classes.table}>
                <InvoicePaymentsTableHead isReadOnly={isReadOnly}/>
                <TableBody>
                    {
                        payments.map((payment) => <InvoicePaymentsTableRow key={payment.payment_id}
                                                                           payment={payment}
                                                                           isReadOnly={isReadOnly}
                                                                           onClickEditPayment={onClickEditPayment}
                                                                           onClickRemovePayment={onClickRemovePayment}/>)
                    }
                    {
                        payments.length === 0 && (
                            <TableRow className={classes.emptyTable}>
                                <TableCell colSpan={isReadOnly ? 2 : 3}>No payments.</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default InvoicePaymentsTable;