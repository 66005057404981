import React, {useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress, MenuItem, Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";
import TextField from "@material-ui/core/TextField";
import CurrencyDisplay from "../../CurrencyDisplay";

const getPayload = (selected) => {
    return selected.map(item=>{
        return {
            item_id: item.item_id,
            amount_pennies: item.amount_pennies,
            quantity: item.quantity
        }})
}

const PaymentRejectDialog = ({isOpen, onClose, onSaveSuccess, selected}) => {
    const dispatch = useDispatch();

    const [inProgress, setInProgress] = useState(false);
    const [reason, setReason] = useState(null);

    const sendReject = () => {
        if(!reason){
            dispatch(setNotification('Please select reason.', 'error'));
            return
        }
        const payload = getPayload(selected);
        setInProgress(true);
        api.payment.sendReject()
        (response=>{
                setInProgress(false);
                setReason(null);
                onSaveSuccess();
            },
            error => {
                setInProgress(false);
                dispatch(setNotification('Reject request failed', 'error'))
            })
        ({items: payload, note: reason});
    }

    const handleReasonChange = event => {
        const {value} = event.target;
        setReason(value);
    }

    const handleLocalClose = () =>{
        setReason(null);
        onClose();
    }

    const quantity = selected.length;
    const total = quantity > 0 ? selected.map(m=>m.amount_pennies).reduce((prev, next)=> prev + next) : 0;
    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Reject items for payment</DialogTitle>
                <DialogContent>
                    <Typography>
                        By clicking “REJECT” the following {quantity} items, totalling <CurrencyDisplay value={total || 0} penniesToDollars />, will be rejected for payment.
                    </Typography>
                    <TextField
                        select
                        name={'reason'}
                        label={'Reason'}
                        value={reason}
                        onChange={handleReasonChange}
                        fullWidth
                        variant={'outlined'}
                        size={'small'}
                        margin={'normal'}>
                        <MenuItem value={'Non-compliance with campaign terms & conditions'}>
                            Non-compliance with campaign terms & conditions
                        </MenuItem>
                        <MenuItem value={'Non-compliance with program terms & conditions'}>
                            Non-compliance with program terms & conditions
                        </MenuItem>
                        <MenuItem value={'Invalid payment amount'}>
                            Invalid payment amount
                        </MenuItem>
                    </TextField>
                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="contained" onClick={handleLocalClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="contained" fullWidth
                                    onClick={sendReject}
                                    color={'primary'}
                                    style={{
                                        backgroundColor: '#c62828',

                                    }}
                                    disabled={inProgress}>
                                Reject
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default PaymentRejectDialog;