import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Paper, Tab} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {setPageTitle} from "../slices/page";
import {useDispatch, useSelector} from "react-redux";
import {withUser} from "../providers/UserProvider";
import {ROLES} from "../helpers/roleHelper";
import {
    createConnection,
    messagesSelector,
} from "../slices/messages";
import Container from "@material-ui/core/Container";
import Messages from "../components/Messages/Messages";
import BrandCreatorMessages from "../components/Messages/BrandCreator/Messages";

const TAB_SUPPORT = "support";
const BRAND_CREATOR_CHATS = "brand-creator";
const MessagesContainer = ({userId}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        isChatWebSocketConnectionOpen,
    } = useSelector(messagesSelector);

    const [activeTab, setActiveTab] = useState(TAB_SUPPORT);

    useEffect(() => {
        dispatch(setPageTitle("Messages"));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);

    useEffect(() => {
        if (isChatWebSocketConnectionOpen === null) {
            dispatch(createConnection(userId, ROLES.ROLE_SUPER_ADMIN));
        }
    }, [dispatch, isChatWebSocketConnectionOpen, userId]);

    return (
        <Box className={classes.root}>
            <TabContext value={activeTab}>
                <Paper className={classes.tabs} square>
                    <TabList value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
                        <Tab label={"Support chats"} value={TAB_SUPPORT}/>
                        <Tab label={"Brand <> Creator chats"} value={BRAND_CREATOR_CHATS}/>
                    </TabList>
                </Paper>
                <Container maxWidth={"lg"}>
                    <TabPanel value={TAB_SUPPORT}>
                        <Messages/>
                    </TabPanel>
                    <TabPanel value={BRAND_CREATOR_CHATS}>
                        <BrandCreatorMessages/>
                    </TabPanel>
                </Container>
            </TabContext>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        backgroundColor: theme.palette.grey[100],
    },
    tabs: {
        background: "#FFF",
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
}));

export default withUser(MessagesContainer);