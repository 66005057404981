import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

const InvoiceLineItemsTableHead = ({isReadOnly}) => {
    return (
        <TableHead>
            <TableRow key="thead">
                <TableCell>
                    <b>ITEM</b>
                </TableCell>
                <TableCell>
                    <b>TYPE</b>
                </TableCell>
                <TableCell>
                    <b> UNIT PRICE</b>
                </TableCell>
                <TableCell>
                    <b>QUANTITY</b>
                </TableCell>
                <TableCell style={{whiteSpace: "nowrap"}} width={120}>
                    <b>TOTAL (USD)</b>
                </TableCell>
                {!isReadOnly && <TableCell padding={"checkbox"}/>}
            </TableRow>
        </TableHead>
    )
}

export default InvoiceLineItemsTableHead;