import moment from "moment";

const MessagesHelper = {
    createCommand: (randomId, code, extra = {}) => ({
        random_id: randomId,
        code,
        ...extra,
    }),

    generateRandomId: () => {
        const max = Math.pow(2, 64) / 2;
        return Math.floor(Math.random() * (max + 1));
    },

    getTimeIndicatorLabel: (timeString) => {
        return MessagesHelper.getTimeIndicator(timeString).format("MMMM D, YYYY");
    },

    getTimeIndicator: (timeString) => {
        return moment.utc(timeString).local().startOf("day");
    },
}

const COMMANDS = Object.freeze({
    ME: "me",
    LIST_CHATS: "list_chats",
    PEERS: "peers",
    HISTORY: "history",
    MESSAGE: "message",
    MESSAGE_SENT: "message_sent",
    READ: "read",
    CHAT: "chat",
    TYPING: "typing",
});

export {COMMANDS};

export default MessagesHelper;