import makeStyles from "@material-ui/core/styles/makeStyles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiTableCell-root": {
            whiteSpace: "nowrap",
        },
    },
}));

const ClientsTableHead = () => {
    const classes = useStyles();
    return (
        <TableHead className={classes.root}>
            <TableRow key="thead">
                <TableCell>
                    Client
                </TableCell>
                <TableCell>
                    Plan
                </TableCell>
                <TableCell>
                    Start date
                </TableCell>
                <TableCell>
                    Payment method
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default ClientsTableHead;