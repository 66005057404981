import environment, {ENV_NAMES} from "./environment";
import routes from "./routes";

const impersonationHelper = {
    getDomainForCookie: () => {
        return `.${environment.getBaseDomain()}`;
    },

    getStartUrlCookieName: () => {
        if (environment.getName() === ENV_NAMES.PROD) {
            return "creatable_impersonation_start_url";
        } else {
            return `${environment.getName()}_creatable_impersonation_start_url`;
        }
    },

    getCookieCreationOptions: () => {
        return {
            domain: impersonationHelper.getDomainForCookie(),
            path: '/',
            sameSite: "lax",
            secure: true
        };
    },

    addImpersonationStartUrl: (cookieValue, url) => {
        const list = Array.isArray(cookieValue) ? cookieValue : [];
        list.push(url);
        return list;
    },

    extractLastImpersonationStartUrl: (cookieValue) => {
        const list = Array.isArray(cookieValue) ? cookieValue : [];
        if (list.length === 0) {
            return [routes.getLoginUrl(), []];
        } else {
            const lastStartUrl = list.pop();
            return [lastStartUrl, list];
        }
    }
}

export default impersonationHelper;
