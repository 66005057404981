import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Box, Paper} from "@material-ui/core";
import {setPageTitle} from "../slices/page";
import {useDispatch} from "react-redux";
import PaymentStatusTable from "../components/Payment/PaymentOrders/PaymentStatusTable";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    }
}));

const PaymentStatus = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle("Payment status"));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <PaymentStatusTable />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PaymentStatus;