import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { TableSortLabel } from '@material-ui/core';

const PlansTableHead = () =>{
    return <TableHead>
        <TableRow>
            <TableCell>
                Plan name
            </TableCell>
            <TableCell>
                <TableSortLabel active direction={"desc"}>Base monthly fee</TableSortLabel>
            </TableCell>
            <TableCell>
                Influence enabled
            </TableCell>
            <TableCell>
                Site enabled
            </TableCell>
            <TableCell>
                Team enabled
            </TableCell>
            <TableCell>
                Plan enabled
            </TableCell>
            <TableCell>
                Default
            </TableCell>

        </TableRow>
    </TableHead>
}
export default PlansTableHead;