import {createSlice} from '@reduxjs/toolkit';
import api from '../helpers/api';
import RequestError from '../helpers/RequestError';
import ResponseStatus from '../helpers/ResponseStatus';

const SECTION_MOBILE_APP = 'mobile_app';
const SECTION_DASHBOARDS = 'dashboards';

export const initialState = {
    mobileAppConfig: null,
    dashboardsConfig: null,
    errorMessage: '',
    isLoading: true
};

const rootConfigSlice = createSlice({
    name: 'rootConfig',
    initialState,
    reducers: {
        loadMobileAppConfigSuccess: (state, {payload}) => {
            state.mobileAppConfig = payload;
            state.isLoading = false;
        },
        loadDashboardsConfigSuccess: (state, {payload}) => {
            state.dashboardsConfig = payload;
            state.isLoading = false;
        },
        setRequestFailure: (state, {payload}) => {
            state.error = true;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        setLoadingState: (state, {payload}) => {
            state.isLoading = payload;
        },
        saveSuccess: (state, {payload}) => {
            state.isLoading = false;
        }
    },
});

export const rootConfigSelector = state => state.rootConfig;
export default rootConfigSlice.reducer;

const {loadMobileAppConfigSuccess, loadDashboardsConfigSuccess, setRequestFailure, saveSuccess, setLoadingState} = rootConfigSlice.actions;
export const fetchMobileAppConfig = () => dispatch  => {
    dispatch(setLoadingState(true));
    api.getRootConfigBySection(SECTION_MOBILE_APP)(
        response => {
            const responseHelper = new ResponseStatus(response);
            if (responseHelper.isError()) {
                dispatch(setRequestFailure({message: responseHelper.getErrorMessage()}));
            } else {
                dispatch(loadMobileAppConfigSuccess(response.data));
            }
        },
        error => {
            const respError = new RequestError(error)
            dispatch(setRequestFailure({
                status: respError.getHttpStatusCode(),
                message: respError.getMessage()
            }));
        }
    );
}

export const fetchDashboardsConfig = () => dispatch  => {
    dispatch(setLoadingState(true));
    api.getRootConfigBySection(SECTION_DASHBOARDS)(
        response => {
            const responseHelper = new ResponseStatus(response);
            if (responseHelper.isError()) {
                dispatch(setRequestFailure({message: responseHelper.getErrorMessage()}));
            } else {
                dispatch(loadDashboardsConfigSuccess(response.data));
            }
        },
        error => {
            const respError = new RequestError(error)
            dispatch(setRequestFailure({
                status: respError.getHttpStatusCode(),
                message: respError.getMessage()
            }));
        }
    );
}

export const saveMobileAppConfig = (config) => dispatch  => {
    dispatch(setLoadingState(true));
    api.saveMobileAppConfig()(
        response => {
            const responseHelper = new ResponseStatus(response);
            if (responseHelper.isError()) {
                dispatch(setRequestFailure({message: responseHelper.getErrorMessage()}));
            } else {
                dispatch(saveSuccess(response.data));
            }
        },
        error => {
            const respError = new RequestError(error)
            dispatch(setRequestFailure({
                status: respError.getHttpStatusCode(),
                message: respError.getMessage()
            }));
        }
    )(config);
}


export const saveMaintenanceModeConfig = (config) => dispatch  => {
    dispatch(setLoadingState(true));
    api.saveMaintenanceModeConfig()(
        response => {
            const responseHelper = new ResponseStatus(response);
            if (responseHelper.isError()) {
                dispatch(setRequestFailure({message: responseHelper.getErrorMessage()}));
            } else {
                dispatch(saveSuccess(response.data));
            }
        },
        error => {
            const respError = new RequestError(error)
            dispatch(setRequestFailure({
                status: respError.getHttpStatusCode(),
                message: respError.getMessage()
            }));
        }
    )(config);
}