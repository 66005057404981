import {createSlice} from '@reduxjs/toolkit';
import api from '../helpers/api';
import ResponseStatus from "../helpers/ResponseStatus";
import RequestError from "../helpers/RequestError";

export const initialState = {
    clientConfig: null,
    rootConfig: null,
    isClientConfigInitialized: false,
    isClientConfigLoading: true,
    isClientConfigError: false,
    clientConfigErrorMessage: "",
}

const clientConfigSlice = createSlice({
    name: 'clientConfig',
    initialState,
    reducers: {
        onRequestStart: (state, {payload}) => {
            state.isClientConfigLoading = true;
            state.clientConfigErrorMessage = "";
            state.isClientConfigError = false;
        },
        onGetClientConfigSuccess: (state, {payload}) => {
            state.isClientConfigError = payload.isError;
            state.clientConfigErrorMessage = payload.errorMessage;
            state.isClientConfigLoading = false;
            state.clientConfig = payload.data;
            state.isClientConfigInitialized = true;
        },
        onRequestSuccess: (state, {payload}) => {
            state.isClientConfigError = payload.isError;
            state.clientConfigErrorMessage = payload.errorMessage;
            state.isClientConfigLoading = false;
        },
        onGetRootClientConfigSuccess: (state, {payload}) => {
            state.rootConfig = {
                ...state.rootConfig,
                ...payload
            }
        },
        onRequestFailure: (state, {payload}) => {
            state.isClientConfigError = payload.isError;
            state.clientConfigErrorMessage = payload.errorMessage;
            state.isClientConfigLoading = false;
        },
    }
});

export default clientConfigSlice.reducer;
export const clientConfigSelector = state => state.clientConfig;

const {
    onRequestStart,
    onGetClientConfigSuccess,
    onRequestSuccess,
    onRequestFailure,
    onGetRootClientConfigSuccess
} = clientConfigSlice.actions;

const handleGetResponse = (response) => ({
    isError: false,
    errorMessage: "",
    data: response.data
})
const handlePutResponse = (response) => {
    const respStatus = new ResponseStatus(response);
    return {
        isError: respStatus.isError(),
        errorMessage: respStatus.getErrorMessage(),
        data: response.data.data
    };
}
const handleError = (error) => {
    const reqError = new RequestError(error);
    return {
        isError: true,
        errorMessage: reqError.getMessage(),
        statusCode: reqError.getHttpStatusCode(),
    };
}

export const getClientConfig = (hash) => dispatch => {
    dispatch(onRequestStart());
    return api.getClientConfig(hash, true)(
        response => dispatch(onGetClientConfigSuccess(handleGetResponse(response))),
        error => dispatch(onRequestFailure(handleError(error)))
    );
}

export const getRootClientConfig = (section) => dispatch => {
    dispatch(onRequestStart());
    return api.getRootConfigBySection(section)(
        response => {
            const newRootSection = {};
            newRootSection[section] = response.data;
            dispatch(onGetRootClientConfigSuccess(newRootSection));
        },
        error => dispatch(onRequestFailure(handleError(error)))
    );
}

const putClientConfig = (section, clientConfig, clientHash) => dispatch => {
    dispatch(onRequestStart());
    return api.putClientConfigSection(clientHash, section)(
        response => dispatch(onRequestSuccess(handlePutResponse(response))),
        error => dispatch(onRequestFailure(handleError(error)))
    )(clientConfig);
}

export const putClientSection = (clientHash, clientSection) => putClientConfig("client", clientSection, clientHash);
export const putDashboardsSection = (clientHash, dashboardsSection) => putClientConfig("dashboards", dashboardsSection, clientHash);
export const putExperiencesSection = (clientHash, experiencesSection) => putClientConfig("experiences", experiencesSection, clientHash);
