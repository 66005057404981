import CurrencyFormat from "react-currency-format";
import React from "react";

function getValue(value, penniesToDollars){
    let amount = value;
    if(penniesToDollars){
        amount = value/100;
    }
    return amount < 0 ? -1 * amount : amount;
}

const CurrencyDisplay = ({value, penniesToDollars}) =>{

    if(!value){
        return <>-</>
    }
    const symbol = value < 0 ? "-$" : "$";
    return <>{symbol}<CurrencyFormat
        decimalScale={2}
        fixedDecimalScale
        displayType={"text"}
        thousandSeparator
        value={getValue(value, penniesToDollars)} /></>

}

CurrencyDisplay.defaultProps = {
    penniesToDollars: false
}
export default CurrencyDisplay;