import React, {useEffect, useState} from "react";
import {Fab, LinearProgress, Table, TableContainer} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import PlansTableHead from "./PlansTableHead";
import {useDispatch, useSelector} from "react-redux";
import {getPlans, plansSelector} from "../../slices/plans";
import NoPlans from "./NoPlans";
import PlansTableRow from "./PlansTableRow";
import AddIcon from '@material-ui/icons/Add';
import {useTheme} from "@material-ui/core/styles";
import AddPlanDialog from "./AddPlanDialog";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const PlansTable = () =>{
    const {isPlansInProgress, plans, totalPlans} = useSelector(plansSelector);
    const [initialized, setInitialized] = useState(false);
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [toEdit, setToEdit] = useState(null);
    const [page, setPage] = useState(0);
    const itemsPerPage= 50;
    const theme = useTheme();
    const dispatch = useDispatch();
    useEffect(() => {
        if(!initialized){
            setInitialized(true);
            dispatch(getPlans(page + 1, itemsPerPage));
        }
    }, [initialized, dispatch, page, itemsPerPage]);

    const openAdd = () =>{
        setToEdit(null);
        setOpenAddEdit(true);
    }
    const openEdit = (planToEdit) => {
        setToEdit(planToEdit);
        setOpenAddEdit(true);
    }
    const reloadCB = () =>{
        setPage(0);
        dispatch(getPlans(1, itemsPerPage));
    }
    const onClose = (reload=false) =>{
        setToEdit(null);
        setOpenAddEdit(false);
        if(reload){
            reloadCB();
        }
    }


    const handleChangePage = (event, page) =>{
        setPage(page);
        dispatch(getPlans(page + 1, itemsPerPage));
    }

    return <TableContainer>
        <Table>
            <PlansTableHead />
            <TableBody>
                {plans.map(plan=>{
                    return <PlansTableRow key={plan.plan_id} onClick={openEdit} plan={plan} />
                })}
                {(isPlansInProgress === false && totalPlans === 0) && <NoPlans />}
            </TableBody>
            <TableFooter>
                <TableRow>
                <TablePagination
                    count={totalPlans}
                    rowsPerPage={itemsPerPage}
                    rowsPerPageOptions={[]}
                    page={page}
                    onChangePage={handleChangePage}

                />
                </TableRow>
            </TableFooter>
        </Table>
        <Fab id={"add-plan"}
             style={{
                position: 'absolute',
                bottom: theme.spacing(2),
                right: theme.spacing(2),
            }}
             color={'primary'} onClick={openAdd}>
            <AddIcon />
        </Fab>

        {isPlansInProgress && <LinearProgress /> }
        {openAddEdit && <AddPlanDialog open={openAddEdit} toEdit={toEdit} onClose={onClose} />}
    </TableContainer>
}
export default PlansTable;