import React, {Fragment, useEffect, useState} from 'react';
import {Box, IconButton, Link, makeStyles, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import dates from "../../helpers/dates";
import CurrencyFormat from "react-currency-format";
import Divider from "@material-ui/core/Divider";
import {useDispatch} from "react-redux";
import {updateOpenDialog} from "../../slices/campaignAdmin";
import Client from "../../helpers/api";
import CampaignInventorySeedingStatusEnum from "../../helpers/CampaignInventorySeedingStatusEnum";
import {useHistory, useParams} from "react-router-dom";
import routes from "../../helpers/routes";

const useStyles =  makeStyles({
    noWrapText:{
        whiteSpace: 'nowrap'
    }
})

const getSampleType = sampleType =>{
    const types = {
        "DirectShipment": "Direct shipment",
        "CouponCode" : "Coupon code",
        "InStoreSample": "In store sample"
    }
    return types[sampleType] || "Other";
}

const formatCurrency =  value => {
    return <>$<CurrencyFormat
        decimalScale={2}
        fixedDecimalScale
        displayType={"text"}
        thousandSeparator
        value={value || 0} /></>
}



const TableRowMenu = ({row}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const {tab} = useParams();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleMenuItemClick = (selectedDialog) => () => {
        setAnchorEl(null);
        if(selectedDialog === "CampaignAdminManager"){
            history.push(routes.getBrandCampaignAdmin(`/${tab}/campaign/${row.id}`));
        }
        dispatch(updateOpenDialog({
            dialog: selectedDialog,
            value: true,
            row: row
        }))
    }

    const disableDelete = row.status === "Ended";
    const disableUnlocks = row.status !== "Draft";
    const enableManagement = row.status === "Draft" && !row.productSeedingFlag;
    return (
        <Fragment>
            <IconButton onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem key={"manage_campaign"} onClick={handleMenuItemClick("CampaignAdminManager")}>Manage campaign</MenuItem>
                <MenuItem key={"details"} onClick={handleMenuItemClick("CampaignAdminDetails")}>View details</MenuItem>
                <MenuItem key={"dates"} onClick={handleMenuItemClick("ChangeCampaignDates")}>Change dates</MenuItem>
                <MenuItem key={"unblock_seeding"} disabled={disableUnlocks} onClick={handleMenuItemClick("RemoveProductSeedingBlock")}>Unblock seeding</MenuItem>
                <MenuItem key={"unblock_credit"} disabled={disableUnlocks} onClick={handleMenuItemClick("RemoveCreditBlock")}>Unblock credit</MenuItem>
                <MenuItem disabled={enableManagement} key={"seeding"} onClick={handleMenuItemClick("CampaignAdminSeeding")}>Seeding</MenuItem>
                <MenuItem disabled={enableManagement} key={"shipments"} onClick={handleMenuItemClick("CampaignAdminShipments")}>Creator shipments</MenuItem>
                <Divider />
                <MenuItem key={"delete"} disabled={disableDelete} onClick={handleMenuItemClick("CampaignAdminDelete")}>Delete</MenuItem>
            </Menu>
        </Fragment>
    )
}

const CampaignAdminTableRow = ({campaign, disableMenu}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [productsReceived, setProductsReceived] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const history = useHistory();
    const {tab} = useParams();
    useEffect(()=>{
        if(!loaded && campaign.productSeedingFlag){
            setLoaded(true);
            Client.CampaignApi.inventorySearch()(
                (response)=>{
                    const received =  response.data.inventories.filter(f=>f.seedingStatus !== CampaignInventorySeedingStatusEnum.RECEIVED)
                    setProductsReceived(received.length === 0)
                },
                error => {

                }
            )({campaignId: campaign.id});
        }
    },[loaded, campaign])
    const handleNameClick = () => {
        history.push(routes.getBrandCampaignAdmin(`/${tab}/campaign/${campaign.id}`));
        dispatch(updateOpenDialog({
            dialog: "CampaignAdminManager",
            value: true,
            row: campaign
        }))
    }
    const getSeedingAmountOptionText = () => {
        if(!campaign.productSeedingFlag){
            return "-";
        }else if(!campaign.productSeedingRate && !campaign.productSeedingPriceSheetFlag){
            return "Choose option";
        }else if(campaign.productSeedingRate){
            return formatCurrency(campaign.productSeedingRate);
        }else{
            return "PRICE SHEET"
        }
    }
    const onClickSeedingAmount = () =>{
        dispatch(updateOpenDialog({
            dialog: "ProductSeedingAmount",
            value: true,
            row: campaign
        }))
    }
    const getSeedingAmount = () => {
        if(campaign.status === "Draft"){
            return <Link onClick={onClickSeedingAmount} component={"button"}>
                {getSeedingAmountOptionText()}
            </Link>
        }else{
            return getSeedingAmountOptionText();
        }
    }

    const getBlockedValue = () =>{
        if(campaign.creditBlockFlag && !campaign.creditBlockOverrideFlag){
            return <Box color={'error.main'}>BLOCKED</Box>
        }else{
            return "-";
        }
    }
    const getSeedingBlockedValue = () =>{
        if(campaign.productSeedingFlag){
            if(campaign.productSeedingOverrideFlag){
                return "-"
            }
            if(productsReceived){
                return "-";
            }else
                return <Box color={'error.main'}>BLOCKED</Box>
        }else{
            return "-";
        }
    }

    return <TableRow key={campaign.id}>
        <TableCell className={classes.noWrapText}>
            {campaign.brand.name}
        </TableCell>
        <TableCell className={classes.noWrapText}>
            <Link component={"button"} color={"primary"}  onClick={()=>handleNameClick()}>{campaign.title}</Link>
        </TableCell>
        <TableCell>
            {campaign.referenceNumber}
        </TableCell>
        <TableCell>
            {campaign.visibility}
        </TableCell>
        <TableCell>
            {campaign.sampleType ? getSampleType(campaign.sampleType) : "-"}
        </TableCell>
        <TableCell>
            {formatCurrency(campaign.campaignTotalCost)}
        </TableCell>
        <TableCell>
            {campaign.productSeedingFlag ? "Yes" : "-" }
        </TableCell>
        <TableCell>
            {getSeedingAmount()}
        </TableCell>
        <TableCell>
            {getSeedingBlockedValue()}
        </TableCell>
        <TableCell>
            {getBlockedValue()}
        </TableCell>
        <TableCell>
            {dates.formatFromString(campaign.dateStart,"MM/DD/YYYY")}
        </TableCell>
        <TableCell>
            {dates.formatFromString(campaign.dateEnd,"MM/DD/YYYY")}
        </TableCell>
        <TableCell>
            {dates.formatFromString(campaign.dateCreated,"MM/DD/YYYY")}
        </TableCell>
        <TableCell>
            {campaign.status}
        </TableCell>
        { disableMenu === false && <TableCell>
            <TableRowMenu row={campaign} />
        </TableCell>}
    </TableRow>
}

CampaignAdminTableRow.defaultProps = {
    disableMenu: false
}

export default CampaignAdminTableRow;