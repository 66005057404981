import React, { Component } from "react";
import { isEmpty } from "../../helpers/utils";
import moment from "moment";
import {strings} from "../../helpers/utils";
import dates from "../../helpers/dates";
import Carousel from "../Carousel/Carousel";
import CampaignSampleTypeEnum from "../../helpers/CampaignSampleTypeEnum";
import CurrencyWithClientCurrencySymbol from "../CurrencyWIthClientCurrencySymbol";
import CampaignOptionsEnum from "../../helpers/CampaignOptionsEnum";
import {
    Avatar, Box,
    Card,
    CardContent, CardHeader,
    Table,
    TableBody,
    TableContainer,
    Typography
} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

const {insertSpacesToCamelCase, stringOrDash} = strings;

const Text = ({ animated, lines, text, width, maxWidth, component, linebreaks }) => {
    return (
        <Box mb={1}>
            <Typography variant="body2" component={component ?? "p"} style={linebreaks ? { whiteSpace: "pre-wrap" } : null}>
                {
                    (!isEmpty(text) || text?.toString().startsWith("0")) && <>{text}</>
                }
                {
                    !(!isEmpty(text) || text?.toString().startsWith("0")) &&
                    Array(lines ?? 1)
                        .fill(null)
                        .map((e, i) => (
                            <Skeleton key={i}
                                      width={i === lines - 1 ? width : maxWidth}
                                      animation={animated ? "wave" : null}/>
                        ))
                }
            </Typography>
        </Box>
    )
}

class CampaignDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isApplyButtonTooltipOpen: false
        };
    }


    renderStartEndDate() {
        let startDate = this.props.campaign?.dateStart;
        let endDate = this.props.campaign?.dateEnd;
        if (startDate && startDate.toString() === 'Invalid Date') {
            startDate = null;
        }
        if (endDate && endDate.toString() === 'Invalid Date') {
            endDate = null;
        }
        if (!startDate && !endDate) {
            return <Text animated={true} lines={1} width={"60%"}/>;
        }

        const startDateString = stringOrDash(dates.formatFromString(startDate, 'M/d/yyyy'));
        const endDateString = stringOrDash(dates.formatFromString(endDate, 'M/d/yyyy'));
        const startsOn = `Starts on ${startDateString}`;
        const endsOn = `Ends on ${endDateString}`;

        return (
            <Text
                text={
                    <>{startsOn}<br />{endsOn}</>
                }
                width={"60%"}/>
        );
    }

    renderContentStyle() {
        const contentStyles = this.props.campaign?.contentStyles;
        if (!contentStyles || isEmpty(contentStyles)) {
            return <Text animated={true} lines={3} width={"60%"}/>;
        }

        return (
            <Text
                component='span'
                text={
                    <ul style={{ marginTop: '0px' }}>
                        {contentStyles.map((contentStyle) => {
                            console.log(`***CONTENT STYLE: ${contentStyle}`);
                            console.log(this.props.contentStyles);
                            return <li key={contentStyle}>{this.props.contentStyles?.find(item => item.id === contentStyle)?.name}</li>;
                        })
                        }
                    </ul>
                }
                width={"60%"}/>
        );
    }

    renderMessageToCreator() {
        const message = this.props.campaign?.message;
        if (!message || message === '') {
            return <Text animated={true} lines={3} width={"60%"}/>;
        }

        return (
            <Text
                text={message}
                linebreaks={true}
                width={"60%"}/>
        );
    }

    renderContentRequirements() {
        const contentRequirements = this.props.campaign?.contentRequirements;
        if (!contentRequirements || contentRequirements === '') {
            return <Text animated={true} lines={3} width={"60%"}/>;
        }

        return (
            <Text
                text={contentRequirements}
                linebreaks={true}
                width={"60%"}/>
        );
    }

    renderSocialPostRequirements() {
        const socialPostRequirements = this.props.campaign?.socialPostRequirements;
        if (!socialPostRequirements || socialPostRequirements === '') {
            return <Text animated={true} lines={3} width={"60%"}/>;
        }

        return (
            <Text
                text={socialPostRequirements}
                linebreaks={true}
                width={"60%"}/>
        );
    }

    renderWhitelisting() {
        const whitelisting = this.props.campaign?.creatorWhitelistFlag;
        if (whitelisting === undefined) {
            return <Text animated={true} lines={1} width={"60%"}/>;
        }

        let whitelistingText = "No opportunities available";
        if (whitelisting) {
            whitelistingText = "Opportunities available";
        }

        return (
            <Text
                text={whitelistingText}
                width={"60%"}/>
        );
    }

    renderTrackingHashtag() {
        const trackingHashtag = this.props.campaign?.trackingHashtag;
        if (!trackingHashtag || trackingHashtag === '') {
            return <Text animated={true} lines={1} width={"60%"}/>;
        }

        return (
            <Text
                text={trackingHashtag}
                width={"60%"}/>
        );
    }



    renderCreators() {

        const numberOfGigs = this.props.campaign?.numberOfGigs;
        if (!numberOfGigs || numberOfGigs === 0) {
            return <Text animated={true} lines={1} width={"60%"}/>;
        }

        return (
            <Text
                text={`${this.props.campaign?.numberOfGigs} Total (${this.props.campaign?.numberOfGigs - this.props.campaign?.creatorCount} spots remaining)`}
                linebreaks={true}
                width={"60%"}/>
        );
    }

    getSocialPostsAccepted() {
        const campaign = this.props.campaign;
        if (campaign) {
            let returnString = "Accepted Types: ";
            for (let socialNetwork of campaign?.socialNetworks) {
                returnString += `${insertSpacesToCamelCase(socialNetwork)}, `;
            }

            return returnString;
        }
        return "unknown";
    }
    render() {

        let data = [];

        for (let product of this.props.products) {
            var currentProduct = { image: product.imageUrl ? product.imageUrl : "https://cdnjs.tvpage.com/assets/img/creatable_placeholder.png", caption: product.name};
            data = [...data, currentProduct];
        }

        const campaign = this.props.campaign;

        return (
            <Box style={{
                "& .MuiCardHeader-root": {
                    backgroundColor: 'grey[100]',
                    "& .MuiAvatar-root": { backgroundColor: 'white', }
                }
            }}>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar src={this.props.clientConfig?.client?.brand?.logo?.square}/>
                        }
                        title={this.props.brand?.name}
                        subheader={moment().format("MMMM D, YYYY")}
                        style={{ 'backgroundColor': '#f5f5f5' }}
                    />
                    <CardContent style={{ backgroundColor: '#f5f5f5', paddingTop: 0 }}>
                        <Typography variant="caption" style={{color:(theme)=>theme.palette.text.secondary}}>
                            <b>CAMPAIGN</b>
                        </Typography>
                        <Typography variant="body2">
                            {campaign.title}
                        </Typography>
                    </CardContent>

                    <TableContainer
                        style={{
                            overflow: 'auto'
                        }}
                    >
                        <Table size={'small'}>
                            <TableBody style={{ padding: "0 20px"}}>
                                <tr><td>
                                    {data && data.length > 0 &&
                                        <Carousel
                                            data={data}
                                            width="100%"
                                            imageHeight='300px'
                                            showNavBtn={false}
                                            showIndexNavigation={true}
                                            slideImageFit="contain"
                                            gigId={this.props.campaign?.id}
                                            onIndexChanged={() => {}}
                                        />
                                    }
                                </td></tr>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <CardContent style={{backgroundColor: '#f5f5f5'}}>

                        <Box mt={2}>

                            <Typography variant="caption">
                                <b>CREATORS</b>
                            </Typography>
                            {this.renderCreators()}

                            <Typography variant="caption"><b>CAMPAIGN PERIOD</b></Typography>
                            {this.renderStartEndDate()}

                            {!(campaign.gigRewardAmount === undefined || campaign.gigRewardAmount === null || campaign.gigRewardAmount === 0 || Number.isNaN(campaign.gigRewardAmount)) &&
                                <>
                                    <Typography variant="caption"><b>PAYMENT</b></Typography>
                                    {(campaign.gigRewardAmount === undefined || campaign.gigRewardAmount === null || campaign.gigRewardAmount === 0 || Number.isNaN(campaign.gigRewardAmount)) && <Text animated={true} lines={1} width={"60%"}/>}
                                    {!(campaign.gigRewardAmount === undefined || campaign.gigRewardAmount === null ||campaign.gigRewardAmount === 0 || Number.isNaN(campaign.gigRewardAmount)) && <Text width={"60%"} text={<CurrencyWithClientCurrencySymbol value={campaign.gigRewardAmount} />}/>}
                                </>
                            }

                            <Typography variant="caption"><b>MESSAGE TO CREATORS</b></Typography>
                            {this.renderMessageToCreator()}

                            {campaign.options && campaign.options.includes(CampaignOptionsEnum.PRODUCTS) &&
                                <>
                                    <Typography variant="caption">
                                        <b>PRODUCT DELIVERY</b>
                                    </Typography>
                                    {!campaign.sampleType && <Text animated={true} lines={1} width={"60%"}/>}
                                    {campaign.sampleType === CampaignSampleTypeEnum.DIRECT_SHIPMENT && <Text width={"60%"}
                                                                                                             text={"Direct shipment. Provide your shipping address to receive the product."}/>}
                                    {campaign.sampleType === CampaignSampleTypeEnum.IN_STORE_SAMPLE && !campaign.sampleInstructions &&
                                        <Text animated={false} lines={1} width={"60%"}/>}
                                    {campaign.sampleType === CampaignSampleTypeEnum.IN_STORE_SAMPLE && campaign.sampleInstructions &&
                                        <Text width={"60%"} text={`In store sample. ${campaign.sampleInstructions}`}/>}
                                    {campaign.sampleType === CampaignSampleTypeEnum.COUPON_CODE && !campaign.couponCode &&
                                        <Text animated={false} lines={1} width={"60%"}/>}
                                    {campaign.sampleType === CampaignSampleTypeEnum.COUPON_CODE && campaign.couponCode &&
                                        <Text width={"60%"}
                                              text={`Coupon code. Use code ${campaign.couponCode} to purchase the product`}/>}
                                    {campaign.sampleType === CampaignSampleTypeEnum.OTHER && !campaign.sampleInstructions &&
                                        <Text animated={false} lines={1} width={"60%"}/>}
                                    {campaign.sampleType === CampaignSampleTypeEnum.OTHER && campaign.sampleInstructions &&
                                        <Text width={"60%"} text={`${campaign.sampleInstructions}`}/>}
                                </>
                            }

                            {campaign.options && campaign.options.includes(CampaignOptionsEnum.CONTENT) &&
                                <>
                                    <Typography variant="caption"><b>CONTENT TYPE</b></Typography>
                                    {!campaign.postType && <Text animated={true} lines={1} width={"60%"}/>}
                                    {campaign.postType && <Text width={"60%"} text={`${campaign.postType}`}/>}

                                    <Typography variant="caption"><b>DAYS TO SUBMIT CONTENT</b></Typography>
                                    {(campaign.daysToComplete === undefined || campaign.daysToComplete === null || campaign.daysToComplete === 0) && <Text width={"60%"} text={`No time limit`} />}
                                    {!(campaign.daysToComplete === undefined || campaign.daysToComplete === null || campaign.daysToComplete === 0) && <Text width={"60%"} text={`${campaign.daysToComplete} days`}/>}

                                    <Typography variant="caption"><b>ACCEPTED CONTENT STYLES</b></Typography>
                                    {this.renderContentStyle()}

                                    <Typography variant="caption"><b>CONTENT REQUIREMENTS</b></Typography>
                                    {this.renderContentRequirements()}
                                </>
                            }

                            <Typography variant="caption"><b>ACCEPTED POST TYPES</b></Typography>
                            { campaign.socialPostAllFlag?
                                <Text width={"60%"} text={`All types accepted`}/> :
                                <Text width={"60%"} text={`${this.getSocialPostsAccepted()}`}/>
                            }

                            <Typography variant="caption"><b>WHITELISTING</b></Typography>
                            {this.renderWhitelisting()}

                            <Typography variant="caption"><b>REQUIRED TRACKING #HASHTAG</b></Typography>
                            {this.renderTrackingHashtag()}

                            <Typography variant="caption"><b>SOCIAL POST REQUIREMENTS</b></Typography>
                            {this.renderSocialPostRequirements()}

                        </Box>
                    </CardContent>
                </Card>
            </Box>
        );
    }
}

export default CampaignDetails;
