import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
    AppBar,
    Box,
    Container,
    Divider,
    IconButton,
    Paper,
    TablePagination,
    Toolbar,
    Typography
} from "@material-ui/core";
import {CloseOutlined} from "@material-ui/icons";
import {
    campaignAdminSelector,
    getCampaignAdminManagerList,
    updateOpenDialog,
    updateSection
} from "../../../slices/campaignAdmin";
import {useDispatch, useSelector} from "react-redux";
import CampaignAdminManagerTable from "../CampaignAdminManager/CampaignAdminManagerTable";
import SearchInput from "../../SearchInput";
import {useHistory, useParams} from "react-router-dom";
import routes from "../../../helpers/routes";


const CampaignAdminShipments = ({open}) => {

    const [initialized, setInitialized] = useState(false);
    const dispatch = useDispatch();
    const {row, campaignManager} = useSelector(campaignAdminSelector);
    const {total, items, smartFilter, page, per_page} = campaignManager;
    const history = useHistory();
    const {tab} = useParams();

    useEffect(()=>{
        if(!initialized){
            setInitialized(true);
            dispatch(updateSection("campaignManager", {campaignId: row.id}));
            dispatch(getCampaignAdminManagerList({...campaignManager, campaignId: row.id}));
        }
    },[initialized, dispatch, campaignManager, row])
    const handleClose = () => {
        history.push({
            pathname:routes.getBrandCampaignAdmin('/'+tab),
            search:  window.location.search
        });
        dispatch(updateOpenDialog({
            dialog: "CampaignAdminManager",
            value: false,
            row: null
        }));

    }

    const handleChangePage = (event, page) => {
        dispatch(updateSection("campaignManager", {page: page}));
        dispatch(getCampaignAdminManagerList({...campaignManager, page: page}));
    }

    const handleChangeRowsPerPage = event => {
        const {value} = event.target;
        dispatch(updateSection("campaignManager", {per_page: value, page:0}));
        dispatch(getCampaignAdminManagerList({...campaignManager, page: 0, per_page: value}));
    }

    const onChangeSearchTerm = value=>{
        dispatch(updateSection("campaignManager", {smartFilter: value, page:0}));
        dispatch(getCampaignAdminManagerList({...campaignManager, page: 0, smartFilter: value}));
    }
    return <div>
        <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar position={"relative"}>
                <Toolbar>
                    <IconButton size={'small'} onClick={handleClose}><CloseOutlined htmlColor={"#fff"} /></IconButton> <Typography>Campaign: {row.brand.name} - {row.title}</Typography>
                </Toolbar>
            </AppBar>
            <Box bgcolor={"#f5f5f5"} height={"100%"} p={2}>
            <Container maxWidth={'lg'}>
                <Paper>
                    <Box px={2}>
                        <Toolbar disableGutters>
                            <Box flexGrow={1} justifyContent={"space-between"} display={"flex"}>
                                <SearchInput value={smartFilter} onChange={(e) => onChangeSearchTerm(e.target.value)}/>
                            </Box>
                        </Toolbar>
                    </Box>
                    <Divider/>
                    <CampaignAdminManagerTable items={items} onRefresh={() => setInitialized(false)}/>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={per_page}
                        page={page}
                        onChangePage={handleChangePage}
                        // onPageChange={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </Container>
            </Box>
        </Dialog>
    </div>;
}

export default CampaignAdminShipments;
