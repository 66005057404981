import {createSlice} from '@reduxjs/toolkit';
import RequestError from '../helpers/RequestError';
import Api from "../helpers/api";

export const initialState = {
    plans: [],
    totalPlans: 0,
    plansError: null,
    isPlansInProgress: null
};

const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        getPlansStart: (state) => {
            state.isPlansInProgress = true;
            state.plansError = null;
        },
        getPlansDone: (state, {payload}) => {
            if (payload.status === 200) {
                state.plans = payload.items.length > 0 ? payload.items.sort((a, b)=>a.base_price_pennies - b.base_price_pennies) : [];
                state.totalPlans = payload.total;
            } else {
                state.plansError = payload.message;
            }
            state.isPlansInProgress = false;
        }
    },
});

export const plansSelector = state => state.plans;
export default plansSlice.reducer;

const {getPlansStart, getPlansDone} = plansSlice.actions;
export const getPlans = (page, itemsPerPage, searchTerm = null) => dispatch  => {
    dispatch(getPlansStart());
    return Api.backoffice.plans.getPlans(page, itemsPerPage, searchTerm)(
        ({data}) => dispatch(getPlansDone({...data, status: 200})),
        error => {
            const respError = new RequestError(error)
            dispatch(getPlansDone({
                status: respError.getHttpStatusCode(),
                message: respError.getMessage()
            }));
        }
    );
}