import React from "react";
import { useHistory } from "react-router-dom";
import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import "./MetricsPanel.css";

const getDynamicDates = () => {
    const currentDate = moment();
    const last90Start = moment(currentDate).subtract(90, "days");
    const prior90Start = moment(last90Start).subtract(90, "days");

    return {
        last90Start: last90Start.format("M/D"),
        last90End: currentDate.format("M/D"),
        prior90Start: prior90Start.format("M/D"),
        prior90End: last90Start.format("M/D"),
    };
};

const LobbyMetricsComponent = () => {
    const { last90Start, last90End, prior90Start, prior90End } =
        getDynamicDates();

    const history = useHistory();

    const handleClose = () => {
        history.push("/"); // Navigate back to the home page or any other route
    };

    return (
        <Dialog
            open={true}
            fullScreen
            PaperProps={{
                style: {
                    backgroundColor: "#000",
                    color: "#fff",
                },
            }}>
            <DialogContent
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    padding: "0",
                }}>
                {/* Close Button */}
                <IconButton
                    onClick={handleClose}
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        color: "#fff",
                    }}>
                    <CloseIcon />
                </IconButton>
                <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center">
                    {/* Influence Bookings */}
                    <Box className="full-width-box" mb="24px">
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "left",
                                paddinfLeft: "20px",
                                fontSize: "96px",
                            }}>
                            Influence bookings
                        </Typography>
                        <Box
                            display="flex"
                            justifyContent="start"
                            padding="10px 20px 10px 0"
                            textAlign="left">
                            <Box mr={5}>
                                <Typography
                                    variant="h1"
                                    style={{
                                        fontSize: "192px",
                                    }}>
                                    {"--"}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontSize: "48px",
                                    }}>
                                    Last 90 days ({last90End} - {last90Start})
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h1"
                                    style={{
                                        fontSize: "192px",
                                    }}>
                                    {"--"}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontSize: "48px",
                                    }}>
                                    90 days prior ({prior90End} - {prior90Start}
                                    )
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {/* Team Bookings */}
                    <Box className="full-width-box">
                        <Typography
                            variant="h3"
                            style={{
                                textAlign: "left",
                                paddinfLeft: "20px",
                                fontSize: "96px",
                            }}>
                            Team bookings
                        </Typography>
                        <Box
                            display="flex"
                            justifyContent="start"
                            padding="10px 20px 10px 0"
                            textAlign="left">
                            <Box mr={5}>
                                <Typography
                                    variant="h1"
                                    style={{
                                        fontSize: "192px",
                                    }}>
                                    {"--"}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontSize: "48px",
                                    }}>
                                    Last 90 days ({last90End} - {last90Start})
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h1"
                                    style={{
                                        fontSize: "192px",
                                    }}>
                                    {"--"}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontSize: "48px",
                                    }}>
                                    90 days prior ({prior90End} - {prior90Start}
                                    )
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default LobbyMetricsComponent;
