import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import WidgetTemplatesTableHead from "./WidgetTemplatesTableHead";
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import {useIntl} from "react-intl";
import moment from 'moment';
import DeleteWidgetTemplatePrompt from "./DeleteWidgetTemplatePrompt";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    root: {
        width: '100%',
    },
    healthy: {
        fill: theme.palette.success.main,
    },
    failed: {
        fill: theme.palette.error.main,
    },
    default: {
        fill: "#ccc",
    },
    actions: {
        '& .MuiTypography-root': {
            marginRight: theme.spacing(2),
        },
        '& .MuiTypography-root:first-child': {
            marginRight: theme.spacing(1),
        }
    }
}));

const formatDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY HH:mm:ss");
}

const WidgetTemplatesTable = (props) => {
    const {
        loading,
        widgetTemplates,
        totalWidgetTemplates,
        rowsPerPage,
        currentPage,
        onChangeCurrentPage,
        onChangeRowsPerPage,
        rowsPerPageOptions = [5, 10, 20],
        onClickEdit,
        onClickDelete,
    } = props;
    const classes = useStyles();
    const intl = useIntl();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState();
    const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);
    const [widgetTemplateToDelete, setWidgetTemplateToDelete] = useState();
    const emptyRows = rowsPerPage - widgetTemplates.length;
    const emptyMessage = loading ? "" : "There are no widget templates.";

    const textEdit = intl.formatMessage({
        id: 'Common.Edit',
        defaultMessage: 'EDIT'
    });

    const textDelete = intl.formatMessage({
        id: 'Common.Delete',
        defaultMessage: 'DELETE'
    });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClickEdit = (widget) => (e) => {
        e.preventDefault();
        onClickEdit(widget);
    }

    const handleClickDelete = (widget) => (e) => {
        e.preventDefault();
        setWidgetTemplateToDelete(widget);
        setIsDeletePromptOpen(true);
    }

    const handleConfirmDelete = () => {
        const widget = {...widgetTemplateToDelete};
        setWidgetTemplateToDelete(null);
        onClickDelete(widget);
        setIsDeletePromptOpen(false);
    }

    const handleCancelDelete = () => {
        setWidgetTemplateToDelete(null);
        setIsDeletePromptOpen(false);
    }

    const handleChangePage = (event, newPage) => {
        onChangeCurrentPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        onChangeRowsPerPage(parseInt(event.target.value));
        onChangeCurrentPage(0);
    }

    return (
        <Box>
            <TableContainer>
                <Table size={'medium'} className={classes.table}>
                    <WidgetTemplatesTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={widgetTemplates.length}
                    />
                    <TableBody>
                        {widgetTemplates.map((widgetTemplate) => {
                            return (
                                <TableRow hover key={widgetTemplate.experience_widget_template_id}>
                                    <TableCell align="left">
                                        {widgetTemplate.name}
                                    </TableCell>
                                    <TableCell align="left">
                                        {formatDate(widgetTemplate.created_timestamp)}
                                    </TableCell>
                                    <TableCell align="left">
                                        {formatDate(widgetTemplate.modified_timestamp)}
                                    </TableCell>
                                    <TableCell padding={"none"} className={classes.actions}>
                                        <Link href={"#"} onClick={handleClickEdit(widgetTemplate)}>{textEdit}</Link>
                                        <Link href={"#"} onClick={handleClickDelete(widgetTemplate)}>{textDelete}</Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {widgetTemplates.length > 0 && emptyRows > 0 && (
                            <TableRow style={{height: (53) * emptyRows}}>
                                <TableCell colSpan={5}/>
                            </TableRow>
                        )}
                        {widgetTemplates.length === 0 && (
                            <TableRow style={{height: (53) * emptyRows}}>
                                <TableCell colSpan={5}>
                                    <Typography align={"center"} variant={"inherit"} component={"div"}>
                                        {emptyMessage}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                widgetTemplates.length > 0 &&
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={totalWidgetTemplates}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            }
            <DeleteWidgetTemplatePrompt open={isDeletePromptOpen} onCancel={handleCancelDelete}
                                        onDelete={handleConfirmDelete} template={widgetTemplateToDelete}/>
        </Box>
    );
}

WidgetTemplatesTable.defaultProps = {
    onClickAdd: () => {
    }
}

export default WidgetTemplatesTable;