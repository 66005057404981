import {
    FormControl,
    FormControlLabel
} from "@material-ui/core"
import React, {useEffect, useState} from "react";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {
    clientConfigSelector,
    getClientConfig,
    putExperiencesSection
} from "../../../slices/clientConfig";
import {setNotification} from "../../../slices/notification";
import CardWithTitleAndAction from "../CardWithTitleAndAction";
import Switch from "@material-ui/core/Switch";

const ExperiencesSettings = () => {

    const dispatch = useDispatch();
    const {
        clientConfig,
        isClientConfigLoading,
        clientConfigErrorMessage,
        isClientConfigError,
        isClientConfigInitialized
    } = useSelector(clientConfigSelector);
    const [isExperiencesEnabled, setIsExperiencesEnabled] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const reloadData = useCallback(() => {
        setIsExperiencesEnabled(clientConfig.experiences?.enable_experiences ?? false);
    }, [clientConfig]);

    useEffect(() => {
        setInProgress(isClientConfigLoading);
    }, [isClientConfigLoading]);

    useEffect(() => {
        if (isClientConfigInitialized) {
            reloadData();
        }
    }, [isClientConfigInitialized, reloadData])

    const Save = () => {
        setInProgress(true);
        const newExperiencesSectionData = {
            ...clientConfig.experiences,
            enable_experiences: isExperiencesEnabled
        };
        dispatch(putExperiencesSection(clientConfig.client.hash, newExperiencesSectionData)).then(() => {
            dispatch(getClientConfig(clientConfig.client.hash)).then(()=>{
                setHasChanges(false);
            });
        });
    }

    useEffect(() => {
        if (isClientConfigError) {
            dispatch(setNotification(clientConfigErrorMessage, 'error'));
        }
    }, [clientConfig, isClientConfigError, clientConfigErrorMessage, dispatch])

    const onChangeSwitch = e =>{
        setIsExperiencesEnabled(e.target.checked);
        setHasChanges(!(e.target.checked === clientConfig.experiences?.enable_experiences));
    }

    return <CardWithTitleAndAction title={'Experiences'} inProgress={inProgress} onClickAction={Save} testId={'experiences-settings'} disableAction={!hasChanges}>
            <FormControl fullWidth>
                <FormControlLabel margin="normal"
                                  onChange={onChangeSwitch}
                                  control={<Switch checked={isExperiencesEnabled}/>}
                                  label="Enable analytics overview on-site data display"/>
            </FormControl>
    </CardWithTitleAndAction>
}

export default ExperiencesSettings;
