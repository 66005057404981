import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import {Box, Fab, LinearProgress, Paper} from "@material-ui/core";
import BrandsTableHead from "./BrandsTableHead";
import BrandsTableToolbar from "./BrandsTableToolbar";
import {setNotification} from "../../../slices/notification";
import {useDispatch} from "react-redux";
import BrandsTableRowMenu from "./BrandsTableRowMenu";
import BrandDetailsModal from "./BrandDetailsModal";
import Api from "../../../helpers/api";
import RequestError from "../../../helpers/RequestError";
import {SpeedDialIcon} from "@material-ui/lab";
import DeleteBrandModal from "./DeleteBrandModal";
import DroppableTableBody from "../../DroppableTable/DroppableTableBody";
import DraggableTableRow from "../../DroppableTable/DraggableTableRow";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {useDebounce} from "use-debounce";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    emptyTable: {
        height: '424px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
        '& td': {
            borderBottom: 0,
        }
    },
    moveButton: {
        paddingTop: theme.spacing(0.5),
        "& svg": {
            color: theme.palette.grey[700],
            cursor: "move",
        }
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
}));

const INITIAL_ITEMS_PER_PAGE = 10;

const AddBrandButton = ({onClick}) => {
    const classes = useStyles();
    return (
        <Fab className={classes.fab} color={'primary'} onClick={onClick}>
            <SpeedDialIcon/>
        </Fab>
    )
}

const BrandsTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [inProgress, setInProgress] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(INITIAL_ITEMS_PER_PAGE);
    const [page, setPage] = useState(1);

    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

    const [brands, setBrands] = useState([]);
    const [totalBrands, setTotalBrands] = useState(0);

    const [currentBrand, setCurrentBrand] = useState(null);
    const [isBrandDetailsModalOpen, setIsBrandDetailsModalOpen] = useState(false);
    const [isDeleteBrandModalOpen, setIsDeleteBrandModalOpen] = useState(false);

    const [isBrandListInitialized, setIsBrandListInitialized] = useState(null);

    const showNotificationFromErrorResponse = useCallback((errorResponse) => {
        dispatch(setNotification((new RequestError(errorResponse)).getMessage(), "error"));
    }, [dispatch]);

    const refreshBrands = useCallback((page, itemsPerPage, search) => {
        setInProgress(true);
        return Api.getMarketplaceBrands(page, itemsPerPage, search)(
            ({data}) => {
                setTotalBrands(data["hydra:totalItems"]);
                setBrands(data["hydra:member"]);
            },
            showNotificationFromErrorResponse
        ).then(() => {
            setInProgress(false);
        });
    }, [showNotificationFromErrorResponse]);

    useEffect(() => {
        if (isBrandListInitialized === null) {
            setIsBrandListInitialized(false);
            refreshBrands(page, itemsPerPage).then( () => {
                setIsBrandListInitialized(true);
            });
        }
    }, [isBrandListInitialized, refreshBrands, page, itemsPerPage]);

    useEffect(() => {
        setPage(1);
        refreshBrands(1, itemsPerPage, debouncedSearchTerm);
    }, [itemsPerPage, debouncedSearchTerm, refreshBrands]);

    const handleChangePage = (event, newPage) => {
        if (newPage >= 0) {
            setPage(newPage + 1);
            refreshBrands(newPage + 1, itemsPerPage);
        }
    }

    const handleChangeRowsPerPage = (event) => {
        setItemsPerPage(event.target.value);
    }

    const onClickEditBrand = (brand) => {
        setCurrentBrand(brand);
        setIsBrandDetailsModalOpen(true);
    }

    const onSaveBrandSuccess = () => {
        setIsBrandDetailsModalOpen(false);
        setCurrentBrand(null);
        setPage(1);
        setIsBrandListInitialized(null);
    }

    const onShowDeleteBrandModal = (brand) => {
        setCurrentBrand(brand);
        setIsDeleteBrandModalOpen(true);
    }

    const onDeleteBrandSuccess = () => {
        setIsDeleteBrandModalOpen(false);
        setCurrentBrand(null);
        setIsBrandListInitialized(null);
    }

    const onClickNewBrand = () => {
        setCurrentBrand(null);
        setIsBrandDetailsModalOpen(true);
    }

    const onCloseBrandDetailsModal = () => {
        setCurrentBrand(null);
        setIsBrandDetailsModalOpen(false);
    }

    const onCloseDeleteBrandModal = () => {
        setCurrentBrand(null);
        setIsDeleteBrandModalOpen(false);
    }

    const onDragEnd = ({source, destination}) => {
        if (source.index !== destination.index) {
            const newBrandsOrder = moveIndex(brands, source.index, destination.index);
            setBrands(newBrandsOrder);
            setInProgress(true);
            Api.setMarketplaceBrandsOrder()(
                () => setIsBrandListInitialized(null),
                showNotificationFromErrorResponse
            )({marketplace_brands_order: newBrandsOrder.map(b => b.marketplace_brand_id)}).then(() => setInProgress(false));
        }
    }

    return (
        <Box>
            <Paper>
                <BrandsTableToolbar onChangeSearchTerm={setSearchTerm}/>
                <TableContainer>
                    <Table size={'medium'} className={classes.table}>
                        <BrandsTableHead/>
                        <TableBody component={DroppableTableBody(onDragEnd)}>
                            {
                                brands.map((brand, currentPosition) => {
                                    return (
                                        <TableRow key={brand.marketplace_brand_id}
                                                  component={DraggableTableRow(brand.marketplace_brand_id.toString(), currentPosition)}>
                                            <TableCell align="left" width={"100%"}>
                                                {brand.name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {brand.marketplace_categories[0]?.name ?? "-"}
                                            </TableCell>
                                            <TableCell align="center" padding={"checkbox"}>
                                                <Box className={classes.moveButton}>
                                                    <DragIndicatorIcon/>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="center" padding={"checkbox"}>
                                                <BrandsTableRowMenu
                                                    onClickEdit={() => onClickEditBrand(brand)}
                                                    onClickDelete={() => onShowDeleteBrandModal(brand)}/>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            {
                                totalBrands >= 1 && (8 - totalBrands) > 0 && (
                                    <TableRow style={{height: (53) * (8 - totalBrands)}}>
                                        <TableCell colSpan={5} style={{border: "none"}}/>
                                    </TableRow>
                                )
                            }
                            {
                                totalBrands === 0 && (
                                    <TableRow className={classes.emptyTable}>
                                        <TableCell colSpan={5}>{inProgress ? "" : "No brands found."}</TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={totalBrands}
                    rowsPerPage={itemsPerPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    backIconButtonProps={{disabled: inProgress || totalBrands === 0 || page === 1}}
                    nextIconButtonProps={{disabled: inProgress || totalBrands === 0 || page >= Math.ceil(totalBrands / itemsPerPage)}}
                />
                {inProgress && <LinearProgress/>}
                <BrandDetailsModal onSaveSuccess={onSaveBrandSuccess} isOpen={isBrandDetailsModalOpen}
                                   brand={currentBrand} onClose={onCloseBrandDetailsModal}/>
                <DeleteBrandModal onDeleteSuccess={onDeleteBrandSuccess} isOpen={isDeleteBrandModalOpen}
                                  onClose={onCloseDeleteBrandModal} brand={currentBrand}/>
            </Paper>
            <AddBrandButton className={classes.fab} onClick={onClickNewBrand}/>
        </Box>
    );
}

const moveIndex = (list, sourceIndex, destinationIndex) => {
    const orderedList = [...list];
    const [removed] = orderedList.splice(sourceIndex, 1);
    orderedList.splice(destinationIndex, 0, removed)
    return orderedList;
}

export default BrandsTable;