import {Divider, IconButton, Link, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import React, {useState} from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from '@material-ui/core/colors';
import CurrencyFormat from "react-currency-format";
import Api from "../../../helpers/api";

const platformCodeToName = code =>{
    switch (code){
        case 'tiktok':
            return 'TikTok'
        case 'facebook':
            return 'Facebook'
        case 'instagram':
            return 'Instagram'
        case 'twitter':
            return 'Twitter'
        case 'google':
            return 'YouTube'
        case 'youtube':
            return 'YouTube'
        default:
            return code
    }
}

const CampaignCreatorManualSocialDataTableRow = ({item, gig, onOpenDelete, onClickSocialDataLog, onClickEditSocialData, onClickEditPost, onBoostStatusChanged}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [boosted, setBoosted] = useState(item?.boosted ?? false); 
    const [isUpdatingBoosted, setIsUpdatingBoosted] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleBoostToggle = async () => {
        const updatedBoosted = !boosted;
        setIsUpdatingBoosted(true);
        handleMenuClose();
        const payload = {
            ...item,
            "post_type": item.post_type.code,
            boosted: updatedBoosted,
        };
        try {
            const response = await Api.social.updateManualPost(item.post_id, gig.campaign.brand.id)(
                (resp) => {
                    if (resp.error) {
                        setIsUpdatingBoosted(false);
                    } else {
                        setBoosted(updatedBoosted);
                        setIsUpdatingBoosted(false);
                        onBoostStatusChanged();
                    }
                },
                (error) => {
                    setIsUpdatingBoosted(false);
                }
            )(payload);
            if (response && response.error) {
                console.error("Server-side error on update:", response.error);
                setIsUpdatingBoosted(false);
            } else {
                console.log("Boost status updated successfully.");
                setBoosted(updatedBoosted);
                setIsUpdatingBoosted(false);
                onBoostStatusChanged();
            }
        } catch (error) {
            console.error("Unexpected error while updating boost status:", error);
            setIsUpdatingBoosted(false);
        }
    };

    const {metrics} = item;

    return (
        <TableRow>
            <TableCell>{platformCodeToName(item.platform)}</TableCell>
            <TableCell>
                <Link target={"_blank"} href={item.url}>
                    VIEW POST
                </Link>
            </TableCell>
            <TableCell>
                { (boosted && !isUpdatingBoosted) ? (
                    <CheckCircleIcon style={{ color: green[500] }} />
                ) : null}
            </TableCell>
            <TableCell>
                {metrics.impressions ? (
                    <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator
                        value={metrics.impressions || 0}
                    />
                ) : (
                    "--"
                )}
            </TableCell>
            <TableCell>
                {metrics.video_views ? (
                    <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator
                        value={metrics.video_views || 0}
                    />
                ) : (
                    "--"
                )}
            </TableCell>
            <TableCell>
                {metrics.likes ? (
                    <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator
                        value={metrics.likes || 0}
                    />
                ) : (
                    "--"
                )}
            </TableCell>
            <TableCell>
                {metrics.shares ? (
                    <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator
                        value={metrics.shares || 0}
                    />
                ) : (
                    "--"
                )}
            </TableCell>
            <TableCell>
                {metrics.comments ? (
                    <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator
                        value={metrics.comments || 0}
                    />
                ) : (
                    "--"
                )}
            </TableCell>
            <TableCell>
                {metrics.saves ? (
                    <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator
                        value={metrics.saves || 0}
                    />
                ) : (
                    "--"
                )}
            </TableCell>
            <TableCell padding={"checkbox"}>
                <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}>
                    <MenuItem
                        onClick={() => {
                            handleMenuClose();
                            onClickEditSocialData(item);
                        }}>
                        Enter social data
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleMenuClose();
                            onClickSocialDataLog(item);
                        }}>
                        View social data log
                    </MenuItem>
                    <MenuItem onClick={handleBoostToggle}>
                        {boosted ? "Mark as not boosted" : "Mark as boosted"}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        onClick={() => {
                            handleMenuClose();
                            onClickEditPost(item);
                        }}>
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleMenuClose();
                            onOpenDelete(item);
                        }}>
                        Delete
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
}

export default CampaignCreatorManualSocialDataTableRow;
