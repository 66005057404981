const communicationsHelper = {
    getGroupedEventTypes: () => {
        return [
            {
                group: "Campaigns",
                options: [
                    {
                        value: "BrandReservationRequested",
                        label: "Brand Reservation Requested",
                    },
                    {
                        value: "BrandShippingInfoProvided",
                        label: "Brand Shipping Info Provided",
                    },
                    {
                        value: "BrandProductConfirmed",
                        label: "Brand Product Confirmed",
                    },
                    {
                        value: "BrandContentSubmitted",
                        label: "Brand Content Submitted",
                    },
                    {
                        value: "BrandSocialPostSubmitted",
                        label: "Brand Social Post Submitted",
                    },
                    {
                        value: "BrandCreatorCommentSubmitted",
                        label: "Brand Creator Comment Submitted",
                    },
                    // {
                    //     value: "BrandCampaignRequested",
                    //     label: "Brand Campaign Requested",
                    // },
                    {
                        value: "CreatorInvite",
                        label: "Creator Invite",
                    },
                    {
                        value: "CreatorReservationAcceptedSelectProduct",
                        label: "Creator Reservation Accepted Select Product",
                    },
                    {
                        value: "CreatorReservationAcceptedGetProduct",
                        label: "Creator Reservation Accepted Get Product",
                    },
                    {
                        value: "CreatorReservationAcceptedSubmitContent",
                        label: "Creator Reservation Accepted Submit Content",
                    },
                    {
                        value: "CreatorReservationAcceptedSubmitSocialPost",
                        label: "Creator Reservation Accepted Submit Social Post",
                    },
                    {
                        value: "CreatorReservationWaitlisted",
                        label: "Creator Reservation Waitlisted",
                    },
                    {
                        value: "CreatorReservationRejected",
                        label: "Creator Reservation Rejected",
                    },
                    {
                        value: "CreatorProductReceived",
                        label: "Creator Product Received",
                    },
                    {
                        value: "CreatorContentAccepted",
                        label: "Creator Content Accepted",
                    },
                    {
                        value: "CreatorContentRejected",
                        label: "Creator Content Rejected",
                    },
                    {
                        value: "CreatorComplete",
                        label: "Creator Complete",
                    },
                    {
                        value: "CreatorSocialPostRejected",
                        label: "Creator Social Post Rejected",
                    },
                    {
                        value: "CreatorBrandCommentSubmitted",
                        label: "Creator Brand Comment Submitted",
                    },
                    {
                        value: "CreatorCancelled",
                        label: "Creator Cancelled",
                    },
                    {
                        value: "InfluencerBookingInvite",
                        label: "Influencer Booking Invite",
                    },
                ]
            },
        ]
    },
};

const EMPTY_CONFIGURATION = Object.freeze({
    "name": "",
    "is_root": true,
    "override_required": false,
    "client_id": null,
    "parent_config_id": null,
    "template": {},
    "template_type": "email",
    "transport": "email",
    "transport_configuration": {},
    "event_type_id": "",
    "client_context_required": false,
    "user_context_required": false,
    "entity_context_required": false,
    "client_context_filter": false,
    "user_context_filter": false,
    "is_enabled": true,
    "access": "private",
    "schedule_rules": null
});

export {EMPTY_CONFIGURATION};

export default communicationsHelper;