import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Box, Paper} from "@material-ui/core";
import {setPageTitle} from "../slices/page";
import {useDispatch, useSelector} from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import PaymentApproveTable from "../components/Payment/PaymentApprove/PaymentApproveTable";
import {paymentApproveSelector, getPaymentApproveList} from "../slices/paymentApprove";
import {updateUrlParameters} from "../helpers/pageParameters";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    }
}));

const PaymentApproval = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {paymentApproveFilters} = useSelector(paymentApproveSelector);

    useEffect(() => {
        dispatch(setPageTitle("Payment approval"));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);

    const onClickRefresh = () =>{
        dispatch(getPaymentApproveList(updateUrlParameters(paymentApproveFilters)));
    }

    return <>
        <Toolbar style={{justifyContent: "center"}}>
            <Button onClick={onClickRefresh} variant={"contained"} color={"default"}>REFRESH</Button>
        </Toolbar>
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <PaymentApproveTable />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    </>
}

export default PaymentApproval;