import Container from "@material-ui/core/Container";
import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {Paper, Tab} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import routes from "../helpers/routes";
import BrandsTable from "../components/Marketplace/Brands/BrandsTable";
import CategoriesTable from "../components/Marketplace/Categories/CategoriesTable";
import {setPageTitle} from "../slices/page";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    content: {
        height: "100%",
        backgroundColor: theme.palette.grey[100],
    },
}));

const TAB_BRANDS = "brands";
const TAB_CATEGORIES = "categories";

const Marketplace = ({location, history}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(TAB_BRANDS);

    useEffect(() => {
        dispatch(setPageTitle("Marketplace"));
        return () => {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);

    useEffect(() => {
        if (location.pathname === routes.getAdminMarketplaceBrandsUrl()) {
            setActiveTab(TAB_BRANDS);
        } else if (location.pathname === routes.getAdminMarketplaceCategoriesUrl()) {
            setActiveTab(TAB_CATEGORIES);
        }
    }, [location]);

    const onChangeTab = (e, tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            if (tab === TAB_BRANDS) {
                history.push(routes.getAdminMarketplaceBrandsUrl());
            } else if (tab === TAB_CATEGORIES) {
                history.push(routes.getAdminMarketplaceCategoriesUrl());
            }
        }
    }

    return (
        <Box height={"100%"}>
            <TabContext value={activeTab}>
                <Paper variant={"outlined"} square>
                    <TabList value={activeTab} onChange={onChangeTab} centered>
                        <Tab label="Brands" value={TAB_BRANDS}/>
                        <Tab label="Categories" value={TAB_CATEGORIES}/>
                    </TabList>
                </Paper>
                <Box className={classes.content}>
                    <Container maxWidth="lg">
                        <TabPanel value={TAB_BRANDS}>
                            <BrandsTable/>
                        </TabPanel>
                        <TabPanel value={TAB_CATEGORIES}>
                            <CategoriesTable/>
                        </TabPanel>
                    </Container>
                </Box>
            </TabContext>
        </Box>
    )
}

export default withRouter(Marketplace);