import React, {useEffect, useState, Fragment} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import {isEmpty} from "../../helpers/utils";
import {setNotification} from "../../slices/notification";
import Api from "../../helpers/api";
import RequestError from "../../helpers/RequestError";
import RoleStatusSelect from "./RoleStatusSelect";

const ChangeUserRoleStatusModal = ({isOpen, userId, userRole, onClose, onSaveSuccess}) => {
    const [status, setStatus] = useState(null);

    const [showErrors, setShowErrors] = useState(false);
    const [inProgress, setInProgress] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpen === false) {
            setStatus(null);
            setShowErrors(false);
            setInProgress(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (userRole) {
            setStatus(userRole.status);
        }
    }, [userRole]);

    const onSave = () => {
        if (isEmpty(status)) {
            setShowErrors(true);
        } else {
            setShowErrors(false);
            setInProgress(true);
            Api.updateUserRole(userId, userRole.client.hash, userRole.role.role_id)(
                () => onSaveSuccess(),
                (error) => dispatch(setNotification((new RequestError(error).getMessage())))
            )({role: {status}}).then(() => setInProgress(false));
        }
    }

    const onChangeStatus = (status) => {
        setStatus(status ? status.value : null)
    }

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Change status</DialogTitle>
                <DialogContent>
                    <RoleStatusSelect onChange={onChangeStatus}
                                      value={status}
                                      disabled={inProgress}
                                      error={showErrors && isEmpty(status)}
                                      helperText={showErrors && isEmpty(status) ? "This field is required." : ""}/>
                </DialogContent>
                <Box p={3}>
                    <Grid container justify="flex-end" spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button variant="outlined" onClick={onClose} fullWidth disabled={inProgress}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button type="submit" color="primary" variant="contained" onClick={onSave} fullWidth
                                    disabled={inProgress}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {inProgress && <LinearProgress/>}
            </Dialog>
        </Fragment>
    )
}

export default ChangeUserRoleStatusModal;