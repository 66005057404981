import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import {IntlProvider} from 'react-intl';
import detectBrowserLanguage from 'detect-browser-language';
import messages from './translations';

import App from './App';
import theme from './helpers/theme';
import sentry from './helpers/sentry';
import {Provider as ReduxProvider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {configureStore} from "@reduxjs/toolkit";
import rootReducer from './slices';

sentry.init();
const store = configureStore({
    reducer: rootReducer
});
const locale = detectBrowserLanguage().split('-')[0];

ReactDOM.render(
    <ReduxProvider store={store}>
        <IntlProvider locale={locale} defaultLocale="en" messages={messages[locale]}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </IntlProvider>
    </ReduxProvider>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
