import {createSlice} from '@reduxjs/toolkit';
import api from '../helpers/api';

export const initialState = {
    subscriptionsList: {
        total: 0,
        items: [],
        page: 1
    },
    subscription: null,
    isEmpty: false,
    deleted: false,
    failed: false,
    saved: false,
    updated: false,
    firstLoadList: true,
    fetching: false,
    sort: '',
    client_id: 1758930
};

const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        getSubscriptionsListSuccess: (state, {payload}) => {
            const items = payload[0];
            state.subscriptionsList = {
                items,
                total : items.length,
                page: 1
            };
            state.firstLoadList = false;
        },
        getSubscriptionsListFailure: (state, {payload}) => {
            state.isEmpty = true;
            state.firstLoadList = false;
        },
        saveSubscriptionSuccess: (state, {payload}) => {
            state.saved = true;
            state.firstLoadList = true;
        },
        saveSubscriptionFailure: (state, {payload}) => {
            state.failed = true;
        },
        resetSubscription: (state)=>{
            state.saved = false;
            state.failed = false;
            state.deleted = false;
            state.firstLoadList = true;
            state.firstLoadConfiguration = true;
            state.compiledData = null;
            state.updated = false;
        },
        updateSubscriptionSuccess: (state, {payload}) =>{
            state.updated = true;
        },
        updateSubscriptionFailure: (state, {payload}) =>{
            state.updated = false;
        },
        deleteSubscriptionSuccess: (state, {payload}) =>{
            state.deleted = true;
        },
        deleteSubscriptionFailure: (state, {payload}) =>{
            state.delete = false;
        }
    }
});

export default subscriptionsSlice.reducer;
export const subscriptionsSelector = state => state.subscriptions;
const {getSubscriptionsListSuccess, getSubscriptionsListFailure,
    resetSubscription, deleteSubscriptionFailure, deleteSubscriptionSuccess}
    = subscriptionsSlice.actions;

export const getSubscriptions = (parameters) => dispatch  => api.getSubscriptionsList(parameters)(
    resp => dispatch(getSubscriptionsListSuccess(resp.data)),
    err => dispatch(getSubscriptionsListFailure(err))
)

export const deleteSubscription = (resource_id, params) => dispatch => api.deleteResource(resource_id, params)(
    resp => dispatch(deleteSubscriptionSuccess(resp.data)),
    err=>dispatch(deleteSubscriptionFailure(err))
)
export const resetSave = () => dispatch =>{
    dispatch(resetSubscription());
}