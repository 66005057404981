import {Divider} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import dates from "../../../helpers/dates";
import InvoiceLineItemsTable from "./InvoiceLineItemsTable/InvoiceLineItemsTable";
import InvoicePaymentsTable from "./InvoicePaymentsTable/InvoicePaymentsTable";

const LOGO_URL = 'https://sitecdn.tvpage.com/static/images/creatable_logo.svg';

const useStyles = makeStyles((theme) => ({
    print: {
        paddingTop: theme.spacing(0),
        "& .MuiTableCell-root": {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
        },
    },
}));

const InvoiceDetail = ({
                           invoice,
                           onClickRemoveLineItem,
                           onClickEditLineItem,
                           onClickEditPayment,
                           onClickRemovePayment,
                           invoiceMetadata,
                           isPrintPreview,
                       }) => {
    const classes = useStyles();
    const [isLineItemsReadOnly, setIsLineItemsReadOnly] = useState(true);
    const [isPaymentsReadOnly, setIsPaymentsReadOnly] = useState(true);

    useEffect(() => {
        setIsLineItemsReadOnly(isPrintPreview || !!invoice.date_issued);
        setIsPaymentsReadOnly(isPrintPreview || !invoice.date_issued);
    }, [isPrintPreview, invoice]);

    return (
        <Box className={isPrintPreview ? classes.print : undefined}>
            <Grid container justify={"space-between"}>
                <Grid item xs={6} md={4}>
                    <Box mb={2}>
                        <img alt="Creatable" src={LOGO_URL} width={"200px"}/>
                    </Box>

                    <Typography variant={"body2"} component={"p"}>
                        6827 Nancy Ridge Drive <br/>
                        San Diego, CA 92121
                    </Typography>

                    <Box mt={2}>
                        <Typography variant={"body2"} component={"p"}>
                            <b>BILL TO</b>
                        </Typography>
                        <Typography variant={"body2"} component={"pre"}>
                            {invoice.billing_address}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Box mb={2} display={"flex"} justifyItems={"center"}>
                        <Typography variant={"h4"} component={"p"}>
                            <b>INVOICE</b>
                        </Typography>
                    </Box>

                    <Typography variant={"body2"} component={"p"}>
                        <b>INVOICE #:</b> {invoice.invoice_number}<br/>
                        <b>INVOICE PERIOD:</b> {dates.formatInvoicePeriod(invoice.period, "MMMM YYYY")}<br/>
                        <b>DATE ISSUED:</b> {dates.formatFromString(invoice.date_issued, "MM/DD/YYYY")}<br/>
                        <b>DUE DATE:</b> {dates.formatFromString(invoice.due_date, "MM/DD/YYYY")}<br/>
                        <b>TERMS:</b> {invoice.net_terms <= 0 ? "Due upon receipt" : `Net ${invoice.net_terms}`}<br/>
                    </Typography>
                </Grid>
            </Grid>

            <Box mt={3}>
                <InvoiceLineItemsTable items={invoice.items ?? []}
                                       payments={invoice.payments ?? []}
                                       itemTypes={invoiceMetadata.invoice_item_type ?? []}
                                       isReadOnly={isLineItemsReadOnly}
                                       onClickEditItem={onClickEditLineItem}
                                       onClickRemoveItem={onClickRemoveLineItem}/>
            </Box>

            <Box mt={3}>
                <InvoicePaymentsTable payments={invoice.payments ?? []}
                                      isReadOnly={isPaymentsReadOnly}
                                      onClickEditPayment={onClickEditPayment}
                                      onClickRemovePayment={onClickRemovePayment}/>
            </Box>

            <Box mt={2} mb={2}>
                <Grid container>
                    <Grid item xs={6}>
                        <Box mb={2}>
                            <Typography variant={"body2"} component={"p"}>
                                Please remit payment via Wire or ACH as follows:
                            </Typography>
                        </Box>
                        <Typography variant={"body2"} component={"p"}>
                            Bank Name: JPMorgan Chase Bank, NA<br/>
                            Bank Routing: (US Wires) 021000021<br/>
                            Bank Routing: (ACH) 322271627<br/>
                            SWIFT Code for International Wires: CHASUS33<br/>
                            Account Name: Creatable, Inc.<br/>
                            Account Number: 892389792<br/>
                            Account Type: Checking
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box mb={2}>
                            <Typography variant={"body2"} component={"p"}>
                                If you would prefer to pay via credit card or check please contact:
                            </Typography>
                        </Box>
                        <Typography variant={"body2"} component={"p"}>
                            Creatable Finance<br/>
                            finance@creatable.co<br/>
                            +1-858-427-4909 x 100
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider/>
            <Box mt={2}>
                <Typography variant={"caption"} component={"p"} align={"center"}>
                    <Box px={2} component={"span"}>Creatable, Inc.</Box>·
                    <Box px={2} component={"span"}>6827 Nancy Ridge Drive, San Diego, CA 92121</Box>·
                    <Box px={2} component={"span"}>Phone: +1-858-427-4909</Box>·
                    <Box px={2} component={"span"}>Fax: +1-858-298-3218</Box>
                </Typography>
            </Box>
        </Box>
    )
}

export default InvoiceDetail;