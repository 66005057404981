import React from 'react';
import {makeStyles, TableCell, TableHead, TableRow} from "@material-ui/core";
const useStyles =  makeStyles({
    noWrapText:{
        whiteSpace: 'nowrap'
    }
})
const CampaignAdminTableHead = () =>{
    const classes = useStyles();
    return <TableHead>
        <TableRow>
            <TableCell>
                Creator
            </TableCell>
            <TableCell>
                Application
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Product selection
            </TableCell>
            <TableCell>
                Product delivery
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Content review
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Social post
            </TableCell>
            <TableCell className={classes.noWrapText}>
                Automated reporting
            </TableCell>
            <TableCell padding={'checkbox'}>
                {/*menu*/}
            </TableCell>
        </TableRow>
    </TableHead>
}
export default CampaignAdminTableHead;
