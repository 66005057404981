import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../slices/page";
import { useTheme } from "@material-ui/core/styles";
import {setNotification} from "../slices/notification";
import LinearProgress from "@material-ui/core/LinearProgress";
import Api from "../helpers/api";
import RequestError from "../helpers/RequestError";
import TotalSales from "../components/AnalyticsMonitoring/TotalSales/TotalSales";
import SalesByClient from "../components/AnalyticsMonitoring/SalesByClient/SalesByClient";

const AnalyticsMonitoring = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [inProgress, setInProgress] = useState(false);
    const [salesData, setSalesData] = useState(null);

    const onError = useCallback((error) => {
        dispatch(setNotification((new RequestError(error)).getMessage(), "error"))
    }, [dispatch]);

    useEffect(() => {
        dispatch(setPageTitle("Analytics monitoring"));
        return function cleanup() {
            dispatch(setPageTitle(""));
        };
    }, [dispatch]);

    useEffect(() => {
        const fetchReport = async () => {
            try {
                Api.AnalyticsMonitoring.getMetricSales()(
                    ({data}) => {
                        setSalesData(data[0]);
                    },
                    onError
                ).then(() => {
                    setInProgress(false);
                });

            } catch (error) {
                console.error("Error fetching sales reports:", error);
            }
        };

        fetchReport();
    }, [onError]);

    if (inProgress || !salesData) {
        return (<LinearProgress/>)
    }

    return (
        <Box bgcolor={theme.palette.grey[100]} height={"100%"} px={10} py={3}>
            <TotalSales salesArray={salesData}/>
            <br />
            <SalesByClient salesArray={salesData}/>
        </Box>
    );
};
export default AnalyticsMonitoring;
