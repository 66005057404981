import {createSlice} from '@reduxjs/toolkit';
import routes from "../helpers/routes";
import {setNotification} from "./notification";
import Sentry from "../helpers/sentry";
import messagesHelper, {COMMANDS} from "../helpers/MessagesHelper";
import WebSocketRpcClient from "../helpers/WebSocketRpcClient";
import RequestError from "../helpers/RequestError";
import Api from "../helpers/api";

export const initialState = {
    isChatWebSocketConnectionOpen: null,
    chatWebSocketConnectionError: null,

    supportChatList: [],
    isSupportChatListInitialized: null,

    currentPeerId: null,
    currentChat: null,
    currentChatMessages: [],
    isCurrentChatReadOnly: null,

    isLoading: false,

    isChatMessagesInProgress: false,

    brandCreatorChatList: [],
    isBrandCreatorChatListInitialized: null,

    totalChats: 0
};

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        connectionError: (state, {payload}) => {
            state.chatWebSocketConnectionError = payload;
        },
        setCurrentPeerId: (state, {payload}) => {
            state.currentPeerId = payload;
        },
        setTotalChats: (state, {payload}) => {
            state.totalChats = payload;
        },
        setIsChatWebSocketConnectionOpen: (state, {payload}) => {
            state.isChatWebSocketConnectionOpen = payload;
        },
        setIsChatWebSocketConnectionInitialized: (state, {payload}) => {
            state.isChatWebSocketConnectionInitialized = payload;
        },
        setIsChatMessagesInProgress: (state, {payload}) => {
            state.isChatMessagesInProgress = payload;
        },
        fetchSupportChatListSuccess: (state, {payload}) => {
            state.supportChatList = payload;
            state.isSupportChatListInitialized = true;
        },
        setIsSupportChatListInitialized: (state, {payload}) => {
            state.isSupportChatListInitialized = payload;
        },
        setCurrentChat: (state, {payload}) => {
            state.currentChat = payload;
        },
        setCurrentChatMessages: (state, {payload}) => {
            state.currentChatMessages = payload;
        },
        setIsLoading: (state, {payload}) => {
            state.isLoading = payload;
        },
        appendMessage: (state, {payload}) => {
            if (state.currentChat) {
                state.currentChatMessages = [...state.currentChatMessages, payload];
            }
        },
        setIsCurrentChatReadOnly: (state, {payload}) => {
            state.isCurrentChatReadOnly = payload;
        },
        fetchBrandCreatorChatListSuccess: (state, { payload }) => {
            state.brandCreatorChatList = payload;
            state.isBrandCreatorChatListInitialized = true;
        },
        setIsBrandCreatorChatListInitialized: (state, {payload}) => {
            state.isBrandCreatorChatListInitialized = payload;
        },
        clearMessages: (state) => {
            state.currentChatMessages = [];
            state.currentChat = null;
        },
    },
});

export const messagesSelector = state => state.messages;
export default messagesSlice.reducer;

const {
    connectionError,
    setIsChatWebSocketConnectionOpen,
    setIsChatWebSocketConnectionInitialized,
    setIsChatMessagesInProgress,
    fetchSupportChatListSuccess,
    setIsSupportChatListInitialized,
    setCurrentChat,
    setCurrentChatMessages,
    appendMessage,
    setIsCurrentChatReadOnly,
    setCurrentPeerId,
    fetchBrandCreatorChatListSuccess,
    setIsBrandCreatorChatListInitialized,
    setIsLoading,
    setTotalChats,
    clearMessages
} = messagesSlice.actions;
export {setIsSupportChatListInitialized, setIsBrandCreatorChatListInitialized, clearMessages};

let webSocketRpcClient = null;

export const createConnection = (userId, roleId, clientId = null) => dispatch => {
    if (webSocketRpcClient) {
        webSocketRpcClient.closeConnection();
    }

    const url = routes.createWebsocketConnectionUrl(userId, roleId, clientId);
    webSocketRpcClient = new WebSocketRpcClient(url, {
        onConnectionError: (error) => {
            Sentry.captureException(error);
            console.error('WebSocket error:', error);
            dispatch(connectionError(new RequestError(error).getMessage()));
        },
        onConnectionOpen: () => {
            dispatch(setIsChatWebSocketConnectionOpen(true));
        },
        onMessage: (event) => {
            try {
                const data = JSON.parse(event.data);
                dispatch(processMessage({...event, data}));
            } catch (error) {
                dispatch(setNotification("Error receiving chat message", "error"));
                Sentry.captureException(error);
                console.error(error);
            }
        },
        onConnectionClose: (event) => {
            dispatch(setIsChatWebSocketConnectionOpen(false));
        }
    });

    dispatch(setIsChatWebSocketConnectionInitialized(true));
};

const sendCommand = (command) => {
    if (webSocketRpcClient) {
        return webSocketRpcClient.sendCommand(command);
    } else {
        return Promise.reject("WebSocket connection is closed");
    }
}

const processMessage = (event) => dispatch => {
    if (event.data.code === COMMANDS.MESSAGE) {
        dispatch(appendMessage(event.data));
    } else if (event.data.code === COMMANDS.ME) {
        dispatch(setCurrentPeerId(event.data.peer_id));
    }
};

export const fetchBrandCreatorChatList = (search, supportUnread, page = 1, itemsPerPage = 10) => (dispatch) => {
    dispatch(setIsChatMessagesInProgress(true));
    dispatch(setIsBrandCreatorChatListInitialized(false));

    return new Promise((resolve, reject) => {
        const isSupport = false;
        Api.messages.getAllChats(isSupport, page, itemsPerPage, search, supportUnread)(
            ({data}) => resolve(data),
            (error) => reject(error),
        );
    }).then(({items, total}) => {
        dispatch(fetchBrandCreatorChatListSuccess(items));
        dispatch(setTotalChats(total));
        return items
    }).finally(() => {
        dispatch(setIsChatMessagesInProgress(false));
        dispatch(setIsBrandCreatorChatListInitialized(true));
    });
};

export const fetchSupportChatList = (search, supportUnread, page = 1, itemsPerPage = 10) => dispatch => {
    dispatch(setIsChatMessagesInProgress(true));
    dispatch(setIsSupportChatListInitialized(false));
    return new Promise((resolve, reject) => {
        const isSupport = true;
        Api.messages.getAllChats(isSupport, page, itemsPerPage, search, supportUnread)(
            ({data}) => resolve(data),
            (error) => reject(error),
        );
    }).then(({items, total}) => {
        dispatch(fetchSupportChatListSuccess(items));
        dispatch(setTotalChats(total));
        return items;
    }).finally(() => {
        dispatch(setIsChatMessagesInProgress(false));
        dispatch(setIsSupportChatListInitialized(true));
    });
};

export const startSupportChat = (userId, roleId, clientId = null) => dispatch => {
    dispatch(setIsChatMessagesInProgress(true));
    return new Promise((resolve, reject) => {
        Api.messages.createSupportChat(userId, roleId, clientId)(
            ({data}) => resolve(data),
            (error) => reject(error),
        )();
    }).finally(() => {
        dispatch(setIsChatMessagesInProgress(false));
    });
};

export const prepareCurrentChatData = (chat, userId) => dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setIsChatMessagesInProgress(true));
    dispatch(setCurrentChat(chat));

    const currentPeer = chat.peers.find(p => p.user_id === userId);
    const isReadOnly = (!currentPeer || !currentPeer.is_active);
    dispatch(setIsCurrentChatReadOnly(isReadOnly));

    if (isReadOnly) {
        return fetchReadOnlyChatMessages(chat).then((messages) => {
            dispatch(setCurrentChatMessages(messages));
            dispatch(setIsChatMessagesInProgress(false));
            dispatch(setIsLoading(false));
        });
    } else {
        return fetchLiveChatMessages(chat).then((messages) => {
            dispatch(setCurrentChatMessages(messages));
            dispatch(setIsChatMessagesInProgress(false));
            dispatch(setIsLoading(false));
        });
    }
};

const fetchLiveChatMessages = (chat, limit = 1000000, addOffset = 0, offsetId = null) => {
    const historyCommand = messagesHelper.createCommand(messagesHelper.generateRandomId(), COMMANDS.HISTORY, {
        chat_id: chat.chat_id,
        limit,
        add_offset: addOffset,
        offset_id: offsetId
    });
    return sendCommand(historyCommand).then(({messages}) => messages);
};

const fetchReadOnlyChatMessages = (chat) => {
    return new Promise((resolve, reject) => {
        Api.messages.getChatMessages(chat.chat_id, 0, 100000)(
            ({data}) => resolve(data),
            (error) => reject(error),
        );
    });
};

export const sendTyping = (chatId) => dispatch => {
    const typingCommand = messagesHelper.createCommand(messagesHelper.generateRandomId(), COMMANDS.TYPING, {chat_id: chatId});
    return sendCommand(typingCommand)
        .then().catch(console.error);
};

export const sendMessage = (chatId, peerId, message) => dispatch => {
    dispatch(setIsChatMessagesInProgress(true));
    const messageCommand = messagesHelper.createCommand(messagesHelper.generateRandomId(), COMMANDS.MESSAGE, {
        chat_id: chatId,
        text: message
    });
    return sendCommand(messageCommand)
        .finally(() => {
            dispatch(setIsChatMessagesInProgress(false));
        });
};

export const setMessageRead = (chatId, messageId) => dispatch => {
    const messageReadCommand = messagesHelper.createCommand(messagesHelper.generateRandomId(), COMMANDS.READ, {
        chat_id: chatId,
        message_id: messageId
    });
    return sendCommand(messageReadCommand).then(() => {
        dispatch(fetchSupportChatList());
    });
};