import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import withNetworkDetector from './hoc/withNetworkDetector';
import withApplicationVersionDetector from './hoc/withApplicationVersionDetector';
import LoadingApp from "./components/LoadingApp";
import {authSelector, authenticate} from "./slices/auth";
import UserProvider from "./providers/UserProvider";
import environment from "./helpers/environment";
import Admin from "./containers/Admin";
import routes from "./helpers/routes";

const App = () => {
    const dispatch = useDispatch();
    const {isAuthenticated, user} = useSelector(authSelector);
    const [isUserLoading, setIsUserLoading] = useState(true);

    useEffect(() => {
        dispatch(authenticate());
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated === false) {
            window.location.href = routes.getLoginUrl(window.location.href);
        } else if (isAuthenticated && user) {
            const matchedRole = user.roles.filter(ur => ur.role.role_id === 3);
            if (matchedRole.length === 0) {
                window.location.href = `//${environment.getMerchantAppHost()}/`;
            } else {
                setIsUserLoading(false);
            }
        }
    }, [isAuthenticated, user]);

    if (isUserLoading) {
        return (
            <>
                <AppStatusNotifier/>
                <LoadingApp/>
            </>
        );
    } else {
        return (
            <>
                <AppStatusNotifier/>
                <UserProvider value={user}>
                    <Admin/>
                </UserProvider>
            </>
        );
    }
}

const AppStatusNotifier = withNetworkDetector(withApplicationVersionDetector(() => {
    return <Fragment/>;
}));

export default App;
