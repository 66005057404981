import {createSlice} from '@reduxjs/toolkit';
import api from '../helpers/api';
import RequestError from '../helpers/RequestError';
import ResponseStatus from '../helpers/ResponseStatus';

export const initialState = {
    clientAnalytics: [],
    isClientAnalyticsLoading: false,
    isGetClientAnalyticsError: false,
    clientAnalyticsErrorMessage: "",
    isClientAnalyticsInitialized: false,
};

const clientAnalyticsSlice = createSlice({
    name: 'clientAnalytics',
    initialState,
    reducers: {
        onGetClientAnalyticsStart: (state) => {
            state.isClientAnalyticsLoading = true;
            state.isClientAnalyticsInitialized = true;
        },
        onGetClientAnalyticsDone: (state, {payload}) => {
            state.isClientAnalyticsLoading = false;
            state.clientAnalytics = payload.data;
            state.clientAnalyticsErrorMessage = '';
        },
        onRequestFail: (state, {payload}) => {
            state.isClientAnalyticsLoading = false;
            state.isGetClientAnalyticsError = true;
            state.clientAnalyticsErrorMessage = payload.errorMessage;
        },
    },
});

export const clientAnalyticsSelector = state => state.clientAnalytics;
export default clientAnalyticsSlice.reducer;

const {onGetClientAnalyticsStart, onGetClientAnalyticsDone, onRequestFail} = clientAnalyticsSlice.actions;
export const getClientAnalytics = (startDate, endDate) => dispatch => {
    dispatch(onGetClientAnalyticsStart());
    return api.getClientAnalytics(startDate, endDate)(
        response => {
            const respStatus = new ResponseStatus(response);
            dispatch(onGetClientAnalyticsDone({
                isError: respStatus.isError(),
                errorMessage: respStatus.getErrorMessage(),
                data: response.data
            }));
        },
        error => {
            const reqError = new RequestError(error);
            dispatch(onRequestFail({
                isError: true,
                errorMessage: reqError.getMessage()
            }));
        }
    );
}