

export const object = {
    get: (prop, obj, defaultValue = null) => prop.split('.').reduce((r, a) => (r && r[a]) ? r[a] : defaultValue, obj),
};
export const strings = {
    capitalize: (text) => text.charAt(0).toUpperCase() + text.slice(1),
    insertSpacesToCamelCase: (value) => {
        if (value) {
            value = value.replace(/([A-Z])/g, ' $1').trim();
            value = strings.capitalize(value.toLowerCase());
        }

        return value;
    },
    stringOrDash: (value) => {
        if (!value) {
            return '-';
        }

        return value;
    }
};
const valueToNumber = value =>{
    const convertedValue = Number(value);
    return isNaN(convertedValue) ? 0 : convertedValue;
}
export const numbers = {
    getShortNumber: (number) => {
        if (number >= 1000000) {
            return `${Math.floor(number/1000000)}M`;
        } else if (number >= 1000) {
            return `${Math.floor(number/1000)}K`;
        } else {
            return number;
        }
    },
    penniesToDollars: pennies => (valueToNumber(pennies) / 100),
    dollarsToPennies: dlls => (valueToNumber(dlls) * 100)
};

export const isEmpty = v => (v === undefined || v === null || v === "" || v === 0 || parseFloat(v) === 0 || (Array.isArray(v) && v.length === 0));
export const isDefined = v => (v !== undefined && v !== null);

export const getCrossSubdomainForCookie = () => {
    const domainParts = window.location.host.split('.');
    domainParts.shift();
    return `.${domainParts.join('.')}`;
}

export const addImpersonationStartUrl = (cookieValue, url) => {
    const list = Array.isArray(cookieValue) ? cookieValue : [];
    list.push(url);
    return list;
}
