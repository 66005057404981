import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import UsersTable from "../components/Users/UsersTable";
import {Box, Paper} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import {SpeedDialIcon} from "@material-ui/lab";
import {setPageTitle} from "../slices/page";
import {useDispatch} from "react-redux";
import AddUserModal from "../components/AddUserModal/AddUserModal";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
}));

const AddUserButton = ({onClick}) => {
    const classes = useStyles();
    return (
        <Fab className={classes.fab} color={'primary'} onClick={onClick}>
            <SpeedDialIcon/>
        </Fab>
    )
}

const Users = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);

    useEffect(() => {
        dispatch(setPageTitle("Users"));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <UsersTable/>
                    </Paper>
                    <Box height={80}>
                        <AddUserButton onClick={() => setIsUserModalOpen(true)}/>
                        <AddUserModal isOpen={isUserModalOpen}
                                        onClose={() => setIsUserModalOpen(false)}
                                        onSaveSuccess={() => setIsUserModalOpen(false)}/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Users;