import Link from "@material-ui/core/Link";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, {useEffect, useState} from "react";
import api from "../../helpers/api";
import dates from "../../helpers/dates";

const getBillingType = type =>{
    const types= {
        credit_card: "Credit card",
        managed: "Managed"
    }

    return types[type] ? types[type] : "-";
}

const ClientsTableRow = ({client, onClickDetails}) => {
    const [plan, setPlan] =  useState(null);
    const [company, setCompany] =  useState(null);

    useEffect(() => {
        api.backoffice.company.getPlan(client.client_id)(
            response => {
                setPlan(response.data);
            },
            error=>{

            }
        )
        api.backoffice.company.details(client.client_id)(
            response => {
                setCompany(response.data);
            },
            error=>{

            }
        )
    }, [client]);
    return (
        <TableRow>
            <TableCell align="left">
                <Link onClick={() => onClickDetails(client)}>
                    {client.name} ({client.client_id})
                </Link>
            </TableCell>
            <TableCell align="left">
                {plan ? plan.name : "-"}
            </TableCell>
            <TableCell align="left">
                {plan ? dates.formatFromStringToDate(plan.start_date) : "-"}
            </TableCell>
            <TableCell align="left">
                {company ? getBillingType(company.payment_method_type) : "-"}
            </TableCell>
        </TableRow>
    );
}

export default ClientsTableRow;