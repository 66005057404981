import Button from "@material-ui/core/Button";
import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Box from "@material-ui/core/Box";
import TablePagination from '@material-ui/core/TablePagination';
import {LinearProgress} from "@material-ui/core";
import {useDebounce} from "use-debounce";
import Api from "../../helpers/api";
import RequestError from "../../helpers/RequestError";
import {isEmpty} from "../../helpers/utils";
import UsersTableHead from "./UsersTableHead";
import UsersTableToolbar from "./UsersTableToolbar";
import environment from "../../helpers/environment";
import { setNotification } from "../../slices/notification";
import { useDispatch } from "react-redux";
import {useCookies} from "react-cookie";
import impersonationHelper from "../../helpers/impersonationHelper";

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    emptyTable: {
        height: '424px',
        '& .MuiTableCell-body': {
            textAlign: 'center'
        },
        '& td': {
            borderBottom: 0,
        }
    },
}));

const UsersTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies([impersonationHelper.getStartUrlCookieName()]);

    const [error, setError] = useState("");

    const [inProgress, setInProgress] = useState(true);

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

    const reloadUsers = useCallback((page, itemsPerPage) => {
        setInProgress(true);
        Api.getAccountAdmins(page, itemsPerPage, debouncedSearchTerm)(
            ({data}) => {
                setUsers(data.items);
                setTotal(data.total);
            }, (error) => {
                const errRequest = new RequestError(error);
                setError(errRequest.getMessage());
            }
        ).then(() => {
            setInProgress(false);
        });
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (!isEmpty(error)) {
            dispatch(setNotification(error, "error"));
            setError("");
        }
    }, [error, dispatch]);

    useEffect(() => {
        reloadUsers(page, itemsPerPage);
    }, [reloadUsers, page, itemsPerPage]);

    useEffect(() => {
        setPage(1);
    }, [debouncedSearchTerm]);

    const handleChangePage = (event, newPage) => {
        if (newPage>=0) {
            setPage(newPage+1);
        }
    }

    const handleChangeRowsPerPage = (event) => {
        setItemsPerPage(+event.target.value);
        setPage(1);
    }

    const impersonateUser = (userId) => {
        Api.impersonateUser(userId)(
            () => {
                const newCookieValue = impersonationHelper.addImpersonationStartUrl(cookies[impersonationHelper.getStartUrlCookieName()], window.location.href);
                setCookie(impersonationHelper.getStartUrlCookieName(), newCookieValue, impersonationHelper.getCookieCreationOptions());
                window.location.href = `//${environment.getBrandAppHost()}`;
            },
            (error) => {
                const errRequest = new RequestError(error);
                setError(errRequest.getMessage());
            }
        );
    }

    return (
        <Box>
            <UsersTableToolbar onChangeSearchTerm={setSearchTerm}/>
            <TableContainer>
                <Table size={'medium'} className={classes.table}>
                    <UsersTableHead/>
                    <TableBody>
                        {
                            users.map((user) => {
                                return (
                                    <TableRow key={`${user.client_id}-${user.user_id}`}>
                                        <TableCell align="left">{user.client_id}</TableCell>
                                        <TableCell align="left">{user.client_name}</TableCell>
                                        <TableCell align="left">{user.user_id}</TableCell>
                                        <TableCell align="left">{user.first_name}</TableCell>
                                        <TableCell align="left">{user.last_name}</TableCell>
                                        <TableCell align="left">{user.email}</TableCell>
                                        <TableCell align="center">
                                            <Button variant={"outlined"} onClick={() => impersonateUser(user.user_id)} size={"small"}>
                                                Login
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                        {
                            users.length >= 1 && (8 - users.length) > 0 && (
                                <TableRow style={{height: (53) * (8 - users.length)}}>
                                    <TableCell colSpan={6} style={{border: "none"}}/>
                                </TableRow>
                            )
                        }
                        {
                            users.length === 0 && (
                                <TableRow className={classes.emptyTable}>
                                    <TableCell colSpan={6}>{inProgress ? "" : "No users found."}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={itemsPerPage}
                page={page-1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                backIconButtonProps={{disabled: inProgress || users.length === 0 || page===1}}
                nextIconButtonProps={{disabled: inProgress || users.length === 0 || page>=Math.ceil(total/itemsPerPage)}}
            />
            {inProgress && <LinearProgress/>}
        </Box>
    );
}

export default UsersTable;
