import React from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionActions from "@material-ui/core/AccordionActions";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";

const defaultValues = {current_version: "", minimum_required_version: ""};

const MobileAppConfig = ({mobileAppConfig, onChange, onSave, onCancel}) => {
    const android = mobileAppConfig ? mobileAppConfig.android : defaultValues;
    const ios = mobileAppConfig ? mobileAppConfig.ios : defaultValues;

    const onChangeField = (section, option) => (e) => {
        onChange({
            ...mobileAppConfig,
            [section]: {
                ...mobileAppConfig[section],
                [option]: e.target.value
            }
        })
    }
    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1c-content"
                id="panel1c-header"
            >
                <Typography variant="h6">
                    Mobile app configuration
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <Typography variant="subtitle1">
                                Android
                            </Typography>
                        </Box>
                        <TextField
                            name="current_version"
                            label="Current version"
                            value={android.current_version}
                            variant="outlined"
                            fullWidth
                            onChange={onChangeField('android', 'current_version')}
                        />
                        <TextField
                            name="minimum_required_version"
                            label="Minimum required version"
                            value={android.minimum_required_version}
                            variant="outlined"
                            fullWidth
                            onChange={onChangeField('android', 'minimum_required_version')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <Typography variant="subtitle1">
                                iOS
                            </Typography>
                        </Box>
                        <TextField
                            name="current_version"
                            label="Current version"
                            value={ios.current_version}
                            variant="outlined"
                            fullWidth
                            onChange={onChangeField('ios', 'current_version')}
                        />
                        <TextField
                            name="minimum_required_version"
                            label="Minimum required version"
                            value={ios.minimum_required_version}
                            variant="outlined"
                            fullWidth
                            onChange={onChangeField('ios', 'minimum_required_version')}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
            <Divider/>
            <AccordionActions>
                <Button size="small" onClick={onCancel}>Cancel</Button>
                <Button size="small" onClick={onSave} color="primary">
                    Save
                </Button>
            </AccordionActions>
        </Accordion>
    )
}

export default MobileAppConfig;