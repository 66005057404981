import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux'
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {setPageTitle} from "../slices/page";
import CampaignReport from "../components/CampaignStatusReport/CampaignReport";
import Client from "../helpers/api";
import {withUser} from "../providers/UserProvider";
import Api from "../helpers/api";
import RequestError from "../helpers/RequestError";
import {setNotification} from "../slices/notification";
import Shared from "../helpers/Shared";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },
}));

const ORDER_DESC = false;

const STATUS_COMPLETED = "Completed";
const STATUS_ENDED = "Ended";
const STATUS_ACTIVE = "Active";
const STATUS_PENDING = "Pending";
const STATUS_DRAFT = "Draft";

const POLLING_INTERVAL = 300000; // refresh all uncompleted campaigns every 5 mins

const CampaignStatusReport = ({user}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isCampaignSessionSet, setIsCampaignSessionSet] = useState(null);
    const [order, setOrder] = useState(ORDER_DESC);

    const [completedCampaigns, setCompletedCampaigns] = useState(null);
    const [completedCampaignsCount, setCompletedCampaignsCount] = useState(null);

    const [endedCampaigns, setEndedCampaigns] = useState(null);
    const [endedCampaignsCount, setEndedCampaignsCount] = useState(null);

    const [activeCampaigns, setActiveCampaigns] = useState(null);
    const [activeCampaignsCount, setActiveCampaignsCount] = useState(null);

    const [pendingCampaigns, setPendingCampaigns] = useState(null);
    const [pendingCampaignsCount, setPendingCampaignsCount] = useState(null);

    const [draftCampaigns, setDraftCampaigns] = useState(null);
    const [draftCampaignsCount, setDraftCampaignsCount] = useState(null);

    const [isRefreshDataActive, setIsRefreshDataActive] = useState(false);
    const [pollingHandler, setPollingHandler] = useState(null);

    const getCampaignsByStatus = useCallback((status, order) => {
        const params = {
            filterByStatus: status,
            resultParameter: {
                resultsLimitOffset: 0,
                resultsLimitCount: 100,
                resultsOrderBy: "startdate",
                resultsOrderAscending: (order ? order : ORDER_DESC),
            }
        }
        return Api.CampaignApi.search()(
            response => response,
            error => dispatch(setNotification((new RequestError(error)).getMessage(), "error")),
        )(params);
    }, [dispatch]);

    useEffect(() => {
        dispatch(setPageTitle("Campaigns status report"));
        return function cleanup() {
            dispatch(setPageTitle(''));
        };
    }, [dispatch]);

    useEffect(() => {
        if (isCampaignSessionSet === null) {
            Client.UserApi.login()(
                (session) => {
                    Shared.session = session.data;
                    setIsCampaignSessionSet(true);
                },
                () => setIsCampaignSessionSet(false)
            )({cookie: document.cookie, user_id: user.user_id});
            setOrder(ORDER_DESC);
        }
    }, [isCampaignSessionSet, user]);

    useEffect(() => {
        if (isCampaignSessionSet && pollingHandler === null) {
            setPollingHandler(setInterval(() => {
                setIsRefreshDataActive(true);
            }, POLLING_INTERVAL));
        }
        return () => {
            clearInterval(pollingHandler);
        }
    }, [isCampaignSessionSet, pollingHandler]);

    useEffect(() => {
        if (isRefreshDataActive) {
            setIsRefreshDataActive(false);
        }
    }, [isRefreshDataActive]);

    useEffect(() => {
        if (isCampaignSessionSet && (completedCampaigns === null || isRefreshDataActive)) {
            getCampaignsByStatus(STATUS_COMPLETED, order).then(response => {
                setCompletedCampaigns(response.data.campaigns);
                setCompletedCampaignsCount(response.data.totalItemCount);
            });
        }
    }, [isCampaignSessionSet, completedCampaigns, isRefreshDataActive, getCampaignsByStatus, order]);

    useEffect(() => {
        if (isCampaignSessionSet && (endedCampaigns === null || isRefreshDataActive)) {
            getCampaignsByStatus(STATUS_ENDED, order).then(response => {
                setEndedCampaigns(response.data.campaigns);
                setEndedCampaignsCount(response.data.totalItemCount);
            });
        }
    }, [isCampaignSessionSet, endedCampaigns, isRefreshDataActive, getCampaignsByStatus, order]);

    useEffect(() => {
        if (isCampaignSessionSet && (activeCampaigns === null || isRefreshDataActive)) {
            getCampaignsByStatus(STATUS_ACTIVE, order).then(response => {
                setActiveCampaigns(response.data.campaigns);
                setActiveCampaignsCount(response.data.totalItemCount);
            });
        }
    }, [isCampaignSessionSet, activeCampaigns, isRefreshDataActive, getCampaignsByStatus, order]);

    useEffect(() => {
        if (isCampaignSessionSet && (pendingCampaigns === null || isRefreshDataActive)) {
            getCampaignsByStatus(STATUS_PENDING, order).then(response => {
                setPendingCampaigns(response.data.campaigns);
                setPendingCampaignsCount(response.data.totalItemCount);
            });
        }
    }, [isCampaignSessionSet, pendingCampaigns, isRefreshDataActive, getCampaignsByStatus, order]);

    useEffect(() => {
        if (isCampaignSessionSet && (draftCampaigns === null || isRefreshDataActive)) {
            getCampaignsByStatus(STATUS_DRAFT, order).then(response => {
                setDraftCampaigns(response.data.campaigns);
                setDraftCampaignsCount(response.data.totalItemCount);
            });
        }
    }, [isCampaignSessionSet, draftCampaigns, isRefreshDataActive, getCampaignsByStatus, order]);

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CampaignReport title={"COMPLETED"} total={completedCampaignsCount} campaigns={completedCampaigns ?? []} inProgress={completedCampaigns===null}/>
                </Grid>
                <Grid item xs={12}>
                    <CampaignReport title={"ENDED"} total={endedCampaignsCount} campaigns={endedCampaigns ?? []} inProgress={endedCampaigns===null}/>
                </Grid>
                <Grid item xs={12}>
                    <CampaignReport title={"ACTIVE"} total={activeCampaignsCount} campaigns={activeCampaigns ?? []} inProgress={activeCampaigns===null}/>
                </Grid>
                <Grid item xs={12}>
                    <CampaignReport title={"PENDING"} total={pendingCampaignsCount} campaigns={pendingCampaigns ?? []} inProgress={pendingCampaigns===null}/>
                </Grid>
                <Grid item xs={12}>
                    <CampaignReport title={"DRAFT"} total={draftCampaignsCount} campaigns={draftCampaigns ?? []} inProgress={activeCampaigns===null}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export default withUser(CampaignStatusReport);
