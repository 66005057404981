import Box from "@material-ui/core/Box";
import {LinearProgress, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React, {useEffect, useState} from "react";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {numbers} from "../../../helpers/utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import api from "../../../helpers/api";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {setNotification} from "../../../slices/notification";
import {clientConfigSelector} from "../../../slices/clientConfig";

const ChangePlanDialog = ({open, onClose, onSuccess}) => {
    const [inProgress, setInProgress] = useState(false);
    const [base_price_pennies, setBasePricePennies] = useState("");
    const [errors, setErrors] = useState({});
    const [plan, setPlan] = useState(null);
    const [effectiveMonth, setEffectiveMonth] = useState(null);
    const [plans, setPlans] = useState([]);
    const {clientConfig} = useSelector(clientConfigSelector);
    const dispatch =  useDispatch();
    const onConvertedMoneyFieldChange = (event, value, convertToPennies=true) =>{
        const newValue = convertToPennies ? numbers.dollarsToPennies(value) : value;
        setBasePricePennies(newValue);
    }

    useEffect(() => {
        api.backoffice.plans.availablePlans()(
            response=>{
                setPlans(response.data);
            },
            error=>{

            }
        )
    }, []);

    const manageErrors = (field, value) =>{
        const newErrors = {...errors};
        if(!value){
            newErrors[field] = true;
        }else{
            delete newErrors[field];
        }
        setErrors(newErrors);
    }

    const onChangePlan = (event, value) =>{
        setPlan(value);
        if(value.hasOwnProperty('base_price_pennies')){
            setBasePricePennies(value.base_price_pennies);
        }
        manageErrors('plan', value);
    }

    const onChangeEffectiveMonth = (event, value) =>{
        setEffectiveMonth(value);
        manageErrors('effectiveMonth', value);
    }

    const clickSave = () =>{
        const newErrors = {...errors};
        if(!plan){
            newErrors['plan'] = true;
        }
        if(!effectiveMonth){
            newErrors['effectiveMonth'] = true;
        }
        if(Object.keys(newErrors).length > 0){
            setErrors(newErrors);
            dispatch(setNotification('Please enter all the required fields', 'error'));
            return;
        }
        setInProgress(true);
        api.backoffice.company.updateCurrentPlan(clientConfig.client.id, effectiveMonth.code)(
            response=>{
                setInProgress(false);
                dispatch(setNotification('Plan updated successfully'));
                onSuccess();
            },
            error=>{
                dispatch(setNotification('Unable to change plan', 'error'));
            }
        )({...plan, base_price_pennies})
    }

    return <Dialog open={open} onClose={onClose} data-testId={'change-plan-dialog'}>
        <Box px={3}>
            <Box pt={2}>
                <Typography>Change plan</Typography>
            </Box>

            <Autocomplete
                onChange={onChangePlan}
                value={plan}
                data-testId={'plan-select'}
                renderInput={props=><TextField {...props} error={errors['plan'] === true} label={'New plan'} margin={'dense'} />}
                getOptionLabel={(option)=> option.name}
                options={plans} />

            <Autocomplete
                onChange={onChangeEffectiveMonth}
                value={effectiveMonth}
                data-testId={'plan-select-effective'}
                getOptionLabel={(option)=> option.value}
                renderInput={props=><TextField error={errors['effectiveMonth'] === true} {...props} label={'Effective date'} margin={'dense'} />}
                options={[{code: "retroactive", value:"This month"}, {code: "prospective ", value:"Next month"}]} />
            <Box data-testId={'base_price_pennies'}>
            <CurrencyTextField margin="dense"
                               name={'base_price_pennies'}
                               textAlign="left"
                               fullWidth
                               error={errors['base_price_pennies'] === true}
                               label="Montlhy fee"
                               placeholder="Montlhy fee"
                               decimalPlaces={2}
                               currencySymbol={"$"}
                               onChange={(e, value) => onConvertedMoneyFieldChange(e, value)}
                               value={base_price_pennies ? numbers.penniesToDollars(base_price_pennies) : 0}
                               variant="outlined" size="small"/>
            </Box>
            <Box pb={3} pt={2} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={onClose} variant={'outlined'} style={{marginRight: "16px"}} data-testId={'change-plan-action-cancel'}>
                    Cancel
                </Button>
                <Button variant={'contained'} data-testId={'change-plan-action-save'} onClick={clickSave}>
                    Save
                </Button>
            </Box>
        </Box>
        {inProgress && <LinearProgress />}
    </Dialog>
}

export default ChangePlanDialog;