import {FormControl, FormHelperText, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import React, {useCallback, useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import Api from "../helpers/api";
import {isEmpty} from "../helpers/utils";

const ClientAutocompleteSelect = ({disabled, onChange, value, error, helperText}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [lastSearch, setLastSearch] = useState("");
    const [isInitialized, setIsInitialized] = useState(false);
    const [debouncedSearchTerm] = useDebounce(searchTerm, 50);
    const [clients, setClients] = useState([]);
    const [isError, setIsError] = useState(false);
    const [internalValue, setInternalValue] = useState(null);

    const getClients = useCallback((search) => {
        Api.getClients(1, 100, search)(
            ({data}) => setClients(data.items), () => setIsError(true)
        ).then(() => {
            setLastSearch(search);
            setIsInitialized(true);
        });
    }, []);

    useEffect(() => {
        if (lastSearch !== debouncedSearchTerm || !isInitialized) {
            getClients(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm, lastSearch, getClients, isInitialized]);

    useCallback(() => {
        if (value?.client_id) {
            setInternalValue(value);
        }
    }, [value]);

    const onInternalChange = (event, client) => {
        setSearchTerm("");
        onChange(client);
        setInternalValue(client);
    }

    return (
        <FormControl error={isError || error} fullWidth margin={"normal"}>
            <Autocomplete
                value={internalValue}
                disabled={disabled}
                size="small"
                noOptionsText={"Search client"}
                options={clients}
                onInputChange={(e, value) => setSearchTerm(value.trim())}
                getOptionLabel={(option) => `${option.name} (${option.client_id})`}
                getOptionSelected={(option, value) => option.client_id === value.client_id}
                renderInput={(params) => (
                    <TextField variant="outlined" disabled={disabled} size="small"
                               error={error}
                               label={"Client"}
                               placeholder={"Search..."} fullWidth {...params}
                               InputLabelProps={{shrink: true, ...params.InputLabelProps}}/>
                )}
                onChange={onInternalChange}
            />
            {isError && <FormHelperText>"Error loading clients"</FormHelperText>}
            {!isError && !isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

export default ClientAutocompleteSelect;