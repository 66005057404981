import React, { useEffect, useState, Fragment } from "react";
import { Box, Button, Dialog, DialogTitle, LinearProgress } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchSupportChatList,
    messagesSelector,
    prepareCurrentChatData,
    sendMessage,
    sendTyping,
    setMessageRead,
    clearMessages,
} from "../../slices/messages";
import TextField from "@material-ui/core/TextField";
import { SendOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import MessageList from "./MessageList";
import { withUser } from "../../providers/UserProvider";
import Api from "../../helpers/api";
import { ROLES } from "../../helpers/roleHelper";
import useNotification from "../../helpers/useNotification";
import { isEmpty } from "../../helpers/utils";
import ChatAvatar from "./ChatAvatar";

const ChatModal = ({ isOpen, onClose, userId, isSupport = true }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const notification = useNotification();
    const {
        currentPeerId,
        currentChat,
        currentChatMessages,
        isCurrentChatReadOnly,
    } = useSelector(messagesSelector);
    const [inProgress, setInProgress] = useState(false);
    const [message, setMessage] = useState("");
    const lastMessageIdReceived = currentChatMessages
        .slice()
        .reverse()
        .find((m) => m.author !== currentPeerId)?.message_id;

    useEffect(() => {
        if (lastMessageIdReceived) {
            dispatch(
                setMessageRead(currentChat.chat_id, lastMessageIdReceived)
            );
        }
    }, [lastMessageIdReceived, currentChat.chat_id, dispatch]);

    useEffect(() => {
        if (isOpen === false) {
            dispatch(clearMessages());
            setMessage("");
        }
        return () => {
            dispatch(clearMessages());
        };
    }, [isOpen, dispatch]);

    const onKeyUp = (e) => {
        dispatch(sendTyping(currentChat?.chat_id));
        if (e && e.keyCode === 13 && !isEmpty(message)) {
            doSendMessage();
        }
    };
    
    const joinChat = () => {
        if (isCurrentChatReadOnly) {
            setInProgress(true);
            Api.messages.joinChat(currentChat?.chat_id, userId, ROLES.ROLE_SUPER_ADMIN)(
                () => {
                    dispatch(fetchSupportChatList()).then((chats) => {
                        const chat = chats.find((chat) => chat?.chat_id === currentChat.chat_id);
                        dispatch(prepareCurrentChatData(chat, userId));
                    });
                },
                notification.captureError
            ).finally(() => {
                setInProgress(false);
            });
        }
    };

    const doSendMessage = () => {
        return dispatch(
            sendMessage(currentChat?.chat_id, currentPeerId, message)
        ).then(() => {
            setMessage("");
        });
    };

    const getChatWith = () => {
        if (!isEmpty(currentChat.support_chat_details.company_name)) {
            return `${currentChat.support_chat_details.full_name}, ${currentChat.support_chat_details.company_name}`;
        } else {
            return currentChat.support_chat_details.full_name;
        }
    };

    return (
        <Fragment>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>
                    Chat with{" "}
                    {isSupport
                        ? currentChat?.support_chat_details?.company_name ??
                          currentChat?.support_chat_details?.full_name
                        : currentChat?.brand_chat_details?.full_name}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        className={classes.closeButton}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <Box px={3} pt={isSupport ? 3 : 1} pb={2}>
                    {isSupport && (
                        <Box display={"flex"} flexDirection={"row"}>
                            <Box>
                                <ChatAvatar
                                    chatDetails={
                                        currentChat.support_chat_details
                                    }
                                    size={40}
                                />
                            </Box>
                            <Box
                                flex={1}
                                pl={2}
                                display={"flex"}
                                justifyContent={"center"}
                                flexDirection={"column"}>
                                <Typography variant="body1">
                                    {getChatWith()}
                                </Typography>
                                <Typography variant="body2">
                                    This is your conversation with{" "}
                                    {currentChat.support_chat_details.full_name}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
                <MessageList px={3} pb={2} />
                {isSupport && (
                    <>
                        <Divider />
                        <Box
                            py={2}
                            px={3}
                            justifyContent={"flex-end"}
                            display={"flex"}>
                            {isCurrentChatReadOnly ? (
                                <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                    width={"100%"}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={joinChat}
                                        disabled={inProgress}
                                        style={{ textTransform: "none", fontWeight: "bold" }}
                                    >
                                        Join chat
                                    </Button>
                                </Box>
                            ) : (
                                <TextField
                                    variant={"outlined"}
                                    size={"small"}
                                    fullWidth
                                    disabled={inProgress}
                                    placeholder={"Type a message"}
                                    value={message}
                                    onKeyUp={onKeyUp}
                                    onChange={(e) =>
                                        setMessage(e.target.value ?? "")
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={"end"}>
                                                <SendOutlined
                                                    onClick={() =>
                                                        doSendMessage()
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </Box>
                    </>
                )}

                {inProgress && <LinearProgress />}
            </Dialog>
        </Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default withUser(ChatModal);
