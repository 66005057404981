import React, {useCallback, useEffect, useState} from 'react';
import CardWithTitleAndAction from "../CardWithTitleAndAction";
import {useDispatch, useSelector} from "react-redux";
import {clientConfigSelector, getClientConfig, putClientSection} from "../../../slices/clientConfig";
import Api from "../../../helpers/api";
import {setNotification} from "../../../slices/notification";
import RequestError from "../../../helpers/RequestError";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";

const ContactsSettings = () => {
    const [admins, setAdmins] = useState([]);
    const dispatch = useDispatch();
    const {
        clientConfig,
        isClientConfigLoading
    } = useSelector(clientConfigSelector);
    const getAdmins =useCallback(()=>{
        Api.getUsers(1, 1000, null, null, 3)(
            ({data}) => setAdmins(data.items.map(user => {
                user.label = `${user.first_name} ${user.last_name}`;
                user.value = user.user_id;
                return user;
            })),
            (error) => dispatch(setNotification((new RequestError(error)).getMessage(), "error"))
        ).then(() => {

        });
    },[dispatch])
    const [initialized, setInitialized] = useState(false);

    const {client:{brand:{sales_manager, account_manager}}} = clientConfig;
    const [hasChanges, setHasChanges] = useState(false);
    const [salesManager, setSalesManager] = useState(null);
    const [accountManager, setAccountManager] = useState(null);
    useEffect(() => {
        if(!initialized){
            setInitialized(true);
            setAccountManager(account_manager);
            setSalesManager(sales_manager);
            getAdmins();
        }
    }, [sales_manager, account_manager, initialized, getAdmins]);
    const onChangeSalesManager = (event, user) =>{
        setSalesManager(user?.user_id || null);
        setHasChanges(!(user?.user_id === sales_manager && accountManager === account_manager));
    }
    const onChangeAccountManager = (event, user) =>{
        setAccountManager(user?.user_id || null);
        setHasChanges(!(salesManager === sales_manager && user?.user_id === account_manager));
    }
    const getValue = user_id =>{
        if(admins.length > 0){
            const userFound = admins.find(admin=>admin.user_id === user_id);
            if(userFound){
                return {...userFound, value: userFound.user_id, label: `${userFound.first_name} ${userFound.last_name}`};
            }
            return null;
        }
        return null;
    }
    const Save = () => {
        const clientSectionUpdated = { ...clientConfig.client, brand: {
                ...clientConfig.client.brand,
                account_manager: accountManager,
                sales_manager: salesManager
            } };
        Promise.resolve(dispatch(putClientSection(clientSectionUpdated.hash, clientSectionUpdated))).then(() => {
            dispatch(getClientConfig(clientSectionUpdated.hash)).then(()=>{
                setHasChanges(false);
            });
        });
    }

    const disableActions = !hasChanges || isClientConfigLoading;
    return <CardWithTitleAndAction title={'Contacts'} disableAction={disableActions} onClickAction={Save} inProgress={isClientConfigLoading}>
        <Autocomplete
            getOptionLabel={(option) => option?.label ?? ""}
            renderInput={(params) => (
                <TextField variant="outlined"  size="small" label={'Sales manager'}
                           margin={"normal"}
                           fullWidth {...params}
                           InputLabelProps={{shrink: true, ...params.InputLabelProps}}/>
            )}
            options={admins} value={admins.length > 0  ? getValue(salesManager) : null} sort label={'Sales manager'} placeholder={'Sales manager'} onChange={onChangeSalesManager} />
        <Autocomplete
            getOptionLabel={(option) => option?.label ?? ""}
            renderInput={(params) => (
                <TextField variant="outlined"  size="small" label={'Account manager'}
                           margin={"normal"}
                           fullWidth {...params}
                           InputLabelProps={{shrink: true, ...params.InputLabelProps}}/>
            )}
            options={admins} value={admins.length > 0  ? getValue(accountManager) : null} sort label={'Account manager'} placeholder={'Account manager'} onChange={onChangeAccountManager} />
    </CardWithTitleAndAction>
}
export default ContactsSettings;