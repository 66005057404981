import environment from './environment';

const proto = window.location.protocol;

const routes = {
    getUserServiceUrl: (path = '') => `//${environment.getUserServiceHost()}/v3/api/user${path}`,
    getUserServiceLogoutUrl: () => '//' + environment.getLoginAppHost() + '/logout',
    getLoginUrl: (continueUrl = null) => `${proto}//${environment.getLoginAppHost()}/${continueUrl ? `?continue=${continueUrl}` : ''}`,
    getBackofficeServiceUrl: (path = '') => `//${environment.getAppApiHost()}/v3/api/backoffice${path}`,
    getClientServiceUrl: (path = '') => `//${environment.getClientServiceHost()}/v3/api/client${path}`,
    getShortLinkServiceUrl: (path = '') => `//${environment.getAppApiHost()}/v3/api/short-link${path}`,

    getCommunicationsApiUrl: (path = '') => `//${environment.getCommunicationServiceHost()}/v3/api/communication${path}`,

    getWebSocketMessagesApiUrl: (path = '') => `${proto === "https:" ? "wss:" : "ws:"}//${environment.getMessagesServiceHost()}/v3/api/im${path}`,
    getMessagesApiUrl: (path = '') => `${proto}//${environment.getMessagesServiceHost()}/v3/api/im${path}`,
    createWebsocketConnectionUrl: (userId, roleId, clientId = null) => routes.getWebSocketMessagesApiUrl(`/ws?user_id=${userId}&role_id=${roleId}${clientId ? `&client_id=${clientId}` : ""}&_=${Math.random()}`),

    getAppApiUrl: (path = '') => `//${environment.getAppApiHost()}/api${path}`,
    getAnalyticsApiUrl: (path = '') => `//${environment.getAnalyticsApiHost()}/v2/api/analytics${path}`,

    getAnalyticsStatsPerClientUrl: (startDate, endDate) => routes.getAnalyticsApiUrl(`/reports/per_client_stats?start=${startDate}&end=${endDate}`),

    getAdminSubscriptionsUrl: () => '/subscriptions',
    getAdminUsersUrl: () => '/users',
    getAccountAdminUsersUrl: () => '/account-admin',
    getAdminSettingsUrl: () => '/settings',
    getAdminMonitoringUrl: () => '/monitoring',

    getCoreUiReportsPageUrl: () => '/platform-operational-tests',
    getAnalyticsMonitoringPageUrl: () => '/analytics-monitoring',
    getLobbyMetricsPageUrl: () => '/lobby',

    getAdminWidgetTemplatesUrl: () => '/widget-templates',
    getAdminClientSummaryUrl: () => '/analytics',

    getAdminClientsUrl: () => '/clients',
    getAdminClientDetailUrl: (clientId) => `/clients/${clientId ?? ":clientId"}`,
    getAdminClientInvoiceDetailUrl: (clientId, invoiceId) => `/clients/${clientId ?? ":clientId"}/invoices/${invoiceId ?? ":invoiceId"}`,
    getAdminInvoicePrintPreview: (invoiceId) => `/invoice/${invoiceId ?? ":invoiceId"}/print`,
    getAdminUserDetailsUrl: (userId)  => `/users/${userId ?? ":userId"}/details`,
    getAdminUserRolesUrl: (userId)  => `/users/${userId ?? ":userId"}/roles`,

    getClientConfigUrl: (clientHash, full) => `${routes.getClientServiceUrl()}/hash/${clientHash}?full=${full ? "true" : "false"}`,
    getRootClientConfigSectionUrl: (section) => `${routes.getClientServiceUrl()}/root/${section}`,
    getClientConfigSectionUrl: (clientHash, section) => `${routes.getClientServiceUrl()}/hash/${clientHash}/${section}`,

    getAdminMarketplaceBrandsUrl: () => `/marketplace/brands`,
    getAdminMarketplaceCategoriesUrl: () => `/marketplace/categories`,
    getPaymentApprovalUrl: () => '/payment/approval',
    getPaymentStatusUrl: () => '/payment/status',
    getBrandCampaignAdmin: (path)=> `/campaign-admin${path}`,
    getBrandCampaignStatusReport: ()=> `/campaign-status-report`,
    getCampaignsServiceUrl: (path = '') => `//${environment.getAppApiHost()}/v3/api/campaigns${path}`,
    getAdminPlansUrl: () => '/plans',

    getAdminCommunicationsUrl: () => `/communications`,
    getAdminMessagesUrl: () => `/messages`,

    getKnowledgeBaseUrl: () => `https://tvpage.atlassian.net/wiki/spaces/CKB/overview`,

    messages: {

    }
};

export default routes;
